import React, { useState, useEffect, isValidElement } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FloatingLabel, Modal } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { Link, useLocation } from "react-router-dom";
import adminhttp from "../../../adminhttp";
import Swal from "sweetalert2";
import Divider from "@mui/material/Divider";
import Spinner from 'react-bootstrap/Spinner';

function AddEventCategory(props) {
  const [validated, setValidated] = useState(false);

  const [catName, setCatName] = useState("");
  const [catDesn, setCatDesn] = useState("");

  const [roleName, setRoleName] = useState("");
  const [status, setStatus] = useState("");
  const [position, setPosition] = useState("");

  const location = useLocation();
  const data = location.state;
  const splitURL = location.pathname.toString().split("/");
  const [progress, setProgress] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === true) {
      setProgress(true)
      const submitData = new FormData();
      submitData.append("name", formData.name);
      submitData.append("description", formData.description);

      if(props.edit===true){
        submitData.append("id", props.eventCatID);
        adminhttp()
        .post(`/admin/updatepavilioneventcatagory`, submitData)
        .then((response) => {
          Swal.fire({
            title: 'Event Category updated successfully!',
            icon: "success",
            allowOutsideClick: false,
            confirmButtonColor: "#e30613",
          });
          setProgress(false);
          props.close();
          props.handleRequest();
        })
        .catch((error) => {
          console.error("There was an error submitting the form!", error);
        });

        
      } else {
        submitData.append("pavilion_id", splitURL[3]);
        adminhttp()
        .post("/admin/createpavilioneventcatagory", submitData)
        .then((response) => {
          Swal.fire({
            title: 'Event Category added successfully!',
            icon: "success",
            allowOutsideClick: false,
            confirmButtonColor: "#e30613",
          });
          setProgress(false);
          props.close();
          props.handleRequest();
        })
        .catch((error) => {
          console.error("There was an error submitting the form!", error);
        });
      }
      }
  };

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setFormData({ ...formData, logo: files[0] }); // Store file object
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  const handleRequest = () => {
   adminhttp().get(`/admin/singlepavilioneventcatagory/${props.eventCatID}`).then((data) => {
    if (data.data.data.EventCategory) {
          setFormData({
            name: data.data.data.EventCategory.name,
            description: data.data.data.EventCategory.description,
          });
        }
      });
  };

  useEffect(() => {
    if(props.edit){
      handleRequest();
    } else {
      setFormData({
        name: "",
        description: "",
      });
    }
    
  }, [props.edit, props.eventCatID]);
  return (
    <Modal centered backdrop="static" show={props.show} onHide={props.close}>
      <Modal.Header className="py-1 my-1" closeButton>
      <Modal.Title>{props.edit===true ? 'Edit' : 'Add'} Event Category</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            id="eventCategoryForm"
          >
            <Form.Group controlId="validationCustom01" className="mb-1">
            <Form.Control
                name="name"
                required
                type="text"
                className={Object.values(formData.name).length > 0 ? "is-valid" : "is-invalid"}
                placeholder="Event Category Name"
                value={formData.name && formData.name}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="validationCustom02" className="mb-1">
            <Form.Control
            name='description'
            rows={3}
            as='textarea'
            placeholder="Event Category Description"
            value={formData.description && formData.description}
            onChange={handleChange}
          />
            </Form.Group>

          </Form>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.close}>
          Close
        </Button>
        <Button variant="primary" type="submit" form="eventCategoryForm">
        {progress && (<Spinner size='sm' className="me-2" animation="border" />)} Save Event Category
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddEventCategory;

