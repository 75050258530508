import React, { useState, useEffect } from "react";
import AdminLayout from "../layouts/AdminLayout";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import VideoCameraFrontIcon from "@mui/icons-material/VideoCameraFront";
import Banner from "../../assets/img/banner-1.png";
import Group from "@mui/icons-material/Group";
import PavilionNavBar from "../../components/pavilion/PavilionNavBar";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { Container, Stack, ListGroup } from "react-bootstrap";
import PavilionLeftSidebar from "../../components/pavilion/PavilionLeftSidebar";
import PavilionHeader from "../../components/pavilion/PavilionHeader";
import PavilionRightSidebar from "../../components/pavilion/PavilionRightSidebar";
import PavilionIcon from "@mui/icons-material/AccountBalanceOutlined";
// import AboutTab from './PavilionTabs/AboutTab';
// import WallPostTab from './PavilionTabs/WallPostTab';
import { Outlet } from "react-router-dom";
import adminhttp from "../../adminhttp";
import LineCharts from "../components/AdminCharts/LineCharts";
import Paper from "@mui/material/Paper";
import AreaCharts from "../components/AdminCharts/AreaCharts";
import Grid from "@mui/material/Grid";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import UsersIcon from '@mui/icons-material/PeopleAlt';

const data = [
  { name: "Page A", uv: 4000, pv: 2400, user: 2400 },
  { name: "Page B", uv: 3000, pv: 1398, user: 2210 },
  { name: "Page C", uv: 2000, pv: 9800, user: 2290 },
  { name: "Page D", uv: 2780, pv: 3908, user: 2000 },
  { name: "Page E", uv: 1890, pv: 4800, user: 2181 },
  { name: "Page F", uv: 2390, pv: 3800, user: 2500 },
  { name: "Page G", uv: 3490, pv: 4300, user: 2100 },
];

const data2 = [
  { name: "Page A", uv: 4000, pv: 2400, user: 2400 },
  { name: "Page B", uv: 3000, pv: 1398, user: 1800 },
  { name: "Page C", uv: 2000, pv: 9800, user: 1500 },
  { name: "Page D", uv: 2780, pv: 3908, user: 2800 },
  { name: "Page E", uv: 1890, pv: 4800, user: 2181 },
];

const data3 = [
  { name: "Page A", uv: 4000, pv: 2400, user: 1500 },
  { name: "Page B", uv: 3000, pv: 1398, user: 2000 },
  { name: "Page C", uv: 2000, pv: 9800, user: 2500 },
];

const sales = [
  { name: "Jan", salary: 2400, user: 4400 },
  { name: "Feb", salary: 1398, user: 2210 },
  { name: "Mar", salary: 7600, user: 1290 },
  { name: "Apr", salary: 3908, user: 2000 },
  { name: "May", salary: 4800, user: 2181 },
  { name: "June", salary: 3800, user: 2500 },
  { name: "July", salary: 4300, user: 2100 },
  { name: "Aug", salary: 9860, user: 3100 },
  { name: "Sep", salary: 3440, user: 2100  },
  { name: "Oct", salary: 4300 , user: 2100 },
  { name: "Nov", salary: 5460, user: 5000 },
  { name: "Dec", salary: 4300, user: 2100 },
];

  const salesToday=[{moneyIn: 100, time: '09:00 AM'},
                    {moneyIn: 140, time: '10:00 AM'},
                    {moneyIn: 550, time: '11:00 AM'},
                    {moneyIn: 340, time: '12:00 AM'},
                    {moneyIn: 450, time: '01:00 PM'},
   ];

   
function Dashboard(props) {
  const [showAbout, setShowAbout] = useState(props.showAbout);
  const [showWallPost, setShowWallPost] = useState(props.showWallPost);
  const [showLS, setShowLS] = useState(true);

  const [roleName, setRoleName] = useState("");
  const [totalPavilions, setTotalPavilions] = useState("");
  const [activePavilions, setActivePavilions] = useState("");
  const [inActivePavilions, setInActivePavilions] = useState("");
  const [activeUsers, setActiveUsers] = useState("");
  const [inActiveUsers, setInActiveUsers] = useState("");
  const [TodaySignupUsers, setSetTodaySignupUsers] = useState("");

  function handleToggle1(evt) {
    if (showLS) {
      setShowLS(false);
    } else {
      setShowLS(true);
    }
  }

  function animateCounters() {
    const counters = document.querySelectorAll(".counter");
    counters.forEach((counter, index) => {
      const target = parseInt(counter.getAttribute("data-target"));
      const duration = 1000;
      const step = Math.ceil((target / duration) * 15);
      let current = 0;
      const updateCounter = () => {
        current += step;
        if (current <= target) {
          counter.innerText = current;
          requestAnimationFrame(updateCounter);
        } else {
          counter.innerText = target;
        }
      };
      setTimeout(() => {
        updateCounter();
      }, index * 1000);
      // Delay each counter by 1 second
    });
  }
  useEffect(() => {
    animateCounters();
  }, []);
  // totalPavilions,
  // activePavilions,
  // inActivePavilions,
  // activeUsers,
  // inActiveUsers,
  useEffect(() => {
    try {
      adminhttp().get("/admin/dashboard").then((data) => {
        setRoleName(data.data.data.roleName);
        setTotalPavilions(data.data.data.totalPavilions);
        setActivePavilions(data.data.data.totalActivePavilions);
        setInActivePavilions(data.data.data.totalInactivePavilions);
        setActiveUsers(data.data.data.totalActiveUsers);
        setInActiveUsers(data.data.data.totalInActiveUsers);
        setSetTodaySignupUsers(data.data.data.signup);
      })
      } catch (e) {
      console.log('Error')
      }
  },[]);

  return (
    <AdminLayout>
      <Container fluid className="bg-light h-100 p-0">
        <ListGroup variant="flush">
          <ListGroup.Item className="bg-light border-0 pt-3 px-4 ">
            <h4>Dashboard</h4>
          </ListGroup.Item>
          {/* <ListGroup.Item style={{background: 'linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 54%, rgba(227,6,19,1) 54%, rgba(227,6,19,1) 100%)'}}> */}
          <ListGroup.Item className="bg-light">
            <div className="container mt-0 ">
              <div
                className="row  
                    justify-content-center"
              >
                <div className="col-md-3">
                  <Card className="bg-primary text-center rounded-3">
                    <Card.Header className="d-flex justify-content-between pb-0 bg-primary border-0 rounded-3">
                      <Grid
                        container
                        spacing={2}
                        className="text-light pt-3 pb-0 px-3"
                      >
                        <Grid xs={8} className="text-start">
                          <h5>Today Signups</h5>
                        </Grid>
                        <Grid xs={4} className="text-end">
                        <h6>
                          <ShowChartIcon /> {TodaySignupUsers <= 1 ? `${TodaySignupUsers} user` : `${TodaySignupUsers} users`}
                        </h6>
                        </Grid>
                      </Grid>
                    </Card.Header>
                    <AreaCharts data={data} />
                  </Card>
                </div>
                <div className="col-md-3">
                  <Card className="bg-warning text-center rounded-3">
                    <Card.Header className="d-flex justify-content-between bg-warning border-0 pb-0 rounded-3">
                      <Grid
                        container
                        spacing={2}
                        className="text-light pt-3 pb-0 px-3"
                      >
                        <Grid xs={8} className="text-start">
                          <h5>Online Users</h5>
                        </Grid>
                        <Grid xs={4} className="text-end">
                          <h6>
                            <ShowChartIcon /> 10%
                          </h6>
                        </Grid>
                      </Grid>
                    </Card.Header>
                    <AreaCharts data={data2} />
                  </Card>
                </div>
                <div className="col-md-3">
                  <Card className="bg-info text-center rounded-3">
                    <Card.Header className="d-flex justify-content-between pb-0 bg-info border-0 rounded-3">
                      <Grid
                        container
                        spacing={2}
                        className="text-light pt-3 pb-0 px-3"
                      >
                        <Grid xs={8} className="text-start">
                          <h5>Total Users</h5>
                        </Grid>
                        <Grid xs={4} className="text-end">
                          <h6>
                            <ShowChartIcon /> 40%
                          </h6>
                        </Grid>
                      </Grid>
                    </Card.Header>
                    <AreaCharts data={data3} />
                  </Card>
                </div>
                <div className="col-md-3">
                  <Card className="bg-success text-center rounded-3">
                    <Card.Header className="d-flex justify-content-between pb-0 bg-success border-0 rounded-3">
                      <Grid
                        container
                        spacing={2}
                        className="text-light pt-3 pb-0 px-3"
                      >
                        <Grid xs={8} className="text-start">
                          <h5>Total Sales</h5>
                        </Grid>
                        <Grid xs={4} className="text-end">
                          <h6>
                            <ShowChartIcon /> £400k
                          </h6>
                        </Grid>
                      </Grid>
                    </Card.Header>
                    <AreaCharts data={sales} />
                  </Card>
                </div>
                <div className="col-md-3">
                  <Card className="bg-dark text-center rounded-3">
                    <Card.Header className="d-flex justify-content-between pb-0 bg-dark border-0 rounded-3">
                      <Grid
                        container
                        spacing={2}
                        className="text-light pt-3 pb-0 px-3"
                      >
                        <Grid xs={8} className="text-start">
                          <h5>Today Sales</h5>
                        </Grid>
                        <Grid xs={4} className="text-end">
                          <h6>
                            <ShowChartIcon /> £100k
                          </h6>
                        </Grid>
                      </Grid>
                    </Card.Header>
                    <LineCharts data={salesToday} />
                  </Card>
                </div>
                <div className="col-md-3">
                  <Card style={{background: 'linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%)'
}}
                    className="text-center bg-light  border-light border border-1
                            text-dark rounded p-3" 
                  >
                    <Card.Body className="pb-0 text-light">
                      <div className="d-flex justify-content-between align-items-center">
                        <PavilionIcon fontSize="large" />
                        <h1
                          className="card-text fs-1 counter "
                          data-target={totalPavilions}
                        >
                          {totalPavilions}
                        </h1>
                      </div>
                      <Card.Title className="text-light fs-5 my-3 ">Total Pavilions</Card.Title>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-3">
                  <Card style={{background: 'linear-gradient(43deg, #ee3300 0%, #ff7575 46%, #ab0000 100%)'}}
                    className="text-center bg-light  border-light border border-1
                            text-dark rounded p-3"
                  >
                    <Card.Body className="pb-0 text-light ">
                      <div className="d-flex justify-content-between align-items-center">
                        <PavilionIcon fontSize="large" />
                        <h1
                          className="card-text fs-1 counter "
                          data-target={activePavilions}
                        >
                          {activePavilions}
                        </h1>
                      </div>
                      <Card.Title className="fs-5 my-3 text-light">Active Pavilions</Card.Title>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-3">
                  <Card style={{background:'linear-gradient(43deg, #a0a0a0 0%, #bfbfbf 46%, #646464 100%)'}}
                    className="text-center bg-light  border-light border border-1
                            text-dark rounded p-3"
                  >
                    <Card.Body className="pb-0 text-light">
                      <div className="d-flex justify-content-between align-items-center">
                        <PavilionIcon fontSize="large" />
                        <h1
                          className="card-text fs-1 counter "
                          data-target={inActivePavilions}
                        >
                          {inActivePavilions}
                        </h1>
                      </div>
                      <Card.Title className="fs-5 my-3 text-light ">Inactive Pavilions</Card.Title>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-3">
                  <Card style={{background:'linear-gradient(43deg, #4158D0 0%, #508eff 46%, #070092 100%)'}}
                    className="text-center bg-light  border-light border border-1
                            text-dark rounded p-3"
                  >
                    <Card.Body className="pb-0 text-light">
                      <div className="d-flex justify-content-between align-items-center">
                        <UsersIcon fontSize="large" />
                        <h1
                          className="card-text fs-1 counter "
                          data-target={activeUsers}
                        >
                          {activeUsers}
                        </h1>
                      </div>
                      <Card.Title className="fs-5 my-3 text-light">Active Members</Card.Title>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-md-3">
                  <Card style={{background:'linear-gradient(43deg, #a0a0a0 0%, #bfbfbf 46%, #646464 100%)'}}
                    className="text-center bg-light border-light border border-1
                            text-dark rounded p-3"
                  >
                    <Card.Body className="pb-0 text-light">
                      <div className="d-flex justify-content-between align-items-center">
                      <UsersIcon fontSize="large" />
                        <h1
                          className="card-text fs-1 counter"
                          data-target={inActiveUsers}
                        >
                          {inActiveUsers}
                        </h1>
                      </div>
                      <Card.Title className="fs-5 my-3 text-light">Inactive Members</Card.Title>
                    </Card.Body>
                  </Card>
                </div>
                
              </div>
            </div>
          </ListGroup.Item>
        </ListGroup>
      </Container>
    </AdminLayout>
  );
}

export default Dashboard;
