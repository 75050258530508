import React, {useState} from "react";
import { styled } from '@mui/material/styles';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Link, useNavigate } from 'react-router-dom'
import { formatDate } from '../../../components/PavilionConstants';
import {
  Form,
  Container,
  Button,
  Stack,
  ListGroup,
  ButtonGroup,
  Row, Col, Image
} from "react-bootstrap";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
// import Card from "react-bootstrap/Card";
import BadgeAvatar from "../../../components/BadgeAvatar";
import Grid from "@mui/material/Grid";
import AdminBreadCrumbs from '../AdminBreadCrumbs';
import Card from "@mui/material/Card";
import {CardMedia} from "@mui/material";
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Badge from 'react-bootstrap/Badge';
import { DateDiff } from "../../../components/PavilionConstants";
import { Pagination, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/DriveFileRenameOutlineTwoTone';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CRUDDropdown from "../../../components/CRUDDropdown";
import DeactivateIcon from '@mui/icons-material/Unpublished';
import ActivateIcon from '@mui/icons-material/CheckCircle';
import Swal from 'sweetalert2'
import adminhttp from "../../../adminhttp";

const EventCatCard = (props) => {
  const handleEdit = () => {
    //setShow(true)
  };
  const handleDelete = (e) => {
      e.stopPropagation();
      Swal.fire({
        title: "Are you sure to delete this Event Category?",
        text: "You won't be able to revert this!",
        icon: "warning",
        width: 'auto',
        showCancelButton: true,
        confirmButtonColor: "#e30613",
        cancelButtonColor: "#6c757d",
        confirmButtonText: "Yes, delete it!"
      }).then((result) => {
        if (result.isConfirmed) {
          adminhttp().get(`/admin/deletepavilioneventcatagory/${props.data.id}`).then((data) => {
          Swal.fire({
            title: "Event Category has been deleted.",
            icon: "success",
            allowOutsideClick: false,
            confirmButtonColor: "#e30613",
          }).then((result) => {
            if (result['isConfirmed']){
              props.handleRequest();
            }
          });
        });
          
        }
      });
  };
  const handleDropdown = () => {
    //setShow(true)
  };

  return (

    <Card className={`rounded-3 bg-opacity-10 ${props.data.status===1 ? ' bg-primary ' : ' bg-secondary'}`} sx={{":hover": { boxShadow: 5 } }}>
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height:'120px',position: "relative" }}
    >
  <CardContent className='p-2 d-flex justify-content-center align-items-center'>
  <Typography variant="h5" component="h4">
  {props.data.name}
      </Typography>
      </CardContent>
      <div
        className="text-end p-2 rounded-5"
        style={{
          backgroundColor: "transparent",
          position: "absolute",
          color: "white",
          top: 0,
          right: "0",
          transform: "translateX(0)",
        }}
      >
        <CRUDDropdown
          title={<MoreVertIcon fontSize="small" />}
          handleDropdown={handleDropdown}
          addTitle={<span><EditIcon fontSize='small'/> Edit</span>}
          addClick={props.handleEdit(props.data.id)}
          editTitle={<span><DeleteIcon fontSize='small'/> Delete</span>}
          editClick={handleDelete}
          // deleteTitle={
          //   props.data.status === 1
          //     ? (<span><ActivateIcon fontSize='small'/> Activate</span>)
          //     : (<span><DeactivateIcon fontSize='small'/> Deactivate</span>)
          // }
          // deleteClick={handleStatus}
        />
      </div>
    </div>
  </Card>

  
  )
}

export default EventCatCard
