import React, { useState, useEffect, isValidElement } from "react";
import Icon from '@mui/material/Icon';
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FloatingLabel, Modal } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { Link, useLocation } from "react-router-dom";
import adminhttp from "../../../adminhttp";
import Swal from "sweetalert2";
import Divider from "@mui/material/Divider";
import Spinner from "react-bootstrap/Spinner";
import Grid from "@mui/material/Grid";
import { CKEditor, ImageInsert } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Avatar from "@mui/material/Avatar";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { experimentalStyled as styled } from "@mui/material/styles";
import MuiButton from "@mui/material/Button";
import IconResolver from './IconResolver';

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function AddPlan(props) {
  const [validated, setValidated] = useState(false);
  const [progress, setProgress] = useState(false);

  const location = useLocation();
  const data = location.state;
  const splitURL = location.pathname.toString().split("/");

  const [iconFile, setIconFile] = useState("");

  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [productType, setProductType] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("");
  const [membershipPlan, setMembershipPlan] = useState("");
  const [imgFile, setImgFile] = useState("");

  const [show, setShow] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    price: "",
    type:"",
    stripeProductID: "",
    stripePriceID: "",
    description: "",
    icon:"",
    status: "",
    id:"",
  });

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setFormData({ ...formData, icon: files[0] }); // Store file object
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    setValidated(true);

    if (form.checkValidity() === true) {
      setProgress(true);
      
      const submitData = new FormData();
      submitData.append("name", formData.name);
      submitData.append("price", formData.price);
      submitData.append("type", formData.type);
      submitData.append("stripe_product_id", formData.stripeProductID);
      submitData.append("stripe_price_id", formData.stripePriceID);
      submitData.append("description", formData.description);
      submitData.append("icon", formData.icon);
      submitData.append("status", formData.status);

      if(props.edit){
        submitData.append("id", props.planID);  
        adminhttp().post(`/admin/updatepackageplan`, submitData).then((response) => {
          Swal.fire({
            title: 'Plan updated successfully!',
            icon: "success",
            allowOutsideClick: false,
            confirmButtonColor: "#e30613",
          });
          setProgress(false);
          props.close();
          props.handleRequest();
        })
        .catch((error) => {
          console.error("There was an error submitting the form!", error);
        });
       
      } else {
        
        adminhttp().post("/admin/createpackageplan", submitData).then((response) => {
          Swal.fire({
            title: 'New plan added successfully!',
            icon: "success",
            allowOutsideClick: false,
            confirmButtonColor: "#e30613",
          });
          setProgress(false);
          props.close();
          props.handleRequest();
        })
        .catch((error) => {
          console.error("There was an error submitting the form!", error);
        });
      }
    }
  };

  const handleRequest = () => {
    adminhttp().get(`/admin/singlepackageplan/${props.planID}`).then((data) => {
        setFormData({
            name: data.data.data.packages.name,
            price: data.data.data.packages.price,
            type: data.data.data.packages.type,
            stripeProductID: data.data.data.packages.stripe_product_id,
            stripePriceID: data.data.data.packages.stripe_price_id,
            description: data.data.data.packages.description,
            icon: data.data.data.packages.icon,
            status: data.data.data.packages.status,
         });
      });
  };

  useEffect(() => {
    if(props.edit){
      handleRequest();
    } else {
      setFormData({
        name: "",
    price: "",
    type:"",
    stripeProductID: "",
    stripePriceID: "",
    description: "",
    icon:"",
    status: "",
    id:"",
      });
    }
    
  }, [props.edit, props.planID]);


  return (
    <Modal
      size="lg"
      centered
      backdrop="static"
      show={props.show}
      onHide={props.close}
    >
      <Modal.Header className="py-1 my-1" closeButton>
        <Modal.Title>{props.edit ?'Edit':'Add'} Plan</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
        
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            id="planForm"
          >
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Form.Group controlId="" className="mb-2">
                  <Form.Control
                    name="name"
                    required
                    type="text"
                    className={
                      Object.values(formData.name).length > 0
                        ? "is-valid"
                        : "is-invalid"
                    }
                    placeholder="Plan Name"
                    value={formData.name && formData.name}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="" className="mb-2">
                  <Form.Control
                    name="price"
                    required
                    type="text"
                    className={
                      Object.values(formData.price).length > 0
                        ? "is-valid"
                        : "is-invalid"
                    }
                    placeholder="Plan Price"
                    value={formData.price && formData.price}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="" className="mb-2">
                  <Form.Select
                    name="type"
                    placeholder="Plan Type"
                    required
                    value={formData.type && formData.type}
                    onChange={handleChange}
                  >
                    <option value="monthly">Monthly</option>
                    <option value="annually">Yearly</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group controlId="" className="mb-2">
                  <Form.Select
                    name="status"
                    placeholder="Status"
                    required
                    value={formData.status && formData.status}
                    onChange={handleChange}
                  >
                    <option value="1">Active</option>
                    <option value="0">InActive</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group controlId="" className="mb-2">
                  <Form.Control
                    name="stripe-product-id"
                    required
                    type="text"
                    // className={
                    //   Object.values(formData.stripeProductID).length > 0
                    //     ? "is-valid"
                    //     : "is-invalid"
                    // }
                    placeholder="Stripe Product ID"
                    value={formData.stripeProductID && formData.stripeProductID}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="" className="mb-2">
                  <Form.Control
                    name="stripe-price-id"
                    required
                    type="text"
                    // className={
                    //   Object.values(formData.stripePriceID).length > 0
                    //     ? "is-valid"
                    //     : "is-invalid"
                    // }
                    placeholder="Stripe Price ID"
                    value={formData.stripePriceID && formData.stripePriceID}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="" className="mb-1 d-flex">
                <Form.Control
                    name="icon"
                    required
                    type="text"
                    // className={
                    //   Object.values(formData.icon).length > 0
                    //     ? "is-valid"
                    //     : "is-invalid"
                    // }
                    placeholder="Icon"
                    value={formData.icon && formData.icon}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Grid>
              <Grid item xs={6} md={6}>
                
                <Form.Group controlId="" className="mb-1">
                  <CKEditor
                    editor={ClassicEditor}
                    data={formData.description && formData.description}
                    onReady={(editor) => {
                      editor.editing.view.change((writer) => {
                        writer.setStyle(
                          "min-height",
                          "150px",
                          editor.editing.view.document.getRoot()
                        );
                      });

                      // You can store the "editor" and use when it is needed.
                      console.log("Further Description", editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setFormData({ ...formData, description: data });
                      console.log({ event, editor, data });
                    }}
                    onBlur={(event, editor) => {
                      console.log("Blur.", editor);
                    }}
                    onFocus={(event, editor) => {
                      console.log("Focus.", editor);
                    }}
                  />
                </Form.Group>
              </Grid>
            </Grid>
          </Form>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.close}>
          Close
        </Button>
        <Button
          variant="primary"
          type="submit"
          form="planForm"
        >
          {progress && (
            <Spinner size="sm" className="me-2" animation="border" />
          )} Save Plan
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddPlan;
