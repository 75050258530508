import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Link, useNavigate } from "react-router-dom";
import { formatDate } from "../../../components/PavilionConstants";
import {
  Form,
  Container,
  Button,
  Stack,
  ListGroup,
  ButtonGroup,
  Row,
  Col,
  Image,
} from "react-bootstrap";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
// import Card from "react-bootstrap/Card";
import BadgeAvatar from "../../../components/BadgeAvatar";
import Grid from "@mui/material/Grid";
import AdminBreadCrumbs from "../AdminBreadCrumbs";
import Card from "@mui/material/Card";
import { CardMedia } from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Badge from "react-bootstrap/Badge";
import { DateDiff } from "../../../components/PavilionConstants";
import { Pagination, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/DriveFileRenameOutlineTwoTone";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Chip from "@mui/material/Chip";
import Dropdown from "react-bootstrap/Dropdown";
import CRUDDropdown from "../../../components/CRUDDropdown";
import Swal from 'sweetalert2'
import adminhttp from '../../../adminhttp';
import { useLocation } from 'react-router-dom'

const BannerCard = (props) => {
  
  const location = useLocation();
  // const data = location.state;

  const splitURL = location.pathname.toString().split("/");
  
  const handleEdit = () => {
    //setShow(true)
  };
  const handleDelete = (e) => {
      e.stopPropagation();
      Swal.fire({
        title: "Are you sure to delete this Banner?",
        text: "You won't be able to revert this!",
        icon: "warning",
        width: 'auto',
        showCancelButton: true,
        confirmButtonColor: "#e30613",
        cancelButtonColor: "#6c757d",
        confirmButtonText: "Yes, delete it!"
      }).then((result) => {
        if (result.isConfirmed) {
          adminhttp().post('/admin/deletebanner', {
            pavilionid:splitURL[3],
            mobile_banner:props.data,
        }).then((data) => {
          
          Swal.fire({
            title: "Your Banner has been deleted.",
            icon: "success",
            allowOutsideClick: false,
            confirmButtonColor: "#e30613",
          });
        });
          
        }
      });
  };
  const handleDropdown = () => {
    //setShow(true)
  };

  return (
    <Card className="rounded-3 " sx={{ ":hover": { boxShadow: 5 } }}>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ position: "relative" }}
      >
        <CardMedia
          component="img"
          className="p-3 rounded-3"
          sx={{ width: "320px", height: "200px", objectFit: "contain" }}
          image={`${localStorage.getItem('bucketName')}/images/pavilion/${props.data}`}
        />
        <div
          className="text-end p-2 rounded-5"
          style={{
            backgroundColor: "#fff",
            position: "absolute",
            color: "white",
            top: 0,
            right: "0",
            transform: "translateX(0)",
          }}
        >
          <CRUDDropdown
            title={<MoreVertIcon fontSize="small" />}
            handleDropdown={handleDropdown}
            addTitle={`Edit Banner`}
            addClick={props.handleEdit(props.index, props.data)}
            editTitle="Delete"
            editClick={(e)=>handleDelete(e,props.data)}
            // deleteTitle={
            //   props.data.status === 1
            //     ? "Deactivate"
            //     : "Activate"
            // }
            // deleteClick={handleEdit}
          />
        </div>
      </div>
    </Card>
  );
};

export default BannerCard;
