import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';



const LineCharts = (props) => {
  return (
    <ResponsiveContainer width="100%" height={100}>
      <LineChart width={300} height={100} data={props.data}>
        <Line type="monotone" dataKey="moneyIn" stroke="#ffffff" strokeWidth={2} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LineCharts;
