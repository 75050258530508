import React, { useState, useEffect } from "react";
import AdminLayout from "../../layouts/AdminLayout";
import { Form, Container, ListGroup } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";

// import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import adminhttp from "../../../adminhttp";
// import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
// import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
// import Stack from '@mui/material/Stack'
// import Stack from "react-bootstrap/Stack";
// import Card from "react-bootstrap/Card";

// import Card from '@mui/material/Card';
// import CardHeader from "@mui/material/CardHeader";
// import CardMedia from "@mui/material/CardMedia";
// import CardContent from "@mui/material/CardContent";
// import CardActions from "@mui/material/CardActions";
// import Collapse from "@mui/material/Collapse";
// import Avatar from "@mui/material/Avatar";
// import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
// import { red } from "@mui/material/colors";
// import FavoriteIcon from "@mui/icons-material/Favorite";
// import ShareIcon from "@mui/icons-material/Share";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AdminBreadCrumbs from "../../components/AdminBreadCrumbs";
import { Link } from "react-router-dom";
// import Badge from '@mui/material/Badge';
// import Badge from "react-bootstrap/Badge";
// import { formatDate } from "../../../components/PavilionConstants";
// import DateIcon from "@mui/icons-material/InsertInvitation";
// import Dropdown from "react-bootstrap/Dropdown";
import { Button } from "react-bootstrap";
// import Button from "@mui/material/Button";
// import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation } from "react-router-dom";
// import EditIcon from "@mui/icons-material/DriveFileRenameOutlineTwoTone";
// import SettingsIcon from "@mui/icons-material/SettingsTwoTone";
// import MenusIcon from "@mui/icons-material/ListAltTwoTone";
// import SponsorsIcon from "@mui/icons-material/Diversity2TwoTone";
// import MultiBannerIcon from "@mui/icons-material/PermMediaTwoTone";
// import StoreIcon from "@mui/icons-material/StoreTwoTone";
// import RolesIcon from "@mui/icons-material/WorkTwoTone";
// import MembershipsIcon from "@mui/icons-material/PeopleAltTwoTone";
// import LibraryIcon from "@mui/icons-material/LibraryBooksTwoTone";
// import EventIcon from "@mui/icons-material/EventAvailableTwoTone";
// import EmailIcon from "@mui/icons-material/EmailTwoTone";
// import PinsIcon from "@mui/icons-material/PushPinTwoTone";
// import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import RoleCard from "../../components/AdminCards/RoleCard";
import AddRole from "../../components/AdminForms/AddRole";
import { ExportToExcel } from "../../components/ExportToExcel";
import { formatDate } from "../../../components/PavilionConstants";
// import Swal from 'sweetalert2'
// import Modal from 'react-bootstrap/Modal'

// const ExpandMore = styled((props) => {
//   const { expand, ...other } = props;
//   return <IconButton {...other} />;
// })(({ theme }) => ({
//   marginLeft: "auto",
//   transition: theme.transitions.create("transform", {
//     duration: theme.transitions.duration.shortest,
//   }),
//   variants: [
//     {
//       props: ({ expand }) => !expand,
//       style: {
//         transform: "rotate(0deg)",
//       },
//     },
//     {
//       props: ({ expand }) => !!expand,
//       style: {
//         transform: "rotate(180deg)",
//       },
//     },
//   ],
// }));

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: "#fff",
//   ...theme.typography.body2,
//   padding: theme.spacing(2),
//   textAlign: "center",
//   color: theme.palette.text.secondary,
//   ...theme.applyStyles("dark", {
//     backgroundColor: "#1A2027",
//   }),
// }));

// function handleAction(row) {
//   alert(row.id);
// }

const Roles = (props) => {

  const [exportData, setExportData] = useState([]);
  const today = new Date();
  const fileName = `Roles List ${formatDate(today)}`; // here enter filename for your excel file

  const location = useLocation();
  const data = location.state;

  const splitURL = location.pathname.toString().split("/");

  const [roles, setRoles] = useState([]);
  // const [pvlnName, setPvlnName] = useState("");
  // const [rows, setRows] = useState(0);
  // const [counter, setCounter] = useState(0);
  // const [pavilionName, setPavilionName] = useState("");
  // const navigate = useNavigate();

  const [value, setValue] = useState(''); 
  
  // const [show, setShow] = useState(false);
  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const [showModal, setShowModal] = useState(false);
  const [editRole, setEditRole] = useState(false);
  const [roleID, setRoleID] = useState();

  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to="/admin/dashboard">
      Dashboard
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      to="/admin/pavilions"
      state={data}
    >
      Pavilions
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      to={`/admin/pavilions/${splitURL[3]}`}
      state={data}
    >
      {data.name}
    </Link>,
    <Typography key="3" sx={{ color: "text.primary" }}>
      Roles
    </Typography>,
  ];

  // function handleClick(event) {
  //   event.preventDefault();
  //   console.info("You clicked a breadcrumb.");
  // }

  
  const handleSearch = (e) => {
    setValue(e.target.value);
    adminhttp().get(`/admin/search_role?search=${e.target.value}&pavilion_id=${splitURL[3]}`).then((data) => {
        // alert(data.data.data.length)
        if (data.data.data.length > 0) {
          setRoles(data.data.data);
        }
      }); 
  };

  
  const handleRequest = (e) => {
    adminhttp()
    .post(`/admin/roles?pavilion_id=${splitURL[3]}`)
    .then((data) => {
      if (data.data.data.roles.length > 0) {
        setLoading(true)
        setRoles(data.data.data.roles);
        setLoading(false)
      }else{
        setNoData(true)
        setLoading(false)
      }
    });
  }

  function handleAddRole() {
    setShowModal(true)
    setEditRole(false)
  }

  const handleEdit = (id) => (e) => {
    setRoleID(id)
    setEditRole(true)
    setShowModal(true)
  };

  useEffect(() => {
    handleRequest();    
  }, []);
  
  useEffect(() => {
    const fetchData = () =>{
      adminhttp().post(`/admin/roles?pavilion_id=${splitURL[3]}`).then((data) => {
      // reshaping the array
     const customHeadings = data.data.data.roles.map(role=>({
      "Role Id": role.id,
      "Role Name": role.name,
    }))
    setExportData(customHeadings) 
     })
    }
    fetchData()
  }, [])

  return (<>
    <AdminLayout>
      <Container fluid className="bg-light h-100">
        <Box component="section" sx={{ px: 2, pt: 2, pb: 1 }}>
          <AdminBreadCrumbs breadcrumbs={breadcrumbs} />
        </Box>

        <Box
          component="section"
          className="d-flex align-items-center justify-content-between header-menu"
          sx={{ px: 2, pt: 0, pb: 1 }}
          data-aos="fade-up"
        >
          <h4>Roles</h4>
          <div className="d-flex justify-content-end align-items-center">
            <Form className="me-2">
            <Form.Control
                type="search"
                size="sm"
                value={value}
                onChange={handleSearch}
                placeholder="Search Roles"
              />
            </Form>
            <Button
              variant="primary"
              type="button"
              size='sm'
              className='me-2'
              onClick={handleAddRole}
            >
              Add Role
            </Button>
            <ExportToExcel apiData={exportData} fileName={fileName}/>

            {/* <Box sx={{ alignItems: "center",}}>
                <Pagination className='my-3' count={totalPages} page={page}  variant="outlined" shape="rounded" onChange={handleChange}/>
           </Box> */}
          </div>
        </Box>

        <ListGroup variant="flush">
          <ListGroup.Item className="bg-transparent">
          {loading ? (
      <Box sx={{ flexGrow: 1, textAlign:'center' }}>
      <Spinner size='lg' className='me-2 ' animation='border' />
      </Box>
    ):(

              <Box sx={{ flexGrow: 1 }}>
                <Grid
                  container
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  {roles.map((role, index) => (
                    <Grid item xs={2} sm={2} md={2}>
                      <RoleCard
                          data={role}
                          handleRequest={handleRequest}
                          handleEdit={handleEdit}
                        />
                    </Grid>
                  ))}
                </Grid>
              </Box>
    )}  
            {noData && (
              <Box
                sx={{ flexGrow: 1 }}
                className="d-flex justify-content-center"
              >
                <Alert variant="outlined" severity="error">
                  Roles not found
                </Alert>
              </Box>
            )}
          </ListGroup.Item>
        </ListGroup>
      </Container>
    </AdminLayout>
    <AddRole handleRequest={handleRequest} show={showModal} edit={editRole} roleID={roleID} close={() => setShowModal(false)} />
</>
  );
};

export default Roles;
