import { useState, useEffect } from 'react';

export function useDeviceType() {
  const [device, setDevice] = useState('');

  useEffect(() => {
    function handleDeviceDetection() {
      const userAgent = navigator.userAgent.toLowerCase();
      const isIOS = /(iphone|ipad|ipod|ipad)/g.test(userAgent);
      const isAndroid = /(android)/g.test(userAgent);
    
      if (isIOS) setDevice('iOS');
      else if (isAndroid) setDevice('Android');
      else setDevice('Desktop');
    }

    handleDeviceDetection();
    window.addEventListener('resize', handleDeviceDetection);

    return () => window.removeEventListener('resize', handleDeviceDetection);
  }, []);

  return device;
}