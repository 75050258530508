import React, { useState, useEffect } from "react";
import AdminLayout from "../../layouts/AdminLayout";
import { Form, Container, ListGroup, Image } from "react-bootstrap";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import adminhttp from "../../../adminhttp";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Stack from "react-bootstrap/Stack";
import Card from "react-bootstrap/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AdminBreadCrumbs from "../../components/AdminBreadCrumbs";
import { Link } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import { formatDate } from "../../../components/PavilionConstants";
import DateIcon from "@mui/icons-material/InsertInvitation";
import Dropdown from "react-bootstrap/Dropdown";
import { Row, Col, Modal, Button } from "react-bootstrap";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation } from "react-router-dom";
import EditIcon from "@mui/icons-material/DriveFileRenameOutlineTwoTone";
import SettingsIcon from "@mui/icons-material/SettingsTwoTone";
import MenusIcon from "@mui/icons-material/ListAltTwoTone";
import SponsorsIcon from "@mui/icons-material/Diversity2TwoTone";
import MultiBannerIcon from "@mui/icons-material/PermMediaTwoTone";
import StoreIcon from "@mui/icons-material/StoreTwoTone";
import RolesIcon from "@mui/icons-material/WorkTwoTone";
import MembershipsIcon from "@mui/icons-material/PeopleAltTwoTone";
import LibraryIcon from "@mui/icons-material/LibraryBooksTwoTone";
import EventIcon from "@mui/icons-material/EventAvailableTwoTone";
import EmailIcon from "@mui/icons-material/EmailTwoTone";
import PinsIcon from "@mui/icons-material/PushPinTwoTone";
import { useNavigate } from "react-router-dom";
import SponsorCard from "../../components/AdminCards/SponsorCard";
import Alert from "@mui/material/Alert";
import RoleCard from "../../components/AdminCards/RoleCard";
import MembershipCard from "../../components/AdminCards/MembershipCard";
import AddMenu from "../../components/AdminForms/AddMenu";
import Swal from "sweetalert2";
import TicketCard from "../../components/AdminCards/TicketCard";
import AddTicket from "../../components/AdminForms/AddTicket";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import MenuCard from "../../components/AdminCards/MenuCard";
import Spinner from 'react-bootstrap/Spinner';
import Chip from "@mui/material/Chip";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CRUDDropdown from "../../../components/CRUDDropdown";
import InputGroup from "react-bootstrap/InputGroup";
import MuiButton from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
  variants: [
    {
      props: ({ expand }) => !expand,
      style: {
        transform: "rotate(0deg)",
      },
    },
    {
      props: ({ expand }) => !!expand,
      style: {
        transform: "rotate(180deg)",
      },
    },
  ],
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  ...theme.applyStyles("dark", {
    backgroundColor: "#1A2027",
  }),
}));

function handleAction(row) {
  alert(row.id);
}

const Menus = (props) => {
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  const data = location.state;
  const splitURL = location.pathname.toString().split("/");
  const [menus, setMenus] = useState([]);
  const [menusParent, setMenusParent] = useState([]);
  const [menusValues, setMenusValues] = useState([]);
  const [pvlnName, setPvlnName] = useState("");
  const [rows, setRows] = useState(0);
  const [counter, setCounter] = useState(1);
  const [pavilionName, setPavilionName] = useState("");
  const navigate = useNavigate();
  const [editMenu, setEditMenu] = useState(false);
  const [edit, setEdit] = useState(false);
  const [menuID, setMenuID] = useState();
  const [value, setValue] = useState("");
  const [filter, setFilter] = useState("0");
  const [show, setShow] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShow(true);
  const [filteredMenus, setFilteredMenus] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [expanded, setExpanded] = React.useState(false);
  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to="/admin/dashboard">
      Dashboard
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      to={`/admin/pavilions/${splitURL[3]}`}
      state={data && data}
    >
      {data && data.name}
    </Link>,
    <Typography key="3" sx={{ color: "text.primary" }}>
      Pavilion Menus
    </Typography>,
  ];
  const handleDropdown = (e) => {
    e.stopPropagation();
  };
  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = menus.filter((menu) =>
      menu.name.toLowerCase().includes(query)
    );
    setFilteredMenus(filtered);
  };
  const handleSelect = (e) => {
    setFilter(e.target.value);
  };
  const [menuData, setMenuData] = useState(null);
  const [apiPath, setApiPath] = useState();
  const handleRequest = () => {
    adminhttp().get(`/admin/menulist/${splitURL[3]}`).then((data) => {
      if (data.data.data.result.length > 0) {
        setMenus(data.data.data.result);
        setMenusParent(data.data.data.permissions);
        setMenusValues(data.data.data.result);
        setFilteredMenus(data.data.data.result);
        setCounter(1);
      }else{
        setCounter(0);
      }
    });
  };
  const [loginModalShow, setLoginModalShow] = useState(showModal);
  const [validated, setValidated] = useState(false);
  const [progress, setProgress] = useState(false);
  const [menu, setMenu] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    permissions: "",
    parent: "",
    order: "",
    status: 1,
    iconorder: "",
    iconimage: "",
    menuid: "",
    slug: "",
    mobilemenu: "",
  });
const [menuIcon, setMenuIcon]=useState();
  const handleChange = (e) => {
    
    const { name, value, type, files } = e.target;
    
    if (type === "file") {
      const url = URL.createObjectURL(files[0]);
      setMenuIcon(url);
      setFormData({ ...formData, iconimage: files[0] }); // Store file object
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  const [apiUrl, setApiUrl] = useState('');
  const [message, setMessage] = useState('');
  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === true) {
      setProgress(true)
      const submitData = new FormData();
      submitData.append("pavilion_id", splitURL[3]);
      submitData.append("name", formData.name);
      submitData.append("permission", formData.permissions);
      submitData.append("parent", formData.parent);
      submitData.append("order", formData.order);
      submitData.append("status", formData.status || 0);
      submitData.append("icon_order", formData.iconorder);
      if (formData.iconimage) {
        submitData.append("mobilemenu", formData.iconimage);
      }
      if (edit === true) {
        submitData.append("menu_id", menuID);
        submitData.append("slug", formData.slug);
        adminhttp()
          .post(`/admin/updatemenu`, submitData)
          .then((response) => {
            Swal.fire({
              title: 'Menu updated successfully!',
              icon: "success",
              allowOutsideClick: false,
              confirmButtonColor: "#e30613",
            });
            setProgress(false);
            setShowModal(false);
            handleRequest();
          })
          .catch((error) => {
            console.error("There was an error submitting the form!", error);
          });
      } else {
        adminhttp().post("/admin/menustore", submitData).then((response) => {
          Swal.fire({
            title: 'New Menu added successfully!',
            icon: "success",
            allowOutsideClick: false,
            confirmButtonColor: "#e30613",
          });
          setProgress(false);
          setShowModal(false);
          handleRequest();
        })
          .catch((error) => {
            console.error("There was an error submitting the form!", error);
          });
      }
    }
  };

  function handleAddMenu() {
    console.log("Add Menu button clicked!");
    setShowModal(true);
    setEditMenu(false);
    setEdit(false);
  }
  function makeDefaultMenu() {
    // console.log('hi');
    if(counter===0){
      adminhttp().get(`/admin/duplicate/${splitURL[3]}`).then((data) => {
        console.log(data)
        Swal.fire({
          title: 'Menu added successfully!',
          icon: "success",
          allowOutsideClick: false,
          confirmButtonColor: "#e30613",
        });
        setCounter(1);
      });
   }
  }

  const handleStatus = (e, id, status) => {
    e.stopPropagation();
    Swal.fire({
      title: `Are you sure to ${status === 1 ? "Deactivate" : "Activate"} this Menu?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      width: "auto",
      showCancelButton: true,
      confirmButtonColor: "#e30613",
      cancelButtonColor: "#6c757d",
      confirmButtonText: `Yes, ${status === 1 ? "Deactivate" : "Activate"} this Menu!`,
    }).then((result) => {
      if (result.isConfirmed) {
        adminhttp()
          .post(`/admin/${status === 1 ? "inactivemenu" : "activemenu"}`, {
            id: id, // Ensure id is passed
          })
          .then((response) => {
            Swal.fire({
              title: `Menu has been ${status === 1 ? "Deactivated" : "Activated"}.`,
              icon: "success",
              allowOutsideClick: false,
              confirmButtonColor: "#e30613",
            }).then((result) => {
              if (result["isConfirmed"]) {
                handleRequest();
              }
            });
          })
          .catch((error) => {
            console.error("Error updating menu status:", error);
            Swal.fire("Error", "Something went wrong. Please try again!", "error");
          });
      }
    });
  };


  const handleEdit = (id) => (e) => {
    setMenuID(id)
    setEditMenu(true)
    setEdit(true);
    setShowModal(true)
    adminhttp().get(`/admin/singlemenu/${id}`).then((data) => {
      console.log(data.data.data.menuRow)
      setMenuIcon(data.data.data.menuRow.icon_path)
      setFormData({
        name: data.data.data.menuRow.name || "",
        permissions: data.data.data.menuRow.permission || "",
        parent: data.data.data.menuRow.parent || "",
        order: data.data.data.menuRow.order || "",
        status: data.data.data.menuRow.status || 1,
        iconorder: data.data.data.menuRow.icon_order || "",
        iconimage: data.data.data.menuRow.icon_path || "",
        menuid: data.data.data.menuRow.id || "",
        slug: data.data.data.menuRow.slug || "",
        mobilemenu: "",
      });
    });
  }


  useEffect(() => {
    handleRequest();
  }, []);

  useEffect(() => {
    if (edit) {
      handleRequest();
    } else {
      setFormData({
        name: "",
        permissions: "",
        parent: "",
        order: "",
        status: 1,
        iconorder: "",
        iconimage: "",
        menuid: "",
        slug: "",
        mobilemenu: ""
      });
    }

  }, [edit, menuID]);
  return (
    <>
      <AdminLayout>
        <Container fluid className="bg-light">
          <Box component="section" sx={{ px: 2, pt: 2, pb: 1 }}>
            <AdminBreadCrumbs breadcrumbs={breadcrumbs} />
          </Box>

          <Box
            component="section"
            className="d-flex align-items-center justify-content-between"
            sx={{ px: 2, pt: 0, pb: 1 }}
            data-aos="fade-up"
          >
            <h4>Pavilion Menus</h4>
            <div className="d-flex justify-content-end align-items-center">
              <Form className="me-2">
                <Form.Control
                  type="text"
                  size="small"
                  name="menuSearch"
                  id="menuSearch"
                  onChange={handleSearch}
                  placeholder="Search Menu Name"
                />
              </Form>
              <Button type="button" variant={counter===1?'secondary': 'primary'} disabled={counter} onClick={makeDefaultMenu}>
                Make from Default Menu
              </Button> &nbsp;
              <Button type="button" variant="primary" onClick={handleAddMenu}>
                Add Menu
              </Button>
            </div>
          </Box>

          <ListGroup variant="flush" className='mb-5'>
            <ListGroup.Item className="bg-transparent">
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  "& > :not(style)": {
                    m: 0,
                    p: 2,
                    width: "100%",
                    height: "auto",
                  },
                }}
              >
                <Paper elevation={3} className="bg-secondary rounded-4 mb-2">
                  <Grid container spacing={2}>
                    <Grid item xs={6} md={1}>
                      <Typography
                        variant="body"
                        component="p"
                        className="mt-1 text-light"
                      >
                        Menu Icon
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <Typography
                        variant="body"
                        component="p"
                        className="mt-1 text-light"
                      >
                        Menu Name
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={2}
                      className="d-flex align-items-center"
                    >
                      <Typography
                        variant="body"
                        component="p"
                        className="text-light"
                      >
                        Menu Slug
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={2}
                      className="d-flex align-items-center"
                    >
                      <Typography
                        variant="body"
                        component="p"
                        className="text-light"
                      >
                        Permission
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={1}
                      className="d-flex align-items-center justify-content-end"
                    >
                      <Typography
                        variant="body"
                        component="p"
                        className="text-light"
                      >
                        Parent
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={1}
                      className="d-flex align-items-center justify-content-end"
                    >
                      <Typography
                        variant="body"
                        component="p"
                        className="text-light"
                      >
                        Status
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={2}
                      className="d-flex align-items-center justify-content-end"
                    >
                      <Typography
                        variant="body"
                        component="p"
                        className="text-light"
                      >
                        Icon Order
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={1}
                      className="d-flex align-items-center justify-content-end"
                    >
                      <Typography
                        variant="body"
                        component="p"
                        className="text-light"
                      >
                        Actions
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Box>
              {filteredMenus.length > 0 ? (
                <Box sx={{ flexGrow: 1 }}>
                  <Grid
                    container
                    spacing={{ xs: 1, md: 1 }}
                    columns={{ xs: 1, md: 12 }}
                  >
                    {filteredMenus.map((menu, index) => (
                      <Grid item xs={12} md={12}>
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            "& > :not(style)": {
                              m: 0,
                              p: 2,
                              width: "100%",
                              height: "auto",
                            },
                          }}
                        >
                          <Paper elevation={3} className="rounded-4 ">
                            <Grid container spacing={2}>
                              <Grid
                                item
                                xs={6}
                                md={1}
                                className="d-flex align-items-center justify-content-center"
                              >
                                {menu.icon_path && (
                                  <Image
                                    height="30px"
                                    width='auto'
                                    src={menu.icon_path}
                                  />
                                )}
                              </Grid>
                              <Grid item xs={6} md={2}>
                                <Typography variant="body" component="p" className="mt-1">
                                  {menu.name}
                                </Typography>
                              </Grid>
                              <Grid item xs={6} md={2}>
                                <Typography variant="body" component="p" className="mt-1">
                                  {menu.slug}
                                </Typography>
                              </Grid>
                              <Grid item xs={6} md={2} className="d-flex align-items-center">
                                <Typography variant="body" component="p" className="">
                                  {menu.permission ? menu.permission : ''}
                                </Typography>
                              </Grid>
                              <Grid item xs={6} md={1} className="d-flex align-items-center">
                                <Typography variant="body" component="p">
                                  {menu.parent.name ? menu.parent.name : ''}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                md={1}
                                className="d-flex align-items-center justify-content-end"
                              >
                                <Typography variant="body" component="p">
                                  <Chip label={menu.status === 1 ? 'Active' : 'In Active'} variant='outlined' size='small' color={menu.status === 1 ? 'success' : 'error'} />
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                md={2}
                                className="d-flex align-items-center justify-content-end"
                              >
                                <Typography variant="body" component="p">
                                  {menu.icon_order}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                md={1}
                                className="d-flex align-items-center justify-content-end"
                              >
                                <Typography variant="body" component="p">
                                  <CRUDDropdown
                                    title={<MoreVertIcon fontSize="small" />}
                                    handleDropdown={(e) => handleDropdown(e)}
                                    addTitle="Edit Menu"
                                    addClick={handleEdit(menu.id)}
                                    editTitle={menu.status === 1 ? "Deactivate" : "Activate"}
                                    editClick={(e) => handleStatus(e, menu.id, menu.status)} // Pass menu.id properly
                                  />
                                </Typography>
                              </Grid>
                            </Grid>
                          </Paper>
                        </Box>

                      </Grid>
                    ))}
                  </Grid>
                </Box>
              ) : (
                <Box
                  sx={{ flexGrow: 1 }}
                  className="d-flex justify-content-center"
                >
                  <Alert variant="outlined" severity="error">
                    Menus not found {menus.length}
                  </Alert>
                </Box>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Container>
      </AdminLayout>

      <Modal show={showModal} onHide={() => setShowModal(false)}>

        <Modal.Header className="py-1 my-1" closeButton>
          <Modal.Title>{edit === true ? 'Edit' : 'Add'} Menu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
              id="menuForm"
            >

              <Form.Group controlId="validationCustom01" className="mb-1">
                <Form.Control
                  name="name"
                  required
                  type="text"
                  className={Object.values(formData.name).length > 0 ? "is-valid" : "is-invalid"}
                  placeholder="Menu Name"
                  value={formData.name && formData.name}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group controlId="validationCustom02" className="mb-1">
                <Form.Select
                  name="permissions"
                  className={Object.values(formData.permissions).length > 0 ? "is-valid" : "is-invalid"}
                  value={formData.permissions}
                  onChange={handleChange}
                >
                  <option value="0" selected>
                    Select Permission
                  </option>
                  {menusParent.map((menuParent, index) => (
                    <option value={menuParent.slug}>{menuParent.slug}</option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group controlId="validationCustom02" className="mb-1">
                <Form.Select
                  name="parent"
                  value={formData.parent && formData.parent}
                  onChange={handleChange}   >
                  <option value="0" selected >
                    Select Parent
                  </option>
                  {menusValues.map((menusValues, index) => (
                    <option value={menusValues.id}>{menusValues.name}</option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group controlId="validationCustom03" className="mb-1">
                <Form.Control
                  name="order"
                  required
                  type="number"
                  // className={formData.order && Object.values(formData.order).length > 0 ? "is-valid" : "is-invalid"}
                  className={formData.order !== "" && formData.order !== null ? "is-valid" : "is-invalid"}
                  placeholder="Enter Menu Order"
                  value={formData.order && formData.order}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group controlId="validationCustom02" className="mb-1">
                <Form.Select
                  name="status"
                  value={formData.status && formData.status}
                  onChange={handleChange}
                >
                  <option value="1">Active</option>
                  <option value="0">InActive</option>
                </Form.Select>
              </Form.Group>
              <Form.Group controlId="validationCustom03" className="mb-1">
                <Form.Control
                  name="iconorder"
                  required
                  type="number"
                  // className={formData.iconorder && Object.values(formData.iconorder).length > 0 ? "is-valid" : "is-invalid"}
                  className={formData.iconorder !== "" && formData.iconorder !== null ? "is-valid" : "is-invalid"}
                  placeholder="Enter Icon Order"
                  value={formData.iconorder && formData.iconorder}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group controlId="validationCustom04" className="mb-3">
                {/* <Form.Control type="file" required
                  // className={formData.iconimage ? "is-valid" : "is-invalid"}
                  className={(!edit && !formData.iconimage) ? "is-invalid" : ""}
                  name="iconimage" onChange={handleChange} /> */}
                   <InputGroup className="mt-3 d-flex align-items-center justify-content-between">
                          
                          <MuiButton
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            color="error"
                            sx={{height:'50px'}}
                            className="rounded-2 me-5"
                            startIcon={<CloudUploadIcon />}
                          >
                            &nbsp;{edit ? 'Update':'Add'} Menu Icon
                            <input
                              type="file"
                              accept=".svg"
                              hidden
                              id='menuIcon'
                              onChange={(e) => handleChange(e)}
                            />
                          </MuiButton>
                          <img id="logoId"
                            src={menuIcon}
                            alt="Menu Icon"
                            style={{ width: "20%", height: "auto", }}
                          />
                        </InputGroup>
              </Form.Group>
            </Form>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>handleClose()} >
            Close
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={handleSubmit}
            form="menuForm"
          >
            {progress && (<Spinner size='sm' className="me-2" animation="border" />)} Save Menu
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  );
};

export default Menus;
