import React, { useState, useEffect, isValidElement } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FloatingLabel, Modal } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { Link, useLocation } from "react-router-dom";
import adminhttp from "../../../adminhttp";
import Swal from "sweetalert2";
import Divider from "@mui/material/Divider";
import Spinner from "react-bootstrap/Spinner";
import PavilionSetupForm from "../../../components/PavilionSetupForm";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Image from "react-bootstrap/Image";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import MuiButton from "@mui/material/Button";
import { experimentalStyled as styled } from "@mui/material/styles";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  ...theme.applyStyles("dark", {
    backgroundColor: "#1A2027",
  }),
}));

function AddPavilion(props) {
   

  const [pvlnModalShow, setPvlnModalShow] = useState(props.showModal);
  const [settings, setSettings] = useState([]);

  const [validated, setValidated] = useState(false);
  const [progress, setProgress] = useState(false);


  const [show, setShow] = useState(false);
  // Define initial form data for resetting
const initialFormData = {
  name: '',
  description: '',
  category_id: '',
  logo: '',
  image: null,
  main_image: null,
  banner: null,
  privacy_policy: null,
  address: '',
  region: '',
  city: '',
  country_id: '',
  privacy: 0,
  status: 1,
  type: 'free',
  oprated_by: '',
  language: 'en',
  location_type: ''
};

const [formData, setFormData] = useState(initialFormData);
const [nameError, setNameError] = useState(''); // New state for name validation errors


// Function to reset form data
const resetFormData = () => {
  setFormData(initialFormData);
  setNameError('');
};  
  // Handle file change
  const handleFileChange = (e) => {
    const { name, files } = e.target;
  setFormData(prevFormData => ({ ...prevFormData, [name]: files[0] }));
  };

// Handle other input changes
const handleChange = (e) => {
  const { name, value } = e.target;
  setFormData((prevData) => ({
    ...prevData,
    [name]: value,
  }));
  
  // Reset name validation error on input change
  if (name === 'name') {
    setNameError('');
  }
};

// Handle form submission
const handleSubmit = async (e) => {
  e.preventDefault();
  setProgress(true);


  // Validate pavilion name (e.g., require at least 3 characters)
  if (formData.name.trim().length < 3) {
    setNameError("Pavilion name must be at least 3 characters.");
    setProgress(false);
    return; // Stop form submission if validation fails
  }

  // Initialize FormData to include file binary and text data
  const apiData = new FormData();
  Object.keys(formData).forEach((key) => {
    apiData.append(key, formData[key]);
  });

  try {
    const response = await adminhttp().post("/admin/createpavilion", apiData, {
      headers: {
        "Content-Type": "multipart/form-data", // Required for file uploads
      },
    });
    Swal.fire("Success!", "Pavilion added successfully!", "success");
    resetFormData();  // Reset the form data here
    props.close();
  } catch (error) {
	if (error.response && error.response.status === 409) {
		Swal.fire('Pavilion name already exists!');
	} else {
		Swal.fire('An unexpected error occurred.');
	}	
  } finally {
    setProgress(false);
  }
};



const [countryList, setCountryList] = useState([]);
const [pavilionCategory, setPavilionCategory] = useState([]);
const [regionList, setRegionList] = useState([]);

// Initial fetch of country and category data
useEffect(() => {
  adminhttp().get('/getcountries').then((data) => {
	  console.log(data.data.data.countries);
    setCountryList(data.data.data.countries);
    // Set the default country ID here if applicable
    setFormData(prev => ({
      ...prev,
      country_id: data.data.data.countries[0]?.id || ''
    }));
  });
  
  adminhttp().get('/pavilioncategory').then((data) => {
    setPavilionCategory(data.data.data);
    // Set the default category ID here if applicable
    setFormData(prev => ({
      ...prev,
      category_id: data.data.data[0]?.id || ''
    }));
  });
    adminhttp().get('/admin/getregions').then((data) => {
    setRegionList(data.data.data.regions); // Assuming the API returns an array of regions
  }).catch((error) => {
    console.error("Error fetching regions:", error);
  });
  
  
}, []);

const [cityList, setCityList] = useState([]);

// Handle country selection change
const handleSelectChange = (event) => {
  const selectedCountryId = event.target.value;
  
  // Update formData with selected country
  setFormData((prevData) => ({
    ...prevData,
    country_id: selectedCountryId,
  }));
  
  // Fetch cities if a valid country is selected
  if (selectedCountryId) {
    adminhttp().get(`/cities/${selectedCountryId}`).then((data) => {
      setCityList(data.data.data.CountryCities);
    }).catch((error) => {
      console.error("Error fetching cities:", error);
    });
  } else {
    // Clear city list if no country is selected
    setCityList([]);
  }
};

 
  return (
    <Modal
      centered
      backdrop="static"
      size="lg"
      show={props.show}
      onHide={props.close}
    >
      <Modal.Header className="py-1 my-1" closeButton>
        <Modal.Title>
          {props.edit === true ? "Edit" : "Add"} Pavilion
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Tabs
            defaultActiveKey="PavilionInfo"
            id="uncontrolled-tab-example"
            className="mb-3 mx-0"
          >
            <Tab eventKey="PavilionInfo" title="Pavilion Info">
          <Form onSubmit={handleSubmit} id="pavilionForm">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Pavilion Name</Form.Label>
                  <Form.Control
					type="text"
					placeholder="Pavilion Name"
					name="name"
					value={formData.name}
					onChange={handleChange}
  isInvalid={!!nameError} // Set isInvalid if there’s an error					
                  />
				  <Form.Control.Feedback type="invalid">
        {nameError}
      </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Pavilion Description</Form.Label>
                 <Form.Control
					as="textarea"
					rows={2}
					placeholder="Pavilion Description"
					name="description"
					value={formData.description}
					onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Pavilion Category</Form.Label>
                  <Form.Select
					name="category_id"
					value={formData.category_id}
					onChange={handleChange}
                  >
					<option  key="" value="">Pavilion Category</option>
					{pavilionCategory.map((category) => (
					<option key={category.value} value={category.value}>{category.label}</option>
					))}
                  </Form.Select>
                </Form.Group>
              </Form>
            </Tab>
            <Tab eventKey="graphics" title="Graphics">
              <div className="mb-4 mt-4 floating-label">
                <Form.Control
                    type="file"
					id="logo"
					name="logo"
					onChange={handleFileChange}
                  />
                <Form.Label className="bg-light text-primary">
                  Logo ( 200 x 70 px )
                </Form.Label>
              </div>
              <div className="mb-4 floating-label">
                <Form.Control
					type="file"
					id="image"
					name="image"
					onChange={handleFileChange}
                />
                <Form.Label className="bg-light text-primary">
                  Icon ( 100 x 100 px )
                </Form.Label>
              </div>
              <div className="mb-4 floating-label">
                <Form.Control
					type="file"
					id="main_image"
					name="main_image"
					onChange={handleFileChange}
                />
                <Form.Label className="bg-light text-primary">
                  Card ( 280 x 181 px )
                </Form.Label>
              </div>
              <div className="mb-4 floating-label">
                <Form.Control
					type="file"
					id="banner"
					name="banner"
					onChange={handleFileChange}
                />
                <Form.Label className="bg-light text-primary">
                  Banner ( 320 x 200 px )
                </Form.Label>
              </div>
              <div className="mb-4 floating-label">
                <Form.Control
					type="file"
					id="privacy_policy"
					name="privacy_policy"
					onChange={handleFileChange}
                />
                <Form.Label className="bg-light text-primary">
                  Privacy Policy
                </Form.Label>
              </div>
            </Tab>
            <Tab eventKey="contact" title="Contact">
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Address</Form.Label>
                  <Form.Control
					name='address'
					required
					rows={2}
					as='textarea'
					placeholder="Address"
					value={formData.address}
					onChange={handleChange}
					/>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Region</Form.Label>
                  <Form.Select  name="region" value={formData.region} onChange={handleChange} >
					<option  key="" value="">Select Region</option>
					{regionList.map((region) => (
					<option key={region.id} value={region.id}>{region.name}</option>
					))}
                  </Form.Select>
                </Form.Group>
				 <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Country</Form.Label>
                  <Form.Select name="country_id" value={formData.country_id} onChange={handleSelectChange}>
                    <option key="" value="">Select Country</option>
					{countryList.map((country) => (
					<option key={country.id} value={country.id}>{country.name}</option>
					))}
                  </Form.Select>
                </Form.Group>
				
				
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Cities</Form.Label>
                  <Form.Select name="city" value={formData.city} onChange={handleChange}>
					<option  key="" value="">Select City</option>
					{cityList.map((city) => (
					<option key={city.id} value={city.id}>{city.name}</option>
					))}
				</Form.Select>
                </Form.Group>
               
              </Form>
            </Tab>
            <Tab eventKey="socialmedia" title="Others">
              <Form className="d-flex ">
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Privacy</Form.Label>
                      <Form.Select name="privacy" value={formData.privacy} onChange={handleChange}>
                        <option value="0">Open</option>
                        <option value="1">Private</option>
                      </Form.Select>
                    </Form.Group>
                  </Grid>
                  <Grid item xs={4}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Status</Form.Label>
                      <Form.Select name="status" value={formData.status} onChange={handleChange}>
						<option value="1">Active</option>
						<option value="0">Inactive</option>
                      </Form.Select>
                    </Form.Group>
                  </Grid>
                  <Grid item xs={4}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Type</Form.Label>
                      <Form.Select name="type" value={formData.type} onChange={handleChange}>
                        <option value="free">Free</option>
                        <option value="paid">Paid</option>
                      </Form.Select>
                    </Form.Group>
                  </Grid>
                  <Grid item xs={4}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Operated By</Form.Label>
                      <Form.Select name="oprated_by" value={formData.oprated_by} onChange={handleChange}>
					<option key="" value="">Select Region</option>
					{regionList.map((region) => (
					<option key={region.id} value={region.id}>{region.name}</option>
					))}
                      </Form.Select>
                    </Form.Group>
                  </Grid>
                  <Grid item xs={4}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Language</Form.Label>
                      <Form.Select name="language" value={formData.language} onChange={handleChange}>
						<option value="en">English</option>
						<option value="ar">Arabic</option>
                      </Form.Select>
                    </Form.Group>
                  </Grid>
                  <Grid item xs={4}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Location Type</Form.Label>
                      <Form.Select name="location_type" value={formData.location_type} onChange={handleChange}>
						<option value="">Location Type</option>
						<option value="online">Online</option>
						<option value="onsite">Onsite</option>
						<option value="both">Onsite/Online</option>
                      </Form.Select>
                    </Form.Group>
                  </Grid>
                </Grid>
              </Form>
            </Tab>
          </Tabs>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.close}>
          Close
        </Button>
        <Button
          variant="primary"
          type="submit"
          form="pavilionForm"
        >
          {progress && (
            <Spinner size="sm" className="me-2" animation="border" />
          )}{" "}
          Save Pavilion
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddPavilion;
