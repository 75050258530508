import React,{useState,useEffect} from "react";
import { styled } from '@mui/material/styles';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { formatDate } from '../../../components/PavilionConstants';
import {
  Form,
  Container,
  Button,
  Stack,
  ListGroup,
  ButtonGroup,
  Row, Col, Image
} from "react-bootstrap";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
// import Card from "react-bootstrap/Card";
import BadgeAvatar from "../../../components/BadgeAvatar";
import Grid from "@mui/material/Grid";
import AdminBreadCrumbs from '../AdminBreadCrumbs';

import Badge from '@mui/material/Badge';

import { Pagination } from "@mui/material";

import Paper from '@mui/material/Paper';


import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from '@mui/icons-material/Add';
import { Typography } from "@mui/material";
import RemoveIcon from '@mui/icons-material/Remove';
import CRUDDropdown from "../../../components/CRUDDropdown";
import HiveIcon from '@mui/icons-material/Hive';
import HubIcon from '@mui/icons-material/Hub';
import Avatar from '@mui/material/Avatar';
import Swal from 'sweetalert2'
import adminhttp from '../../../adminhttp';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';

const UsersCard = (props) => {

  const navigate = useNavigate();
  const location = useLocation();
  // const data = location.state;
  // const data2 = {
  //   name: data.name,
  //   ticketname: props.data.name,
  // }

  const [roles, setRoles]=useState([]);
  const [memberships, setMemberships]=useState([]);
  const splitURL = location.pathname.toString().split("/");

  const handleEdit = (id) => (e) => {
    //setShow(true)
  };
  const handleDelete = (e) => {
    e.stopPropagation();
    Swal.fire({
      title: "Are you sure to delete this Pavilion User?",
      text: "You won't be able to revert this!",
      icon: "warning",
      width: 'auto',
      showCancelButton: true,
      confirmButtonColor: "#e30613",
      cancelButtonColor: "#6c757d",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        adminhttp().post('/admin/deleteuser', {
          pavilion_id: props.data.pivot.pavilion_id,
          user_id:props.data.id,
      }).then((data) => {
        Swal.fire({
          title: "Pavilion User has been deleted.",
          icon: "success",
          allowOutsideClick: false,
          confirmButtonColor: "#e30613",
        }).then((result) => {
          if (result['isConfirmed']){
            props.handleRequest();
          }
        })
      });
        
      }
    });
};
  const handleDropdown = (e) => {
    e.stopPropagation();
    //setShow(true)
  };

  const handleStatus = (id,roleid,membershipid)=> (e) => {
    e.stopPropagation();
    Swal.fire({
      title: `Are you sure to ${props.data.status===1 ? 'Deactive' : 'Activate'} this User's Membership?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      width: 'auto',
      showCancelButton: true,
      confirmButtonColor: "#e30613",
      cancelButtonColor: "#6c757d",
      confirmButtonText: `Yes, ${props.data.status===1 ? 'Deactive' : 'Activate'} this User's Membership!`
    }).then((result) => {
      if (result.isConfirmed) {
        adminhttp().post('/admin/attachuser', {
          pavilion_id: splitURL[3],
          user_id: id,
          role_id: roleid,
          data_flag: 'Yes',
          membership_id: membershipid,
          // status: props.data.status===1?0:1,
      }).then((data) => {
        Swal.fire({
          title: `User's Membership has been ${props.data.status===1 ? 'Deactivated' : 'Activated'}.`,
          icon: "success",
          allowOutsideClick: false,
          confirmButtonColor: "#e30613",
        }).then((result) => {
          if (result['isConfirmed']){
             props.handleRequest();
          }
        });
      });
      }
    });
  };

  // const handleRequest = (e) => {
  //   adminhttp()
  //   .post(`/admin/roles?pavilion_id=${splitURL[3]}`)
  //   .then((data) => {
  //     if (data.data.data.roles.length > 0) {
  //       setRoles(data.data.data.roles);
  //     }
  //   });
  //   adminhttp().post(`/admin/memberships?pavilion_id=${splitURL[3]}`)
  //   .then((data) => {
  //     if (data.data.data.membership.length > 0) {
  //       setMemberships(data.data.data.membership);
  //     }
  //   });

  // }

  useEffect(() => {
    // handleRequest();    
  }, []);
  
  return (
    
    <Box 
    sx={{
      display: 'flex',
      flexWrap: 'wrap',
      '& > :not(style)': {
        m: 0,
        p: 2,
        width: '100%',
        height: 'auto',
      },
    }}
  >
    
    <Paper elevation={3} className='py-2 mb-1 rounded-4'>
  
    <Grid container spacing={2} >
    <Grid item xs={6} md={3} >
         <Typography variant="body" component="p" className='my-2' >
         {props.data.name}
        </Typography>   
      </Grid>
      <Grid item xs={6} md={3} >
         <Typography variant="body" component="p" style={{wordBreak:'break-all'}} className='mt-1' >
         {props.data.email}
        </Typography>   
      </Grid>
      {/* <Grid item xs={6} md={1} className='d-flex align-items-center'>
      <Typography variant="body" component="p"  className=''>
      {props.data.designation}
        </Typography>
      </Grid>
      <Grid item xs={6} md={1} className='d-flex align-items-center'>
      <Typography variant="body" component="p"  className=''>
      {props.data.organization}
        </Typography>
      </Grid> */}
    <Grid item xs={6} md={2} className='d-flex align-items-center'>
      {/* <Typography variant="body" component="p"  className=''> */}
 <Chip label={props.data.roleName} size='small' variant='outlined' color="warning" />
         {/* </Typography> */}
      </Grid>
    {/* <Grid item xs={6} md={2} >
    <Chip label={props.data.role_name} size='small' variant='outlined' color="success" /> */}
    {/* <Form.Select  placeholder="Assign Role" size="sm" >
    {roles.length > 0 && ( 
      roles.map((role, index) => (  
        <option value={role.id}>{role.name}</option>
      )  ))}
      </Form.Select> */}
    
      {/* </Grid> */}
      <Grid item xs={6} md={3} >
      <Chip label={props.data.membershipName.length>0 ? props.data.membershipName : 'No Membership'} size='small' variant='outlined' color={props.data.membershipName.length>0 ? "success" : 'error'} />
      {/* <Form.Select  placeholder="Assign Membership" size="sm" value={}>
    {memberships.length > 0 && ( 
      memberships.map((membership, index) => (  
        <option value={membership.id}>{membership.name}</option>
      )  ))}
      </Form.Select> */}
    
      </Grid>
 
      
      
      <Grid item xs={6} md={1} className='d-flex align-items-center justify-content-end' >
      <Typography variant="body" component="p"  className=''>
      <CRUDDropdown
            title={<MoreVertIcon fontSize="small" />}
            handleDropdown={(e)=>handleDropdown(e)}
            addTitle="Edit"
            addClick={props.handleEdit(props.data.id)}
            editTitle={props.data.membershipselectedId > 0 && ('Deactive')}
            editClick={props.data.membershipselectedId > 0 && (handleStatus(props.data.id,props.data.roleselectedId,props.data.membershipselectedId))}
            deleteTitle='Delete'
            deleteClick={handleDelete}
          />
        </Typography>
      </Grid>
    </Grid>
    </Paper>
  </Box>

    
  
    
   
//     <Card className='rounded-3'   sx={{':hover': {boxShadow: 5},}}>
//     <div style={{ position: "relative" }}>
//       <CardMedia style={{ }}   component="img" image={"https://img.freepik.com/free-vector/colorful-watercolor-background_23-2148492944.jpg?size=626&ext=jpg&ga=GA1.1.942094041.1718883224&semt=ais_hybrid"} title="Pancakes" alt="Pancakes"/> 
//       <div style={{width:'100%',height:'100%',backgroundColor:'rgba(0,0,0,0.5)',position: "absolute", color: "white",top: 0,left: "50%",transform: "translateX(-50%)",}}> 
//       <div className='h-100 d-flex flex-column align-items-center justify-content-center w-100'>
//         <BadgeAvatar
//                   src={localStorage.getItem('bucketName')+'images/profile_picture/'+ props.data.image}
//                   name={props.data.name}
//                   size="70"
//                 />    
//                   <Typography variant='h5' className='mt-1 text-light fw-bold'>{props.data.name}</Typography>
//                   <Typography variant='h6' className='text-light'>{props.data.email}</Typography>
//         <ProgressBar now={60} variant='warning' style={{height:'5px' , width:'100%'}} className='w-75 mt-3' />
//         </div>
//       </div>
//   </div>
//   <CardContent className='pb-1 pt-4 text-center'>
//   <Grid container spacing={2}>
//         <Grid item xs={4}>
//         <Badge fullWidth className='mb-3' color="success" max={formatDate(props.data.last_login_at)} badgeContent={props.data.last_login_at ? formatDate(props.data.last_login) : '0' }></Badge>
//         <h6>Last Login</h6>
//         </Grid>
//         <Grid item xs={4}>
//         <Badge  className='mb-3' color="warning" max={formatDate(props.data.last_seen)} badgeContent={formatDate(props.data.last_seen)}></Badge>
//         <h6>Last Seen</h6>
//         </Grid>
//         <Grid item xs={4}>
//         <Badge  className='mb-3' color="info" max={props.data.status} badgeContent={props.data.status===1 ? 'Active' : 'Deactive'}></Badge>
//         <h6>Status</h6>
//         </Grid>
//       </Grid>
//       </CardContent>
// </Card>
  )
}

export default UsersCard
