import React, { useState, useEffect, isValidElement } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FloatingLabel, Modal } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { Link, useLocation } from "react-router-dom";
import adminhttp from '../../../adminhttp';
import Swal from "sweetalert2";
import Divider from '@mui/material/Divider';
import Spinner from 'react-bootstrap/Spinner';

function AddTicket(props) {
  
  const [validated, setValidated] = useState(false);
  const [progress, setProgress] = useState(false);
  

  const location = useLocation();
  const data = location.state;
  const splitURL = location.pathname.toString().split("/");
  
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [productType, setProductType] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("");
  const [membershipPlan, setMembershipPlan] = useState("");
  const [imgFile, setImgFile] = useState("");


  const [show, setShow] = useState(false);

  const [formData, setFormData] = useState({
    id:"",
    status: "",
  });

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setFormData({ ...formData, image: files[0] }); // Store file object
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === true) {
      setProgress(true)
      alert(props.ticketID)
      alert(formData.status)
      const submitData = new FormData();
      submitData.append("id", props.ticketID);
      submitData.append("status", formData.status);

      // Submit the form using axios or fetch
      adminhttp().post("/admin/tickets/update", {id: 1, status: 'CLOSED' }).then((response) => {
        alert(response)
          Swal.fire({
            title: "Ticket Status updated successfully!",
            icon: "success",
            allowOutsideClick: false,
            confirmButtonColor: "#e30613",
          });
          setProgress(false)
          props.close();
          props.handleRequest();
        })
        .catch((error) => {
          console.error("There was an error submitting the form!", error);
        });
      }
  };


//   const memberFileEvent = (event) => {
//     event.preventDefault();
//     let files;
//     if (event.dataTransfer) {
//         files = event.dataTransfer.files;
//     } else if (event.target) {
//         files = event.target.files;
//     }
//     if (files.length === 0) {
//         return alert("Please select a file.");
//     }
    
//     const reader = new FileReader();
//     setImgFile(event.target.files[0].name);
//     reader.onload = () => {
      
//    //   setImgFile(reader.result)
//         // http().post('/addPavilion', {
//         //     pavilion_id: pavilonId,
//         //     cardfile: reader.result,
//         //     pavilion_action: 'edit',
//         //     action_name: 'cardupload'
//         // }).then((data) => {
//         //     console.log(data.data);
//         // });
//     };
//     reader.readAsDataURL(files[0]);
// }

  return (
    <Modal centered backdrop="static"  show={props.show}
    onHide={props.close}>
      <Modal.Header className="py-1 my-1" closeButton>
        <Modal.Title>Update Ticket Status</Modal.Title>
      </Modal.Header>
      <Modal.Body>
    <Container fluid>
      <Form noValidate validated={validated} onSubmit={handleSubmit} id="ticketStatusForm">
        {/* <Form.Group controlId="validationCustom01" className="mb-1">
          <Form.Control
            name='name'
            required
            type="text"
            className={formData.name.trim().length > 0 ? "is-valid" : "is-invalid"}
            placeholder="Name"
            value={formData.name}
            onChange={handleChange}
          />
        
        </Form.Group> */}
        {/* <Form.Group controlId="validationCustom02" className="mb-1">
          <Form.Control
            name='price'
            required
            type="text"
            className={formData.price.trim().length > 0 ? "is-valid" : "is-invalid"}
            placeholder="Price"
            value={formData.price}
            onChange={handleChange}
          />
                </Form.Group> */}
        {/* <Form.Group controlId="validationCustom03" className="mb-1">
          <Form.Control
            name='producttype'
            required
            type="text"
            className={formData.producttype.trim().length > 0 ? "is-valid" : "is-invalid"}
            placeholder="Product Type"
            value={formData.producttype}
            onChange={handleChange}
          />
        </Form.Group> */}
        {/* <Form.Group controlId="validationCustom04" className="mb-1">
          <Form.Control
            name='description'
            required
            type="text"
            className={formData.description.trim().length > 0 ? "is-valid" : "is-invalid"}
            placeholder="Description"
            value={formData.description}
            onChange={handleChange}
          />
        </Form.Group> */}

        <Form.Group controlId="validationCustom05" className="mb-1">
        <Form.Select name='status' 
        placeholder="Status"
        required
        value={formData.status}
        onChange={handleChange}
            >
           <option value="NEW">NEW</option>
           <option value="CLOSED">CLOSED</option>
           <option value="PENDING">PENDING</option>
           <option value="INPROGRESS">INPROGRESS</option>
           <option value="RESOLVED">RESOLVED</option>
         </Form.Select>
    </Form.Group>
    {/* <Form.Group controlId="validationCustom06" className="mb-1">
        <Form.Select name='membershipplan' 
        className={formData.membershipplan.length > 0 ? "is-valid" : "is-invalid"}
        placeholder="Membership Plan" required
        value={formData.membershipplan}
        onChange={handleChange}
            >
           <option value="1_year">One Year</option>
           <option value="2_year">Two Year</option>
           <option value="3_year">Three Year</option>
         </Form.Select>
    </Form.Group> */}
    {/* <Form.Group controlId="validationCustom07" className="mb-3">
    <Form.Control type="file" required accept="image/*"
               className={formData.image ? "is-valid" : "is-invalid"}
                 name="image" onChange={handleChange} /> */}
          {/* <Form.Control
            type="file"
            name="imagefile"
            accept="image/*" id="imagefile"
            onChange={(event)=>setImgFile(event.target.files[0].name)}
          /> */}
        {/* </Form.Group> */}
      </Form>
    </Container>
    </Modal.Body>
    <Modal.Footer>
        <Button variant="secondary" onClick={props.close}>
          Close
        </Button>
        <Button
          variant="primary"
          type="submit"
          onClick={handleSubmit}
          form="ticketStatusForm"
        >
          {progress && (<Spinner size='sm' className="me-2" animation="border" />)} Update Ticket Status
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddTicket;
