import React from 'react'
import Layout from '../components/layouts/Layout'

function PrivacyPolicy() {
    
    return (
        <Layout>
            <div className="container col-12 pt-5 px-5 " style={{marginTop:'100px'}} data-aos="slide-up">
            <div className="row flex-lg-row-reverse align-items-center g-5 pt-0">
                <div className="col-lg-12 text-start">
                    <h3 className="fw-bold text-primary  lh-1 mb-5 text-center">Child Safety & CSAE Prevention Policy
                    </h3>
                    {/* <h4>1. Introduction</h4>
                    <p className="lead">[Your App Name] is committed to ensuring the safety of all users, especially minors. We have a zero-tolerance policy against child sexual abuse and exploitation (CSAE) and strictly enforce guidelines to prevent such content and activities. 
                    </p>*/}
                    <ol>
                        <li><strong>Introduction</strong>
                          <p className='mt-2'>Pavilion is committed to ensuring the safety of all users, especially minors. We have a zero-tolerance policy against child sexual abuse and exploitation (CSAE) and strictly enforce guidelines to prevent such content and activities.
                        </p>
                        </li>
                        <li><strong>Prohibited Activities</strong>
                            <p className='my-2'>Users are strictly prohibited from:</p>
<p>Uploading, sharing, or distributing CSAM (Child Sexual Abuse Material).
Engaging in grooming, predatory behavior, or inappropriate conversations with minors.
Soliciting or coercing minors for any explicit content or personal information.
Any activity that violates local or international child protection laws.
                            </p>
                        </li>
                        <li><b>Reporting Violations</b>
                            <p className="my-2">We encourage users to report any violations through:</p>
                            <p className="">
                            In-app reporting tools<br/>
Email: <a href='mailto:support@bicpavilion.com'>support@bicpavilion.com</a><br/>
Law enforcement partnerships<br/>
All reports are reviewed promptly, and necessary actions (such as account bans and legal reporting) will be taken.
                            </p>
                        </li>
                        <li><b>Content Moderation & Enforcement</b>
                           <p className='mt-2'>Pavilion employs AI-based moderation, human review teams, and automated filters to detect and remove prohibited content. Repeat offenders will be permanently banned, and illegal content will be reported to authorities.
                           </p>
                        </li>
                        <li><b>Compliance & Cooperation</b>
                           <p className='mt-2'>We comply with international child protection laws, including the United Nations Convention on the Rights of the Child (UNCRC) and work closely with law enforcement agencies and organizations like NCMEC (National Center for Missing & Exploited Children).
                           </p>
                        </li>
                        <li><b>Contact Us</b>
                           <p className='my-2'>For any concerns, you can contact us at <a href='mailto:support@bicpavilion.com'>support@bicpavilion.com</a>.</p>
                           <p>📌 URL: <a href='https://bicpavilion.com/csae-policy'>https://bicpavilion.com/csae-policy</a> </p>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
      </Layout >
     )
}
export default PrivacyPolicy
