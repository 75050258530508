import React, { useEffect, useState } from 'react';
import Layout from '../components/layouts/Layout2';
import { Form, Card, Button, Container, Image, Modal, Row, Col } from 'react-bootstrap';
import http from '../http'; // Ensure this is correctly set up to use Axios
import Swal from 'sweetalert2';
import { Chart } from "react-google-charts";
import '../survey.css';
import { useNavigate } from 'react-router-dom'
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import ToggleButton2 from '@mui/material/ToggleButton';
import ToggleButtonGroup2 from '@mui/material/ToggleButtonGroup';
import Looks1Icon from '@mui/icons-material/LooksOne';
import Looks2Icon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';
import Looks5Icon from '@mui/icons-material/Looks5';
import Spinner from 'react-bootstrap/Spinner';
import Chip from '@mui/material/Chip';
import Alert from '@mui/material/Alert';

function ParliamentarySurvey() {

    const navigate=useNavigate();
    const [surveyResults, setSurveyResults] = useState();
    
    const [range, setRange] = useState(['Strongly Disagree', 'Disagree', 'Neutral', 'Agree', 'Strongly Agree']);

    const preparePieChartData = (scores) => {
        return [
            ["Response", "Percentage"],
            ["No Risk", scores[1] || 0],
            ["Low Risk", scores[2] || 0],
            ["Medium Risk", scores[3] || 0],
            ["High Risk", scores[4] || 0],
            ["Very High Risk", scores[5] || 0],
        ];
    };


    const preparePieChartData5 = (scores) => {
        return [
            ["Response", "Percentage"],
            ["Rank1", scores[1] || 0],
            ["Rank2", scores[2] || 0],
            ["Rank3", scores[3] || 0],
            ["Rank4", scores[4] || 0],
            ["Rank5", scores[5] || 0],
        ];
    };


    const preparePieChartData6 = (scores) => {
        return [
            ["Response", "Percentage"],
            ["Strongly Disagree", scores[1] || 0],
            ["Disagree", scores[2] || 0],
            ["Neutral", scores[3] || 0],
            ["Agree", scores[4] || 0],
            ["Strongly Agree", scores[5] || 0],
        ];
    };
    const prepareBarChartData7 = (questionData) => {
        return [
            ["Option", "Percentage"],
            ...questionData.map((item) => [item.option, parseFloat(item.percentage)]),
        ];
    };
    const prepareBarChartData8 = (questionData) => {
        return [
            ["Option", "Percentage"],
            ...questionData.map((item) => [item.option, parseFloat(item.percentage)]),
        ];
    };

    const prepareBarChartData9 = (questionData) => {
        return [
            ["Option", "Percentage"],
            ...questionData.map((item) => [item.option, parseFloat(item.percentage)]),
        ];
    }

    const prepareBarChartData10 = (questionData) => {
        return [
            ["Option", "Percentage"],
            ...questionData.map((item) => [item.option, parseFloat(item.percentage)]),
        ];
    };
    const prepareBarChartData11 = (questionData) => {
        return [
            ["Option", "Percentage"],
            ...questionData.map((item) => [item.option, parseFloat(item.percentage)]),
        ];
    };
    const prepareBarChartData12 = (questionData) => {
        return [
            ["Option", "Percentage"],
            ...questionData.map((item) => [item.option, parseFloat(item.percentage)]),
        ];
    };


    // Render the bar chart for the seventh question
    const renderBarChartForSeventhQuestion = () => {
        return (
            <div>
                <h5>7. Question Responses</h5>
                {surveyData?.sevenQuestion && (
                    <div>
                        <Chart
                            chartType="BarChart"
                            data={prepareBarChartData7(surveyData.sevenQuestion)}
                            options={{
                                title: "Seventh Question Responses",
                                colors: ["#e30613"],
                                backgroundColor: "white",
                                hAxis: {
                                    title: "Percentage",
                                    minValue: 0,
                                    maxValue: 100,
                                },
                                vAxis: {
                                    title: "Options",
                                },
                            }}
                            width="100%"
                            height="350px"
                        />
                    </div>
                )}
            </div>
        );
    };
    // Render the bar chart for the eighth question
    const renderBarChartForEighthQuestion = () => {
        return (
            <div>
                <h5>8. Question Responses</h5>
                {surveyData?.eightQuestion && (
                    <div>
                        <Chart
                            chartType="BarChart"
                            data={prepareBarChartData8(surveyData.eightQuestion)}
                            options={{
                                title: "Eighth Question Responses",
                                colors: ["#4caf50"], // Adjust the color as desired
                                backgroundColor: "white",
                                hAxis: {
                                    title: "Percentage",
                                    minValue: 0,
                                    maxValue: 100,
                                },
                                vAxis: {
                                    title: "Options",
                                },
                            }}
                            width="100%"
                            height="350px"
                        />
                    </div>
                )}
            </div>
        );
    };
    // Render the bar chart for the eighth question
    const renderBarChartForNineQuestion = () => {
        return (
            <div>
                <h5>9. Question Responses</h5>
                {surveyData?.nineQuestion && (
                    <div>
                        <Chart
                            chartType="BarChart"
                            data={prepareBarChartData9(surveyData.nineQuestion)}
                            options={{
                                title: "Nine Question Responses",
                                colors: ["#4caf50"], // Adjust the color as desired
                                backgroundColor: "white",
                                hAxis: {
                                    title: "Percentage",
                                    minValue: 0,
                                    maxValue: 100,
                                },
                                vAxis: {
                                    title: "Options",
                                },
                            }}
                            width="100%"
                            height="350px"
                        />
                    </div>
                )}
            </div>
        );
    };
    // Render the bar chart for the eighth question
    const renderBarChartForTenQuestion = () => {
        return (
            <div>
                <h5>10. Question Responses</h5>
                {surveyData?.tenQuestion && (
                    <div>
                        <Chart
                            chartType="BarChart"
                            data={prepareBarChartData10(surveyData.tenQuestion)}
                            options={{
                                title: "Ten Question Responses",
                                colors: ["#4caf50"], // Adjust the color as desired
                                backgroundColor: "white",
                                hAxis: {
                                    title: "Percentage",
                                    minValue: 0,
                                    maxValue: 100,
                                },
                                vAxis: {
                                    title: "Options",
                                },
                            }}
                            width="100%"
                            height="350px"
                        />
                    </div>
                )}
            </div>
        );
    };

    // Render the bar chart for the eighth question
    const renderBarChartForElevenQuestion = () => {
        return (
            <div>
                <h5>11. Question Responses</h5>
                {surveyData?.elevenQuestion && (
                    <div>
                        <Chart
                            chartType="BarChart"
                            data={prepareBarChartData11(surveyData.elevenQuestion)}
                            options={{
                                title: "Elven Question Responses",
                                colors: ["#4caf50"], // Adjust the color as desired
                                backgroundColor: "white",
                                hAxis: {
                                    title: "Percentage",
                                    minValue: 0,
                                    maxValue: 100,
                                },
                                vAxis: {
                                    title: "Options",
                                },
                            }}
                            width="100%"
                            height="350px"
                        />
                    </div>
                )}
            </div>
        );
    };
    // Render the bar chart for the eighth question
    const renderBarChartForTwelveQuestion = () => {
        return (
            <div>
                <h5>12. Question Responses</h5>
                {surveyData?.twelveQuestion && (
                    <div>
                        <Chart
                            chartType="BarChart"
                            data={prepareBarChartData12(surveyData.twelveQuestion)}
                            options={{
                                title: "Twelve Question Responses",
                                colors: ["#4caf50"], // Adjust the color as desired
                                backgroundColor: "white",
                                hAxis: {
                                    title: "Percentage",
                                    minValue: 0,
                                    maxValue: 100,
                                },
                                vAxis: {
                                    title: "Options",
                                },
                            }}
                            width="100%"
                            height="350px"
                        />
                    </div>
                )}
            </div>
        );
    };

    const [formData, setFormData] = useState({
           set_poll_id:10000,
           quest1:"",
quest2:"",
quest31:"",
quest32:"",
quest41:"",
quest42:"",
quest43:"",
quest44:"",
quest45:"",
quest46:"",
quest47:"",
quest48:"",
quest49:"",
quest410:"",
quest411:"",
quest412:"",
quest413:"",
quest414:"",
quest415:"",
quest51:"",
quest52:"",
quest53:"",
quest54:"",
quest55:"",
quest61:"3",
quest62:"3",
quest63:"3",
quest64:"3",
quest65:"3",
quest7:"",
quest8:"",
quest9:"",
quest10:"",
quest11:"",
quest12:"",
comments: "",
    });

    const [errors, setErrors] = useState({}); // State for validation errors

    const handleQuest51 = (val) => {
      setFormData({ ...formData, quest51: val,});
      setErrors({ ...errors, quest51: "", });
    }
    const handleQuest52 = (val) =>  {
        setFormData({ ...formData, quest52: val,});
        setErrors({ ...errors, quest52: "", });
      }
      const handleQuest53 = (val) =>  {
        setFormData({ ...formData, quest53: val,});
        setErrors({ ...errors, quest53: "", });
      }
      const handleQuest54 = (val) =>  {
        setFormData({ ...formData, quest54: val,});
        setErrors({ ...errors, quest54: "", });
      }
      const handleQuest55 = (val) =>  {
        setFormData({ ...formData, quest55: val,});
        setErrors({ ...errors, quest55: "", });
      }
      const handleChange = (e) => {
        const { name, value } = e.target;
    
        if (["quest51", "quest52", "quest53", "quest54", "quest55"].includes(name)) {
            const updatedFormData = { ...formData };
            let isDuplicate = false;
    
            Object.keys(updatedFormData).forEach((key) => {
                if (key !== name && ["quest51", "quest52", "quest53", "quest54", "quest55"].includes(key)) {
                    if (updatedFormData[key] === value) {
                        updatedFormData[key] = ""; 
                        isDuplicate = true;
                    }
                }
            });
    
            if (isDuplicate) {
                // alert("Duplicate value detected. The conflicting field(s) have been reset.");
            }
    
            updatedFormData[name] = value;
            setFormData(updatedFormData);
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    
        setErrors({
            ...errors,
            [name]: "",
        });
    };
    
    
    

    const validateForm = () => {
        const newErrors = {}; 
        if (!formData.quest1) newErrors.quest1 = "Please select an answer for Question 1.";
        if (!formData.quest2) newErrors.quest2 = "Please select any top 3 answer for Question 2.";
        if (!formData.quest31) newErrors.quest31 = "Please select an answer for Question 3.";
        if (!formData.quest32) newErrors.quest32 = "Please select an answer for Question 3.";
        if (!formData.quest41) newErrors.quest41 = "Please select an answer for Question 4.1";
        if (!formData.quest42) newErrors.quest42 = "Please select an answer for Question 4.2";
        if (!formData.quest43) newErrors.quest43 = "Please select an answer for Question 4.3";
        if (!formData.quest44) newErrors.quest44 = "Please select an answer for Question 4.4";
        if (!formData.quest45) newErrors.quest45 = "Please select an answer for Question 4.5";
        if (!formData.quest46) newErrors.quest46 = "Please select an answer for Question 4.6";
        if (!formData.quest47) newErrors.quest47 = "Please select an answer for Question 4.7";
        if (!formData.quest48) newErrors.quest48 = "Please select an answer for Question 4.8";
        if (!formData.quest49) newErrors.quest49 = "Please select an answer for Question 4.9";
        if (!formData.quest410) newErrors.quest410 = "Please select an answer for Question 4.10";
        if (!formData.quest411) newErrors.quest411 = "Please select an answer for Question 4.11";
        if (!formData.quest412) newErrors.quest412 = "Please select an answer for Question 4.12";
        if (!formData.quest413) newErrors.quest413 = "Please select an answer for Question 4.13";
        if (!formData.quest414) newErrors.quest414 = "Please select an answer for Question 4.14";
        if (!formData.quest415) newErrors.quest415 = "Please select an answer for Question 4.15";
        if (!formData.quest51) newErrors.quest51 = "Please select an answer for Question 5.1";
        if (!formData.quest52) newErrors.quest52 = "Please select an answer for Question 5.2";
        if (!formData.quest53) newErrors.quest53 = "Please select an answer for Question 5.3";
        if (!formData.quest54) newErrors.quest54 = "Please select an answer for Question 5.4";
        if (!formData.quest55) newErrors.quest55 = "Please select an answer for Question 5.5";
        if (!formData.quest7) newErrors.quest7 = "Please select an answer for Question 7.";
        if (!formData.quest8) newErrors.quest8 = "Please select an answer for Question 8.";
        if (!formData.quest9) newErrors.quest9 = "Please select an answer for Question 9.";
        if (!formData.quest10) newErrors.quest10 = "Please select an answer for Question 10.";
        if (!formData.quest11) newErrors.quest11 = "Please select an answer for Question 11.";
        if (!formData.quest12) newErrors.quest12 = "Please select an answer for Question 12.";
        return newErrors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        
         if (Object.keys(validationErrors).length > 0) {
             setErrors(validationErrors);
             Swal.fire({
                             title: 'Found Errors!',
                             text: 'Please check and answer as per validations.',
                             icon: "error", // Changed icon to 'success' for better UX
                             allowOutsideClick: false,
                             confirmButtonColor: "#e30613",
                         });
            return
         } else {
        //console.log(formData)
        //     alert(formData.quest2[0])
    //         const submitData = new FormData();
    // submitData.append('set_poll_id', 10000);
    // submitData.append('quest1', formData.quest1);
    // submitData.append('quest2[0]', formData.quest2[0]);
    // submitData.append('quest2[1]', formData.quest2[1]);
    // submitData.append('quest2[2]', formData.quest2[2]);
    // submitData.append('quest3-1', formData.quest31);
    // submitData.append('quest3-2', formData.quest32);
    // submitData.append('quest4-1', formData.quest41);
    // submitData.append('quest4-2', formData.quest42);
    // submitData.append('quest4-3', formData.quest43);
    // submitData.append('quest4-4', formData.quest44);
    // submitData.append('quest4-5', formData.quest45);
    // submitData.append('quest4-6', formData.quest46);
    // submitData.append('quest4-7', formData.quest47);
    // submitData.append('quest4-8', formData.quest48);
    // submitData.append('quest4-9', formData.quest49);
    // submitData.append('quest4-10', formData.quest410);
    // submitData.append('quest4-11', formData.quest411);
    // submitData.append('quest4-12', formData.quest412);
    // submitData.append('quest4-13', formData.quest413);
    // submitData.append('quest4-14', formData.quest414);
    // submitData.append('quest4-15', formData.quest415);
    // submitData.append('quest5-1', formData.quest51);
    // submitData.append('quest5-2', formData.quest52);
    // submitData.append('quest5-3', formData.quest53);
    // submitData.append('quest5-4', formData.quest54);
    // submitData.append('quest5-5', formData.quest55);
    // submitData.append('quest6-1', formData.quest61);
    // submitData.append('quest6-2', formData.quest62);
    // submitData.append('quest6-3', formData.quest63);
    // submitData.append('quest6-4', formData.quest64);
    // submitData.append('quest6-5', formData.quest65);
    // submitData.append('quest7', formData.quest7);
    // submitData.append('quest8', formData.quest8);
    // submitData.append('quest9', formData.quest9);
    // submitData.append('quest10', formData.quest10);
    // submitData.append('quest11', formData.quest11);
    // submitData.append('quest12', formData.quest12);


            //console.log("Form Submitted:", submitData);
            http().post('/parliamentary-ai-survey', formData) // Send formData as the request body
                .then((response) => {
                    //console.log("API Response:", response.data);
                    if (response.data.error_code == 0) {
                        Swal.fire({
                            title: response.data.message,
                            icon: "error", // Changed icon to 'success' for better UX
                            allowOutsideClick: false,
                            confirmButtonColor: "#e30613",
                        }).then(() => {
                        });
                    }else{
                    Swal.fire({
                        title: "Survey is submitted successfully!",
                        icon: "success", // Changed icon to 'success' for better UX
                        allowOutsideClick: false,
                        confirmButtonColor: "#e30613",
                    }).then(() => {
                        // Optionally reset the form or perform other actions
                        setFormData({
                            set_poll_id: 10000,
                            quest1: "",
                            quest2: "",
                            quest31: "",
                            quest32: "",
                            quest4: "",
                            quest5: "",
                            quest6: "",
                            quest7: "",
                            quest8: "",
                            quest9: "",
                            quest10: "",
                            quest11: "",
                            quest12: "",
                            comments: "",
                        });
                        // Optionally, refresh poll data after submission
                    //    fetchPollData();
                    window.location.href='https://bicpavilion.com/parliamentary-ai-awareness-survey';
                    });
                  }
                })
                .catch((error) => {
                    console.error("API Error:", error.response ? error.response.data : error.message);
                    Swal.fire({
                        title: "Submission Failed",
                        text: "There was an error submitting your survey. Please try again later.",
                        icon: "error",
                        confirmButtonColor: "#e30613",
                    });
                });
         }
    };
    const [show, setShow] = useState(false);

    // Handlers to open and close the modal
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    // Chart state
    const [data1, setData1] = useState([
        ["Opinion", "No of Persons"],
        ["Yes", 0],
        ["No", 0],
        ["Not Sure", 0],
    ]);

    // Chart state
    const [data2, setData2] = useState([
        ["Opinion", "No of Persons"],
        ["Yes", 0],
        ["No", 0],
    ]);


    const [data3, setData3] = useState([
        ["Opinion", "No of Persons"],
        ["Yes", 0],
        ["No", 0],
        ["Not Sure", 0],
    ]);


    const [data4, setData4] = useState([
        ["Opinion", "No of Persons"],
        ["Yes", 0],
        ["No", 0],
        ["Not Sure", 0],
    ]);


    const [data5, setData5] = useState([
        ["Opinion", "No of Persons"],
        ["Yes", 0],
        ["No", 0],
        ["Not Sure", 0],
    ]);

    const [loading, setLoading] = useState(true); // Loading state

    useEffect(() => {
      fetchSurveyResults1()
    }, [show===true]);


    // const fetchSurveyResults = () => {
    //     setShow(true)
    //     http().get('/parliamentary-ai-result') // Adjust the endpoint URL as needed
    //         .then((response) => {
    //             const data = response.data;
    //             // console.log(data)
    //             setSurveyResults(response.data)
    //             // alert(response)
    //         })
           
    // }

    const [surveyData, setSurveyData] = useState({});
    const [surveyDataNoofusers, setSurveyDataNoofusers] = useState();
    const [surveyDatacomment, setSurveyDatacomment] = useState([]);

    const fetchSurveyResults1 = () => {
        setLoading(true)
        http().get('/parliamentary-ai-survey-result') // Adjust the endpoint URL as needed
            .then((response) => {
                // console.log(response.data.data)
                if (response.data.success) {
                    // console.log(response.data.data.noofusers)
                    setSurveyData(response.data.data);
                    setSurveyDataNoofusers(response.data.data.noofusers);
                    setSurveyDatacomment(response.data.data.comment);
                }
                
            })
            setLoading(false)
    }
    const renderBarChart = (questionData, title) => {
        const chartData = [
            ["Response", "Percentage"],
            ...questionData.map((item) => [item.message, parseFloat(item.percentage)]),
        ];

        // Function to prepare the data for the fourth question pie chart

        return (
            <Chart
                chartType="BarChart"
                data={chartData}
                options={{
                    title: title,
                    colors: ["#e30613"],
                    backgroundColor: "white",
                    hAxis: {
                        title: "Percentage",
                        minValue: 0,
                        maxValue: 100,
                    },
                    vAxis: {
                        title: "Responses",
                    },
                }}
                width="100%"
                height="350px"
            />
        );
    };

    const fetchPollData = () => {
        setLoading(true);
        // Fetch data from the Laravel API
        http().get('/webpublicpollresult4') // Adjust the endpoint URL as needed
            .then((response) => {
                const data = response.data;
                if (data?.data) {
                    const { yes_count_1, no_count_1, not_sure_count_1, yes_count_2, no_count_2, yes_count_3, no_count_3, not_sure_count_3, yes_count_4, no_count_4, not_sure_count_4, yes_count_5, no_count_5, not_sure_count_5 } = data.data;

                    // Update the chart data dynamically
                    setData1([
                        ["Opinion", "No of Persons"],
                        ["Yes", yes_count_1 || 0],
                        ["No", no_count_1 || 0],
                        ["Not Sure", not_sure_count_1 || 0],
                    ]);
                    setData2([
                        ["Opinion", "No of Persons"],
                        ["Yes", yes_count_2 || 0],
                        ["No", no_count_2 || 0],
                    ]);
                    setData3([
                        ["Opinion", "No of Persons"],
                        ["Yes", yes_count_3 || 0],
                        ["No", no_count_3 || 0],
                        ["Not Sure", not_sure_count_3 || 0],
                    ]);
                    setData4([
                        ["Opinion", "No of Persons"],
                        ["Yes", yes_count_4 || 0],
                        ["No", no_count_4 || 0],
                        ["Not Sure", not_sure_count_4 || 0],
                    ]);
                    setData5([
                        ["Opinion", "No of Persons"],
                        ["Yes", yes_count_5 || 0],
                        ["No", no_count_5 || 0],
                        ["Not Sure", not_sure_count_5 || 0],
                    ]);

                }
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching poll data:", error);
                setLoading(false);
            });
    };

    const options1 = {
        title: "Q1. Do you understand what the new government's approach to regulation of AI is?",
        pieHole: 0, // Set to 0 for a standard pie chart, use 0.4 for a donut chart
        is3D: false,
        chartArea: { width: '95%', height: '350px' },
        // Additional customization options
        legend: { position: 'bottom' },
        colors: ['#4caf50', '#f44336', '#ffeb3b'], // Colors for Yes, No, Not Sure
    };

    const options2 = {
        title: "Q2.  Have you had any approach or input into the government's policy development for AI?",
        pieHole: 0, // Set to 0 for a standard pie chart, use 0.4 for a donut chart
        is3D: false,
        chartArea: { width: '95%', height: '350px' },
        // Additional customization options
        legend: { position: 'bottom' },
        colors: ['#4caf50', '#f44336', '#ffeb3b'], // Colors for Yes, No, Not Sure
    };

    const options3 = {
        title: "Q3. Do you, in general terms, support the UK government's approach to regulation of AI to date?",
        pieHole: 0, // Set to 0 for a standard pie chart, use 0.4 for a donut chart
        is3D: false,
        chartArea: { width: '95%', height: '350px' },
        // Additional customization options
        legend: { position: 'bottom' },
        colors: ['#4caf50', '#f44336', '#ffeb3b'], // Colors for Yes, No, Not Sure
    };

    const options4 = {
        title: "Q4.  Do you think the new Labour government has placed enough emphasis on AI during its first five months in office?",
        pieHole: 0, // Set to 0 for a standard pie chart, use 0.4 for a donut chart
        is3D: false,
        chartArea: { width: '95%', height: '350px' },
        // Additional customization options
        legend: { position: 'bottom' },
        colors: ['#4caf50', '#f44336', '#ffeb3b'], // Colors for Yes, No, Not Sure
    };

    const options5 = {
        title: "Q5.  Do you favour a more light touch, US-style regulatory approach, or do you prefer the approach taken by the EU in the AI Act?",
        pieHole: 0, // Set to 0 for a standard pie chart, use 0.4 for a donut chart
        is3D: false,
        chartArea: { width: '95%', height: '350px' },
        // Additional customization options
        legend: { position: 'bottom' },
        colors: ['#4caf50', '#f44336', '#ffeb3b'], // Colors for Yes, No, Not Sure
    };
const [load,setLoad]=useState(0)
const [url,setUrl]=useState('https://docs.google.com/forms/d/e/1FAIpQLSePfzv4PDCGLjTMXcf7SxCLfuqF6xieLQGwq9pIptIwhO_6Ow/viewform?embedded=true')
    const scrollToTop = () => {
        // alert(load)
        // if(load===1){
        //     setUrl('https://docs.google.com/forms/d/e/1FAIpQLSePfzv4PDCGLjTMXcf7SxCLfuqF6xieLQGwq9pIptIwhO_6Ow/viewanalytics?embedded=true')
        //  }    
        window.scrollTo({
            top: 0,
            behavior: "smooth"
            /* you can also use 'auto' behaviour
         in place of 'smooth' */
        });
        setLoad(load+1)
    };
  
  
const [permissions,setPermissions] = useState([]);

const quest2_options = [
    { name: "Increased Efficiency: AI automates repetitive tasks, allowing humans to focus on more complex and creative activities.", value:1, },
    { name: "Improved Healthcare: AI assists in diagnosing diseases, personalising treatment plans, and accelerating drug discovery.", value:2, },
    { name: "Enhanced Safety: AI enables predictive maintenance, hazard detection, and autonomous vehicles, reducing accidents and fatalities.", value:3, },
    { name: "Enhanced Customer Experience: AI-powered agents (such as chatbots), recommendation systems, and personalised marketing improve customer satisfaction.", value:4, },
    { name: "Economic Growth: AI stimulates innovation and boosts productivity, contributing to economic growth and development.", value:5, },
    { name: "Environmental Sustainability: AI helps to optimise energy usage, aids in environmental monitoring, and facilitates eco-friendly practices.", value:6, },
    { name: "Advanced Research: AI accelerates scientific discoveries, assists in data analysis, and supports breakthroughs in various fields.", value:7, },
    { name: "Accessibility: AI develops assistive technologies for individuals with disabilities, improving accessibility and inclusion.", value:8, },
    { name: "Personalisation: AI tailors products, services, and content to individual preferences, enhancing user experiences.", value:9, },
    { name: "Humanitarian Aid: AI aids in disaster response, refugee assistance, and humanitarian efforts, saving lives and alleviating suffering.", value:10, },
  ];

const handleCheck = (event) => {

  let quest2_array = [...formData.quest2];

  if (event.target.checked) {
    quest2_array = [...formData.quest2, event.target.value];
  } else {
    quest2_array.splice(formData.quest2.indexOf(event.target.value), 1);
  }

  setFormData({ ...formData, quest2: quest2_array, });

 if (formData.quest2.length===2){
     setErrors({ ...errors, quest2: "",  }); // Clear the error for this field when user interacts 
 } 
 else {
    setErrors({ ...errors, quest2: "Please select any top 3 answer for Question 2.",  }); // Clear the error for this field when user interacts 
 }
 
};
    
    // $('input.quest2').on('change', function(evt) {
    //     var chkObj = $('input.quest2').filter(':checked');
    //     if (chkObj.length >= 4) {
    //         this.checked = false;
    //     }
    //     if (chkObj.length < 3) {
    //         $("#quest2-error").text("Tick only top-3 from the list").show();
    //     } else {
    //         $("#quest2-error").text("").hide();
    //     }
    // });

    const [checkedState, setCheckedState] = useState(
        new Array(quest2_options.length).fill(false)
      );
      console.log("checkedState", checkedState);
      const [total, setTotal] = useState(0);
    
      const handleOnChange = (position, e) => {

        console.log(checkedState.filter((i) => i).length)
        
       if (checkedState.filter((i) => i).length >= 3 && e.target.checked) return;

        const updatedCheckedState = checkedState.map((item, index) =>
          index === position ? !item : item
        );
    
        setCheckedState(updatedCheckedState);

        let quest2_array = [...formData.quest2];
        if (e.target.checked) {
            quest2_array = [...formData.quest2, e.target.value];
        } else {
            quest2_array.splice(formData.quest2.indexOf(e.target.value), 1);
        }
        setFormData({ ...formData, quest2: quest2_array, });

         if (Object.values(formData.quest2).length >=2 && e.target.checked) {
             setErrors({ ...errors, quest2: "",  });
         }   else {
             setErrors({ ...errors, quest2: "Please select your top three AI statements only.",  });
         }
    
    };

    return (
      <Layout>
        <>
          <Container
            fluid
            className="px-0 mx-0 bg-primary d-flex justify-content-center align-items-center"
          >
            <Image
              src="https://bic3-live.s3.eu-west-2.amazonaws.com/images/pavilion/88941701198001.png"
              className="mw-100"
              alt="Public Survey Banner"
            />
          </Container>
          <Container className="pt-4 px-5">
            <h2 className="survey-title fw-bold text-primary mb-4">
                     Parliamentary AI Awareness Survey
            </h2>
            <p>We invite all Parliamentarians to complete a short survey collecting views on Artificial Intelligence (AI). 11 questions in total.</p>
            <p>Your answers will be used by the All-Party Parliamentary Group on AI (APPG AI) to inform their discussions, agenda, and activities. All answers are treated anonymously, and only overall results are reported.</p>
             
                         <Form name="pollsubmitform" id="pollsubmitform" onSubmit={handleSubmit} >
            <input type="hidden"  value={formData.set_poll_id} onChange={handleChange} name="set_poll_id" id="set_poll_id" value="10000" /> 
                        <Form.Check.Input type="hidden" name="_token" value="bNlgU85ClD2KsBXcsuuDzZ38RC33iSozz3jhMwSg"/>                        
                        <Card className="">
                            <Card.Header className="bg-primary text-light  align-middle">
                                <strong>1. How do you feel about an increased use of AI in
                                        the UK?</strong> <i>( Tick any one option )</i>
                            </Card.Header>
                            <Card.Body className="px-0 py-3">
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input checked={formData.quest1 === "1"}
                                    onChange={handleChange} className="" type="radio" name="quest1" value="1" id="quest1-opt1"/>
                                    <Form.Check.Label className=" ps-2" for="quest1-opt1">
                                        More excited than concerned.
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input checked={formData.quest1 === "2"}
                                    onChange={handleChange} className=" " type="radio" name="quest1" value="2" id="quest1-opt2"/>
                                    <Form.Check.Label className=" ps-2" for="quest1-opt2">
                                        Equally concerned and excited.
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input checked={formData.quest1 === "3"}
                                    onChange={handleChange} className=" " type="radio" name="quest1" value="3" id="quest1-opt3"/>
                                    <Form.Check.Label className=" ps-2" for="quest1-opt3">
                                        More concerned than excited.
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input checked={formData.quest1 === "4"}
                                    onChange={handleChange} className=" " type="radio" name="quest1" value="4" id="quest1-opt4"/>
                                    <Form.Check.Label className=" ps-2" for="quest1-opt4">
                                        I don't know.
                                    </Form.Check.Label>
                                </Form.Check>
                            </Card.Body>
                            {errors.quest1 && <Card.Footer className='d-flex align-items-center text-danger py-2'>
                            <ErrorIcon className='me-2' fontSize='small'/> {errors.quest1}
                            </Card.Footer>}
                        </Card>
                        <Card className="">
                            <Card.Header className="bg-primary text-light ">
                                        <strong>2. Taking a positive glance, what is the most significant way AI may improve our
                                        lives over the next 5 years?</strong> <i>( Select your top three AI statements )</i> 
                            </Card.Header>
                            <Card.Body className="px-0 py-3">

                            <ul >
        {quest2_options.map(({ name, value }, index) => {
          return (
            <li key={index} style={{listStyleType:'none'}}>
              <div >
                <div className='d-flex'>
                  <input
                    type="checkbox"
                    className='form-check-input'
                    id={`quest2-opt${index}`}
                    name='quest2'
                    value={value}
                    checked={checkedState[index]}
                    onChange={(e) => handleOnChange(index, e)}
                  />
                  <label className='ms-2 mt-1' htmlFor={`quest2-opt${index}`}>{name}</label>
                </div>
              </div>
            </li>
          );
        })}
        
      </ul>


                                  {/* <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input onChange={(event)=>handleCheck(event)}
                                     className=" quest2" type="checkbox" name="quest2" value="1" id="quest2-opt1"/>
                                    <Form.Check.Label className=" ps-2" for="quest2-opt1">
                                        Increased Efficiency: AI automates repetitive tasks, allowing humans to focus on
                                        more complex and creative activities.
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input onChange={(event)=>handleCheck(event)}
                                     className=" quest2" type="checkbox" name="quest2" value="2" id="quest2-opt2"/>
                                    <Form.Check.Label className=" ps-2" for="quest2-opt2">
                                        Improved Healthcare: AI assists in diagnosing diseases, personalising treatment
                                        plans, and accelerating drug discovery.
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input onChange={(event)=>handleCheck(event)} 
                                     className=" quest2" type="checkbox" name="quest2" value="3" id="quest2-opt3"/>
                                    <Form.Check.Label className=" ps-2" for="quest2-opt3">
                                        Enhanced Safety: AI enables predictive maintenance, hazard detection, and
                                        autonomous
                                        vehicles, reducing accidents and fatalities.
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input onChange={(event)=>handleCheck(event)}
                                     className=" quest2" type="checkbox" name="quest2" value="4" id="quest2-opt4"/>
                                    <Form.Check.Label className=" ps-2" for="quest2-opt4">
                                        Enhanced Customer Experience: AI-powered agents (such as chatbots),
                                        recommendation systems, and personalised marketing improve customer
                                        satisfaction.
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input onChange={(event)=>handleCheck(event)}
                                     className=" quest2" type="checkbox" name="quest2" value="5" id="quest2-opt5"/>
                                    <Form.Check.Label className=" ps-2" for="quest2-opt5">
                                        Economic Growth: AI stimulates innovation and boosts productivity, contributing
                                        to
                                        economic growth and development.
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input onChange={(event)=>handleCheck(event)}
                                     className=" quest2" type="checkbox" name="quest2" value="6" id="quest2-opt6"/>
                                    <Form.Check.Label className=" ps-2" for="quest2-opt6">
                                        Environmental Sustainability: AI helps to optimise energy usage, aids in
                                        environmental monitoring, and facilitates eco-friendly practices.
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input onChange={(event)=>handleCheck(event)}
                                     className=" quest2" type="checkbox" name="quest2" value="7" id="quest2-opt7"/>
                                    <Form.Check.Label className=" ps-2" for="quest2-opt7">
                                        Advanced Research: AI accelerates scientific discoveries, assists in data
                                        analysis,
                                        and supports breakthroughs in various fields.
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input onChange={(event)=>handleCheck(event)}
                                     className=" quest2" type="checkbox" name="quest2" value="8" id="quest2-opt8"/>
                                    <Form.Check.Label className=" ps-2" for="quest2-opt8">
                                        Accessibility: AI develops assistive technologies for individuals with
                                        disabilities,
                                        improving accessibility and inclusion.
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input onChange={(event)=>handleCheck(event)}
                                     className=" quest2" type="checkbox" name="quest2" value="9" id="quest2-opt9"/>
                                    <Form.Check.Label className=" ps-2" for="quest2-opt9">
                                        Personalisation: AI tailors products, services, and content to individual
                                        preferences, enhancing user experiences.
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input onChange={(event)=>handleCheck(event)}
                                     className=" quest2" type="checkbox" name="quest2" value="10" id="quest2-opt10"/>
                                    <Form.Check.Label className=" ps-2" for="quest2-opt10">
                                        Humanitarian Aid: AI aids in disaster response, refugee assistance, and
                                        humanitarian efforts, saving lives and alleviating suffering.
                                    </Form.Check.Label>
                                </Form.Check> */}
                                {/* <div id="quest2-error" class="invalid-feedback"></div> */}
                            </Card.Body>
                            {errors.quest2 && <Card.Footer className='d-flex align-items-center text-danger py-2'>
                            <ErrorIcon className='me-2' fontSize='small'/> {errors.quest2}
                            </Card.Footer>}
                        </Card>
                        <Card className="">
                            <Card.Header className="bg-primary text-light ">
                                <strong>3. Have you used Generative AI tools?</strong> (e.g.  Chat GPT, Gemini, Microsoft co-pilot, Midjourney, Claude, etc.)
                            </Card.Header>
                            <Card.Body className="ps-4 pe-0 py-3">
                                <Card.Text className=" mb-2">As part of your work? <span class="text-primary">[Tick one
                                        option]</span></Card.Text>
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input checked={formData.quest31 === "1"}
                                    onChange={handleChange} className=" " type="radio" name="quest31" value="1" id="quest3-1-opt1"/>
                                    <Form.Check.Label className=" ps-2" for="quest3-1-opt1">
                                        I have used it frequently.
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input checked={formData.quest31 === "2"}
                                    onChange={handleChange} className=" " type="radio" name="quest31" value="2" id="quest3-1-opt2"/>
                                    <Form.Check.Label className=" ps-2" for="quest3-1-opt2">
                                        I have used it once in a while.
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input checked={formData.quest31 === "3"}
                                    onChange={handleChange} className=" " type="radio" name="quest31" value="3" id="quest3-1-opt3"/>
                                    <Form.Check.Label className=" ps-2" for="quest3-1-opt3">
                                        I have tried it once but haven't used it after.
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input checked={formData.quest31 === "4"}
                                    onChange={handleChange} className=" " type="radio" name="quest31" value="4" id="quest3-1-opt4"/>
                                    <Form.Check.Label className=" ps-2" for="quest3-1-opt4">
                                        I have never used it.
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input checked={formData.quest31 === "5"}
                                    onChange={handleChange} className=" " type="radio" name="quest31" value="5" id="quest3-1-opt5"/>
                                    <Form.Check.Label className=" ps-2" for="quest3-1-opt5">
                                        I don't know what these tools are.
                                    </Form.Check.Label>
                                </Form.Check>
                                <Card.Text className=" mb-2 mt-3">As part of your personal life? <span class="text-primary">[Tick one option]</span></Card.Text>
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input checked={formData.quest32 === "1"}
                                    onChange={handleChange} className=" " type="radio" name="quest32" value="1" id="quest3-2-opt1"/>
                                    <Form.Check.Label className=" ps-2" for="quest3-2-opt1">
                                        I have used it frequently.
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input checked={formData.quest32 === "2"}
                                    onChange={handleChange} className=" " type="radio" name="quest32" value="2" id="quest3-2-opt2"/>
                                    <Form.Check.Label className=" ps-2" for="quest3-2-opt2">
                                        I have used it once in a while. 
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input checked={formData.quest32 === "3"}
                                    onChange={handleChange}  className=" " type="radio" name="quest32" value="3" id="quest3-2-opt3"/>
                                    <Form.Check.Label className=" ps-2" for="quest3-2-opt3">
                                        I have tried it once but haven't used it after.
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input checked={formData.quest32 === "4"}
                                    onChange={handleChange} className=" " type="radio" name="quest32" value="4" id="quest3-2-opt4"/>
                                    <Form.Check.Label className=" ps-2" for="quest3-2-opt4">
                                        I have never used it.
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input checked={formData.quest32 === "5"}
                                    onChange={handleChange} className=" " type="radio" name="quest32" value="5" id="quest3-2-opt5"/>
                                    <Form.Check.Label className=" ps-2" for="quest3-2-opt5">
                                        I don't know what these tools are.
                                    </Form.Check.Label>
                                </Form.Check>
                            </Card.Body>
                            {(errors.quest31 || errors.quest32) && <Card.Footer className='d-flex flex-column text-danger py-2'>
                               <div><ErrorIcon className='me-2' fontSize='small'/> {errors.quest31}</div>
                               <div><ErrorIcon className='me-2' fontSize='small'/> {errors.quest32}</div>
                            </Card.Footer>}
                        </Card>
                        <Card className="">
                        <Card.Header className="bg-primary text-light ">
                                        <strong>4. The use of AI has raised multiple concerns. What level of risk do you envisage the following AI concerns have? </strong> <i>( Tick one option for each concern: no risk, low risk, medium risk, high risk, very high risk )</i>
                                
                            </Card.Header>
                            <Card.Body className="ps-3 pe-0 py-3 mx-3">
                                <ol class="ps-0">
                                    <Row className=" mb-2 px-3 pt-3 rounded rounded-3 " id="quest4-row1">
                                        <Col className=" col-12 col-md-9 d-flex align-items-center">
                                            <li classname="p-0">Lack of transparency: Users struggle to understand how AI systems make decisions, why they produce specific results, and what data they're using.</li>
                                        </Col>
                                        <Col className=" col-12 col-md-3 px-0">
                                            <Form.Select onChange={handleChange}  value={formData.quest41>0 && formData.quest41} size='sm' name="quest41" id="quest4-1">
                                                <option selected="" value="" >Select Risk Level</option>
                                                <option value={1}>No Risk</option>
                                                <option value={2}>Low Risk</option>
                                                <option value={3}>Medium Risk</option>
                                                <option value={4}>High Risk</option>
                                                <option value={5}>Very High Risk</option>
                                            </Form.Select>

                                        </Col>

                                    </Row>
                                    {errors.quest41 && <Row className='d-flex align-items-center text-danger py-0'>
                            <ErrorIcon className='me-2' fontSize='small'/> {errors.quest41}
                            </Row>}
                                    <Row className=" mb-2 px-3 pt-3 rounded rounded-3" id="quest4-row2" style={{backgroundColor: '#ececec !important'}}>
                                        <Col className=" col-12 col-md-9  d-flex align-items-center">
                                            <li classname="p-0">Bias and discrimination: AI systems can unintentionally perpetuate
                                                societal
                                                biases,
                                                exacerbating discrimination.</li>
                                        </Col>
                                        <Col className=" col-12 col-md-3 px-0">
                                            <Form.Select onChange={handleChange} value={formData.quest42>0 && formData.quest42} class="" name="quest42" id="quest4-2">
                                                <option selected="" value="">Select Risk Level</option>
                                                <option value={1}>No Risk</option>
                                                <option value={2}>Low Risk</option>
                                                <option value={3}>Medium Risk</option>
                                                <option value={4}>High Risk</option>
                                                <option value={5}>Very High Risk</option>
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                    {errors.quest42 && <Row className='d-flex align-items-center text-danger py-0'>
                            <ErrorIcon className='me-2' fontSize='small'/> {errors.quest42}
                            </Row>}
                                    <Row className=" mb-2 px-3 pt-3 rounded rounded-3" id="quest4-row3">
                                        <Col className=" col-12 col-md-9  d-flex align-items-center">
                                            <li classname="p-0">Privacy concerns: AI's capacity to analyse large amounts of personal
                                                data
                                                raises significant privacy issues.</li>
                                        </Col>
                                        <Col className=" col-12 col-md-3 px-0">
                                            <Form.Select onChange={handleChange} value={formData.quest43>0 && formData.quest43} name="quest43" id="quest4-3">
                                            <option selected="" value="">Select Risk Level</option>
                                                <option value={1}>No Risk</option>
                                                <option value={2}>Low Risk</option>
                                                <option value={3}>Medium Risk</option>
                                                <option value={4}>High Risk</option>
                                                <option value={5}>Very High Risk</option>
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                    {errors.quest43 && <Row className='d-flex align-items-center text-danger py-0'>
                            <ErrorIcon className='me-2' fontSize='small'/> {errors.quest43}
                            </Row>}
                                    <Row className=" mb-2 px-3 pt-3 rounded rounded-3" id="quest4-row4">
                                        <Col className=" col-12 col-md-9 d-flex align-items-center">
                                            <li classname="p-0">Ethical concerns: Instilling moral and ethical values in AI systems
                                                poses
                                                significant challenges.</li>
                                        </Col>
                                        <Col className=" col-12 col-md-3 px-0">
                                            <Form.Select onChange={handleChange} value={formData.quest44>0 && formData.quest44} name="quest44" id="quest4-4">
                                            <option selected="" value="">Select Risk Level</option>
                                                <option value={1}>No Risk</option>
                                                <option value={2}>Low Risk</option>
                                                <option value={3}>Medium Risk</option>
                                                <option value={4}>High Risk</option>
                                                <option value={5}>Very High Risk</option>
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                    {errors.quest44 && <Row className='d-flex align-items-center text-danger py-0'>
                            <ErrorIcon className='me-2' fontSize='small'/> {errors.quest44}
                            </Row>}
                                    <Row className=" mb-2 px-3 pt-3 rounded rounded-3" id="quest4-row5">
                                        <Col className=" col-12 col-md-9 d-flex align-items-center">
                                            <li classname="p-0">National security risks: Development of AI-driven autonomous weaponry
                                                raises
                                                serious security concerns.</li>
                                        </Col>
                                        <Col className=" col-12 col-md-3 px-0">
                                            <Form.Select onChange={handleChange}  value={formData.quest45>0 && formData.quest45} name="quest45" id="quest4-5">
                                            <option selected="" value="">Select Risk Level</option>
                                                <option value={1}>No Risk</option>
                                                <option value={2}>Low Risk</option>
                                                <option value={3}>Medium Risk</option>
                                                <option value={4}>High Risk</option>
                                                <option value={5}>Very High Risk</option>
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                    {errors.quest45 && <Row className='d-flex align-items-center text-danger py-0'>
                            <ErrorIcon className='me-2' fontSize='small'/> {errors.quest45}
                            </Row>}
                                    <Row className=" mb-2 px-3 pt-3 rounded rounded-3" id="quest4-row6">
                                        <Col className=" col-12 col-md-9 d-flex align-items-center">
                                            <li classname="p-0">Cyber Security Risks: Development of AI-driven automation raises serious
                                                cyber security concerns against hacking and cybercrime. </li>
                                        </Col>
                                        <Col className=" col-12 col-md-3 px-0">
                                            <Form.Select onChange={handleChange}  value={formData.quest46>0 && formData.quest46} name="quest46" id="quest4-6">
                                            <option selected="" value="">Select Risk Level</option>
                                                <option value={1}>No Risk</option>
                                                <option value={2}>Low Risk</option>
                                                <option value={3}>Medium Risk</option>
                                                <option value={4}>High Risk</option>
                                                <option value={5}>Very High Risk</option>
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                    {errors.quest46 && <Row className='d-flex align-items-center text-danger py-0'>
                            <ErrorIcon className='me-2' fontSize='small'/> {errors.quest46}
                            </Row>}
                                    <Row className=" mb-2 px-3 pt-3 rounded rounded-3" id="quest4-row7">
                                        <Col className=" col-12 col-md-9  d-flex align-items-center">
                                            <li classname="p-0">Concentration of power: Dominance of AI development by a few
                                                corporations
                                                poses risks. </li>
                                        </Col>
                                        <Col className=" col-12 col-md-3 px-0">
                                            <Form.Select onChange={handleChange} value={formData.quest47>0 && formData.quest47} name="quest47" id="quest4-7">
                                            <option selected="" value="">Select Risk Level</option>
                                                <option value={1}>No Risk</option>
                                                <option value={2}>Low Risk</option>
                                                <option value={3}>Medium Risk</option>
                                                <option value={4}>High Risk</option>
                                                <option value={5}>Very High Risk</option>
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                    {errors.quest47 && <Row className='d-flex align-items-center text-danger py-0'>
                            <ErrorIcon className='me-2' fontSize='small'/> {errors.quest47}
                            </Row>}
                                    <Row className=" mb-2 px-3 pt-3 rounded rounded-3" id="quest4-row8">
                                        <Col className=" col-12 col-md-9  d-flex align-items-center">
                                            <li classname="p-0">Dependence on AI: Overreliance on AI may diminish creativity, critical
                                                thinking, and human intuition.</li>
                                        </Col>
                                        <Col className=" col-12 col-md-3 px-0">
                                            <Form.Select onChange={handleChange} value={formData.quest48>0 && formData.quest48}  name="quest48" id="quest4-8">
                                            <option selected="" value="">Select Risk Level</option>
                                                <option value={1}>No Risk</option>
                                                <option value={2}>Low Risk</option>
                                                <option value={3}>Medium Risk</option>
                                                <option value={4}>High Risk</option>
                                                <option value={5}>Very High Risk</option>
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                    {errors.quest48 && <Row className='d-flex align-items-center text-danger py-0'>
                            <ErrorIcon className='me-2' fontSize='small'/> {errors.quest48}
                            </Row>}
                                    <Row className=" mb-2 px-3 pt-3 rounded rounded-3" id="quest4-row9">
                                        <Col className=" col-12 col-md-9 d-flex align-items-center">
                                            <li classname="p-0">Job displacement: AI could create job-less societies.</li>
                                        </Col>
                                        <Col className=" col-12 col-md-3 px-0">
                                            <Form.Select onChange={handleChange} value={formData.quest49>0 && formData.quest49} name="quest49" id="quest4-9">
                                            <option selected="" value="">Select Risk Level</option>
                                                <option value={1}>No Risk</option>
                                                <option value={2}>Low Risk</option>
                                                <option value={3}>Medium Risk</option>
                                                <option value={4}>High Risk</option>
                                                <option value={5}>Very High Risk</option>
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                    {errors.quest49 && <Row className='d-flex align-items-center text-danger py-0'>
                            <ErrorIcon className='me-2' fontSize='small'/> {errors.quest49}
                            </Row>}
                                    <Row className=" mb-2 px-3 pt-3 rounded rounded-3" id="quest4-row10">
                                        <Col className=" col-12 col-md-9 d-flex align-items-center">
                                            <li classname="p-0">Economic inequality: AI may disproportionately benefit the young,
                                                wealthy,
                                                those in jobs or education, and worsen digital exclusion.</li>
                                        </Col>
                                        <Col className=" col-12 col-md-3 px-0">
                                            <Form.Select onChange={handleChange} value={formData.quest410>0 && formData.quest410} name="quest410" id="quest4-10">
                                            <option selected="" value="">Select Risk Level</option>
                                                <option value={1}>No Risk</option>
                                                <option value={2}>Low Risk</option>
                                                <option value={3}>Medium Risk</option>
                                                <option value={4}>High Risk</option>
                                                <option value={5}>Very High Risk</option>
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                    {errors.quest410 && <Row className='d-flex align-items-center text-danger py-0'>
                            <ErrorIcon className='me-2' fontSize='small'/> {errors.quest410}
                            </Row>}
                                    <Row className=" mb-2 px-3 pt-3 rounded rounded-3" id="quest4-row11">
                                        <Col className=" col-12 col-md-9 d-flex align-items-center">
                                            <li classname="p-0">Legal and regulatory challenges: Regulations struggle to keep pace with
                                                rapid innovation.</li>
                                        </Col>
                                        <Col className=" col-12 col-md-3 px-0">
                                            <Form.Select onChange={handleChange} value={formData.quest411>0 && formData.quest411} name="quest411" id="quest4-11">
                                            <option selected="" value="">Select Risk Level</option>
                                                <option value={1}>No Risk</option>
                                                <option value={2}>Low Risk</option>
                                                <option value={3}>Medium Risk</option>
                                                <option value={4}>High Risk</option>
                                                <option value={5}>Very High Risk</option>
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                    {errors.quest411 && <Row className='d-flex align-items-center text-danger py-0'>
                            <ErrorIcon className='me-2' fontSize='small'/> {errors.quest411}
                            </Row>}
                                    <Row className=" mb-2 px-3 pt-3 rounded rounded-3" id="quest4-row12">
                                        <Col className=" col-12 col-md-9 d-flex align-items-center">
                                            <li classname="p-0">AI arms race: Competition among companies and countries prioritises the
                                                AI-innovation and speed to market over ethics and regulations.</li>
                                        </Col>
                                        <Col className=" col-12 col-md-3 px-0">
                                            <Form.Select onChange={handleChange} value={formData.quest412>0 && formData.quest412} name="quest412" id="quest4-12">
                                            <option selected="" value="">Select Risk Level</option>
                                                <option value={1}>No Risk</option>
                                                <option value={2}>Low Risk</option>
                                                <option value={3}>Medium Risk</option>
                                                <option value={4}>High Risk</option>
                                                <option value={5}>Very High Risk</option>
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                    {errors.quest412 && <Row className='d-flex align-items-center text-danger py-0'>
                            <ErrorIcon className='me-2' fontSize='small'/> {errors.quest412}
                            </Row>}
                                    <Row className=" mb-2 px-3 pt-3 rounded rounded-3" id="quest4-row13">
                                        <Col className=" col-12 col-md-9  d-flex align-items-center">
                                            <li classname="p-0">Misinformation and manipulation: AI-generated content can spread false
                                                information and manipulate public opinion.</li>
                                        </Col>
                                        <Col className=" col-12 col-md-3 px-0">
                                            <Form.Select onChange={handleChange} value={formData.quest413>0 && formData.quest413} name="quest413" id="quest4-13">
                                            <option selected="" value="">Select Risk Level</option>
                                                <option value={1}>No Risk</option>
                                                <option value={2}>Low Risk</option>
                                                <option value={3}>Medium Risk</option>
                                                <option value={4}>High Risk</option>
                                                <option value={5}>Very High Risk</option>
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                    {errors.quest413 && <Row className='d-flex align-items-center text-danger py-0'>
                            <ErrorIcon className='me-2' fontSize='small'/> {errors.quest413}
                            </Row>}
                                    <Row className=" mb-2 px-3 pt-3 rounded rounded-3" id="quest4-row14">
                                        <Col className=" col-12 col-md-9 d-flex align-items-center">
                                            <li classname="p-0">Unintended consequences: Complexity and lack of human oversight will
                                                lead to
                                                undesirable outcomes.</li>
                                        </Col>
                                        <Col className=" col-12 col-md-3 px-0">
                                            <Form.Select onChange={handleChange} value={formData.quest414>0 && formData.quest414} name="quest414" id="quest4-14">
                                            <option selected="" value="">Select Risk Level</option>
                                                <option value={1}>No Risk</option>
                                                <option value={2}>Low Risk</option>
                                                <option value={3}>Medium Risk</option>
                                                <option value={4}>High Risk</option>
                                                <option value={5}>Very High Risk</option>
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                    {errors.quest414 && <Row className='d-flex align-items-center text-danger py-0'>
                            <ErrorIcon className='me-2' fontSize='small'/> {errors.quest414}
                            </Row>}
                                    <Row className=" mb-2 px-3 pt-3 rounded rounded-3" id="quest4-row15">
                                        <Col className=" col-12 col-md-9 d-flex align-items-center">
                                            <li classname="p-0">Existential risks: The emergence of artificial general intelligence
                                                (AGI)
                                                poses long-term risks to humanity's future.</li>
                                        </Col>
                                        <Col className=" col-12 col-md-3 px-0">
                                            <Form.Select onChange={handleChange} value={formData.quest415>0 && formData.quest415} name="quest415" id="quest4-15">
                                            <option selected="" value="">Select Risk Level</option>
                                                <option value={1}>No Risk</option>
                                                <option value={2}>Low Risk</option>
                                                <option value={3}>Medium Risk</option>
                                                <option value={4}>High Risk</option>
                                                <option value={5}>Very High Risk</option>
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                    {errors.quest415 && <Row className='d-flex align-items-center text-danger py-0'>
                            <ErrorIcon className='me-2' fontSize='small'/> {errors.quest415}
                            </Row>}
                                </ol>
                            </Card.Body>
                        </Card>
                        <Card className="">
                            <Card.Header className="bg-primary text-light ">
                                    <strong>5. Which of the following do you think AI will do in the next 5 years?</strong> <i>( please rank from 1 to 5, most likely as number 1 to the least likely as number 5 )</i>
                            </Card.Header>
                            <Card.Body className="px-4 py-3 pe-0">
                                <Row className=" mb-2">
                                    <Col md='2' className="mt-3 mt-md-0">
                                        
                                        <Form.Select onChange={handleChange}  value={formData.quest51>0 && formData.quest51}   name="quest51" id="quest51">
                                            <option selected="" value="">Select Options</option>
                                            <option value={1}>1</option>
                                            <option value={2}>2</option>
                                            <option value={3}>3</option>
                                            <option value={4}>4</option>
                                            <option value={5}>5</option>
                                        </Form.Select>
                        
                                    </Col>
                                    <Col md='10' className="d-flex align-items-center">
                                        AI will replace jobs, which become AI automated. 
                                    </Col>
                                </Row>
                                {errors.quest51 && <Row className='d-flex align-items-center text-danger py-0'>
                            <ErrorIcon className='me-2' fontSize='small'/> {errors.quest51}
                            </Row>}
                                <Row className=" mb-2">
                                    <Col md='2' className="mt-3 mt-md-0">
                                        <Form.Select onChange={handleChange}  value={formData.quest52>0 && formData.quest52} name="quest52" id="quest52">
                                        <option selected="" value="">Select Options</option>
                                            <option value={1}>1</option>
                                            <option value={2}>2</option>
                                            <option value={3}>3</option>
                                            <option value={4}>4</option>
                                            <option value={5}>5</option>
                                        </Form.Select>
                                       
                                    </Col>
                                    <Col md='10' className="d-flex align-items-center">
                                        AI will augment / change jobs.
                                    </Col>
                                </Row>
                                {errors.quest52 && <Row className='d-flex align-items-center text-danger py-0'>
                            <ErrorIcon className='me-2' fontSize='small'/> {errors.quest52}
                            </Row>}
                                <Row className=" mb-2">
                                    <Col md='2' className="mt-3 mt-md-0">
                                        <Form.Select onChange={handleChange} value={formData.quest53>0 && formData.quest53} name="quest53" id="quest53">
                                        <option selected="" value="">Select Options</option>
                                            <option value={1}>1</option>
                                            <option value={2}>2</option>
                                            <option value={3}>3</option>
                                            <option value={4}>4</option>
                                            <option value={5}>5</option>
                                        </Form.Select>
                                      
                                    </Col>
                                    <Col md='10' className="d-flex align-items-center">
                                        AI will create new jobs.
                                    </Col>
                                </Row>
                                {errors.quest53 && <Row className='d-flex align-items-center text-danger py-0'>
                            <ErrorIcon className='me-2' fontSize='small'/> {errors.quest53}
                            </Row>}
                                <Row className=" mb-2">
                                    <Col md='2' className="mt-3 mt-md-0">
                                        <Form.Select onChange={handleChange} value={formData.quest54>0 && formData.quest54} name="quest54" id="quest54">
                                        <option selected="" value="">Select Options</option>
                                            <option value={1}>1</option>
                                            <option value={2}>2</option>
                                            <option value={3}>3</option>
                                            <option value={4}>4</option>
                                            <option value={5}>5</option>
                                        </Form.Select>
                                       
                                    </Col>
                                    <Col md='10' className="d-flex align-items-center">
                                        AI will require mass workforce reskilling.
                                    </Col>
                                </Row>
                                {errors.quest54 && <Row className='d-flex align-items-center text-danger py-0'>
                            <ErrorIcon className='me-2' fontSize='small'/> {errors.quest54}
                            </Row>}
                                <Row className=" mb-2">
                                    <Col md='2' className="mt-3 mt-md-0">
                                     <Form.Select 
                                    onChange={handleChange} value={formData.quest55>0 && formData.quest55}  name="quest55" id="quest55">
                                            <option selected="" value="">Select Options</option>
                                            <option value={1}>1</option>
                                            <option value={2}>2</option>
                                            <option value={3}>3</option>
                                            <option value={4}>4</option>
                                            <option value={5}>5</option>
                                        </Form.Select>
                                        
                                    </Col>
                                    <Col md='10' className="d-flex align-items-center">
                                        AI will change professional relationships between work colleagues.
                                    </Col>
                                </Row>
                                {errors.quest55 && <Row className='d-flex align-items-center text-danger py-0'>
                            <ErrorIcon className='me-2' fontSize='small'/> {errors.quest55}
                            </Row>}
                            </Card.Body>
                        </Card>
                        <Card className="">
                            <Card.Header className="bg-primary text-light">
                                <strong>6. Thinking about the future of AI in the UK, to what extent would you agree with each
                                of the following statements? </strong> <br/> <i>( Strongly Disagree, Disagree, Neutral, Agree, Strongly Agree )</i>
                            </Card.Header>
                            <Card.Body className="ps-3 pe-0 py-3">
                            
                                <Row className=" mb-2 d-flex align-items-center">
                                    <Col className=" col-12 col-md-5  d-flex align-items-center">
                                    Generative AI presents more opportunities than risks.
                                    </Col>
                                    <Col className=" col-12 col-md-5   my-2">
                                        <Form.Range value={formData.quest61} 
      onChange={handleChange} name="quest61" id="quest61" min="1" max="5" defaultValue='3' step="1" class="w-100"/>
      
                                    </Col>
                                    <Col className=" col-12 col-md-2 text-center">
                                        <span id="quest6-1-label" class="text-primary">
                                            {range[formData.quest61-1]}</span>
                                    </Col>
                                </Row>
                                <Row className=" mb-2 d-flex align-items-center">
                                    <Col className=" col-12 col-md-5  d-flex align-items-center">
                                    The UK is well placed to be a world leader in the development of AI.
                                    </Col>
                                    <Col className=" col-12 col-md-5   my-2">
                                        <Form.Range value={formData.quest62} 
      onChange={handleChange} name="quest62" id="quest62"  min="1" max="5" defaultValue='3' step="1" class="w-100"/>

                                    </Col>
                                    <Col className=" col-12 col-md-2 text-center">
                                        <span id="quest6-2-label" class="text-primary">{range[formData.quest62-1]}</span>
                                    </Col>
                                </Row>
                                <Row className=" mb-2 d-flex align-items-center">
                                    <Col className=" col-12 col-md-5  d-flex align-items-center">
                                    Adopting AI responsibly (across public and private sectors, and everyday lives)
                                    is vital, and as important as developing new AI technologies.
                                    </Col>
                                    <Col className=" col-12 col-md-5  my-2">
                                        <Form.Range value={formData.quest63} 
      onChange={handleChange} name="quest63" id="quest63"  min="1" max="5" defaultValue='3' step="1" class="w-100"/>
                                    </Col>
                                    <Col className=" col-12 col-md-2 text-center">
                                        <span id="quest6-3-label" class="text-primary">{range[formData.quest63-1]}</span>
                                    </Col>
                                </Row>
                                <Row className=" mb-2 d-flex align-items-center">
                                    <Col className=" col-12 col-md-5 d-flex align-items-center">
                                    The UK is doing enough, via its education system, to equip people for a working
                                    world with AI at the heart of it.
                                    </Col>
                                    <Col className=" col-12 col-md-5   my-2">
                                        <Form.Range value={formData.quest64} 
      onChange={handleChange} name="quest64" id="quest64"  min="1" max="5" defaultValue='3' step="1" class="w-100"/>
                                    </Col>
                                    <Col className=" col-12 col-md-2 text-center">
                                        <span id="quest6-4-label" class="text-primary">{range[formData.quest64-1]}</span>
                                    </Col>
                                </Row>
                                <Row className=" mb-2 d-flex align-items-center">
                                    <Col className=" col-12 col-md-5 d-flex align-items-center">
                                    The UK is able to determine its own approach to AI, resisting pressure from other nation states.
                                    </Col>
                                    <Col className=" col-12 col-md-5   my-2">
                                        <Form.Range value={formData.quest65} 
      onChange={handleChange} name="quest65" id="quest65"  min="1" max="5" step="1" defaultValue='3' class="w-100"/>
                                    </Col>
                                    <Col className=" col-12 col-md-2 text-center">
                                        <span id="quest6-5-label" class="text-primary">{range[formData.quest65-1]}</span>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <Card className="">
                            <Card.Header className="bg-primary text-light ">
                                <strong>7. Existing UK laws (e.g., data protection, non-discrimination) apply to AI, but there
                                is no specific legal framework for regulating its development and use. Considering the debate
                                on potential regulation of AI in the UK, with which of the below statements do you agree
                                the most?</strong> <i >( Tick any one option )</i>
                            </Card.Header>
                            <Card.Body className="px-0 py-3">
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input checked={formData.quest7 === "1"}
                                    onChange={handleChange} className=" " type="radio" name="quest7" value="1" id="quest7-opt1"/>
                                    <Form.Check.Label className=" ps-2" for="quest7-opt1">
                                        AI-specific regulation will hinder innovation.
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input checked={formData.quest7 === "2"}
                                    onChange={handleChange} className=" " type="radio" name="quest7" value="2" id="quest7-opt2"/>
                                    <Form.Check.Label className=" ps-2" for="quest7-opt2">
                                        Existing laws and regulations are sufficient for now.
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input checked={formData.quest7 === "3"}
                                    onChange={handleChange} className=" " type="radio" name="quest7" value="3" id="quest7-opt3"/>
                                    <Form.Check.Label className=" ps-2" for="quest7-opt3">
                                        {/* AI risks opportunities can be addressed by updating the current regulatory
                                        framework */}
                                        Update the current regulatory framework to address AI risks and opportunities (e.g. new AI Bill)
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input checked={formData.quest7 === "4"}
                                    onChange={handleChange} className=" " type="radio" name="quest7" value="4" id="quest7-opt4"/>
                                    <Form.Check.Label className=" ps-2" for="quest7-opt4">
                                        {/* The risks are too great; a completely new regulatory framework for AI is
                                        necessary. */}
                                        Introduce narrow AI legislation to place requirements on those working to develop the most powerful AI models/systems’ (Government’s stated ambition)
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input checked={formData.quest7 === "5"}
                                    onChange={handleChange} className=" " type="radio" name="quest7" value="5" id="quest7-opt5"/>
                                    <Form.Check.Label className=" ps-2" for="quest7-opt5">
                                        {/* The risks are too great; a completely new regulatory framework for AI is
                                        necessary. */}
                                        The risks are too great; a broader new regulatory framework for AI is necessary.
                                    </Form.Check.Label>
                                </Form.Check>
                            </Card.Body>
                            {errors.quest7 && <Card.Footer className='d-flex align-items-center text-danger py-2'>
                                <ErrorIcon className='me-2' fontSize='small'/> {errors.quest7}
                            </Card.Footer>}
                        </Card>
                        <Card className="">
                            <Card.Header className="bg-primary text-light ">
                                <strong>8. AI and recent Generative AI advances are likely to have an important role in the UK’s economy and society in future.  Who do you consider the most trusted to lead our transformation towards new AI rules, regulations, norms, or technology standards?</strong> <i>( Tick any one option )</i>
                            </Card.Header>
                            <Card.Body className="px-0 py-3">
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input checked={formData.quest8 === "1"}
                                    onChange={handleChange} className=" " type="radio" name="quest8" value="1" id="quest8-opt1"/>
                                    <Form.Check.Label className=" ps-2" for="quest8-opt1">
                                        The computer scientists or software engineers hired by companies, as they
                                        directly
                                        understand the algorithms they write.
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input checked={formData.quest8 === "2"}
                                    onChange={handleChange} className=" " type="radio" name="quest8" value="2" id="quest8-opt2"/>
                                    <Form.Check.Label className=" ps-2" for="quest8-opt2">
                                       The corporations / organisations developing AI.
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input checked={formData.quest8 === "3"}
                                    onChange={handleChange} className=" " type="radio" name="quest8" value="3" id="quest8-opt3"/>
                                    <Form.Check.Label className=" ps-2" for="quest8-opt3">
                                        The corporations / organisations adopting AI into products and services sold on
                                        the
                                        market or adopted into society.
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input checked={formData.quest8 === "4"}
                                    onChange={handleChange} className=" " type="radio" name="quest8" value="4" id="quest8-opt4"/>
                                    <Form.Check.Label className=" ps-2" for="quest8-opt4">
                                        The people using the AI.
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input checked={formData.quest8 === "5"}
                                    onChange={handleChange} className=" " type="radio" name="quest8" value="5" id="quest8-opt5"/>
                                    <Form.Check.Label className=" ps-2" for="quest8-opt5">
                                        The UK Government and existing UK Regulators.
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input checked={formData.quest8 === "6"}
                                    onChange={handleChange} className=" " type="radio" name="quest8" value="6" id="quest8-opt6"/>
                                    <Form.Check.Label className=" ps-2" for="quest8-opt6">
                                        The UK Parliament setting the laws.
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input  checked={formData.quest8 === "7"}
                                    onChange={handleChange} className=" " type="radio" name="quest8" value="7" id="quest8-opt7"/>
                                    <Form.Check.Label className=" ps-2" for="quest8-opt7">
                                        Existing international institutions leading AI harmonisation principles (e.g.,
                                        EU, WTO, UN, etc.).
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input checked={formData.quest8 === "8"}
                                    onChange={handleChange} className=" " type="radio" name="quest8" value="8" id="quest8-opt8"/>
                                    <Form.Check.Label className=" ps-2" for="quest8-opt8">
                                        A new dedicated international body.
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input checked={formData.quest8 === "9"}
                                    onChange={handleChange} className=" " type="radio" name="quest8" value="9" id="quest8-opt9"/>
                                    <Form.Check.Label className=" ps-2" for="quest8-opt9">
                                        A new independent UK oversight committee with citizen involvement.
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input checked={formData.quest8 === "10"}
                                    onChange={handleChange} className=" " type="radio" name="quest8" value="10" id="quest8-opt10"/>
                                    <Form.Check.Label className=" ps-2" for="quest8-opt10">
                                        No one should oversee the transformation; it is best left to the free market.
                                    </Form.Check.Label>
                                </Form.Check>
                            </Card.Body>
                            {errors.quest8 && <Card.Footer className='d-flex align-items-center text-danger py-2'>
                            <ErrorIcon className='me-2' fontSize='small'/> {errors.quest8}
                            </Card.Footer>}
                        </Card>
                        <Card className="">
                            <Card.Header className="bg-primary text-light ">
                                <strong>9. Who is best placed to be held responsible and accountable for ensuringAI is used safely and responsibly?</strong> <i >( Tick any one option )</i>
                            </Card.Header>
                            <Card.Body className="px-0 py-3">
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input checked={formData.quest9 === "1"}
                                    onChange={handleChange} className=" " type="radio" name="quest9" value="1" id="quest9-opt1"/>
                                    <Form.Check.Label className=" ps-2" for="quest9-opt1">
                                        The computer scientists or software engineers hired by companies, as they
                                        directly understand the algorithms they write.
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input checked={formData.quest9 === "2"}
                                    onChange={handleChange} className=" " type="radio" name="quest9" value="2" id="quest9-opt2"/>
                                    <Form.Check.Label className=" ps-2" for="quest9-opt2">
                                    	The corporations or organisations developing AI.
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input checked={formData.quest9 === "3"}
                                    onChange={handleChange} className=" " type="radio" name="quest9" value="3" id="quest9-opt3"/>
                                    <Form.Check.Label className=" ps-2" for="quest9-opt3">
                                        The corporations / organisations adopting AI into products and services sold on
                                        the
                                        market or adopted into society.
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input checked={formData.quest9 === "4"}
                                    onChange={handleChange} className=" " type="radio" name="quest9" value="4" id="quest9-opt4"/>
                                    <Form.Check.Label className=" ps-2" for="quest9-opt4">
                                        The people using the AI.
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input checked={formData.quest9 === "5"}
                                    onChange={handleChange} className=" " type="radio" name="quest9" value="5" id="quest9-opt5"/>
                                    <Form.Check.Label className=" ps-2" for="quest9-opt5">
                                        The UK Government and existing UK Regulators.
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input checked={formData.quest9 === "6"}
                                    onChange={handleChange} className=" " type="radio" name="quest9" value="6" id="quest9-opt6"/>
                                    <Form.Check.Label className=" ps-2" for="quest9-opt6">
                                        The UK Parliament setting the laws.
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input checked={formData.quest9 === "7"}
                                    onChange={handleChange} className=" " type="radio" name="quest9" value="7" id="quest9-opt7"/>
                                    <Form.Check.Label className=" ps-2" for="quest9-opt7">
                                        Existing international institutions leading AI harmonisation principles (e.g.,
                                        EU,
                                        WTO, UN, etc.).
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input checked={formData.quest9 === "8"}
                                    onChange={handleChange} className=" " type="radio" name="quest9" value="8" id="quest9-opt8"/>
                                    <Form.Check.Label className=" ps-2" for="quest9-opt8">
                                        A new dedicated international body.
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input checked={formData.quest9 === "9"}
                                    onChange={handleChange} className=" " type="radio" name="quest9" value="9" id="quest9-opt9"/>
                                    <Form.Check.Label className=" ps-2" for="quest9-opt9">
                                        A new independent UK oversight committee with citizen involvement.
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input checked={formData.quest9 === "10"}
                                    onChange={handleChange} className=" " type="radio" name="quest9" value="10" id="quest9-opt10"/>
                                    <Form.Check.Label className=" ps-2" for="quest9-opt10">
                                        {/* No one should oversee the transformation; it is best left to the free market. */}
                                        	No one should be held responsible and accountable.
                                    </Form.Check.Label>
                                </Form.Check>
                            </Card.Body>
                            {errors.quest9 && <Card.Footer className='d-flex align-items-center text-danger py-2'>
                            <ErrorIcon className='me-2' fontSize='small'/> {errors.quest9}
                            </Card.Footer>}
                        </Card>
                        <Card className="">
                            <Card.Header className="bg-primary text-light ">
                                <strong>10. Given the recent and upcoming AI breakthrough (Generative AI, Agentic AI etc.) and their associated risks, which of the following safeguards do you lean most towards to encourage responsible innovation in the UK?</strong> <i >( Tick any one option )</i>
                            </Card.Header>
                            <Card.Body className="px-0 py-3">
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input checked={formData.quest10 === "1"}
                                    onChange={handleChange} className=" " type="radio" name="quest10" value="1" id="quest10-opt1"/>
                                    <Form.Check.Label className=" ps-2" for="quest10-opt1">
                                    Mandatory transparency requirements for companies developing the most capable AI models (e.g., training processes, data sources, bias tests, etc.) for both governments and users (companies deploying AI, customers).
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input checked={formData.quest10 === "2"}
                                    onChange={handleChange} className=" " type="radio" name="quest10" value="2" id="quest10-opt2"/>
                                    <Form.Check.Label className=" ps-2" for="quest10-opt2">
                                        Voluntary commitments by leading AI companies developing these most capable
                                        AI models.
                                    </Form.Check.Label>
                                </Form.Check>

                            </Card.Body>
                            {errors.quest10 && <Card.Footer className='d-flex align-items-center text-danger py-2'>
                            <ErrorIcon className='me-2' fontSize='small'/> {errors.quest10}
                            </Card.Footer>}
                        </Card>
                        <Card className="">
                            <Card.Header className="bg-primary text-light ">
                                <strong>11. Thinking about Artificial Intelligence (AI) which of the following best describes
                                you?</strong> <i>( Tick any one option )</i>
                            </Card.Header>
                            <Card.Body className="px-0 py-3">
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input checked={formData.quest11 === "1"}
                                    onChange={handleChange} className=" " type="radio" name="quest11" value="1" id="quest11-opt1"/>
                                    <Form.Check.Label className=" ps-2" for="quest11-opt1">
                                        I see myself as an expert on AI. 
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input checked={formData.quest11 === "2"}
                                    onChange={handleChange} className=" " type="radio" name="quest11" value="2" id="quest11-opt2"/>
                                    <Form.Check.Label className=" ps-2" for="quest11-opt2">
                                        I know a lot about AI but would still like to learn more.
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input checked={formData.quest11 === "3"}
                                    onChange={handleChange} className=" " type="radio" name="quest11" value="3" id="quest11-opt3"/>
                                    <Form.Check.Label className=" ps-2" for="quest11-opt3">
                                        I've heard or read about AI but recognise there's a lot more I need to learn.
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input checked={formData.quest11 === "4"}
                                    onChange={handleChange} className=" " type="radio" name="quest11" value="4" id="quest11-opt4"/>
                                    <Form.Check.Label className=" ps-2" for="quest11-opt4">
                                        I don't know much about AI and should probably learn more.
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Check className=" d-flex align-items-center">
                                    <Form.Check.Input checked={formData.quest11 === "5"}
                                    onChange={handleChange} className=" " type="radio" name="quest11" value="5" id="quest11-opt5"/>
                                    <Form.Check.Label className=" ps-2" for="quest11-opt5">
                                        I'm not really interested in AI.
                                    </Form.Check.Label>
                                </Form.Check>
                            </Card.Body>
                            {errors.quest11 && <Card.Footer className='d-flex align-items-center text-danger py-2'>
                            <ErrorIcon className='me-2' fontSize='small'/> {errors.quest11}
                            </Card.Footer>}
                        </Card>
                        <Card className="">
                            <Card.Body className="px-0 py-3 ">
                                <Form.Check className=" d-flex align-items-center" value="1"  >
                                    <Form.Check.Input checked={formData.quest12 === "1"}
                                    onChange={handleChange} className=" " type="radio" name="quest12" value="1" id="quest12-opt1"/>
                                    <Form.Check.Label className=" ps-2" for="quest12-opt1">
                                        I am from the House of Commons
                                    </Form.Check.Label>
                                </Form.Check>
                                <Form.Check className=" d-flex align-items-center" >
                                    <Form.Check.Input checked={formData.quest12 === "2"}
                                    onChange={handleChange} className=" " type="radio" name="quest12" value="2" id="quest12-opt2"/>
                                    <Form.Check.Label className=" ps-2" for="quest12-opt2">
                                        I am from the House of Lords
                                    </Form.Check.Label>
                                </Form.Check>

                                <Form.Check className=" d-flex align-items-center" >
                                    <Form.Check.Input checked={formData.quest12 === "3"}
                                    onChange={handleChange} className=" " type="radio" name="quest12" value="3" id="quest12-opt3"/>
                                    <Form.Check.Label className=" ps-2" for="quest12-opt3">
                                        I am affiliated across both the Houses&nbsp;of&nbsp;Parliament
                                    </Form.Check.Label>
                                </Form.Check>
                            </Card.Body>
                            {errors.quest12 && <Card.Footer className='d-flex align-items-center text-danger py-2'>
                            <ErrorIcon className='me-2' fontSize='small'/> {errors.quest12}
                            </Card.Footer>}
                        </Card>
                        <Card className="">
                            <Card.Header className="bg-primary text-light ">
                                Comments
                            </Card.Header>
                            <Card.Body className="px-3 py-3">
                 <Form.Group className="">
                                            <Form.Control
                                                as="textarea"
                                                name="comments"
                                                value={formData.comments}
                                                onChange={handleChange}
                                                placeholder="Add your comments here..."
                                                rows={4}
                                            />
                                        </Form.Group>
                                        </Card.Body>
                </Card>
               
                        <Card className="p-3">
                    <Card.Body class="d-flex justify-content-between">
                        <Button className="" id="submit-survey" type="submit">Submit
                            Survey</Button>
                        {/* <Button className="resultpollbt" onClick={(e)=>fetchSurveyResults(e)} id="resultpollbt" data-bs-toggle="modal" data-bs-target="#pollsResultModal" type="button">Survey Results</Button> */}
                        <Button className="resultpollbt" onClick={ () =>setShow(true)} id="resultpollbt1" data-bs-toggle="modal" data-bs-target="#pollsResultModal" type="button">Survey Results</Button>
                    </Card.Body>
                </Card>
                </Form>
            {/* Button to open the modal */}

            {/* Modal Component */}
            <Modal show={false} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Survey Result</Modal.Title>
              </Modal.Header>
              {/* Pie Chart Section */}
              <div className="mt-5">
                {/* <h2>Poll Results</h2> */}
                {loading ? (
                  <p>Loading poll data...</p>
                ) : (
                  <div>
                    {" "}
                    {/* Added a parent container */}
                    <Chart
                      chartType="PieChart"
                      data={data1}
                      options={options1}
                      width="100%"
                      height="400px"
                      loader={<div>Loading Chart...</div>}
                    />
                    <Chart
                      chartType="PieChart"
                      data={data2}
                      options={options2}
                      width="100%"
                      height="400px"
                      loader={<div>Loading Chart...</div>}
                    />
                    <Chart
                      chartType="PieChart"
                      data={data3}
                      options={options3}
                      width="100%"
                      height="400px"
                      loader={<div>Loading Chart...</div>}
                    />
                    <Chart
                      chartType="PieChart"
                      data={data4}
                      options={options4}
                      width="100%"
                      height="400px"
                      loader={<div>Loading Chart...</div>}
                    />
                    <Chart
                      chartType="PieChart"
                      data={data5}
                      options={options5}
                      width="100%"
                      height="400px"
                      loader={<div>Loading Chart...</div>}
                    />
                  </div>
                )}
              </div>{" "}
              <Modal.Body></Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </Container>
          <Modal show={show} size="lg" centered onHide={handleClose}>
                    <Modal.Header className="py-2" closeButton>
                        <Modal.Title>Survey Results</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                         {loading ? ( <Spinner className="position-relative start-50 top-50 " animation="border" /> ) : ( 
                        <>
                        

                            {surveyData?.firstQuestion && (
                                <div>
                                    <h5>1. Question</h5>
                                    {renderBarChart(surveyData.firstQuestion, "First Question Responses")}
                                </div>
                            )}
                        
                            {surveyData?.secondQuestion && (
                                <div>
                                    <h5>2. Question</h5>
                                    {renderBarChart(surveyData.secondQuestion, "Second Question Responses")}
                                </div>
                            )}

                            {surveyData?.thirdoneResults && (
                                <div>
                                    <h5>3. Question - Set 1</h5>
                                    {renderBarChart(surveyData.thirdoneResults, "Third Question - Set 1 Responses")}
                                </div>
                            )}

                            {surveyData?.thirdtwoResults && (
                                <div>
                                    <h5>Third Question - Set 2</h5>
                                    {renderBarChart(surveyData.thirdtwoResults, "Third Question - Set 2 Responses")}
                                </div>
                            )}

                            {surveyData?.fourthQuestion && (
                                <div>
                                    <h5>4. Question Responses</h5>
                                    {surveyData.fourthQuestion.map((questionData, index) => (
                                        <div key={index}>
                                            <h6>{questionData.question}</h6>
                                            <Chart
                                                chartType="PieChart"
                                                data={preparePieChartData(questionData.scores)}
                                                options={{
                                                    title: questionData.question,
                                                    pieSliceText: 'percentage',
                                                    backgroundColor: "white",
                                                }}
                                                width="100%"
                                                height="350px"
                                            />
                                        </div>
                                    ))}
                                </div>
                            )}



                            {surveyData?.fifthQuestion && (
                                <div>
                                    <h5>5. Question Responses</h5>
                                    {surveyData.fifthQuestion.map((questionData, index) => (
                                        <div key={index}>
                                            <h6>{questionData.question}</h6>
                                            <Chart
                                                chartType="PieChart"
                                                data={preparePieChartData5(questionData.scores)}
                                                options={{
                                                    title: questionData.question,
                                                    pieSliceText: 'percentage',
                                                    backgroundColor: "white",
                                                }}
                                                width="100%"
                                                height="350px"
                                            />
                                        </div>
                                    ))}
                                </div>
                            )}

                            {surveyData?.sixthQuestion && (
                                <div>
                                    <h5>6. Question Responses</h5>
                                    {surveyData.sixthQuestion.map((questionData, index) => (
                                        <div key={index}>
                                            <h6>{questionData.question}</h6>
                                            <Chart
                                                chartType="PieChart"
                                                data={preparePieChartData6(questionData.scores)}
                                                options={{
                                                    title: questionData.question,
                                                    pieSliceText: 'percentage',
                                                    backgroundColor: "white",
                                                }}
                                                width="100%"
                                                height="350px"
                                            />
                                        </div>
                                    ))}
                                </div>
                            )}

                            {surveyData?.sevenQuestion && renderBarChartForSeventhQuestion()}
                            {surveyData?.eightQuestion && renderBarChartForEighthQuestion()}
                            {surveyData?.nineQuestion && renderBarChartForNineQuestion()}
                            {surveyData?.tenQuestion && renderBarChartForTenQuestion()}
                            {surveyData?.elevenQuestion && renderBarChartForElevenQuestion()}
                            {surveyData?.twelveQuestion && renderBarChartForTwelveQuestion()}

                            Users participant count so far : {surveyDataNoofusers}
 <><br/><br/></>
                            <strong>Comments:</strong><></>
                            <><br/><br/></>
                            <ul>
        {surveyDatacomment.map((comment, index) => (
            <li key={index}>
                 {comment.comment}
            </li>
        ))}
    </ul>

                            
                        </>
)} 
                    </Modal.Body>
                    <Modal.Footer className="py-2">
                        <Button
                            size="sm"
                            variant="secondary"
                            onClick={() => setShow(false)}
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
          {/* <Modal show={show} size="lg" centered onHide={handleClose}>

            <Modal.Header className="py-2" closeButton>
              <Modal.Title>View Responses</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div dangerouslySetInnerHTML={{__html: surveyResults}}></div>
            </Modal.Body>
            <Modal.Footer className="py-2">
              <Button
                size="sm"
                variant="secondary"
                onClick={() => setShow(false)}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal> */}
        </>
      </Layout>
    );

}

export default ParliamentarySurvey;
