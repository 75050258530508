import axios from 'axios';

const token = localStorage.getItem('token');
const baseUrl = process.env.REACT_APP_BASE_URL;
const siteUrl = process.env.REACT_APP_SITE_URL;

const http = () => {
  const axiosInstance = axios.create({
    baseURL: baseUrl,
  });
  
  axiosInstance.interceptors.request.use(config => {
    // Set the Authorization header if token is available
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  });
  
// Add a response interceptor
axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        // Handle unauthorized access here
        // For example, logout the user and redirect to login page
        // console.log('Unauthorized access. Logging out...');
        // Add your logout logic here
        // Example: localStorage.removeItem('accessToken');
        // Example: history.push('/login');
        
        localStorage.removeItem('token');
        // window.location.href = "/";
      }
      return Promise.reject(error);
    }
  );
  return axiosInstance;
};

export { siteUrl, baseUrl, http as default };
