import React, { useState, useEffect } from 'react'
import AdminLayout from '../../layouts/AdminLayout'
import { Form,Container,  ListGroup } from 'react-bootstrap'
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import adminhttp from '../../../adminhttp';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
// import Stack from '@mui/material/Stack'
import Stack from 'react-bootstrap/Stack';
import Card from 'react-bootstrap/Card';

// import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AdminBreadCrumbs from '../../components/AdminBreadCrumbs';
import { Link } from 'react-router-dom'
// import Badge from '@mui/material/Badge';
import Badge from 'react-bootstrap/Badge';
import { formatDate } from '../../../components/PavilionConstants';
import DateIcon from '@mui/icons-material/InsertInvitation';
import Dropdown from 'react-bootstrap/Dropdown';
import {Row, Col} from 'react-bootstrap';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import { useLocation } from 'react-router-dom'
import EditIcon from '@mui/icons-material/DriveFileRenameOutlineTwoTone';
import SettingsIcon from '@mui/icons-material/SettingsTwoTone';
import MenusIcon from '@mui/icons-material/ListAltTwoTone';
import SponsorsIcon from '@mui/icons-material/Diversity2TwoTone';
import MultiBannerIcon from '@mui/icons-material/PermMediaTwoTone';
import StoreIcon from '@mui/icons-material/StoreTwoTone';
import RolesIcon from '@mui/icons-material/WorkTwoTone';
import MembershipsIcon from '@mui/icons-material/PeopleAltTwoTone';
import LibraryIcon from '@mui/icons-material/LibraryBooksTwoTone';
import EventIcon from '@mui/icons-material/EventAvailableTwoTone';
import EmailIcon from '@mui/icons-material/EmailTwoTone';
import PinsIcon from '@mui/icons-material/PushPinTwoTone';
import { useNavigate } from 'react-router-dom'
import BannerCard from '../../components/AdminCards/BannerCard';
import Alert from '@mui/material/Alert';


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
  variants: [
    {
      props: ({ expand }) => !expand,
      style: {
        transform: 'rotate(0deg)',
      },
    },
    {
      props: ({ expand }) => !!expand,
      style: {
        transform: 'rotate(180deg)',
      },
    },
  ],
}));


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('light', {
    backgroundColor: 'light',
  }),
}));


function handleAction(row) {
  alert(row.id)
}




const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    padding:0,
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  
  function createData(row1, row2, row3, row4, row5,row6, row7, row8 ) {
    return { row1, row2, row3, row4, row5,row6, row7, row8 };
  }
  
  const rows = [
    createData('Settings', <Checkbox />, <Checkbox  />, <Checkbox  />, <Checkbox  />,<Checkbox />, <Checkbox  />, <Checkbox  />, <Checkbox  />),
    createData('Admin Users Roles', <Checkbox/>,<Checkbox/>, <Checkbox/>, <Checkbox/>,<Checkbox />, <Checkbox  />, <Checkbox  />, <Checkbox  />),
    createData('Admin Users', <Checkbox/>, <Checkbox/>, <Checkbox/>, <Checkbox/>,<Checkbox />, <Checkbox  />, <Checkbox  />, <Checkbox  />),
    createData('Admin ACL', <Checkbox/>, <Checkbox/>, <Checkbox/>, <Checkbox/>,<Checkbox />, <Checkbox  />, <Checkbox  />, <Checkbox  />),
    createData('Pavilions', <Checkbox/>, <Checkbox/>, <Checkbox/>, <Checkbox/>,<Checkbox />, <Checkbox  />, <Checkbox  />, <Checkbox  />),
    createData('Members', <Checkbox/>, <Checkbox/>, <Checkbox/>, <Checkbox/>,<Checkbox />, <Checkbox  />, <Checkbox  />, <Checkbox  />),
    createData('Pavilion ACL', <Checkbox/>, <Checkbox/>, <Checkbox/>, <Checkbox/>,<Checkbox />, <Checkbox  />, <Checkbox  />, <Checkbox  />),
    createData('Tickets Requests', <Checkbox/>, <Checkbox/>, <Checkbox/>, <Checkbox/>,<Checkbox />, <Checkbox  />, <Checkbox  />, <Checkbox  />),
  ];
const ACL = (props) => {

  const location = useLocation();
  const data = location.state;

  const splitURL = location.pathname.toString().split("/");
    
  const [banners, setBanners] = useState([]);
  const [pvlnName, setPvlnName] = useState('');
//   const [rows, setRows] = useState(0);
  const [counter, setCounter] = useState(0);
  const [pavilionName, setPavilionName] = useState('');
  const navigate = useNavigate();

  const [expanded, setExpanded] = React.useState(false);

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to="/admin/dashboard" >
      Dashboard
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      to='/admin/pavilions'
      state={ data}
    >
      Pavilions
    </Link>,
    <Link
    underline="hover"
    key="2"
    color="inherit"
    to={`/admin/pavilions/${splitURL[3]}`}
    state={ data}
  >
    {data.name}
  </Link>,
    <Typography key="3" sx={{ color: 'text.primary' }}>
      ACL 
    </Typography>,
  ];

  function handleClick(event) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    adminhttp().get(`/admin/multibanner/${splitURL[3]}`).then((data) => {
      if(data.data.data.mobile_banner.length>0){
        setBanners(data.data.data.mobile_banner);

       }
      // setRows(data.data.data.pavilions.length);
    //   alert(data.data.data.pavilions.data.id)
    });

    
  },[]);
  return (
    <AdminLayout >
      <Container fluid className='bg-light' sx={{height:'100vh'}}>
        <Box component="section" sx={{ px: 2, pt: 2, pb: 1 }}>
          <AdminBreadCrumbs breadcrumbs={breadcrumbs} />
        </Box>

        <Box component="section" className='d-flex align-items-center justify-content-between' sx={{ px: 2, pt: 0, pb: 1 }} data-aos="fade-up">
          <h4>ACL</h4>
         <div className="d-flex justify-content-end align-items-center">
          <Form className='me-2'>
            <Form.Control type="search" size='small' placeholder="Search Members" />
          </Form>
          <Button variant='contained' sx={{backgroundColor:'#e30613'}} type="button" >Add</Button>
          {/* <Box sx={{ alignItems: "center",}}>
                <Pagination className='my-3' count={totalPages} page={page}  variant="outlined" shape="rounded" onChange={handleChange}/>
           </Box> */}
           </div>
        </Box> 

        <ListGroup variant="flush">
          
          <ListGroup.Item className='bg-transparent'>
          <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Actions </StyledTableCell>
            <StyledTableCell align="center">Select All <Checkbox defaultChecked /></StyledTableCell>
            <StyledTableCell align="center">View<Checkbox defaultChecked /></StyledTableCell>
            <StyledTableCell align="center">Create<Checkbox defaultChecked /></StyledTableCell>
            <StyledTableCell align="center">Edit<Checkbox defaultChecked /></StyledTableCell>
            <StyledTableCell align="center">Active<Checkbox defaultChecked /></StyledTableCell>
            <StyledTableCell align="center">Inactive<Checkbox defaultChecked /></StyledTableCell>
            <StyledTableCell align="center">Export<Checkbox defaultChecked /></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name} >
              <StyledTableCell component="th"  scope="row">{row.row1}</StyledTableCell>
              <StyledTableCell align="center">{row.row2}</StyledTableCell>
              <StyledTableCell align="center">{row.row3}</StyledTableCell>
              <StyledTableCell align="center">{row.row4}</StyledTableCell>
              <StyledTableCell align="center">{row.row5}</StyledTableCell>
              <StyledTableCell align="center">{row.row6}</StyledTableCell>
              <StyledTableCell align="center">{row.row7}</StyledTableCell>
              <StyledTableCell align="center">{row.row8}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
            
          </ListGroup.Item>
        </ListGroup>


      </Container>
    </AdminLayout>
  )
}

export default ACL
