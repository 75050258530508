import React, { useState, useEffect } from "react";
import { ExportToExcel } from "../../components/ExportToExcel";
import AdminLayout from "../../layouts/AdminLayout";
import { Form, Container, ListGroup } from "react-bootstrap";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import adminhttp from "../../../adminhttp";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Stack from "react-bootstrap/Stack";
import Card from "react-bootstrap/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AdminBreadCrumbs from "../../components/AdminBreadCrumbs";
import { Link } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import { formatDate } from "../../../components/PavilionConstants";
import DateIcon from "@mui/icons-material/InsertInvitation";
import Dropdown from "react-bootstrap/Dropdown";
import { Row, Col, Modal, Button } from "react-bootstrap";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation } from "react-router-dom";
import EditIcon from "@mui/icons-material/DriveFileRenameOutlineTwoTone";
import SettingsIcon from "@mui/icons-material/SettingsTwoTone";
import MenusIcon from "@mui/icons-material/ListAltTwoTone";
import SponsorsIcon from "@mui/icons-material/Diversity2TwoTone";
import MultiBannerIcon from "@mui/icons-material/PermMediaTwoTone";
import StoreIcon from "@mui/icons-material/StoreTwoTone";
import RolesIcon from "@mui/icons-material/WorkTwoTone";
import MembershipsIcon from "@mui/icons-material/PeopleAltTwoTone";
import LibraryIcon from "@mui/icons-material/LibraryBooksTwoTone";
import EventIcon from "@mui/icons-material/EventAvailableTwoTone";
import EmailIcon from "@mui/icons-material/EmailTwoTone";
import PinsIcon from "@mui/icons-material/PushPinTwoTone";
import { useNavigate } from "react-router-dom";
import SponsorCard from "../../components/AdminCards/SponsorCard";
import Alert from "@mui/material/Alert";
import RoleCard from "../../components/AdminCards/RoleCard";
import MembershipCard from "../../components/AdminCards/MembershipCard";
import AddMembership from "../../components/AdminForms/AddMembership";
import Swal from "sweetalert2";
import TicketCard from "../../components/AdminCards/TicketCard";
import AddTicket from "../../components/AdminForms/AddTicket";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import MenuCard from "../../components/AdminCards/MenuCard";
import AdminUserRoleCard from "../../components/AdminCards/AdminUserRoleCard";
import AddAdminRole from "../../components/AdminForms/AddAdminRole";
import UsersCard from "../../components/AdminCards/UsersCard";
import PaidMemshipsCard from "../../components/AdminCards/PaidMemshipsCard";
import ExpiredMemshipsCard from "../../components/AdminCards/ExpiredMemshipsCard";
import Spinner from "react-bootstrap/Spinner";
import { Pagination } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
  variants: [
    {
      props: ({ expand }) => !expand,
      style: {
        transform: "rotate(0deg)",
      },
    },
    {
      props: ({ expand }) => !!expand,
      style: {
        transform: "rotate(180deg)",
      },
    },
  ],
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  ...theme.applyStyles("dark", {
    backgroundColor: "#1A2027",
  }),
}));

const ExpiredMemships = (props) => {
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [exportData, setExportData] = useState([]);
  const today = new Date();
  const fileName = `Memberships Expiry List ${formatDate(today)}`; // here enter filename for your excel file

  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  const data = location.state;
  const [selectedEmails, setSelectedEmails] = useState([]); // Track selected emails

  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);

  const splitURL = location.pathname.toString().split("/");

  const [expiredMemships, setExpiredMemships] = useState([]);
  const [pvlnName, setPvlnName] = useState("");
  const [rows, setRows] = useState(0);
  const [counter, setCounter] = useState(0);
  const [pavilionName, setPavilionName] = useState("");
  const navigate = useNavigate();
  const [value, setValue] = useState("");
  const [filter, setFilter] = useState("0");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to="/admin/dashboard">
      Dashboard
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      to="/admin/pavilions"
      state={data}
    >
      Pavilions
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      to={`/admin/pavilions/${splitURL[3]}`}
      state={data}
    >
      {data.name}
    </Link>,
    <Typography key="3" sx={{ color: "text.primary" }}>
      Memberships Expiry
    </Typography>,
  ];

  const id = splitURL[3]; // Extract the dynamic id value
  const val = ""; // Replace with the actual value you want to send

  const [selectedValue, setSelectedValue] = useState("all"); // Default to "All"
  const [selectAll, setSelectAll] = useState(false);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue); // Update the selected value in state
    setLoading(true);
    setNoData(false);
    // Make the API call with the updated value
    adminhttp()
      .post(`/admin/expiremembership?page=${page}`, {
        id: splitURL[3],
        val: newValue,
      })
      .then((response) => {
        const memberships = response.data.data.all_data;
        if (memberships.data.length > 0) {
          setExpiredMemships(memberships.data);
          setTotalPages(memberships.last_page);
          setLoading(false);
        } else {
          setNoData(true); // No data
          setLoading(false);
          setExpiredMemships([]); // Clear data
        }
        // setLoading(false); // Hide loading spinner
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        // setLoading(false);
        // setNoData(true);
      });
  };

  const handleRequest = () => {
    setLoading(true); // Show loading spinner
    adminhttp()
      .post(`/admin/expiremembership?page=${page}`, {
        id: splitURL[3],
        val: selectedValue,
      }) // Use the selectedValue
      .then((response) => {
        const memberships = response.data.data.all_data;
        if (memberships.data.length > 0) {
          setExpiredMemships(memberships.data);
          setTotalPages(memberships.last_page);
          setNoData(false); // Data exists
        } else {
          setExpiredMemships([]); // Clear data
          setNoData(true); // No data
        }
        setLoading(false); // Hide loading spinner
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        // setLoading(false);
        // setNoData(true);
      });
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    const checkboxes = document.querySelectorAll(".item-checkbox");

    checkboxes.forEach((checkbox) => {
      checkbox.checked = !selectAll;
    });
  };

  // Handle checkbox change
  const handleCheckboxChange = (value) => {
    setSelectedEmails((prevSelectedEmails) => {
      if (prevSelectedEmails.includes(value)) {
        // Remove the value if it's already in the array
        return prevSelectedEmails.filter((v) => v !== value);
      } else {
        // Add the value to the array if it's not already there
        return [...prevSelectedEmails, value];
      }
    });
  };

  const handlePagination = (event, value) => {
    setPage(value);
  };

  // Handle send email button click
  const sendEmails = () => {
    if (selectedEmails.length === 0) {
      Swal.fire(
        "No Members Selected",
        "Please select at least one member.",
        "warning"
      );
      return;
    }

    Swal.fire({
      title: "Are you sure to send Email to all selected Members",
      text: "You won't be able to revert this!",
      icon: "warning",
      width: "auto",
      showCancelButton: true,
      confirmButtonColor: "#e30613",
      cancelButtonColor: "#6c757d",
      confirmButtonText: "Yes, Send Email!",
    }).then((result) => {
      if (result.isConfirmed) {
        // Send the selected emails with all values (in the format of `user_email__user_name__users_id...`)
        adminhttp()
          .post(`/admin/expiremail`, {
            useremail: selectedEmails,
            pavilion_id: splitURL[3],
            pavilion_name: data.name,
            pavilion_slug: data.name,
          }) // Send the selected values to backend
          .then(() => {
            Swal.fire(
              "Emails Sent",
              "Emails have been successfully sent to all selected Members.",
              "success"
            );
            setSelectedEmails([]); // Clear selection after sending emails
            //window.location.reload(); // Reload the page after sending emails
          })
          .catch((error) => {
            console.error("Error sending emails:", error);
            Swal.fire(
              "Error",
              "Failed to send emails. Please try again.",
              "error"
            );
          });
      }
    });
  };

  useEffect(() => {
    handleRequest();
  }, [selectedValue, page]);

  useEffect(() => {
    const fetchData = () => {
      adminhttp()
        .post(`/admin/expiremembership`, { id: splitURL[3], var: "all" })
        .then((data) => {
          // reshaping the array
          if (data.data.data.all_data.data.length > 0) {
            const customHeadings = data.data.data.all_data.data.map((user) => ({
              // "Membership Id": user.membership_id,
              "User Name": user.user_name,
              Email: user.user_email,
              "Membership Name": user.membership_name,
              // "Pavilion Name": data.name,
              Designation: user.designation,
              Organization: user.organization,
              "Expiration at": user.expiration_at,
            }));
            setExportData(customHeadings);
          }
        });
    };
    fetchData();
  }, []);

  return (
    <>
      <AdminLayout>
        <Container fluid className="bg-light">
          <Box component="section" sx={{ px: 2, pt: 2, pb: 1 }}>
            <AdminBreadCrumbs breadcrumbs={breadcrumbs} />
          </Box>

          <Box
            component="section"
            className="d-flex align-items-center justify-content-between header-menu"
            sx={{ px: 2, pt: 0, pb: 1 }}
            data-aos="fade-up"
          >
            <h4>Membership Expiration Details</h4>
            <div className="d-flex justify-content-end align-items-center">
              {/* <Form className="me-2">
                <Form.Control
                  type="search"
                  size="sm"
                  value={value}
                  placeholder="Search Expired Memberships"
                /> 
              </Form>*/}
              <Form.Select
                className="w-25 me-2"
                name="expire-in"
                size="sm"
                value={selectedValue}
                onChange={handleChange}
              >
                <option value="all">All</option>
                <option value="0">Expired</option>
                <option value="1">Expire in a day</option>
                <option value="7">Expire in 7 days</option>
                <option value="15">Expire in 15 days</option>
                <option value="30">Expire in 30 days</option>
              </Form.Select>

              <Button
                variant="primary"
                type="button"
                className="w-auto mx-2"
                size="sm"
                onClick={sendEmails}
              >
                Send Emails
              </Button>
              <ExportToExcel apiData={exportData} fileName={fileName} />
            </div>
          </Box>

          <ListGroup variant="flush">
            <ListGroup.Item className="bg-transparent">
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  "& > :not(style)": {
                    m: 0,
                    p: 2,
                    width: "100%",
                    height: "auto",
                  },
                }}
              >
                <Paper
                  elevation={3}
                  className="bg-secondary py-3 mb-3 rounded-4"
                >
                  <Grid container spacing={2}>
                    <Grid item xs={6} md={1}>
                      {/* <Checkbox size="small" className="itemcheckbox"
                            sx={{ color: red[500], "&.Mui-checked": {color: red[600],},}}
                            checked={selectAll} // Bind to the selectAll state
                            onChange={handleSelectAll} // Handle Select All
                          />*/}
                      <Typography
                        variant="body"
                        component="p"
                        className="mt-1 text-light"
                      >
                        Select
                      </Typography>
                    </Grid>

                    <Grid item xs={6} md={2}>
                      <Typography
                        variant="body"
                        component="p"
                        className="mt-1 text-light"
                      >
                        Name
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <Typography
                        variant="body"
                        component="p"
                        className="mt-1 text-light"
                      >
                        Email
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={1.5}
                      className="d-flex align-items-center"
                    >
                      <Typography
                        variant="body"
                        component="p"
                        className="mt-1 text-light"
                      >
                        Organizaiton
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={1.5}
                      className="d-flex align-items-center"
                    >
                      <Typography
                        variant="body"
                        component="p"
                        className="mt-1 text-light"
                      >
                        Designation
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={1.5}
                      className="d-flex align-items-center"
                    >
                      <Typography
                        variant="body"
                        component="p"
                        className="mt-1 text-light"
                      >
                        Plan
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={1.5}
                      className="d-flex align-items-center"
                    >
                      <Typography
                        variant="body"
                        component="p"
                        className="mt-1 text-light"
                      >
                        Expiration
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Box>
              {loading === true ? (
                <Box sx={{ flexGrow: 1, textAlign: "center" }}>
                  <Spinner size="lg" className="me-2 " animation="border" />
                </Box>
              ) : (
                <>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid
                      container
                      spacing={{ xs: 1, md: 1 }}
                      columns={{ xs: 1, md: 12 }}
                    >
                      {expiredMemships.map((memship, index) => (
                        <Grid item xs={12} md={12}>
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              "& > :not(style)": {
                                m: 0,
                                p: 2,
                                width: "100%",
                                height: "auto",
                              },
                            }}
                          >
                            <Paper
                              elevation={3}
                              className="py-2 mb-1 rounded-4"
                            >
                              <Grid container spacing={2}>
                                <Grid item xs={6} md={1}>
                                  <Typography
                                    variant="body"
                                    component="p"
                                    className="my-2"
                                  >
                                    <input
                                      sx={{
                                        color: red[500],
                                        "&.Mui-checked": { color: red[600] },
                                      }}
                                      className="item-checkbox"
                                      type="checkbox"
                                      value={`${memship.user_email}__${
                                        memship.user_name
                                      }__${memship.users_id}__${
                                        memship.user_purchased_membership_id
                                      }__${
                                        memship.membership_id
                                      }__${memship.expiration_at.slice(
                                        0,
                                        10
                                      )}__${memship.expiration_at.slice(
                                        0,
                                        10
                                      )}`}
                                      onChange={(e) =>
                                        handleCheckboxChange(e.target.value)
                                      } // Capture the full value when checked/unchecked
                                    />
                                  </Typography>
                                </Grid>

                                <Grid item xs={6} md={2}>
                                  <Typography
                                    variant="body"
                                    component="p"
                                    className="my-2"
                                  >
                                    {memship.user_name}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} md={3}>
                                  <Typography
                                    variant="body"
                                    component="p"
                                    className="mt-1"
                                    style={{wordBreak:'break-all'}}
                                  >
                                    {memship.user_email}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={6}
                                  md={1.5}
                                  className="d-flex align-items-center"
                                >
                                  <Typography
                                    variant="body"
                                    component="p"
                                    className=""
                                  >
                                    {memship.organization}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={6}
                                  md={1.5}
                                  className="d-flex align-items-center"
                                >
                                  <Typography
                                    variant="body"
                                    component="p"
                                    className=""
                                  >
                                    {memship.designation}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={6}
                                  md={1.5}
                                  className="d-flex align-items-center"
                                >
                                  <Typography
                                    variant="body"
                                    component="p"
                                    className=""
                                  >
                                    {memship.membership_name}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={6}
                                  md={1.5}
                                  className="d-flex align-items-center"
                                >
                                  <Typography
                                    variant="body"
                                    component="p"
                                    className=""
                                  >
                                    {formatDate(memship.expiration_at)}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Paper>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                  {noData ? (
                    <Box
                      sx={{ flexGrow: 1 }}
                      className="d-flex justify-content-center mt-3"
                    >
                      <Alert variant="outlined" severity="error">
                        Expiry Memberships not found
                      </Alert>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        margin: "auto",
                        width: "fit-content",
                        alignItems: "center",
                      }}
                    >
                      <Pagination
                        className="my-3 pb-0"
                        count={totalPages}
                        page={page}
                        variant="outlined"
                        shape="rounded"
                        onChange={handlePagination}
                      />
                    </Box>
                  )}
                </>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Container>
      </AdminLayout>
    </>
  );
};

export default ExpiredMemships;
