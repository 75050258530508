import React, { useState, useEffect, isValidElement } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FloatingLabel, Modal } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { Link, useLocation } from "react-router-dom";
import adminhttp from "../../../adminhttp";
import Swal from "sweetalert2";
import Divider from "@mui/material/Divider";
import Spinner from 'react-bootstrap/Spinner';

function AddBanner(props) {

  const [bannerModalShow, setBannerModalShow] = useState(props.showModal);

  const [validated, setValidated] = useState(false);
  const [progress, setProgress] = useState(false);

  const location = useLocation();
  const data = location.state;
  const splitURL = location.pathname.toString().split("/");

  // const [name, setName] = useState("");
  // const [category, setCategory] = useState("");
  // const [link, setLink] = useState("");
  // const [logo, setLogo] = useState("");
  // const [position, setPosition] = useState("");

  const [show, setShow] = useState(false);

  const [formData, setFormData] = useState({
    id: splitURL[3],
    pavilion_id: splitURL[3], 
    mobile_banner: "",
    oldbannerImage:"",
  });

  // Handle input changes
  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setFormData({ ...formData, mobile_banner: files[0] }); 
      setFormData({ ...formData, oldbannerImage: files[0] }); 
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // alert(formData.mobile_banner)
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }

    setValidated(true)

    if (form.checkValidity() === true) {
      setProgress(true)
      const submitData = new FormData();
      submitData.append('pavilion_id', splitURL[3]);
      submitData.append('mobile_banner', formData.mobile_banner);


      // If editing, include the banner's ID to update it
      
      if(props.edit===true){

        submitData.append('id', formData.id);
        submitData.append('oldbannerImage', formData.oldbannerImage);

          adminhttp().post("/admin/updatemobilebanner", submitData).then((response) => {
          Swal.fire({
            title: 'Banner updated successfully!',
            icon: "success",
            allowOutsideClick: false,
            confirmButtonColor: "#e30613",
          });
          setProgress(false);
          props.close();
          props.handleRequest();
        })
        .catch((error) => {
          console.error("There was an error submitting the form!", error);
        });

        
      } else {
        
        adminhttp().post("/admin/createmultibanner", submitData).then((response) => {
          Swal.fire({
            title: 'New Banner added successfully!',
            icon: "success",
            allowOutsideClick: false,
            confirmButtonColor: "#e30613",
          });
          setProgress(false);
          props.close();
          props.handleRequest();
        })
        .catch((error) => {
          console.error("There was an error submitting the form!", error);
        });
      }
      // Submit the form using axios or fetch
      // adminhttp().post("/admin/createmultibanner", submitData).then((response) => {
      //     Swal.fire({
      //       title: "Banner added successfully!",
      //       icon: "success",
      //       allowOutsideClick: false,
      //       confirmButtonColor: "#e30613",
      //     });
      //     setProgress(false)
      //     props.close();
      //     props.handleRequest();
      //   })
      //   .catch((error) => {
      //     console.error("There was an error submitting the form!", error);
      //   });
      }
  };

  return (
    <Modal centered backdrop="static"  show={props.show}
    onHide={props.close}>
      <Modal.Header className="py-1 my-1" closeButton>
      <Modal.Title>{props.edit===true ? 'Edit' : 'Add'} Banner</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            id="bannerForm"
          >
            <Form.Group controlId="validationCustom04" className="mb-3">
              <Form.Label htmlFor='mobile_banner'>Banner File (320x200 (1x,2x,3x))</Form.Label> 
              <Form.Control type="file" required 
               className={formData.mobile_banner && Object.values(formData.mobile_banner).length > 0 ? "is-valid" : "is-invalid"}
               value={formData.mobile_banner && formData.mobile_banner}  name="mobile_banner" onChange={handleChange} />
            </Form.Group>
          </Form>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.close}>
          Close
        </Button>
        <Button
          variant="primary"
          type="submit"
          form="bannerForm"
        >
          {progress && (<Spinner size='sm' className="me-2" animation="border" />)} Save Banner
        </Button>
      </Modal.Footer>
    </Modal>
    //   <Modal centered backdrop="static" show={showModal} onHide={() => setShowModal(false)}>
    //   <Modal.Header className="py-1 my-1" closeButton>
    //     <Modal.Title>{editBanner ? "Edit Banner" : "Add Banner"}</Modal.Title>
    //   </Modal.Header>
    //   <Modal.Body>
    //     <Container fluid>
    //       <Form noValidate validated={validated} onSubmit={handleSubmit} id="bannerForm">
    //         <Form.Group controlId="validationCustom04" className="mb-3">
    //           <Form.Control
    //             type="file"
    //             required
    //             className={formData.mobile_banner ? "is-valid" : "is-invalid"}
    //             name="mobile_banner"
    //             onChange={handleChange}
    //             defaultValue={editBanner ? editBanner.mobile_banner : ""}
    //           />
    //         </Form.Group>
    //       </Form>
    //     </Container>
    //   </Modal.Body>
    //   <Modal.Footer>
    //     <Button variant="secondary" onClick={() => setShowModal(false)}>
    //       Close
    //     </Button>
    //     <Button variant="primary" type="submit" form="bannerForm">
    //       {progress && <Spinner size="sm" className="me-2" animation="border" />}
    //       {editBanner ? "Save Changes" : "Save Banner"}
    //     </Button>
    //   </Modal.Footer>
    // </Modal>
  );
}

export default AddBanner;
