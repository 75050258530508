import React, { useState, useEffect, isValidElement } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FloatingLabel, Modal } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { Link, useLocation } from "react-router-dom";
import adminhttp from "../../../adminhttp";
import Swal from "sweetalert2";
import Divider from "@mui/material/Divider";
import Spinner from 'react-bootstrap/Spinner';

function AddSponsor(props) {

  const [loginModalShow, setLoginModalShow] = useState(props.showModal);

  const [validated, setValidated] = useState(false);
  const [progress, setProgress] = useState(false);
  const [sponsor, setSponsor] = useState([]);

  const location = useLocation();
  const data = location.state;
  const splitURL = location.pathname.toString().split("/");

  // const [name, setName] = useState("");
  // const [category, setCategory] = useState("");
  // const [link, setLink] = useState("");
  // const [logo, setLogo] = useState("");
  // const [position, setPosition] = useState("");

  const [show, setShow] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    category: "",
    logo: "",
    position: "",
    link: "",
    pavilion_id: splitURL[3], // Default value
  });

  // Handle input changes
  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setFormData({ ...formData, logo: files[0] }); // Store file object
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  const [apiUrl, setApiUrl]=useState('');
  const [message, setMessage]=useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    setValidated(true)

    if (form.checkValidity() === true) {
      setProgress(true)
      const submitData = new FormData();
      submitData.append("name", formData.name);
      submitData.append("category", formData.category);
      submitData.append("image", formData.logo); // File input
      submitData.append("position_at", formData.position);
      submitData.append("pavilion_id", splitURL[3]);
      submitData.append("link", formData.link);

      if(props.edit===true){
        
        adminhttp()
        .post(`/admin/update_sponsor/${props.sponsorID}`, submitData)
        .then((response) => {
          Swal.fire({
            title: 'Sponsor updated successfully!',
            icon: "success",
            allowOutsideClick: false,
            confirmButtonColor: "#e30613",
          });
          setProgress(false);
          props.close();
          props.handleRequest();
        })
        .catch((error) => {
          console.error("There was an error submitting the form!", error);
        });

        
      } else {
        
        adminhttp()
        .post("/admin/add_sponsor", submitData)
        .then((response) => {
          Swal.fire({
            title: 'New sponsor added successfully!',
            icon: "success",
            allowOutsideClick: false,
            confirmButtonColor: "#e30613",
          });
          setProgress(false);
          props.close();
          props.handleRequest();
        })
        .catch((error) => {
          console.error("There was an error submitting the form!", error);
        });
      }
      // Submit the form using axios or fetch
      // adminhttp()
      //   .post(apiUrl, submitData)
      //   .then((response) => {
      //     Swal.fire({
      //       title: message,
      //       icon: "success",
      //       allowOutsideClick: false,
      //       confirmButtonColor: "#e30613",
      //     });
      //     setProgress(false);
      //     props.close();
      //     props.handleRequest();
      //   })
      //   .catch((error) => {
      //     console.error("There was an error submitting the form!", error);
      //   });
      }
  };

  const handleRequest = () => {
    
    adminhttp()
      .post(`/admin/fetch_single_sponsor?pavilion_id=${splitURL[3]}&id=${props.sponsorID}`)
      .then((data) => {
        if (data.data.data.length > 0) {
          // setSponsor(data.data.data);
          setFormData({
            name: data.data.data[0].name,
            category: data.data.data[0].category,
            logo: data.data.data[0].logo,
            position: data.data.data[0].position_at,
            link: data.data.data[0].link,
            pavilion_id: splitURL[3], // Default value
          });
        }
      });
  };
  useEffect(() => {
    if(props.edit){
      handleRequest();
    } else {
      setFormData({
        name: "",
        category: "",
        logo: "",
        position: "",
        link: "",
        pavilion_id: splitURL[3], // Default value
      });
    }
    
  }, [props.edit, props.sponsorID]);

  return (
    <Modal centered backdrop="static"  show={props.show}
    onHide={props.close}>
      <Modal.Header className="py-1 my-1" closeButton>
        <Modal.Title>{props.edit===true ? 'Edit' : 'Add'} Supporter</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            id="sponsorForm"
          >
            
            <Form.Group controlId="validationCustom01" className="mb-1">
              <Form.Control
                name="name"
                required
                type="text"
                className={Object.values(formData.name).length > 0 ? "is-valid" : "is-invalid"}
                placeholder="Supporter Name"
                value={formData.name && formData.name}
                onChange={handleChange}
              />
              {/* value={name}
          onChange={(event) => setName(event.target.value)} */}
              {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
            </Form.Group>
            <Form.Group controlId="validationCustom02" className="mb-1">
              <Form.Select
                name="category"
                value={formData.category}
                onChange={handleChange}
              >
                <option value="0" selected disabled>
                  Select Category
                </option>
                <option value="thank you">Thank You</option>
                <option value="diamond">Diamond</option>
                <option value="gold">Gold</option>
                <option value="silver">Silver</option>
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="validationCustom03" className="mb-1">
              <Form.Control
                name="link"
                type="text"
                // className={formData.link && Object.values(formData.link).length > 0 ? "is-valid" : "is-invalid"}
                placeholder="Link"
                value={formData.link && formData.link}
                onChange={handleChange}
              />
              {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
            </Form.Group>
            <Form.Group controlId="validationCustom04" className="mb-3">
              {/* <Form.Label>Default file input example</Form.Label> */}
              <Form.Control type="file" required 
               className={formData.logo ? "is-valid" : "is-invalid"}
                 name="logo" onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="validationCustom05" className="mb-1">
              <Form.Control
                name="position"
                type="text"
                // className={formData.position &&
                //   (Object.values( formData.position).length > 0 ? "is-valid" : "is-invalid")
                // }
                placeholder="Position"
                value={formData.position && formData.position}
                onChange={handleChange}
              />
              {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
            </Form.Group>
          </Form>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.close}>
          Close
        </Button>
        <Button
          variant="primary"
          type="submit"
          form="sponsorForm"
        >
          {progress && (<Spinner size='sm' className="me-2" animation="border" />)} Save Sponsor
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddSponsor;
