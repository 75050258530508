import React, { useEffect, useState } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { Card, Row, Col, Container } from "react-bootstrap";
import { AccountBalanceOutlined } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
// import Button from '@mui/material/Button';
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Typography from "@mui/material/Typography";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { SvgIcon } from "@mui/material";
import { Home, Search } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import about from "../../../../assets/icons/about.svg";
import members from "../../../../assets/icons/members.svg";
import events from "../../../../assets/icons/myevents.svg";
import polls from "../../../../assets/icons/polls.svg";
import wallPosts from "../../../../assets/icons/wallposts.svg";
import wallGallery from "../../../../assets/icons/wallgallery.svg";
import publications from "../../../../assets/icons/publications.svg";
import library from "../../../../assets/icons/myresources.svg";
import videos from "../../../../assets/icons/videos.svg";
import photos from "../../../../assets/icons/photos2.svg";
import press from "../../../../assets/icons/press.svg";
import shop from "../../../../assets/icons/shop.svg";
import axios from "axios";
import http from "../../../../http";
import { useNavigate, useLocation } from "react-router-dom";
import PreLoader from "../../../PreLoader";
import { PLSIZE } from "../../../PavilionConstants";
import BackHandIcon from "@mui/icons-material/BackHand";
import HandshakeIcon from "@mui/icons-material/Handshake";
import Swal from "sweetalert2";

function PVLNMenu(props, { handleClick }) {
  const [loading, setLoading] = useState(true);
  const [isWhosWho, setIsWhosWho] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);

  // console.log(props);
  // console.log(props.idx);

  const navigate = useNavigate();

  const [pavilionAbout, setPavilionAboutlist] = useState([]);
  const [countrylist, setCountrylist] = useState([]);
  const token = localStorage.getItem("token");
  const [selectedOption, setSelectedOption] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [countryData, setcountryData] = useState("");
  const [pavilionName, setPavilionName] = useState([]);
  const [pavilionFoundYear, setPavilionFoundYear] = useState("");
  const [pavilionPrincipalHost, setPavilionPrincipalHost] = useState("");
  const [pavilionSeretriate, setPavilionSeretriate] = useState("");
  const [pavilionLocation, setPavilionLocation] = useState("");
  const [menuNames, setMenuNames] = useState([]);
  const [freemiumUser, setFreemiumUser] = useState("");
  const location = useLocation();
  function membershipRequired() {
    Swal.fire({
      title: "Membership Required!",
      type: "warning",
      text: "Please visit shop to purchase membership first.",
      showCancelButton: true,
      confirmButtonColor: "#e11e08",
      confirmButtonText: "Visit Shop",
    }).then((result) => {
      //window.location = BASE_URL + '/shop/' + '<?= encode_url($pavilion->name) ?>';
      if (result.isConfirmed) {
        props.handleClick("PVLN-SHOPP", props.idx);
      }
    });
  }

  useEffect(() => {
    http()
      .get("/pavilionsmenu/" + props.idx)
      .then((data) => {
        setMenuNames(data.data.data);
      });
  }, []);
  useEffect(() => {
    const splitURL = location.pathname.toString().split("/");
    if (splitURL[1] === "poll") {
      if (splitURL[2]) {
        props.handleClick("PVLN-POLLS", props.idx);
      }
    }
    if (splitURL[1] === "walls") {
      if (splitURL[2]) {
        props.handleClick("PVLN-WPOST", props.idx);
      }
    }
    if (splitURL[1] === "event") {
      if (splitURL[2]) {
        props.handleClick("PVLN-EVNTS", props.idx);
      }
    }
    if (splitURL[1] === "publication") {
      if (splitURL[2]) {
        props.handleClick("PVLN-WPUBS", props.idx);
      }
    }
    if (splitURL[1] === "newsinformation") {
      if (splitURL[2]) {
        props.handleClick("PVLN-PRESS", props.idx);
      }
    }
    if (splitURL[1] === "shop") {
      if (splitURL[2]) {
        props.handleClick("PVLN-SHOPP", props.idx);
      }
    }
    console.log(`The current route is Menu1 ${splitURL[1]}`);
  }, [location]);

  useEffect(() => {
    if (token) {
      if (props.idx) {
        http()
          .get("/about/" + props.idx)
          .then((data) => {
            if (
              data.data.data.aboutPavilion.slug === `who's-who` ||
              data.data.data.aboutPavilion.slug === `who%27s-who`
            ) {
              setIsWhosWho(true);
              props.handleClick("PVLN-MMBRS", props.idx);
            }
            setPavilionName(data.data.data.aboutPavilion.name);
            setPavilionFoundYear(data.data.data.aboutPavilion.founded_year);
            setPavilionLocation(data.data.data.aboutPavilion.location);
            setPavilionSeretriate(data.data.data.aboutPavilion.secretriat);
            setPavilionPrincipalHost(
              data.data.data.aboutPavilion.principal_host
            );
            setPavilionAboutlist(data.data.data.aboutPavilion.data);
            setFreemiumUser(data.data.data.freemiumUser);
            setLoading(false);
          });
      }
    }
  }, [props.idx]);

  const pavilions = [
    {
      phost: "UK parliament",
      seretriate: "Big Innovation Centre",
      fyear: 2019,
      location: "London",
    },
    {
      phost: "UK parliament",
      seretriate: "Big Innovation Centre",
      fyear: 2017,
      location: "London",
    },
  ];
  return (
    <ListGroup
      className="overlayContainer zoom overflow-hidden h-100 px-0 "
      style={{ borderRadius: "15px" }}
    >
      {!token && (
        <div class="box stack-top">
          <Button onClick={() => navigate(`/`)}>Login</Button>
        </div>
      )}
      <ListGroup.Item
        variant="light"
        className="d-flex justify-content-between align-items-center  border-0 text-center py-0 "
      >
        <Typography variant="small">{pavilionName}</Typography>
        <IconButton
          onClick={(event) => props.handleClick("PVLN-HOME", props.idx)}
        >
          <Home />
        </IconButton>
      </ListGroup.Item>
      <ListGroup.Item className="d-flex flex-column align-items-center justify-content-start h-100  border-0 bg-light bg-opacity-75">
        {loading ? (
          <PreLoader size={PLSIZE} />
        ) : (
          <>
            <div className="container bg-light rounded py-2">
              <Row>
                <Col>
                  <h6 className="mb-0 lead">Principal Host</h6>
                  <small className="text-muted">
                    {(pavilionPrincipalHost
                      ? pavilionPrincipalHost.split("@##@").join(",")
                      : "") + ","}{" "}
                  </small>
                  <h6 className="mb-0 mt-1 lead">Founding Year</h6>
                  <small className="text-muted">{pavilionFoundYear}</small>
                </Col>
                <Col>
                  <h6 className="mb-0 lead">Secretriat</h6>
                  <small className="text-muted">
                    {" "}
                    {(pavilionSeretriate
                      ? pavilionSeretriate.split("@##@").join(",")
                      : "") + ","}
                  </small>
                  <h6 className="mb-0 mt-1">Location</h6>
                  <small className="text-muted">{pavilionLocation}</small>
                </Col>
              </Row>
            </div>
            <div className="container slim-scrollbar mh-70  py-1">
              <div className={props.idx === 9 ? 'profile-grid-2-cols py-2' : 'profile-grid py-2' }>
                {menuNames.map((menuName, index) => (
                  <>
                    {menuName.value === "About" && (
                      <Button
                        disabled={disableBtn}
                        style={{ maxWidth: "100%" }}
                        onClick={(event) =>
                          props.handleClick("PVLN-ABOUT", props.idx)
                        }
                        variant="light"
                        size="sm"
                        className="d-flex flex-column small align-items-center justify-content-end"
                      >
                        <img src={`${localStorage.getItem('bucketName')}/images/mobilemenu/${menuName.icon}`} className="mb-1" alt={menuName.value} />
                        <Typography variant="small">{menuName.value}</Typography>
                      </Button>
                    )}

                    {menuName.value === "Members" && (
                      <Button
                        disabled={disableBtn}
                        style={{ maxWidth: "100%" }}
                        onClick={(event) =>
                          props.handleClick("PVLN-MMBRS", props.idx)
                        }
                        variant="light"
                        size="sm"
                        className="d-flex flex-column small align-items-center justify-content-end"
                      >
                        <img src={`${localStorage.getItem('bucketName')}/images/mobilemenu/${menuName.icon}`} className="mb-1" alt={menuName.value} />
                        <Typography variant="small">Members</Typography>
                      </Button>
                    )}

                    {menuName.value === "Events" && (
                      <Button
                        disabled={disableBtn}
                        onClick={(event) =>
                          props.handleClick("PVLN-EVNTS", props.idx)
                        }
                        variant="light"
                        size="sm"
                        className="d-flex small flex-column align-items-center justify-content-end"
                      >
                        <img src={`${localStorage.getItem('bucketName')}/images/mobilemenu/${menuName.icon}`} className="mb-1" alt={menuName.value} />
                        <Typography variant="small">{menuName.value}</Typography>
                      </Button>
                    )}
                    {menuName.value === "Polls" && (
                      <Button
                        disabled={disableBtn}
                        onClick={(event) =>
                          props.handleClick("PVLN-POLLS", props.idx)
                        }
                        variant="light"
                        size="sm"
                        className="d-flex flex-column small align-items-center justify-content-end"
                      >
                        <img src={`${localStorage.getItem('bucketName')}/images/mobilemenu/${menuName.icon}`} className="mb-1" alt={menuName.value} />
                        <Typography variant="small">{menuName.value}</Typography>
                      </Button>
                    )}
                    {menuName.value === "Wall Post" && (
                      <Button
                        disabled={disableBtn}
                        onClick={(event) =>
                          props.handleClick("PVLN-WPOST", props.idx)
                        }
                        variant="light"
                        size="sm"
                        className="d-flex flex-column small align-items-center justify-content-end"
                      >
                        <img
                          src={`${localStorage.getItem('bucketName')}/images/mobilemenu/${menuName.icon}`}
                          className="mb-1"
                          alt={menuName.value}
                        />
                        <Typography variant="small">{menuName.value}</Typography>
                      </Button>
                    )}
                    {menuName.value === "Wall Gallery" && (
                      <Button
                        disabled={disableBtn}
                        onClick={(event) =>
                          props.handleClick("PVLN-WGALL", props.idx)
                        }
                        variant="light"
                        size="sm"
                        className="d-flex flex-column small align-items-center justify-content-end"
                      >
                        <img
                          src={`${localStorage.getItem('bucketName')}/images/mobilemenu/${menuName.icon}`}
                          className="mb-1"
                          alt={menuName.value}
                        />
                        <Typography variant="small">{menuName.value}</Typography>
                      </Button>
                    )}
                    {menuName.value === "Publications" && (
                      <Button
                        disabled={disableBtn}
                        onClick={(event) =>
                          props.handleClick("PVLN-WPUBS", props.idx)
                        }
                        variant="light"
                        size="sm"
                        className="d-flex flex-column small align-items-center justify-content-end"
                      >
                        <img
                          src={`${localStorage.getItem('bucketName')}/images/mobilemenu/${menuName.icon}`}
                          className="mb-1"
                          alt={menuName.value}
                        />
                        <Typography variant="small">{menuName.value}</Typography>
                      </Button>
                    )}
                    {menuName.value === "Library" && (
                      <Button
                        disabled={disableBtn}
                        onClick={freemiumUser===true ? (e) => membershipRequired() : (event) =>
                          props.handleClick("PVLN-LIBRY", props.idx)
                        }
                        variant="light"
                        size="sm"
                        className="d-flex flex-column small align-items-center justify-content-end"
                      >
                        <img
                          src={`${localStorage.getItem('bucketName')}/images/mobilemenu/${menuName.icon}`}
                          className="mb-1"
                          alt={menuName.value}
                        />
                        <Typography variant="small">{menuName.value}</Typography>
                      </Button>
                    )}
                    {menuName.value === "Videos" && (
                      <Button
                        disabled={disableBtn}
                        onClick={freemiumUser===true ? (e) => membershipRequired() : (event) =>
                          props.handleClick("PVLN-VID0S", props.idx)
                        }
                        variant="light"
                        size="sm"
                        className="d-flex flex-column small align-items-center justify-content-end"
                      >
                        <img
                          src={`${localStorage.getItem('bucketName')}/images/mobilemenu/${menuName.icon}`}
                          className="mb-1"
                          alt={menuName.value}
                        />
                        <Typography variant="small">{menuName.value}</Typography>
                      </Button>
                    )}
                  {menuName.value === "Photo Albums" && (
                      <Button
                        disabled={disableBtn}
                        onClick={(event) => {
                          if (freemiumUser != true) {
                            props.handleClick("PVLN-PHOTO", props.idx);
                          } else {
                            membershipRequired();
                          }
                        }}
                        variant="light"
                        size="sm"
                        className="d-flex flex-column small align-items-center justify-content-end"
                      >
                        <img
                          src={`${localStorage.getItem('bucketName')}/images/mobilemenu/${menuName.icon}`}
                          className="mb-1"
                          alt={menuName.value}
                        />
                        <Typography variant="small">{menuName.value}</Typography>
                      </Button>
                    )}
                    {menuName.value === "Press" && (
                      <Button
                        disabled={disableBtn}
                        onClick={(event) =>
                          props.handleClick("PVLN-PRESS", props.idx)
                        }
                        variant="light"
                        size="sm"
                        className="d-flex flex-column small align-items-center justify-content-end"
                      >
                        <img
                          src={`${localStorage.getItem('bucketName')}/images/mobilemenu/${menuName.icon}`}
                          className="mb-1"
                          alt={menuName.value}
                        />
                        <Typography variant="small">{menuName.value}</Typography>
                      </Button>
                    )}
                    {menuName.value === "Shop" && (
                      <Button
                        onClick={(event) =>
                          props.handleClick("PVLN-SHOPP", props.idx)
                        }
                        variant="light"
                        size="sm"
                        className="border border-1 d-flex flex-column small align-items-center justify-content-end"
                      >
                        <img 
                        src={`${localStorage.getItem('bucketName')}/images/mobilemenu/${menuName.icon}`}
                        className="mb-1" alt={menuName.value} />
                        <Typography variant="small">{menuName.value}</Typography>
                      </Button>
                    )}

                    {/* {() => {
                      if (
                        menuName.value === "Library" ||
                        menuName.value === "Videos"
                      ) {
                        const handleClick = menuName.permission
                          ? (event) => {
                              if (!freemiumUser) {
                                props.handleClick(
                                  menuName.value === "Library" ? "PVLN-LIBRY" : "PVLN-VID0S",
                                  props.idx
                                );
                              } else {
                                membershipRequired();
                              }
                            }
                          : membershipRequired;

                        return (
                          <Button
                            key={index}
                            disabled={disableBtn}
                            onClick={handleClick}
                            variant="light"
                            size="sm"
                            className="d-flex flex-column small align-items-center justify-content-end"
                          >
                            <img
                              // src={
                              //   menuName.value === "Library" ? library : videos
                              // }
                              src={`${localStorage.getItem('bucketName')}/images/mobilemenu/${menuName.icon}`}
                              className="mb-1"
                              alt={
                                menuName.value === "Library"
                                  ? "My Wall"
                                  : "My Wall Gallery"
                              }
                            />
                            <Typography variant="small">
                              {menuName.value}
                            </Typography>
                          </Button>
                        );
                      }
                    }} */}
                    
                  </>
                ))}
              </div>
              {/* {menuName.value === "Supporters" && ( */}
                <Button
                  onClick={(event) =>
                    props.handleClick("PVLN-SUPPO", props.idx)
                  }
                  variant="light"
                  size="sm"
                  className="w-100 d-flex flex-column small align-items-center justify-content-center py-2 mt-1"
                >
                  <HandshakeIcon fontSize="small" sx={{ color: "#e30613" }} />{" "}
                  <Typography variant="small">SUPPORTERS</Typography>
                </Button>
              {/* )} */}
            </div>
          </>
        )}
      </ListGroup.Item>
    </ListGroup>
  );
}

export default PVLNMenu;
