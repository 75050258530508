import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Chip from '@mui/material/Chip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import CheckedIcon from '@mui/icons-material/Done';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Button from '@mui/material/Button';
import CRUDDropdown from "../../../components/CRUDDropdown";
import Swal from 'sweetalert2'
import adminhttp from '../../../adminhttp';


const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
  variants: [
    {
      props: ({ expand }) => !expand,
      style: {
        transform: 'rotate(0deg)',
      },
    },
    {
      props: ({ expand }) => !!expand,
      style: {
        transform: 'rotate(180deg)',
      },
    },
  ],
}));

export default function PlansCard(props) {
  const [expanded, setExpanded] = React.useState(false);

  const handleEdit = () => {
    //setShow(true)
  };
  const handleDelete = () => {
    //setShow(true)
  };

  const handleStatus = (e) => {
    e.stopPropagation();
    Swal.fire({
      title: `Are you sure to ${props.data.status===1 ? 'Deactive' : 'Activate'} this Plan?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      width: 'auto',
      showCancelButton: true,
      confirmButtonColor: "#e30613",
      cancelButtonColor: "#6c757d",
      confirmButtonText: `Yes, ${props.data.status===1 ? 'Deactive' : 'Activate'} it!`
    }).then((result) => {
      if (result.isConfirmed) {
        adminhttp().post('/admin/changepackageplanstatus', {
          package_id:props.data.id,
          status:props.data.status===1?0:1,
      }).then((data) => {
        Swal.fire({
          title: `Plan has been ${props.data.status===1 ? 'Deactivated' : 'Activated'}.`,
          icon: "success",
          allowOutsideClick: false,
          confirmButtonColor: "#e30613",
        }).then((result) => {
          if (result['isConfirmed']){
            props.handleRequest();
          }
        })
      });
      }
    });
  };
  
  const handleDropdown = () => {
    //setShow(true)
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className='rounded-3' sx={{':hover': {boxShadow: 5},}}>
      <CardHeader
        // avatar={
        //   <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
        //     R
        //   </Avatar>
        // }
        
        action={
          <CRUDDropdown
            title={<MoreVertIcon fontSize="small" />}
            handleDropdown={handleDropdown}
            addTitle="Edit Plan"
            addClick={props.handleEdit(props.data.id)}
            // editTitle="Delete Plan"
            // editClick={handleDelete}
            deleteTitle={
              props.data.status === 1
                ? "Deactivate Plan"
                : "Activate Plan"
            }
            deleteClick={handleStatus}
          />
        }
        title={<Chip label={props.data.name} variant="outlined" />}
        // subheader="Professional"
      />
      {/* <CardMedia
        component="img"
        height="194"
        image="/static/images/cards/paella.jpg"
        alt="Paella dish"
      /> */}

      <CardContent className='pt-0'>
      {/* <h5>Professional</h5> */}
      <Typography variant="h3" component="h3" gutterBottom>
      £{props.data.price}
                    <Typography variant="subtitle1" component="span">
                      / {props.data.type==='monthly' ? 'month' : 'year'}
                    </Typography>
      </Typography>                    
      <Divider/>
      <ul className='fs-8' dangerouslySetInnerHTML={{ __html: props.data.description }} />

      {/* <List dense={true}>
                <ListItem className='mb-0'>
                <CheckedIcon sx={{fontSize:'1rem'}} className='me-2' /> <ListItemText  primary="Single-line item" />
                </ListItem>
                <ListItem className='mb-0'>
                <CheckedIcon sx={{fontSize:'1rem'}} className='me-2'/> <ListItemText  primary="Single-line item" />
                </ListItem >
                <ListItem className='mb-0'>
                <CheckedIcon sx={{fontSize:'1rem'}}  className='me-2'/> <ListItemText  primary="Single-line item" />
                </ListItem>
            </List> */}

      </CardContent>
      {/* <Divider/> */}
      {/* <CardActions className='d-flex justify-content-between'> */}
          {/* <Typography level="title-lg" sx={{ mr: 'auto' }}>
            5.990€{' '}
            <Typography textColor="text.tertiary" sx={{ fontSize: 'sm' }}>
              / month
            </Typography>
          </Typography> */}
          {/* {props.data.status===1 ? (
                  <Button variant="contained" color="error" size='small'>Deactive</Button>
          ) : ( 
            <Button variant="contained" color="warning" size='small'>Active</Button>
          )}
          
          <Button variant="contained" color='info' size='small'>Edit</Button> */}

        {/* </CardActions> */}
      
    </Card>
  );
}
