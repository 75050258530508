import React, { useState, useEffect } from "react";
import AdminLayout from "../layouts/AdminLayout";
import { Container, ListGroup } from "react-bootstrap";
// import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import adminhttp from "../../adminhttp";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Stack from '@mui/material/Stack'
// import Stack from "react-bootstrap/Stack";
import Card from "react-bootstrap/Card";
// import Card from '@mui/material/Card';
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";

import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DateIcon from "@mui/icons-material/InsertInvitation";
// import EditIcon from "@mui/icons-material/DriveFileRenameOutlineTwoTone";
import EditIcon from '@mui/icons-material/Edit';
import SettingsIcon from "@mui/icons-material/Settings";
import MenusIcon from "@mui/icons-material/ListAltTwoTone";
import SponsorsIcon from "@mui/icons-material/Diversity2TwoTone";
import MultiBannerIcon from "@mui/icons-material/PermMediaTwoTone";
import StoreIcon from "@mui/icons-material/StoreTwoTone";
import RolesIcon from "@mui/icons-material/WorkTwoTone";
import MembershipsIcon from "@mui/icons-material/PeopleAltTwoTone";
import LibraryIcon from "@mui/icons-material/LibraryBooksTwoTone";
import EventIcon from "@mui/icons-material/EventAvailableTwoTone";
import EmailIcon from "@mui/icons-material/EmailTwoTone";
import PinsIcon from "@mui/icons-material/PushPinTwoTone";
import UserIcon from '@mui/icons-material/AccountCircleTwoTone';
import ExpiredIcon from '@mui/icons-material/GroupRemoveTwoTone';
import PaidIcon from '@mui/icons-material/GroupAddTwoTone';
import EventCatIcon from '@mui/icons-material/DateRangeTwoTone';
import AdminBreadCrumbs from "../components/AdminBreadCrumbs";
import PavilionIcon from '@mui/icons-material/AccountBalanceTwoTone';

import { Link } from "react-router-dom";
// import Badge from '@mui/material/Badge';
import Badge from "react-bootstrap/Badge";
import { formatDate } from "../../components/PavilionConstants";
import Dropdown from "react-bootstrap/Dropdown";
import { Row, Col } from "react-bootstrap";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import EditPavilion from "../components/AdminForms/EditPavilion";
import SendEmail from "../components/AdminForms/SendEmail";
import Swal from 'sweetalert2'
// import {getPermissions} from '../utils/utils'
import BackHandIcon from '@mui/icons-material/BackHand';
import HandshakeIcon from '@mui/icons-material/HandshakeTwoTone';
import BannersIcon from '@mui/icons-material/ViewCarouselTwoTone';
import PubCatIcon from '@mui/icons-material/InterestsTwoTone';
// import UsersIcon from '@mui/icons-material/Groups2TwoTone';
import UsersIcon from '@mui/icons-material/Diversity1TwoTone';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
  variants: [
    {
      props: ({ expand }) => !expand,
      style: {
        transform: "rotate(0deg)",
      },
    },
    {
      props: ({ expand }) => !!expand,
      style: {
        transform: "rotate(180deg)",
      },
    },
  ],
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  ...theme.applyStyles("dark", {
     backgroundColor: "#1A2027",
  }),
}));

function handleAction(row) {
  alert(row.id);
}

const Pavilion = (props) => {
  const [permissions, setPermissions]=useState(JSON.parse(localStorage.getItem('permissions')));
  const pavilion_menu = [
    <IconButton
      onClick={(e) => handleEditPavilion(e)}
      color="inherit"
      className="bg-toggle border border-1 rounded-4 shadow-sm py-3 h-100"
      sx={{ width: "100%", flexDirection: "column" }}
    >
      {/* <EditIcon sx={{ color: "#e30613", }} fontSize="large" /> */}
      <Stack spacing={-3}className='d-flex align-items-end justify-content-end' >
      <PavilionIcon sx={{ color: "#e30613", }} fontSize="large" />
      <EditIcon className='text-primary rounded-5 ' fontSize="medium" sx={{ paintOrder: 'stroke fill',
stroke: "#ffffff", strokeWidth: 3, }}/>
</Stack>
      <Typography sx={{ color: "text.secondary", fontSize: 20, pt: 2, mb: 0 }}>
        Edit Pavilion
      </Typography>
    </IconButton>,
    <IconButton
      onClick={() => navigate("settings", { state: data })}
      color="inherit"
      className="bg-toggle border border-1 rounded-4 shadow-sm py-3 h-100"
      sx={{ width: "100%", flexDirection: "column" }}
    >
      {/* <SettingsIcon sx={{ stroke: "#ffffff", strokeWidth: 1 }} style={{ color: "#e30613", }} fontSize="large" /> */}
      <Stack spacing={-2.5} className='d-flex flex-column align-items-end  justify-content-end'>
      <PavilionIcon sx={{ color: "#e30613", }} fontSize="large" />
      <SettingsIcon className='text-primary rounded-5 ms-2' fontSize="medium" sx={{ paintOrder: 'stroke fill', stroke: "#ffffff", strokeWidth: 3 }}/>
</Stack>
      <Typography sx={{ color: "text.secondary", fontSize: 20, pt: 2, mb: 0 }}>
        Settings
      </Typography>
    </IconButton>,
      <IconButton onClick={()=>navigate('menus',{state : data})} color="inherit" className='bg-toggle border border-1 rounded-4 shadow-sm py-3 h-100' sx={{ width:'100%',flexDirection: 'column' }}>
      <MenusIcon sx={{ color: "#e30613", }} fontSize='large'/>
      <Typography sx={{ color: 'text.secondary', fontSize: 20, pt:2, mb:0 }}>Menus</Typography>
    </IconButton>,
    <IconButton
      onClick={() => navigate("sponsors", { state: data })}
      color="light"
      className="bg-toggle border border-1 rounded-4 shadow-sm py-3 h-100"
      sx={{ width: "100%", flexDirection: "column" }}
    >
      <HandshakeIcon style={{ color: "#e30613", }} fontSize="large" />
      <Typography sx={{ color: "text.secondary", fontSize: 20, pt: 2, mb: 0 }}>
        Supporters
      </Typography>
    </IconButton>,
    <IconButton
      onClick={() => navigate("banners", { state: data })}
      color="light"
      className="bg-toggle border border-1 rounded-4 shadow-sm py-3 h-100"
      sx={{ width: "100%", flexDirection: "column" }}
    >
      <BannersIcon sx={{ color: "#e30613", }} fontSize="large" />
      <Typography sx={{ color: "text.secondary", fontSize: 20, pt: 2, mb: 0 }}>
        Banners
      </Typography>
    </IconButton>,
    // <IconButton onClick={()=>navigate('manageshop')} color="light" className='bg-toggle border border-1 rounded-4 shadow-sm py-3 h-100' sx={{ width:'100%',flexDirection: 'column' }}>
    //   <StoreIcon fontSize='large'/>
    //   <Typography sx={{ color: 'text.secondary', fontSize: 20, pt:2, mb:0 }}>Manage Shop</Typography>
    // </IconButton>,
    <IconButton
      onClick={() => navigate("roles", { state: data })}
      color="light"
      className="bg-toggle border border-1 rounded-4 shadow-sm py-3 h-100"
      sx={{ width: "100%", flexDirection: "column" }}
    >
      <RolesIcon sx={{ color: "#e30613", }} fontSize="large" />
      <Typography sx={{ color: "text.secondary", fontSize: 20, pt: 2, mb: 0 }}>
        Roles
      </Typography>
    </IconButton>,

    <IconButton
      onClick={() => navigate("memberships", { state: data })}
      color="light"
      className="bg-toggle border border-1 rounded-4 shadow-sm py-3 h-100"
      sx={{ width: "100%", flexDirection: "column" }}
    >
      <MembershipsIcon sx={{ color: "#e30613", }} fontSize="large" />
      <Typography sx={{ color: "text.secondary", fontSize: 20, pt: 2, mb: 0 }}>
        Memberships
      </Typography>
    </IconButton>,

    //   <IconButton onClick={()=>navigate('mylibrary')} color="light" className='bg-toggle border border-1 rounded-4 shadow-sm py-3 h-100' sx={{ width:'100%',flexDirection: 'column' }}>
    //   <LibraryIcon fontSize='large'/>
    //   <Typography sx={{ color: 'text.secondary', fontSize: 20, pt:2, mb:0 }}>Library</Typography>
    // </IconButton>,

    <IconButton
      onClick={() => navigate("eventcategories", { state: data })}
      color="light"
      className="bg-toggle border border-1 rounded-4 shadow-sm py-3 h-100"
      sx={{ width: "100%", flexDirection: "column" }}
    >
      <EventCatIcon sx={{ color: "#e30613", }} fontSize="large" />
      <Typography sx={{ color: "text.secondary", fontSize: 20, pt: 2, mb: 0 }}>
        Events Categories
      </Typography>
    </IconButton>,

    <IconButton
      onClick={() => navigate("categories", { state: data })}
      color="light"
      className="bg-toggle border border-1 rounded-4 shadow-sm py-3 h-100"
      sx={{ width: "100%", flexDirection: "column" }}
    >
      <PubCatIcon sx={{ color: "#e30613", }} fontSize="large" />
      <Typography sx={{ color: "text.secondary", fontSize: 20, pt: 2, mb: 0 }}>
        Publication Categories
      </Typography>
    </IconButton>,
    // <IconButton onClick={()=>navigate('communityengagement')} color="light" className='bg-toggle border border-1 rounded-4 shadow-sm py-3 h-100' sx={{ width:'100%',flexDirection: 'column' }}>
    // <EventIcon fontSize='large'/>
    // <Typography sx={{ color: 'text.secondary', fontSize: 20, pt:2, mb:0 }}>Community Engagement</Typography>
    // </IconButton>,

    <IconButton
      // onClick={() => setShowEModal(true)}
      onClick={() => navigate("sendemail", { state: data })}
      color="light"
      className="bg-toggle border border-1 rounded-4 shadow-sm py-3 h-100"
      sx={{ width: "100%", flexDirection: "column" }}
    >
      <EmailIcon sx={{ color: "#e30613", }} fontSize="large" />
      <Typography sx={{ color: "text.secondary", fontSize: 20, pt: 2, mb: 0 }}>
        Send Email
      </Typography>
    </IconButton>,

    // <IconButton onClick={()=>navigate('featuredpins')} color="light" className='bg-toggle border border-1 rounded-4 shadow-sm py-3 h-100' sx={{ width:'100%',flexDirection: 'column' }}>
    // <PinsIcon fontSize='large'/>
    // <Typography sx={{ color: 'text.secondary', fontSize: 20, pt:2, mb:0 }}>Featured Pins</Typography>
    // </IconButton>,
    <IconButton
      onClick={() => navigate("users", { state: data })}
      color="light"
      className="bg-toggle border border-1 rounded-4 shadow-sm py-3 h-100"
      sx={{ width: "100%", flexDirection: "column" }}
    >
      <UsersIcon sx={{ color: "#e30613", }} fontSize="large" />
      <Typography sx={{ color: "text.secondary", fontSize: 20, pt: 2, mb: 0 }}>
        Users
      </Typography>
    </IconButton>,
    <IconButton
      onClick={() => navigate("paidmemships", { state: data })}
      color="light"
      className="bg-toggle border border-1 rounded-4 shadow-sm py-3 h-100"
      sx={{ width: "100%", flexDirection: "column" }}
    >
      <PaidIcon sx={{ color: "#e30613", }} fontSize="large" />
      <Typography sx={{ color: "text.secondary", fontSize: 20, pt: 2, mb: 0 }}>
        Paid Memberships
      </Typography>
    </IconButton>,
    <IconButton
      onClick={() => navigate("expiredmemships", { state: data })}
      color="light"
      className="bg-toggle border border-1 rounded-4 shadow-sm py-3 h-100"
      sx={{ width: "100%", flexDirection: "column" }}
    >
      <ExpiredIcon sx={{ color: "#e30613", }} fontSize="large" />
      <Typography sx={{ color: "text.secondary", fontSize: 20, pt: 2, mb: 0 }}>
        Expired Memberships
      </Typography>
    </IconButton>,
  ];

  const location = useLocation();
  const data = location.state;

  const splitURL = location.pathname.toString().split("/");
  
  const [pavilions, setPavilions] = useState([]);
  const [rows, setRows] = useState(0);
  const [counter, setCounter] = useState(0);
  const [pavilionName, setPavilionName] = useState("");
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [showEModal, setShowEModal] = useState(false);

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to="/admin/dashboard">
      Dashboard
    </Link>,
    <Link underline="hover" key="2" color="inherit" to="/admin/pavilions">
      Pavilions
    </Link>,
    <Typography key="3" sx={{ color: "text.primary" }}>
      {data.name}
    </Typography>,
  ];

  const handleEditPavilion = (e) => {
   if(Object.values(permissions).length>0 && permissions['edit-pavilions']){
     setShowModal(true)
   } 
   if(Object.values(permissions).length>0 && !permissions['edit-pavilions']){
    Swal.fire({
      title: "Permission Denied",
      text: "You don't have permission, please contact Administrator",
      icon: "warning",
      confirmButtonColor: "#d33",
    })
   }
  }
  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }

  // const handleExpandClick = () => {
  //   setExpanded(!expanded);
  // };

  // useEffect(() => {
    // adminhttp().get(`/admin/pavilions?id=${splitURL[3]}`).then((data) => {
    //    setPavilions(data.data.data.pavilions.data);
    //   // setRows(data.data.data.pavilions.length);
    //   alert(data.data.data.pavilions.data.id)
    // });
  // }, []);

  // useEffect(() => {
  //     getPermissions().then((res)=> (setPermissions(res)))
  // }, []);
  
  return (
    <>
      <AdminLayout>
        <Container fluid className="bg-light h-100">
          <Box component="section" sx={{ px: 2, pt: 2, pb: 1 }}>
            <AdminBreadCrumbs breadcrumbs={breadcrumbs} />
          </Box>

          <Box component="section" sx={{ px: 2, pt: 0, pb: 1 }}>
            <h4> {data.name} </h4>
          </Box>
          <ListGroup variant="flush">
            {/* <ListGroup.Item className='d-flex justify-content-between'>
            <h4>All Pavilions</h4>
            <Button>Add Pavilion</Button>
          </ListGroup.Item> */}
            <ListGroup.Item className="bg-transparent">
              {/* <Container fluid >
              <Box sx={{ height: 'auto', width: '100%' }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  sx={{
                    maxWidth: 'fit-content',
                    boxShadow: 0,
                    border: 0,
                    borderColor: 'secondary.light',
                    '& .MuiDataGrid-cell:hover': {
                      color: 'primary.main',
                    },
                    '& .MuiDataGrid-cell:visited': {
                      border: 0,
                    },
                  }}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                  }}
                  pageSizeOptions={[5]}
                  checkboxSelection
                  disableRowSelectionOnClick
                  slots={{ toolbar: GridToolbar }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                    },
                  }}

                />

              </Box>
            </Container> */}
              <Box sx={{ flexGrow: 1 }}>
                <Grid
                  container
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  {pavilion_menu.map((menu, index) => (
                    <Grid item xs={2} sm={2} md={2} key={index}>
                      {/* <Item key={index}> */}
                      {menu}
                      {/* </Item> */}
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </ListGroup.Item>
          </ListGroup>
        </Container>
      </AdminLayout>
      <EditPavilion show={showModal} close={() => setShowModal(false)} />
      <SendEmail show={showEModal} close={() => setShowEModal(false)} />
    </>
  );
};

export default Pavilion;
