import React, { useState, useEffect, isValidElement } from 'react'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { FloatingLabel } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom'
import http, {baseUrl} from '../http';
import Swal from 'sweetalert2';

function RegisterForm2() {
    const [validated, setValidated] = useState(false);
    const [showForm, setShowForm] = useState(true);

    const [fname, setFName] = useState('');
    const [lname, setLName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');

    const [password, setPassword] = useState('');
    const [cpassword, setCPassword] = useState('');

    const [desig, setDesig] = useState('');
    const [org, setOrg] = useState('');

    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [cPasswordClass, setCPasswordClass] = useState('form-control');
    const [isCPasswordDirty, setIsCPasswordDirty] = useState(false);
        
	const areEqual = ((password.trim().length>0 && cpassword.trim().length>0) ? (password===cpassword ? true : false) : false)
	const result = areEqual ? "is-valid" : "is-invalid";
	
	const emailcheck = (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email) ? false : true);
	const resultEmail = emailcheck ? "is-valid" : "is-invalid";

	


async function adduser() {
		if(emailcheck){
			try {
			  await fetch(baseUrl+'/register', {
				method: 'POST',
				headers: {
				  'Content-Type': 'application/json',
				},
				body: JSON.stringify({
				  first_name: fname,
				  last_name: lname,
				  c_password: cpassword,
				  password: password,
				  designation: desig,
				  phone_no: phone,
				  organization: org,
				  email: email,
				}),
			  })
				.then((respose) => {
				  if (respose.ok) {
					return respose.json()
				  }else{
					//alert('Server issue!');
				  }
				})
				.then((data) => {
				  if (data.data.token) {
					setShowForm(false);
					//localStorage.setItem('token', data.data.token);
				  }			  
				})
			} catch (error) {
				console.debug(error);
				//alert('Please give correct values');
			}
		}
	}
	

async function registerRequest() {
	  if((email.length)>0 && emailcheck){		
			try {
			await fetch(baseUrl+'/userExist', {
			method: 'POST',
			headers: {
			  'Content-Type': 'application/json'
			},
			body: JSON.stringify({
			 user_email: email
			}),
			})
			.then((respose) => {
			  if (respose.ok) {
				return respose.json();
			  }else{
				  //console.debug(respose);
				  //alert('Server issue!');
			  }
			})
			.then((data) => {
			  if (data.message=='yes') {
                Swal.fire({
                    title: 'The email already exists; please try with another email.',
                    icon: 'error',
                });
				setEmail('');
			  } else {
				adduser();
			  }
			})
			} catch (error) {
                Swal.fire({
                    title: 'Server Issue! Please try again later.',
                    icon: 'error',
                });
			} 
		}
	}

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        const pwd=form.password.value;
        const cpwd=form.cpassword.value;
		event.preventDefault();
		registerRequest();
    };


    return (
        <>
            {showForm ? (<Container fluid>
                <h3 className="fw-bold text-light lh-1 mb-3">Welcome to Pavilion 3.0 </h3>
                <p className="lead text-light">Share your professional interests, contribute and be connected with your community.</p>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Form.Group controlId="validationCustom01" className="mb-1">
                        <Form.Control required type="text" className={fname.trim().length>0?'is-valid':'is-invalid'} placeholder="First name" value={fname} onChange={(event) => setFName(event.target.value)} />
                    </Form.Group>
                    <Form.Group controlId="validationCustom02" className="mb-1">
                        <Form.Control required type="text" className={lname.trim().length>0?'is-valid':'is-invalid'} placeholder="Last Name" value={lname} onChange={(event) => setLName(event.target.value)}/>
                    </Form.Group>
                    <Form.Group controlId="validationCustom03" className="mb-1">
                        <Form.Control size="sm" required className={resultEmail}  type="email" placeholder="Email" id="email" value={email} onChange={(event) => setEmail(event.target.value)} />
                    </Form.Group>
                    <Form.Group controlId="validationCustom07" className="mb-1">
                        <Form.Control required type="tel" className={phone.trim().length>0?'is-valid':'is-invalid'} placeholder="Phone" value={phone} onChange={(event) => setPhone(event.target.value)}/>
                    </Form.Group>
                    <Form.Group controlId="validationCustom04" className="mb-1">
                        <Form.Control className={result} required type="password" id="password" value={password} onChange={(event) => setPassword(event.target.value)}
                            placeholder="Password" />
                    </Form.Group>
                    <Form.Group controlId="validationCustom05" className="mb-1">
                        <Form.Control className={result} required type="password" id='cpassword' value={cpassword} onChange={(event) => setCPassword(event.target.value)} placeholder="Confirm Password" />
                    </Form.Group>
                    <Form.Group controlId="validationCustom06" className="mb-1">
                        <Form.Control required type="text" className={desig.trim().length>0?'is-valid':'is-invalid'} placeholder="Designation" value={desig} onChange={(event) => setDesig(event.target.value)} />
                    </Form.Group>
                    <Form.Group controlId="validationCustom07" className="mb-1">
                        <Form.Control required type="text" className={org.trim().length>0?'is-valid':'is-invalid'} placeholder="Organization" value={org} onChange={(event) => setOrg(event.target.value)} />
                    </Form.Group>
                    <Button variant='light' className="w-100" type="submit">Sign Up</Button>
                </Form>
                <div className="text-light text-center mt-3">
                    <p>Already have an account? <Link style={{ textDecoration: 'none' }} to="/">Sign In</Link></p>
                </div>

            </Container>) : (
                <Container className="text-light">
                    <h1 >Congratulations!</h1>
                    <p>Your account has been created successfully and you can now login with your credentials.</p>
                    <Button as={Link} to="/" variant="light">Login</Button>
                </Container>
            )}
        </>
    )
}

export default RegisterForm2