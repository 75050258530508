import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Link, useNavigate } from "react-router-dom";
import { formatDate } from "../../../components/PavilionConstants";
import {
  Form,
  Container,
  Button,
  Stack,
  ListGroup,
  ButtonGroup,
  Row,
  Col,
  Image,
} from "react-bootstrap";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
// import Card from "react-bootstrap/Card";
import BadgeAvatar from "../../../components/BadgeAvatar";
import Grid from "@mui/material/Grid";
import AdminBreadCrumbs from "../../components/AdminBreadCrumbs";
import Card from "@mui/material/Card";
import { CardMedia } from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Badge from "react-bootstrap/Badge";
import { DateDiff } from "../../../components/PavilionConstants";
import { Pagination, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/DriveFileRenameOutlineTwoTone";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Chip from "@mui/material/Chip";
import Dropdown from "react-bootstrap/Dropdown";
import CRUDDropdown from "../../../components/CRUDDropdown";
import Swal from 'sweetalert2'
import adminhttp from '../../../adminhttp';
import { useLocation } from 'react-router-dom'

const SponsorCard = (props) => {

  const [logoPath, setLogoPath] = useState(
    "https://bic3-live.s3.eu-west-2.amazonaws.com" + "/images/sponsor/"
  );

  const navigate = useNavigate();
  const location = useLocation();
  // const data = location.state;
  const splitURL = location.pathname.toString().split("/");
  const handleEdit = () => {
    // setShowModal(true)
  };
  const handleDelete = (e) => {
      e.stopPropagation();
      Swal.fire({
        title: "Are you sure to delete this Sponsor?",
        text: "You won't be able to revert this!",
        icon: "warning",
        width: 'auto',
        showCancelButton: true,
        confirmButtonColor: "#e30613",
        cancelButtonColor: "#6c757d",
        confirmButtonText: "Yes, delete it!"
      }).then((result) => {
        if (result.isConfirmed) {
          adminhttp().post('/admin/delete_sponsor', {
            id: props.data.id,
            pavilion_id: splitURL[3],
        }).then((data) => {
          
          Swal.fire({
            title: "Your Sponsor has been deleted.",
            icon: "success",
            allowOutsideClick: false,
            confirmButtonColor: "#e30613",
          }).then((result) => {
            if (result['isConfirmed']){
              props.handleRequest();
            }
          })
        });
        }
      });
  };
  const handleDropdown = () => {
    //setShow(true)
  };

  return (
    <Card className="rounded-3 " sx={{ ":hover": { boxShadow: 5 } }}>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ position: "relative" }}
      >
        <CardMedia
          component="img"
          className="p-3"
          sx={{ width: "180px", height: "180px", objectFit: "contain" }}
          image={
            localStorage.getItem("bucketName") +
            "/images/sponsor/" +
            props.data.logo
          }
        />
        <div
          className="text-end p-2 rounded-5"
          style={{
            backgroundColor: "#fff",
            position: "absolute",
            color: "white",
            top: 0,
            right: "0",
            transform: "translateX(0)",
          }}
        >
          <CRUDDropdown
            title={<MoreVertIcon fontSize="small" />}
            handleDropdown={handleDropdown}
            addTitle="Edit Sponsor"
            addClick={props.handleEdit(props.data.id)}
            editTitle="Delete Sponsor"
            editClick={handleDelete}
            // deleteTitle={
            //   props.data.status === 1
            //     ? "Deactivate Sponsor"
            //     : "Activate Sponsor"
            // }
            // deleteClick={handleDelete}
          />
        </div>
      </div>
    </Card>
  );
};

export default SponsorCard;
