import React, {useState} from 'react'
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import CRUDDropdown from '../../components/CRUDDropdown'
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
export const ExportToExcel = ({ apiData, fileName }) => {
  const [ftype,setFtype]=useState('');

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  // const fileExtension = `.${ftype}`;

  const handleDropdown = () => {
    //setShow(true)
  };

  const exportToXLSX = (apiData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + '.xlsx');
  };

  const exportToCSV = (apiData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: 'csv', type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + '.csv');
  };

  return (
    
    <Dropdown >
      <Dropdown.Toggle  size='sm' sx={{backgroundColor:'#e30613'}} id="dropdown-basic" sx={{zIndex:'999999999999'}}>
        Export as <ArrowDownIcon fontSize='small'/>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={(e) => exportToXLSX(apiData,fileName)}>Export as XLSX</Dropdown.Item>
        <Dropdown.Item onClick={(e) => exportToCSV(apiData,fileName)}>Export as CSV</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
    // <CRUDDropdown
    //         title='Export as &#129171;' 
    //         handleDropdown={handleDropdown}
    //         addTitle="Export as XLSX"
    //         addClick={(e) => exportToXLSX(apiData,fileName)}
    //         editTitle="Export as CSV"
    //         editClick={(e) => exportToCSV(apiData,fileName)}
    //       />
    // <button className='btn btn-primary btn-sm' onClick={(e) => exportToCSV(apiData, fileName)}>Export</button>
  );
};
