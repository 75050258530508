import React from "react";
import { styled } from "@mui/material/styles";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { formatDate } from "../../../components/PavilionConstants";
import {
  Form,
  Container,
  Button,
  Stack,
  ListGroup,
  ButtonGroup,
  Row,
  Col,
  Image,
} from "react-bootstrap";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
// import Card from "react-bootstrap/Card";
import BadgeAvatar from "../../../components/BadgeAvatar";
import Grid from "@mui/material/Grid";
import AdminBreadCrumbs from "../AdminBreadCrumbs";

import Badge from "@mui/material/Badge";

import { Pagination } from "@mui/material";

import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import { Typography } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import CRUDDropdown from "../../../components/CRUDDropdown";
import HiveIcon from "@mui/icons-material/Hive";
import HubIcon from "@mui/icons-material/Hub";
import Avatar from "@mui/material/Avatar";
import Swal from "sweetalert2";
import adminhttp from "../../../adminhttp";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";

const TicketCard = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  // const data = location.state;
  // const data2 = {
  //   name: data.name,
  //   ticketname: props.data.name,
  // }
  const splitURL = location.pathname.toString().split("/");

  const handleEdit = () => {
    //setShow(true)
  };
  const handleDelete = () => {
    //setShow(true)
  };
  const handleDropdown = (e) => {
    e.stopPropagation();
    //setShow(true)
  };
  const handleStatus = (e) => {
    e.stopPropagation();
    Swal.fire({
      title: `Are you sure to ${
        props.data.status === 1 ? "Deactive" : "Activate"
      } this Ticket?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      width: "auto",
      showCancelButton: true,
      confirmButtonColor: "#e30613",
      cancelButtonColor: "#6c757d",
      confirmButtonText: `Yes, ${
        props.data.status === 1 ? "Deactive" : "Activate"
      } this Membership!`,
    }).then((result) => {
      if (result.isConfirmed) {
        adminhttp()
          .post("/admin/update_membership_status", {
            membership_id: props.data.id,
            pavilion_id: splitURL[3],
            status: props.data.status === 1 ? 0 : 1,
          })
          .then((data) => {
            Swal.fire({
              title: `Membership has been ${
                props.data.status === 1 ? "Deactivated" : "Activated"
              }.`,
              icon: "success",
              allowOutsideClick: false,
              confirmButtonColor: "#e30613",
            }).then((result) => {
              if (result["isConfirmed"]) {
                props.handleRequest();
              }
            });
          });
      }
    });
  };
  return (
    <Accordion className="rounded-4 bg-toggle">
      <AccordionSummary
        sx={{ flexDirection: "row-reverse" }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Box sx={{ flexGrow: 1 }} className="ms-3 my-0">
          <Grid container spacing={2}>
            <Grid item xs={6} md={1}>
              <Typography variant="body" component="p" className="mt-1">
                {props.data.ticket_id}
              </Typography>
            </Grid>
            <Grid item xs={6} md={1.5}>
              {/* <BadgeAvatar src={localStorage.getItem('bucketName')+'/images/pavilion/thumbnails/'+ props.data.image} name={props.data.name} size='50'/>  */}
              <Typography variant="body" component="p" className="mt-1">
                {formatDate(props.data.created_at)}
              </Typography>
              {/* <Avatar > <HubIcon /> </Avatar> */}
            </Grid>
            <Grid item xs={6} md={1.5} className="d-flex align-items-center">
              <Typography variant="body" component="p">
                {props.data.name}
              </Typography>
            </Grid>
            <Grid item xs={6} md={2} className="d-flex align-items-center">
              <Typography variant="body" component="p">
                {props.data.subject}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              md={2}
              className="d-flex align-items-center"
              wrap="wrap"
            >
              <Typography variant="body" component="p">
                {props.data.email}
              </Typography>
            </Grid>
            <Grid item md={1} className="d-flex align-items-center">
              <Typography variant="body" component="p">
                <Avatar
                  variant="square"
                  src={`${localStorage.getItem("bucketName")}/images/tickets/${
                    props.data.attachments
                  }`}
                  name={props.data.name}
                  size="50"
                />
              </Typography>
            </Grid>

            <Grid item xs={6} md={1} className="d-flex align-items-center">
              <Typography variant="body" component="p">
                <Chip
                  label={props.data.priority}
                  variant="outlined"
                  size="small"
                  color={
                    props.data.priority === "LOW"
                      ? "success"
                      : props.data.priority === "HIGH"
                      ? "error"
                      : "warning"
                  }
                />
              </Typography>
            </Grid>

            <Grid item md={1} className="d-flex align-items-center">
              <Typography variant="body" component="p">
                <Chip
                  label={props.data.status}
                  variant="outlined"
                  size="small"
                />
              </Typography>
            </Grid>

            <Grid
              item
              xs={6}
              md={1}
              className="d-flex align-items-center justify-content-end"
            >
              <CRUDDropdown
                title={<MoreVertIcon fontSize="small" />}
                handleDropdown={(e) => handleDropdown(e)}
                addTitle="Update Status"
                addClick={props.handleEdit(props.data.id)}
                deleteTitle="ACL"
                // deleteClick={() => navigate(`${props.data.id}`, { state: data2 })}
              />
            </Grid>
          </Grid>
        </Box>
      </AccordionSummary>
      <Divider />
      <AccordionDetails>
        <h5>Description</h5>
        {props.data.description}
      </AccordionDetails>
    </Accordion>
    //     <Card className='rounded-3'   sx={{':hover': {boxShadow: 5},}}>
    //     <div style={{ position: "relative" }}>
    //       <CardMedia style={{ }}   component="img" image={"https://img.freepik.com/free-vector/colorful-watercolor-background_23-2148492944.jpg?size=626&ext=jpg&ga=GA1.1.942094041.1718883224&semt=ais_hybrid"} title="Pancakes" alt="Pancakes"/>
    //       <div style={{width:'100%',height:'100%',backgroundColor:'rgba(0,0,0,0.5)',position: "absolute", color: "white",top: 0,left: "50%",transform: "translateX(-50%)",}}>
    //       <div className='h-100 d-flex flex-column align-items-center justify-content-center w-100'>
    //         <BadgeAvatar
    //                   src={localStorage.getItem('bucketName')+'images/profile_picture/'+ props.data.image}
    //                   name={props.data.name}
    //                   size="70"
    //                 />
    //                   <Typography variant='h5' className='mt-1 text-light fw-bold'>{props.data.name}</Typography>
    //                   <Typography variant='h6' className='text-light'>{props.data.email}</Typography>
    //         <ProgressBar now={60} variant='warning' style={{height:'5px' , width:'100%'}} className='w-75 mt-3' />
    //         </div>
    //       </div>
    //   </div>
    //   <CardContent className='pb-1 pt-4 text-center'>
    //   <Grid container spacing={2}>
    //         <Grid item xs={4}>
    //         <Badge fullWidth className='mb-3' color="success" max={formatDate(props.data.last_login_at)} badgeContent={props.data.last_login_at ? formatDate(props.data.last_login) : '0' }></Badge>
    //         <h6>Last Login</h6>
    //         </Grid>
    //         <Grid item xs={4}>
    //         <Badge  className='mb-3' color="warning" max={formatDate(props.data.last_seen)} badgeContent={formatDate(props.data.last_seen)}></Badge>
    //         <h6>Last Seen</h6>
    //         </Grid>
    //         <Grid item xs={4}>
    //         <Badge  className='mb-3' color="info" max={props.data.status} badgeContent={props.data.status===1 ? 'Active' : 'Deactive'}></Badge>
    //         <h6>Status</h6>
    //         </Grid>
    //       </Grid>
    //       </CardContent>
    // </Card>
  );
};

export default TicketCard;
