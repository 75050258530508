import React, { useState, useEffect, isValidElement } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FloatingLabel, Modal } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { Link, useLocation } from "react-router-dom";
import adminhttp from "../../../adminhttp";
import Swal from "sweetalert2";
import Divider from "@mui/material/Divider";
import Spinner from 'react-bootstrap/Spinner';

function AddMenu(props) {

  const [loginModalShow, setLoginModalShow] = useState(props.showModal);

  const [validated, setValidated] = useState(false);
  const [progress, setProgress] = useState(false);
  const [menu, setMenu] = useState([]);

  const location = useLocation();
  const data = location.state;
  const splitURL = location.pathname.toString().split("/");

  // const [name, setName] = useState("");
  // const [category, setCategory] = useState("");
  // const [link, setLink] = useState("");
  // const [logo, setLogo] = useState("");
  // const [position, setPosition] = useState("");

  const [show, setShow] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    permissions: "",
    parent: "",
    order: "",
    status: "",
    iconorder: "",
    iconimage: "",
    menuid: "",
    slug:"",
  });

  // Handle input changes
  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setFormData({ ...formData, iconimage: files[0] }); // Store file object
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  const [apiUrl, setApiUrl]=useState('');
  const [message, setMessage]=useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === true) {
      setProgress(true)
      const submitData = new FormData();
      submitData.append("pavilion_id", splitURL[3]);
      submitData.append("name", formData.name);
      submitData.append("permission", formData.permissions);
      submitData.append("parent", formData.parent); 
      submitData.append("order", formData.order);
      submitData.append("status", formData.status);
      submitData.append("icon_order", formData.iconorder);
      submitData.append("mobilemenu", formData.iconimage); // File input
      
      if(props.edit===true){
      
        submitData.append("menu_id", props.menuID);
        submitData.append("slug", formData.slug);

        adminhttp()
        .post(`/admin/updatemenu`, submitData)
        .then((response) => {
          Swal.fire({
            title: 'Menu updated successfully!',
            icon: "success",
            allowOutsideClick: false,
            confirmButtonColor: "#e30613",
          });
          setProgress(false);
          props.close();
          props.handleRequest();
        })
        .catch((error) => {
          console.error("There was an error submitting the form!", error);
        });

        
      } else {
        adminhttp().post("/admin/menustore", submitData).then((response) => {
          Swal.fire({
            title: 'New Menu added successfully!',
            icon: "success",
            allowOutsideClick: false,
            confirmButtonColor: "#e30613",
          });
          setProgress(false);
          props.close();
          props.handleRequest();
        })
        .catch((error) => {
          console.error("There was an error submitting the form!", error);
        });
      }
      }
  };

  const handleRequest = () => {
    
    adminhttp()
      .post(`/admin/menulist?pavilion_id=${splitURL[3]}`)
      .then((data) => {
        if (data.data.data.result.length > 0) {
          // setSponsor(data.data.data);
          setFormData({
            name: data.data.data.result[0].name,
            category: data.data.data[0].category,
            logo: data.data.data[0].logo,
            position: data.data.data[0].position_at,
            link: data.data.data[0].link,
            pavilion_id: splitURL[3], // Default value
          });
        }
      });
  };
  useEffect(() => {
    if(props.edit){
      handleRequest();
    } else {
      setFormData({
        name: "",
    permissions: "",
    parent: "",
    order: "",
    status: "",
    iconorder: "",
    iconimage: "",
    menuid: "",
    slug:"",
      });
    }
    
  }, [props.edit, props.menuID]);

  return (
    <Modal centered backdrop="static"  show={props.show}
    onHide={props.close}>
      <Modal.Header className="py-1 my-1" closeButton>
        <Modal.Title>{props.edit===true ? 'Edit' : 'Add'} Menu</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            id="menuForm"
          >
            
            <Form.Group controlId="validationCustom01" className="mb-1">
              <Form.Control
                name="name"
                required
                type="text"
                className={Object.values(formData.name).length > 0 ? "is-valid" : "is-invalid"}
                placeholder="Menu Name"
                value={formData.name && formData.name}
                onChange={handleChange}
              />
              {/* value={name}
          onChange={(event) => setName(event.target.value)} */}
              {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
            </Form.Group>
            <Form.Group controlId="validationCustom02" className="mb-1">
              <Form.Select
                name="permissions"
                className={Object.values(formData.permissions).length > 0 ? "is-valid" : "is-invalid"}
                value={formData.permissions}
                onChange={handleChange}
              >
                <option value="0" selected disabled>
                  Select Permission
                </option>
                <option value="view-members">View Members</option>
                <option value="view-events">View Events</option>
                <option value="create-events">Create Events</option>
                <option value="view-publications">View Publications</option>
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="validationCustom02" className="mb-1">
              <Form.Select
                name="parent"
                className={Object.values(formData.parent).length > 0 ? "is-valid" : "is-invalid"}
                value={formData.parent && formData.parent}
                onChange={handleChange}
              >
                <option value="0" selected disabled>
                  Select Parent
                </option>
                {/* <option value="view-members">View Members</option>
                <option value="view-events">View Events</option>
                <option value="create-events">Create Events</option>
                <option value="view-publications">View Publications</option> */}
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="validationCustom03" className="mb-1">
              <Form.Control
                name="order"
                required
                type="number"
                className={formData.order && Object.values(formData.order).length > 0 ? "is-valid" : "is-invalid"}
                placeholder="Enter Menu Order"
                value={formData.order && formData.order}
                onChange={handleChange}
              />
              {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
            </Form.Group>
            <Form.Group controlId="validationCustom02" className="mb-1">
            <Form.Select
                name="status"
                value={formData.status && formData.status}
                onChange={handleChange}
              >
                <option value="1">Active</option>
                <option value="0">InActive</option>
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="validationCustom03" className="mb-1">
              <Form.Control
                name="iconorder"
                required
                type="number"
                className={formData.iconorder && Object.values(formData.iconorder).length > 0 ? "is-valid" : "is-invalid"}
                placeholder="Enter Icon Order"
                value={formData.iconorder && formData.iconorder}
                onChange={handleChange}
              />
              {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
            </Form.Group>
            <Form.Group controlId="validationCustom04" className="mb-3">
              {/* <Form.Label>Default file input example</Form.Label> */}
              <Form.Control type="file" required 
               className={formData.iconimage ? "is-valid" : "is-invalid"}
                 name="iconimage" onChange={handleChange} />
            </Form.Group>
          </Form>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.close}>
          Close
        </Button>
        <Button
          variant="primary"
          type="submit"
          onClick={handleSubmit}
          form="menuForm"
        >
          {progress && (<Spinner size='sm' className="me-2" animation="border" />)} Save Menu
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddMenu;
