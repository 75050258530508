import React, { useState, useEffect, isValidElement } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FloatingLabel, Modal, ListGroup } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { Link, useLocation } from "react-router-dom";
import adminhttp, { baseUrl } from "../../../adminhttp";
import Swal from "sweetalert2";
import Divider from "@mui/material/Divider";
import Spinner from "react-bootstrap/Spinner";
import Grid from "@mui/material/Grid";
import { Box, Chip } from "@mui/material";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';

import { ECOSYSTEM_OPTIONS, COUNTRIES } from '../../../components/PavilionConstants';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import axios from "axios";
import AdminLayout from '../../layouts/AdminLayout';
import Typography from '@mui/material/Typography';
import AdminBreadCrumbs from '../../components/AdminBreadCrumbs';

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



const ComposeEmail = (props) => {

  const [validated, setValidated] = useState(false);
  const [progress, setProgress] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  const data = location.state;
  const splitURL = location.pathname.toString().split('/');
  const [banners, setBanners] = useState([]);
  const [editBanner, setEditBanner] = useState(null);


  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to="/admin/dashboard" >
      Dashboard
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      to='/admin/pavilions'
      state={data}
    >
      Pavilions
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      to={`/admin/pavilions/${splitURL[3]}`}
      state={data}
    >
      {data.name}
    </Link>,
    <Typography key="3" sx={{ color: 'text.primary' }}>
      Send Email
    </Typography>,
  ];

  const token = localStorage.getItem('token');
  const [editorData, setEditorData] = useState('');


  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [productType, setProductType] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("");
  const [membershipPlan, setMembershipPlan] = useState("");
  const [imgFile, setImgFile] = useState("");
  const [roles, setRoles] = useState([]);
  const [show, setShow] = useState(false);
  const [options, setOptions] = useState([])
  const [formData, setFormData] = useState({
    email: "",
    subject: "",
    message: "",
    attachment: "",
    banner: "",
  });

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setFormData({ ...formData, [name]: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleChangeSelect = (event) => {
    const {
      target: { value },
    } = event;
    setOptions(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (!formData.email) {
      Swal.fire({
        title: "Please give Email .",
        icon: "error",
      });
      return;
    }

    if ( !formData.subject ) {
      Swal.fire({
        title: "Please give Subject.",
        icon: "error",
      });
      return;
    }
    if (!formData.banner) {
      Swal.fire({
        title: "Please Email Header Banner.",
        icon: "error",
      });
      return;
    }

    // if (form.checkValidity() === true) {
    setProgress(true);

    const submitData = new FormData();
    submitData.append("senduseremail", 'senduseremail');
    submitData.append("pavilion_id_sendemail", splitURL[3]);
    submitData.append("single", selectedValue === "1" ? "singleEmail" : "multipleEmail");
    submitData.append("emailId", formData.email);
    options.forEach((role) => submitData.append("useremail[]", role));
    submitData.append("pavilion_id", splitURL[3]);
    submitData.append("file", formData.attachment);
    submitData.append("subject", formData.subject);
    submitData.append("emailDescription", editorData);
    submitData.append("banner", formData.banner);

    adminhttp().post("/admin/sendemail", submitData).then((response) => {
      Swal.fire({
        title: "Email sent successfully!",
        icon: "success",
        allowOutsideClick: false,
        confirmButtonColor: "#e30613",
      });
      setProgress(false);
      props.close();
      handleRequest();
      setFormData({
        email: "",
        subject: "",
        message: "",
        attachment: "",
        banner: "",
      });
      setOptions([]);
      window.location.reload();
    })
      .catch((error) => {
        console.error("There was an error submitting the form!", error);
      });
    // }
  };

  const handleRequest = () => {
    adminhttp()
      .post(`/admin/roles?pavilion_id=${splitURL[3]}`)
      .then((data) => {
        if (data.data.data.roles.length > 0) {
          setRoles(data.data.data.roles)
        }
      });
  };

  const handleChangeOptRoles = (optRoles) => {
  };

  const [selectedValue, setSelectedValue] = useState('1');

  const handleSwitch = (event) => {
    setSelectedValue(event.target.value);
  };

  function uploadPlugin(editor: Editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorData(data);
  };

  function uploadAdapter(loader: FileLoader): UploadAdapter {
    return {
      upload: () => {
        return new Promise(async (resolve, reject) => {
          try {
            const file = await loader.file;
            const response = await axios.request({
              method: "POST",
              url: `${baseUrl}/admin/rolewisepostAttachment`,
              data: {
                upload: file,
                wall_action: 'create',
              },
              headers: {
                "Content-Type": "multipart/form-data",
                'Authorization': `Bearer ${token}`
              }
            });
            resolve({
              default: `${response.data.data.url}`
            });
          } catch (error) {
            reject("Hello");
          }
        });
      },
      abort: () => { }
    };
  }
  useEffect(() => {
    handleRequest();
  }, []);

  return (
    <>
      <AdminLayout>
        <Container fluid className='bg-light h-100'>
          <Box component="section" sx={{ px: 2, pt: 2, pb: 1 }}>
            <AdminBreadCrumbs breadcrumbs={breadcrumbs} />
          </Box>
          <Box component="section" sx={{ px: 2, pt: 0, pb: 1 }}>

            <div className="d-flex justify-content-between align-items-center">
              <h4>Compose Email</h4>
              <Button variant="primary" sx={{ backgroundColor: '#e30613' }} onClick={handleSubmit}>
                {progress && (<Spinner size="sm" className="me-2" animation="border" />)} Send Email
              </Button>
            </div>
          </Box>
          <ListGroup variant="flush">
            <ListGroup.Item className='bg-transparent'>

              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2} columns={12}>

                  <Container fluid>
                    <Form
                      noValidate
                      validated={validated}
                      onSubmit={handleSubmit}
                      id="sendEmailForm"
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Form.Group controlId="validationCustom01" className="p-0 mb-1">
                            <FormControl>
                              <FormLabel id="demo-row-radio-buttons-group-label">Send Email to</FormLabel>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                checked={selectedValue}
                                onChange={handleSwitch}
                                value={selectedValue}
                                name="toEmail"
                              >
                                <FormControlLabel value="1" control={<Radio color='error' />} label="Individual" />
                                <FormControlLabel value="2" control={<Radio color='error' />} label="Roles" />
                              </RadioGroup>
                            </FormControl>
                          </Form.Group>
                          {selectedValue === '2' && (
                            <Form.Group controlId="validationCustom01" className="p-0 mb-1">
                              <FormControl fullWidth sx={{ m: 0, }}>
                                <InputLabel id="demo-multiple-checkbox-label">Roles</InputLabel>
                                <Select
                                  menuPosition="fixed"
                                  labelId="demo-multiple-checkbox-label"
                                  id="demo-multiple-checkbox"
                                  sx={{ borderRadius: '0.376rem' }}
                                  multiple
                                  value={options}
                                  onChange={handleChangeSelect}
                                  input={<OutlinedInput label="Roles" />}
                                  MenuProps={MenuProps}
                                  renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                      {selected.map((value) => {
                                        const role = roles.find(role => role.id === value);
                                        return (
                                          role ? <Chip key={value} color="error" label={role.name} /> : null
                                        );
                                      })}
                                    </Box>
                                  )}
                                >
                                  {roles.map((role) => (
                                    <MenuItem sx={{ display: "inline-flex", width: "20%" }} key={role.id} value={role.id}>
                                      <Checkbox color='error' size='small' checked={options.includes(role.id)} />
                                      <ListItemText primary={role.name} />
                                    </MenuItem>
                                  ))}
                                </Select>

                              </FormControl>

                            </Form.Group>
                          )}
                          {selectedValue === '1' && (
                            <Form.Group controlId="validationCustom01" className="mb-1">
                              <Form.Control
                                name="email"
                                required
                                type="email"
                                className={Object.values(formData.email).length > 0 ? "is-valid" : "is-invalid"}
                                placeholder="Email"
                                value={formData.email && formData.email}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          )}
                          <Form.Group controlId="validationCustom02" className="mb-1">
                            <Form.Control
                              name="subject"
                              required
                              type="text"
                              className={Object.values(formData.subject).length > 0 ? "is-valid" : "is-invalid"}
                              placeholder="Subject"
                              value={formData.subject && formData.subject}
                              onChange={handleChange}
                            />
                          </Form.Group>
                          <Form.Group controlId="validationCustom03" className="mb-1" style={{background:'#ffffff'}}>
                            <CKEditor
                              config={{
                                extraPlugins: [uploadPlugin],
                                placeholder: 'Write something...'
                              }}
                              editor={ClassicEditor}
                              onReady={editor => {
                                editor.editing.view.change((writer) => {
                                  writer.setStyle(
                                    "min-height", "150px",
                                    editor.editing.view.document.getRoot()
                                  );
                                });
                              }}
                              data={editorData}
                              onChange={handleEditorChange}
                              onBlur={(event, editor) => {
                                console.log('Blur.', editor);
                              }}
                              onFocus={(event, editor) => {
                                console.log('Focus.', editor);
                              }}
                            />
                          </Form.Group>
                        </Grid>
                        <Grid item xs={6} className='mt-2 py-0'>
                          <Form.Group controlId="validationCustom04" className="mb-1">
                            <Form.Label className="">Attachment</Form.Label>
                            <Form.Control
                              type="file"
                              required
                              accept="image/*"
                              name="attachment"
                              onChange={handleChange}
                            />

                          </Form.Group>
                        </Grid>
                        <Grid item xs={6} className='py-0 mt-2'>
                          <Form.Group controlId="validationCustom04" className="mb-1">
                            <Form.Label className="">Email Header Banner</Form.Label>
                            <Form.Control
                              type="file"
                              required
                              accept="image/*"
                              name="banner"
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Grid>
                      </Grid>
                    </Form>
                  </Container>

                </Grid>
              </Box>

            </ListGroup.Item>
          </ListGroup>

        </Container>
      </AdminLayout>
    </>
  );
};

export default ComposeEmail;
