import { Link, useNavigate } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import adminhttp from '../../adminhttp';
import TwitterIcon from '@mui/icons-material/X';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Container, Row, Col } from 'react-bootstrap';
import IconButton from '@mui/material/IconButton';
import ButtonGroup from '@mui/material/ButtonGroup';
import { pink } from '@mui/material/colors';
import Checkbox from '@mui/material/Checkbox';
import Spinner from 'react-bootstrap/Spinner';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import SvgIcon from '@mui/material/SvgIcon';
import Global from '../utils/utils'

const Root = styled('div')(({ theme }) => ({
  width: '100%',
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  '& > :not(style) ~ :not(style)': {
    marginTop: theme.spacing(2),
  },
}));


export default function AdminLoginForm() {
  const navigate = useNavigate()
  
  const [progress, setProgress] = useState(false)
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const [redirectionUrl, setredirectionUrl] = useState('')
  const [errorMessage, setErrorMessage] = useState('') // Add state for error message

  const passwordHandler = (event) => {
    setPassword(event.target.value)
  }
  const emailHandler = (event) => {
    setEmail(event.target.value)
  }

  function signinRequest() {
    setProgress(true)
    adminhttp().post('/login/admin', { email: email, password: password })
      .then((res) => {
        localStorage.setItem('token', res.data.data.token);
        localStorage.setItem('localstorage_user_id', res.data.data.id);
        localStorage.setItem('localstorage_role_id', res.data.data.role_id);
        localStorage.setItem('permissions', JSON.stringify(res.data.data.permissions));
        // navigate("/admin/dashboard");
        window.location.href = '/admin/dashboard';
        setProgress(false)
      })
      .catch((error) => {
        console.error('Error:', error);
        setErrorMessage('An error occurred while signing in. Please try again.'); // Set error message
      });
  }

  const submitHandler = (event) => {
    event.preventDefault()
    setErrorMessage(''); // Clear any previous error message
    signinRequest()
  }

  useEffect(() => {
    console.log(window.location.hostname);
    if (window.location.hostname == 'bicpavilion.com') {
      setredirectionUrl('https://react-live.bicpavilion.com');
    } else if (window.location.hostname == 'bic3.bicpavilion.com') {
      setredirectionUrl('https://staging-backend.bicpavilion.com');
    } else {
      setredirectionUrl('http://127.0.0.1:8000');
    }

  }, []);
  return (<>

    <Form onSubmit={submitHandler} >
      <Form.Group controlId="validationCustom01" className='mb-2'>
        {/* <FloatingLabel  size="sm"
                    controlId="floatingInput"
                    label="Email address"
                    className="mb-3"
                > */}
        <Form.Control size="sm"
          required
          type="email"
          placeholder="Email"
          pattern="/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}
            [a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.com"
          onChange={emailHandler}
        />
        {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
        <Form.Control.Feedback type="invalid">Please provide a valid Email.</Form.Control.Feedback>
        {/* </FloatingLabel> */}
      </Form.Group>
      <Form.Group controlId="validationCustom02" >
        {/* <FloatingLabel controlId="floatingPassword" label="Password"> */}
        <Form.Control size="sm"
          required
          type="password"
          className='mt-3'
          placeholder="Password"
          onChange={passwordHandler}
        />
        {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
        <Form.Control.Feedback type="invalid">Please provide a valid Password.</Form.Control.Feedback>
        {/* </FloatingLabel> */}

      </Form.Group>
      <div className="mb-0 mt-2">
        <div className="my-2 d-flex align-items-center justify-content-between">
          {/* <Form.Check type="checkbox" name='rememberme' id='rememberme' label="Remember Me" className='ps-0' /> */}
          <Form.Check type='checkbox' id='chkRememberMe' className='ps-0 pt-2'>
            <Form.Check.Input type='checkbox' className='mt-0 me-2' />
            <Form.Check.Label>Remember Me</Form.Check.Label>
          </Form.Check>
          <p className='lead mb-0'><Link style={{ textDecoration: 'none' }} to="/resetpwd">Forgot Password</Link></p>

        </div>

        <div className="my-2 d-flex align-items-center justify-content-center">
          <Button type="submit" className="fw-bold">
          {progress && (<Spinner size='sm' className="me-2" animation="border" />)} LOGIN
          </Button>
        </div>

      </div>
    </Form>
  </>
  )
}
