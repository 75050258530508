import React, { useState, useEffect } from "react";
import AdminLayout from "../layouts/AdminLayout";
import { Form, Container, ListGroup } from "react-bootstrap";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import adminhttp from "../../adminhttp";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
// import Stack from '@mui/material/Stack'
import Stack from "react-bootstrap/Stack";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";

// import Card from '@mui/material/Card';
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AdminBreadCrumbs from "../components/AdminBreadCrumbs";
import { Link } from "react-router-dom";
// import Badge from '@mui/material/Badge';
import Badge from "react-bootstrap/Badge";
import { formatDate } from "../../components/PavilionConstants";
import DateIcon from "@mui/icons-material/InsertInvitation";
import Dropdown from "react-bootstrap/Dropdown";
import { Row, Col, Modal, Button } from "react-bootstrap";
// import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation } from "react-router-dom";
import EditIcon from "@mui/icons-material/DriveFileRenameOutlineTwoTone";
import SettingsIcon from "@mui/icons-material/SettingsTwoTone";
import MenusIcon from "@mui/icons-material/ListAltTwoTone";
import SponsorsIcon from "@mui/icons-material/Diversity2TwoTone";
import MultiBannerIcon from "@mui/icons-material/PermMediaTwoTone";
import StoreIcon from "@mui/icons-material/StoreTwoTone";
import RolesIcon from "@mui/icons-material/WorkTwoTone";
import MembershipsIcon from "@mui/icons-material/PeopleAltTwoTone";
import LibraryIcon from "@mui/icons-material/LibraryBooksTwoTone";
import EventIcon from "@mui/icons-material/EventAvailableTwoTone";
import EmailIcon from "@mui/icons-material/EmailTwoTone";
import PinsIcon from "@mui/icons-material/PushPinTwoTone";
import { useNavigate } from "react-router-dom";
import SponsorCard from "../components/AdminCards/SponsorCard";
import Alert from "@mui/material/Alert";
import RoleCard from "../components/AdminCards/RoleCard";
import MembershipCard from "../components/AdminCards/MembershipCard";
import AddMembership from "../components/AdminForms/AddMembership";
import Swal from "sweetalert2";
import SEOCard from "../components/AdminCards/SEOCard";
import AddTicket from "../components/AdminForms/AddTicket";
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import CRUDDropdown from "../../components/CRUDDropdown";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Divider from '@mui/material/Divider';
import AccordionDetails from '@mui/material/AccordionDetails';
import { ExportToExcel } from "../components/ExportToExcel";


const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
  variants: [
    {
      props: ({ expand }) => !expand,
      style: {
        transform: "rotate(0deg)",
      },
    },
    {
      props: ({ expand }) => !!expand,
      style: {
        transform: "rotate(180deg)",
      },
    },
  ],
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  ...theme.applyStyles("dark", {
    backgroundColor: "#1A2027",
  }),
}));

// function handleAction(row) {
//   alert(row.id);
// }

const SEOSettings = (props) => {

  const [exportData, setExportData] = useState([]);
  const today = new Date();
  const fileName = `SEO Settings List ${formatDate(today)}`; // here enter filename for your excel file
  
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  const data = location.state;

  const splitURL = location.pathname.toString().split("/");

  const [SEOSettings, setSEOSettings] = useState([]);
  const [pvlnName, setPvlnName] = useState("");
  const [rows, setRows] = useState(0);
  const [counter, setCounter] = useState(0);
  const [pavilionName, setPavilionName] = useState("");
  const navigate = useNavigate();

  const [value, setValue] = useState("");
  const [filter, setFilter] = useState("0");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [expanded, setExpanded] = React.useState(false);


  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to="/admin/dashboard">
      Dashboard
    </Link>,
    <Typography key="3" sx={{ color: "text.primary" }}>
      SEO Settings
    </Typography>,
  ];
  const [editMode, setEditMode] = useState(false); // Toggle between add and edit mode
  const [editSeoId, setEditSeoId] = useState(null); // ID of the SEO setting to edit


  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const [filteredSEOSettings, setFilteredSEOSettings] = useState([]);



const handleSearch = (e) => {
  const searchValue = e.target.value.toLowerCase();
  setValue(searchValue);

  if (searchValue.trim() === "") {
    setFilteredSEOSettings(SEOSettings); // Show all if input is empty
    return;
  }

  const filteredSettings = SEOSettings.filter(
    (seo) =>
      seo.page_name?.toLowerCase().includes(searchValue) ||
      seo.title?.toLowerCase().includes(searchValue) ||
      seo.keywords?.toLowerCase().includes(searchValue) ||
      seo.url?.toLowerCase().includes(searchValue)
  );
  setFilteredSEOSettings(filteredSettings);
};


  const handleSelect = (e) => {
    setFilter(e.target.value);
  };


  const [apiPath, setApiPath] = useState();

  const handleRequest = () => {
      adminhttp()
        .get(`/admin/seopages`)
        .then((data) => {
          if (data.data.data.seo.length > 0) {
            setLoading(true)
            setSEOSettings(data.data.data.seo);
            setLoading(false)
          }else{
            setNoData(true)
            setLoading(false)
          }
        });
  };
  const handleModalClose = () => {
  setShowModal(false);
  setEditMode(false);
  setNewSeoSetting({
    page_name: "",
    title: "",
    keywords: "",
    url: "",
    description: "",
  });
};

  
  
  const handleModalShow = () => setShowModal(true);


  const [newSeoSetting, setNewSeoSetting] = useState({
    page_name: "",
    title: "",
    keywords: "",
    url: "",
	description:"",
  });


  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewSeoSetting((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddSeoSetting = (e) => {
    e.preventDefault();
    adminhttp()
      .post(`/admin/createseo`, newSeoSetting)
      .then((response) => {
        //console.log("SEO Setting added successfully:", response.data);
		Swal.fire("Success!", "SEO Setting added successfully!", "success");
        setNewSeoSetting({ page_name: "", title: "", keywords: "", url: "",description:"" });
        handleRequest(); // Refresh SEO settings list
        handleModalClose();
      })
      .catch((err) => {
        console.error("Error adding SEO setting:", err);
      });
  };
  
  
  
   const handleSubmit = (e) => {
    e.preventDefault();
    if (editMode) {
      // Update SEO Setting
      adminhttp()
        .post(`/admin/updateseo/${editSeoId}`, newSeoSetting)
        .then(() => {
          Swal.fire("Success!", "SEO Setting updated successfully!", "success");
          handleRequest();
          handleModalClose();
        })
        .catch((err) => {
          console.error("Error updating SEO setting:", err);
          Swal.fire("Error", "Failed to update SEO setting", "error");
        });
    } else {
      // Add New SEO Setting
      adminhttp()
        .post(`/admin/createseo`, newSeoSetting)
        .then(() => {
          Swal.fire("Success!", "SEO Setting added successfully!", "success");
          handleRequest();
          handleModalClose();
        })
        .catch((err) => {
          console.error("Error adding SEO setting:", err);
          Swal.fire("Error", "Failed to add SEO setting", "error");
        });
    }
  };
  
  const handleEdit = (id) => {
  setEditMode(true);
  setEditSeoId(id);
  adminhttp()
    .get(`/admin/signleseo/${id}`)
    .then((response) => {
      const dataResult = response.data.data.seo;
	  console.log(dataResult)
      setNewSeoSetting({
        page_name: dataResult.page_name || "",
        title: dataResult.title || "",
        keywords: dataResult.keywords || "",
        url: dataResult.url || "",
        description: dataResult.description || "",
      });
      setShowModal(true); // Ensure this is called to display the modal
    })
    .catch((err) => {
      console.error("Error fetching SEO setting:", err);
      Swal.fire("Error", "Failed to fetch SEO setting", "error");
    });
};
  const handleDropdown = (e) => {
    e.stopPropagation();
    //setShow(true)
  };
  const handleDelete = (id) => {
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#d33",
    cancelButtonColor: "#3085d6",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      adminhttp()
        .delete(`/admin/deleteseo/${id}`)
        .then(() => {
          Swal.fire("Deleted!", "SEO Setting has been deleted.", "success");
          handleRequest(); // Refresh SEO settings list
        })
        .catch((err) => {
          console.error("Error deleting SEO setting:", err);
          Swal.fire("Error", "Failed to delete SEO setting", "error");
        });
    }
  });
};


  useEffect(() => {
    handleRequest();
  }, []);
  
  useEffect(() => {
  setFilteredSEOSettings(SEOSettings);
}, [SEOSettings]);

useEffect(() => {
  const fetchData = () =>{
    adminhttp().get(`/admin/seopages`).then((data) => {
    // reshaping the array
   const customHeadings = data.data.data.seo.map(page=>({
    "Id": page.id,
    "Page Name": page.page_name,
    "Url": page.url,
    "Title" : page.title,
    "Description": page.description,
    "Keywords":page.keywords,
  }))
  setExportData(customHeadings) 
   })
  }
  fetchData()
}, [])
  
  return (
    <>
      <AdminLayout>
        <Container fluid className="bg-light">
          <Box component="section" sx={{ px: 2, pt: 2, pb: 1 }}>
            <AdminBreadCrumbs breadcrumbs={breadcrumbs} />
          </Box>

          <Box
            component="section"
            className="d-flex align-items-center justify-content-between header-menu"
            sx={{ px: 2, pt: 0, pb: 1 }}
          >
            <h4>SEO Settings</h4>
            <div className="d-flex justify-content-end align-items-center">
			              <input
				  type="text"
				  value={value}
				  onChange={(e) => handleSearch(e)}
				  placeholder="Search SEO Settings"
				  style={{ maxWidth: 300 }}
				/>
            
            </div>
            <Button variant="primary" size='sm' onClick={handleModalShow}>
                Add SEO Setting
              </Button>
              <ExportToExcel apiData={exportData} fileName={fileName}/>
          </Box>

          <ListGroup variant="flush">
            <ListGroup.Item className="bg-transparent">
            <Accordion className='rounded-4 bg-secondary mb-2'>
    <AccordionSummary sx={{flexDirection: 'row-reverse'}} 
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1-content"
      id="panel1-header"
    >

    <Box sx={{ flexGrow: 1 }} className='ms-3 my-0'>
      <Grid container spacing={2} >
        <Grid item xs={6} md={2} >
           <Typography variant="body" component="p" className='mt-1 text-light' >
           Page Name
          </Typography>   
        </Grid>
        <Grid item xs={6} md={3} className='d-flex align-items-center'>
        <Typography variant="body" component="p"  className='text-light'>
Page Title
          </Typography>
        </Grid>
        <Grid item xs={6} md={4} className='d-flex align-items-center'>
        <Typography variant="body" component="p"   className='text-light'>
                Keywords
         </Typography>                    
        </Grid>
        <Grid item xs={6} md={2} className='d-flex align-items-center'>
        <Typography variant="body" component="p"  className='text-light'>
Page URL
          </Typography>
        </Grid>
        <Grid item xs={6} md={1} className='d-flex align-items-center justify-content-end' >
        <Typography variant="body" component="p"  className='text-light'>
Actions
          </Typography>
        </Grid>
      </Grid>
    </Box>
  
    </AccordionSummary>
  </Accordion>
  {loading ? (
      <Box sx={{ flexGrow: 1, textAlign:'center' }}>
      <Spinner size='lg' className='me-2 ' animation='border' />
      </Box>
    ):(
             filteredSEOSettings.length > 0 && (
  <Box sx={{ flexGrow: 1 }}>
    <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 1, md: 12 }}>
      {filteredSEOSettings.map((seo, index) => (
        <Grid item xs={12} md={12} key={seo.id}>
          <Accordion className="rounded-4 bg-toggle">
            <AccordionSummary
              sx={{ flexDirection: "row-reverse" }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Box sx={{ flexGrow: 1 }} className="ms-3 my-0">
                <Grid container spacing={2}>
                  <Grid item xs={6} md={2}>
                    <Typography variant="body" component="p" className="mt-1">
                      {seo.page_name || "N/A"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={3} className="d-flex align-items-center">
                    <Typography variant="body" component="p">
                      {seo.title || "N/A"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={4} className="d-flex align-items-center">
                    <Typography variant="body" component="p">
                      {seo.keywords || "N/A"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={2} className="d-flex align-items-center">
                    <Typography variant="body" component="p">
                      {seo.url || "N/A"}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={1}
                    className="d-flex align-items-center justify-content-end"
                  >
                    <CRUDDropdown
                      title={<MoreVertIcon fontSize="small" />}
                      handleDropdown={(e) => handleDropdown(e)}
                      addTitle="Edit"
                      addClick={() => handleEdit(seo.id)}
                      deleteTitle="Delete"
                      deleteClick={() => handleDelete(seo.id)}
                    />
                  </Grid>
                </Grid>
              </Box>
            </AccordionSummary>
            <Divider />
            <AccordionDetails>
              <h5>Description</h5>
              {seo.description || "No Description Available"}
            </AccordionDetails>
          </Accordion>
        </Grid>
      ))}
    </Grid>
  </Box>
))}

{noData && (
<Box sx={{ flexGrow: 1 }} className="d-flex justify-content-center">
    <Alert variant="outlined" severity="error">
      SEO Settings not found
    </Alert>
  </Box>
)}
            </ListGroup.Item>
          </ListGroup>
        </Container>
		 {/* ... Your existing code ... */}
			 {/* <Button variant="primary" onClick={handleModalShow}>
          Add SEO Setting
			 </Button>*/}
        {/* Modal for Add/Edit */}
<Modal show={showModal} onHide={handleModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>{editMode ? "Edit SEO Setting" : "Add SEO Setting"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formPageName">
                <Form.Label>Page Name</Form.Label>
                <Form.Control
                  type="text"
                  name="page_name"
                  value={newSeoSetting.page_name}
                  onChange={handleChange}
                  required
                  placeholder="Enter page name"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formPageTitle">
                <Form.Label>Page Title</Form.Label>
                <Form.Control
                  type="text"
                  name="title"
                  value={newSeoSetting.title}
                  onChange={handleChange}
                  required
                  placeholder="Enter page title"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formKeywords">
                <Form.Label>Keywords</Form.Label>
                <Form.Control
                  type="text"
                  name="keywords"
                  value={newSeoSetting.keywords}
                  onChange={handleChange}
                  required
                  placeholder="Enter keywords"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formDescription">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  name="description"
                  value={newSeoSetting.description}
                  onChange={handleChange}
                  required
                  placeholder="Enter description"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formPageUrl">
                <Form.Label>Page URL</Form.Label>
                <Form.Control
                  type="url"
                  name="url"
                  value={newSeoSetting.url}
                  onChange={handleChange}
                  required
                  placeholder="Enter page URL"
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                {editMode ? "Update" : "Add"}
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      </AdminLayout>
      {/* <AddTicket handleRequest={handleNewRequest} show={showModal} close={() => setShowModal(false)} /> */}
    </>
  );
};

export default SEOSettings;
