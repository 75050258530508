import React, { useState, useEffect, isValidElement } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FloatingLabel, Modal, Image } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { Link, useLocation } from "react-router-dom";
import adminhttp from "../../../adminhttp";
import Swal from "sweetalert2";
import Divider from "@mui/material/Divider";
import Spinner from "react-bootstrap/Spinner";
import Grid from "@mui/material/Grid";
import MuiButton from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { experimentalStyled as styled } from "@mui/material/styles";
import { CKEditor, ImageInsert } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";


const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});



function AddMembership(props) {
  const [validated, setValidated] = useState(false);
  const [progress, setProgress] = useState(false);

  const location = useLocation();
  const data = location.state;
  const splitURL = location.pathname.toString().split("/");

  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [productType, setProductType] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("");
  const [membershipPlan, setMembershipPlan] = useState("");
  const [imgFile, setImgFile] = useState(`${localStorage.getItem('bucketName')}/images/pavilion/noimage.jpeg`);

  const [show, setShow] = useState(false);
  const [desn, setDesn] = useState('');
  const [formData, setFormData] = useState({
        name: "",
        pavilionid: splitURL[3],
        price: "",
        producttype: "",
        description: desn,
        status: "",
        membershipplan: "",
        image: "",
  });

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    alert(e.target.value)
    if (type === "file") {
      const url = URL.createObjectURL(files[0]);
      setFormData({ ...formData, image: files[0] }); // Store file object
      setImgFile(url)
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      //event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
    // if(formData.image===''){
    //   Swal.fire({
    //     title: 'Membership Image must be added!',
    //     icon: "error",
    //     allowOutsideClick: false,
    //     confirmButtonColor: "#e30613",
    //   });
    //   return
    // }
       
     if (form.checkValidity() === true) {
      setProgress(true);
      const submitData = new FormData();
      submitData.append("name", formData.name);
      submitData.append("pavilion_id", splitURL[3]);
      submitData.append("price", formData.price); 
      submitData.append("product_type", formData.producttype);
      submitData.append("description", desn);
      submitData.append("status", formData.status);
      submitData.append("membership_plan", formData.membershipplan);
      submitData.append("image", formData.image);

      if (props.edit === true) {
        adminhttp()
        .post(`/admin/update_membership/${props.membershipID}`, submitData)
        .then((response) => {
          Swal.fire({
            title: 'Membership updated successfully!',
            icon: "success",
            allowOutsideClick: false,
            confirmButtonColor: "#e30613",
          });
          setProgress(false);
          props.close();
          props.handleRequest();
        })
        .catch((error) => {
          console.error("There was an error submitting the form!", error);
        });
      } else {
        adminhttp().post("/admin/add_membership", submitData).then((response) => {
            Swal.fire({
              title: "Membership added successfully!",
              icon: "success",
              allowOutsideClick: false,
              confirmButtonColor: "#e30613",
            });
            setProgress(false);
            props.close();
            props.handleRequest();
          })
          .catch((error) => {
            console.error("There was an error submitting the form!", error);
          });
      }
    }
  };
  
  const handleRequest = () => {
    adminhttp().post(`/admin/fetch_single_membership?pavilion_id=${splitURL[3]}&membership_id=${props.membershipID}`)
      .then((data) => {
        if (data.data.data.length > 0) {
          // setSponsor(data.data.data);
          setFormData({
            name: data.data.data[0].name,
            pavilionid: data.data.data[0].pavilion_id, // Default value
            price: data.data.data[0].price.toString() ,
            producttype: data.data.data[0].type,
            description: data.data.data[0].description,
            status: data.data.data[0].status,
            membershipplan: data.data.data[0].membership_plan,
            image: data.data.data[0].image,
          });
          setImgFile(`${localStorage.getItem('bucketName')}/images/pavilion/${data.data.data[0].image}`)
        }
      });
  };

  useEffect(() => {
    if(props.edit){
      handleRequest();
    } 
     else {
       setFormData({
         name: "",
         pavilionid: splitURL[3],
         price: "",
         producttype: "",
         description: desn,
         status: "",
         membershipplan: "",
         image: "",
       });
      
     }
    
  }, [props.edit, props.membershipID]);

  return (
<Form noValidate validated={validated} onSubmit={handleSubmit}  id="membershipForm">
    <Modal
      centered
      backdrop="static"
      size="lg"
      show={props.show}
      onHide={props.close}
    >
      <Modal.Header className="py-1 my-1" closeButton>
        <Modal.Title>Add Membership</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
           <Grid container spacing={2}>
              <Grid item xs={6}>
                <Form.Group controlId="validationCustom01" className="mb-1">
                  <Form.Control
                    name="name"
                    id='name'
                    required
                    type="text"
                    className={Object.values(formData.name).length > 0 ? "is-valid" : "is-invalid"}
                    placeholder="Membership Name"
                    value={formData.name && formData.name}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="validationCustom02" className="mb-1">
                  <Form.Control
                    name="price"
                    required
                    type="text"
                    className={formData.price && Object.values(formData.price).length > 0 ? "is-valid" : "is-invalid"}
                    placeholder="Price"
                    value={formData.price && formData.price}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="validationCustom03" className="mb-1">
                  <Form.Control
                    name="producttype"
                    required
                    type="text"
                    className={formData.producttype && Object.values(formData.producttype).length > 0 ? "is-valid" : "is-invalid"}
                    placeholder="Product Type"
                    value={formData.producttype && formData.producttype}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="validationCustom04" className="mb-1">
                   
                  {/* <Form.Control
                    name="description"
                    required
                    type="text"
                    className={formData.description && Object.values(formData.description).length > 0 ? "is-valid" : "is-invalid"}
                    placeholder="Description"
                    value={formData.description}
                    onChange={handleChange}
                  /> */}
<CKEditor
                                                        editor={ClassicEditor}
                                                        data={formData.description && formData.description}
                                                        onReady={(editor) => {
                                                          editor.editing.view.change((writer) => {
                                                            writer.setStyle(
                                                              "min-height",
                                                              "150px",
                                                              editor.editing.view.document.getRoot()
                                                            );
                                                          });
                                    
                                                          // You can store the "editor" and use when it is needed.
                                                        //  console.log("Further Description", editor);
                                                        }}
                                                        onChange={(event, editor) => {
                                                          const data = editor.getData();
                                                          // setFormData({ ...formData, description: data });
                                                          setDesn(data)
                                                          //console.log({ event, editor, data });
                                                        }}
                                                        onBlur={(event, editor) => {
                                                          // console.log("Blur.", editor);
                                                        }}
                                                        onFocus={(event, editor) => {
                                                          // console.log("Focus.", editor);
                                                        }}
                                                      />
                 </Form.Group> 

                <Form.Group controlId="validationCustom05" className="mb-1">
                  <Form.Select
                    name="status"
                    placeholder="Status"
                    required
                    value={formData.status && formData.status}
                    onChange={handleChange}
                  >
                    <option selected value="1">Active</option>
                    <option value="0">InActive</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group controlId="validationCustom06" className="mb-1">
                  <Form.Select
                    name="membershipplan"
                    placeholder="Membership Plan"
                    required
                    value={formData.membershipplan && formData.membershipplan}
                    onChange={handleChange}
                  >
                    <option selected value="1_year">One Year</option>
                    <option value="2_year">Two Year</option>
                    <option value="3_year">Three Year</option>
                  </Form.Select>
                </Form.Group>
              </Grid>
              <Grid item xs={6}>
                <Form.Group controlId="validationCustom07" className="mb-3">
                  {/* <Form.Control
                    type="file"
                    required
                    accept="image/*"
                     className={formData.image && Object.values(formData.image).length > 0 ? "is-valid" : "is-invalid"}
                    name="image"
                    onChange={handleChange}
                  /> */}
                  <InputGroup className="mb-3 d-flex flex-column">
                          <Image id='logoId' invalid src={imgFile} className="rounded-top" fluid style={{ width: "100%", height: "auto" }}/>
                          <MuiButton
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            color="error"
                            className="rounded-bottom-2 "
                            startIcon={<CloudUploadIcon />}
                          >
                            &nbsp;Upload Membership Image (200x200 px)
                            <VisuallyHiddenInput
                              type="file"
                              required
                              accept="image/*"
                              className={formData.image && Object.values(formData.image).length > 0 ? "is-valid" : "is-invalid"}
                              onChange={handleChange}
                            />
                            {/* <Form.Control
                              type="file"
                              hidden
                              id='companylogo'
                              onChange={(e) => handleFileChange(e, "logo")}
                            /> */}
                          </MuiButton>
                        </InputGroup>
                        
                </Form.Group>
                
                {/* <Form.Group controlId="validationCustom07" className="d-flex justify-content-center align-items-center">
                <Image className='text-center p-3' width='50%' height='auto' src={imgFile} />
                </Form.Group> */}
              </Grid>
              
            </Grid>
          
          
        </Container>
      </Modal.Body>
      <Modal.Footer className='d-flex justify-content-between align-items-center'>
        {!progress && (<Form.Label className='text-primary'><strong>Note:</strong> All fields must be filled and Membership Image must be uploaded!</Form.Label> )}
        <div>
        <Button variant="secondary" onClick={props.close} className='me-3'>
          Close
        </Button>
        <Button
          variant="primary"
          type="submit"
          form="membershipForm"

        >
          {progress && (
            <Spinner size="sm" className="me-2" animation="border" />
          )} Save Membership
        </Button>
        </div>
      </Modal.Footer>
    </Modal>
    </Form>

  );
}

export default AddMembership;
