import React, { useState, useEffect } from 'react'
import AdminLayout from '../layouts/AdminLayout'
import { Container, Button, ListGroup } from 'react-bootstrap'
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import adminhttp from '../../adminhttp';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
// import Stack from '@mui/material/Stack'
import Stack from 'react-bootstrap/Stack';
import Card from 'react-bootstrap/Card';
import Alert from "@mui/material/Alert";
// import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AdminBreadCrumbs from '../components/AdminBreadCrumbs';
import { Link, useNavigate } from 'react-router-dom'
// import Badge from '@mui/material/Badge';
import CRUDDropdown from "../../components/CRUDDropdown";
import Badge from 'react-bootstrap/Badge';
import { formatDate } from '../../components/PavilionConstants';
import DateIcon from '@mui/icons-material/InsertInvitation';
import Dropdown from 'react-bootstrap/Dropdown';
import { Row, Col, Form } from 'react-bootstrap';
import AddPavilion from '../components/AdminForms/AddPavilion';
import EditIcon from "@mui/icons-material/DriveFileRenameOutlineTwoTone";
import Swal from 'sweetalert2'
import { useLocation } from 'react-router-dom'
import Spinner from "react-bootstrap/Spinner";
// import {getPermissions} from '../utils/utils'
import Slide from '@mui/material/Slide';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
  variants: [
    {
      props: ({ expand }) => !expand,
      style: {
        transform: 'rotate(0deg)',
      },
    },
    {
      props: ({ expand }) => !!expand,
      style: {
        transform: 'rotate(180deg)',
      },
    },
  ],
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027',
  }),
}));
const columns = [
  { field: 'id', headerName: 'ID', width: 50 },
  {
    field: 'pavilion_name',
    headerName: 'Pavilion Name',
    width: 250,
    editable: true,
  },
  {
    field: 'privacy',
    headerName: 'Privacy',
    width: 75,
    editable: true,
  },
  {
    field: 'status',
    headerName: 'Status',
    type: 'number',
    width: 100,
    editable: true,
  },
  {
    field: 'payment',
    headerName: 'Payment',
    sortable: false,
    width: 100,
  },
  {
    field: 'created_at',
    headerName: 'Created at',
    sortable: false,
    width: 160,
  },

  {
    field: "action",
    headerName: "Action",
    sortable: false,
    renderCell: ({ row }) =>
      <Button onClick={() => handleAction(row)}>
        Delete
      </Button>,
  },
];

const rows = [
  { id: 1, pavilion_name: 'Whos Who', privacy: 'Private', status: 'Active', payment: 'Paid', created_at: '12-02-2024', action: '' },
  { id: 2, pavilion_name: 'APPG Artificial Intelligence', privacy: 'Private', status: 'Active', payment: 'Paid', created_at: '12-02-2024', action: '' },
  { id: 3, pavilion_name: 'Blockchain', privacy: 'Private', status: 'Active', payment: 'Paid', created_at: '12-02-2024', action: '' },
  { id: 4, pavilion_name: 'BIC Premium Members', privacy: 'Public', status: 'Active', payment: 'Paid', created_at: '12-02-2024', action: '' },
];
function handleAction(row) {
  alert(row.id)
}



const AllPavilions = () => {

  const [permissions, setPermissions]=useState(JSON.parse(localStorage.getItem('permissions')));
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);

  const [value, setValue] = useState("");
  const location = useLocation();
  // const data = location.state;
  const splitURL = location.pathname.toString().split("/");

  const [showModal, setShowModal] = useState(false);

  const [pavilions, setPavilions] = useState([]);
  const [rows, setRows] = useState(0);
const navigate=useNavigate();
  const [expanded, setExpanded] = React.useState(false);

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to="/admin/dashboard" >
      Dashboard
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
    >
      Pavilions
    </Link>,
    <Typography key="3" sx={{ color: 'text.primary' }}>
      All Pavilions
    </Typography>,
  ];

  const [filteredPavilions, setFilteredPavilions] = useState([]);

  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setValue(searchValue);
  
    if (searchValue.trim() === "") {
      setFilteredPavilions(pavilions); // Show all if input is empty
      return;
    }
  
    const filteredSettings = pavilions.filter(
      (pvlns) =>
        pvlns.name?.toLowerCase().includes(searchValue) ||
        pvlns.keywords?.toLowerCase().includes(searchValue) 
        //||
        // pvlns.title?.toLowerCase().includes(searchValue) ||
        
        // pvlns.url?.toLowerCase().includes(searchValue)
    );
    setFilteredPavilions(filteredSettings);
  };
  

  const handleClick = (pid,pname) => {
    const data = { name: pname, age: 30 };

    // event.preventDefault();
    navigate('/'+ 'admin/pavilions/'+ pid, {state:data})
    //console.info('You clicked a breadcrumb.');
  }

  const handleDropdown = (e) => {e.stopPropagation()};

  const handleEditClick = (e) => {
    e.stopPropagation();
    alert('yes')
  };

  
  const handleDelete = (id)=> (e) => {
    e.stopPropagation();
    Swal.fire({
      title: "Are you sure to delete this Pavilion?",
      text: "You won't be able to revert this!",
      icon: "warning",
      width: 'auto',
      showCancelButton: true,
      confirmButtonColor: "#e30613",
      cancelButtonColor: "#6c757d",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        adminhttp().get(`/admin/deletepavilion/${id}`).then((data) => {
          Swal.fire({
          title: "Your Pavilion has been deleted.",
          icon: "success",
          allowOutsideClick: false,
          confirmButtonColor: "#e30613",
        }).then((result) => {
          if (result['isConfirmed']){
            handleRequest();
          }
        });

      });
        
      }
    });
};

const handleStatus = (id, status)=> (e) => {
  e.stopPropagation();
  Swal.fire({
    title: `Are you sure to ${status===1 ? 'Deactive' : 'Activate'} this Pavilion?`,
    text: "You won't be able to revert this!",
    icon: "warning",
    width: 'auto',
    showCancelButton: true,
    confirmButtonColor: "#e30613",
    cancelButtonColor: "#6c757d",
    confirmButtonText: `Yes, ${status===1 ? 'Deactive' : 'Activate'} it!`
  }).then((result) => {
    if (result.isConfirmed) {
      adminhttp().post(`/admin/pavilion/activate-deactivate/${id}`, {
        status: status===1?0:1,
        pavilion_id: id,
    }).then((data) => {
      Swal.fire({
        title: `Pavilion has been ${status===1 ? 'Deactivated' : 'Activated'}.`,
        icon: "success",
        allowOutsideClick: false,
        confirmButtonColor: "#e30613",
      }).then((result) => {
        if (result['isConfirmed']){
          handleRequest();
        }
      });
    });
    }
  });
};
const handleRequest = (e) => {
  adminhttp().get('/admin/pavilions').then((data) => {
    if(data.data.data.pavilions.length>0){
      setLoading(true)
      setPavilions(data.data.data.pavilions);
      setRows(data.data.data.pavilions.length);
      setLoading(false)
    }else{
      setNoData(true)
      setLoading(false)
    }
  });
}

useEffect(() => {
  handleRequest();    
}, []);

const showAlert = () => {
  if (Object.values(permissions).length > 0 && !permissions['view-pavilions']) {
    Swal.fire({
      title: "Permission Denied",
      text: "",
      icon: "warning",
      confirmButtonColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/admin/dashboard");
      }
    });
  }
};

  
  // useEffect(() => {
  //   getPermissions().then((res)=> (setPermissions(res)))
  // }, []);

  useEffect(() => {
    setFilteredPavilions(pavilions);
  }, [pavilions]);
  
  return (
    <>
    <AdminLayout >
    {loading && <Box display="flex" flexDirection="column" justifyContent="center" alignItems='center' height='100%'>
      <Spinner size='lg' className='me-2 ' animation='border' />
      </Box>}
      {(Object.values(permissions).length>0 && permissions['view-pavilions']) ? <Container fluid className='bg-light' style={{minHeight:'100vh'}} >
        <Box component="section" sx={{ px: 2, pt: 2, pb: 1 }}>
          <AdminBreadCrumbs breadcrumbs={breadcrumbs} />
        </Box>
        
        <Box component="section" className='d-flex justify-content-between' sx={{ px: 2, pt: 0, pb: 1 }}>
          <h4>All Pavilions</h4>
          <Form className="d-flex justify-content-center">
                <Form.Control type="search" placeholder="Search Pavilions" 
                value={value}
                onChange={(e) => handleSearch(e)}
                />
          </Form>
          {permissions['create-pavilions'] && <Button onClick={()=>setShowModal(true)} variant='primary'>Add Pavilion</Button>}
          
        </Box>
        
        <ListGroup variant="flush">
          {/* <ListGroup.Item className='d-flex justify-content-between'>
            <h4>All Pavilions</h4>
            <Button>Add Pavilion</Button>
          </ListGroup.Item> */}
          <ListGroup.Item className='bg-transparent'>
            {/* <Container fluid >
              <Box sx={{ height: 'auto', width: '100%' }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  sx={{
                    maxWidth: 'fit-content',
                    boxShadow: 0,
                    border: 0,
                    borderColor: 'secondary.light',
                    '& .MuiDataGrid-cell:hover': {
                      color: 'primary.main',
                    },
                    '& .MuiDataGrid-cell:visited': {
                      border: 0,
                    },
                  }}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                  }}
                  pageSizeOptions={[5]}
                  checkboxSelection
                  disableRowSelectionOnClick
                  slots={{ toolbar: GridToolbar }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                    },
                  }}

                />

              </Box>
            </Container> */}
{loading ? (
      <Box sx={{ flexGrow: 1, textAlign:'center' }}>
      <Spinner size='lg' className='me-2 ' animation='border' />
      </Box>
    ):(
            filteredPavilions.length > 0 && (
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>

                {filteredPavilions.map((pavilion, index) =>
                  <Grid item xs={2} sm={4} md={3} key={index}>
                    {/* <Item key={index} className='shadow rounded-4'> */}

                    <Card key={index} className="bg-dark text-white" style={{cursor:'pointer'}} onClick={()=>handleClick(pavilion.id,pavilion.name)}>
                      <Card.Header className='d-flex justify-content-between align-items-center py-2'>
                        <div className='d-flex align-items-center'> <DateIcon fontSize='small' className='me-1'/> {formatDate(pavilion.created_at)}</div>
                            {/* <IconButton onClick={handleEditClick} className='p-1' aria-label="settings">
                            <EditIcon />
                            </IconButton> */}
                            <CRUDDropdown
            title={<MoreHorizIcon fontSize="small" />}
            handleDropdown={(e)=>handleDropdown(e)}
            // addTitle="Edit"
            // addClick={handleEditClick}
            editTitle={
              pavilion.status === 1
                ? (permissions['inactive-pavilions'] && "Deactivate")
                : (permissions['active-pavilions'] && "Activate")
            }
            editClick={handleStatus(pavilion.id, pavilion.status)}
            deleteTitle={permissions['delete-pavilions'] && "Delete"}
            deleteClick={handleDelete(pavilion.id)}
          />
                      </Card.Header>
                      <Card.Img src={localStorage.getItem('bucketName') + '/images/pavilion/' + pavilion.main_image} alt={pavilion.name}  />
                      {/* <Card.ImgOverlay>
        <Card.Title>Card title</Card.Title>
        <Card.Text>
          This is a wider card with supporting text below as a natural lead-in
          to additional content. This content is a little bit longer.
        </Card.Text>
        <Card.Text>Last updated 3 mins ago</Card.Text>
      </Card.ImgOverlay> */}
                      <Card.Title className='px-2 text-light fw-bold'>{pavilion.name}</Card.Title>
                      <Card.Footer className='d-flex justify-content-between align-items-center'>

                        <Badge pill bg={pavilion.privacy === 1 ? 'warning' : 'secondary'}>{pavilion.privacy === 1 ? 'Private' : 'Public'}</Badge>
                        <Badge pill bg={pavilion.status === 1 ? 'info' : 'secondary'}>{pavilion.status === 1 ? 'Active' : 'InActive'}</Badge>
                        <Badge pill bg={pavilion.type === 'paid' ? 'success' : 'danger'}>{pavilion.type === 'paid' ? 'Paid' : 'Free'}</Badge>

                      </Card.Footer>
                    </Card>
                    {/* </Item> */}
                  </Grid>
                )}
              </Grid>
            </Box>
            ))}    
            {noData && (
              <Box sx={{ flexGrow: 1 }} className="d-flex justify-content-center">
                <Alert variant="outlined" severity="error">
                  Pavilions not found
                </Alert>
              </Box>
          )}
          </ListGroup.Item>
        </ListGroup>
      </Container> : ( showAlert() )}
    </AdminLayout>
    <AddPavilion show={showModal}  close={() => setShowModal(false)} />
    </>
  )
}

export default AllPavilions
