import React, { useState, useEffect } from "react";
import AdminLayout from "../layouts/AdminLayout";
import { Container, ListGroup } from "react-bootstrap";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import adminhttp from "../../adminhttp";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
// import Stack from '@mui/material/Stack'
import Stack from "react-bootstrap/Stack";
import Card from "react-bootstrap/Card";

// import Card from '@mui/material/Card';
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AdminBreadCrumbs from "../components/AdminBreadCrumbs";
import { Link } from "react-router-dom";
// import Badge from '@mui/material/Badge';
import Badge from "react-bootstrap/Badge";
import { formatDate } from "../../components/PavilionConstants";
import DateIcon from "@mui/icons-material/InsertInvitation";
import Dropdown from "react-bootstrap/Dropdown";
import { Row, Col, Button } from "react-bootstrap";
// import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation } from "react-router-dom";
import EditIcon from "@mui/icons-material/DriveFileRenameOutlineTwoTone";
import SettingsIcon from "@mui/icons-material/SettingsTwoTone";
import MenusIcon from "@mui/icons-material/ListAltTwoTone";
import SponsorsIcon from "@mui/icons-material/Diversity2TwoTone";
import MultiBannerIcon from "@mui/icons-material/PermMediaTwoTone";
import StoreIcon from "@mui/icons-material/StoreTwoTone";
import RolesIcon from "@mui/icons-material/WorkTwoTone";
import MembershipsIcon from "@mui/icons-material/PeopleAltTwoTone";
import LibraryIcon from "@mui/icons-material/LibraryBooksTwoTone";
import EventIcon from "@mui/icons-material/EventAvailableTwoTone";
import EmailIcon from "@mui/icons-material/EmailTwoTone";
import PinsIcon from "@mui/icons-material/PushPinTwoTone";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Image from "react-bootstrap/Image";
import InputGroup from "react-bootstrap/InputGroup";
import MuiButton from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Swal from 'sweetalert2'
import Spinner from 'react-bootstrap/Spinner';
// import {getPermissions} from '../utils/utils'

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
  variants: [
    {
      props: ({ expand }) => !expand,
      style: {
        transform: "rotate(0deg)",
      },
    },
    {
      props: ({ expand }) => !!expand,
      style: {
        transform: "rotate(180deg)",
      },
    },
  ],
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  ...theme.applyStyles("dark", {
    backgroundColor: "#1A2027",
  }),
}));

function handleAction(row) {
  alert(row.id);
}

const Settings = (props) => {

  const [permissions, setPermissions]=useState(JSON.parse(localStorage.getItem('permissions')));
  const location = useLocation();
  const data = location.state;

  const splitURL = location.pathname.toString().split("/");

  const [loading, setLoading] = useState(true);

  const [progress, setProgress] = useState(false);
  const [rows, setRows] = useState(0);
  const [counter, setCounter] = useState(0);
  const [pavilionName, setPavilionName] = useState("");
  const navigate = useNavigate();
  
  const [path, setPath] = useState(`${localStorage.getItem('bucketName')}/images/front/thumbnails/`);

  const [logoFile, setLogoFile] = useState('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22171%22%20height%3D%22180%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20171%20180%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_192d26452ad%20text%20%7B%20fill%3A%23999%3Bfont-weight%3Anormal%3Bfont-family%3AArial%2C%20Helvetica%2C%20Open%20Sans%2C%20sans-serif%2C%20monospace%3Bfont-size%3A10pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_192d26452ad%22%3E%3Crect%20width%3D%22171%22%20height%3D%22180%22%20fill%3D%22%23373940%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%2259.921875%22%20y%3D%2294.5%22%3E171x180%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E');
  const [logoFile2, setLogoFile2] = useState('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22171%22%20height%3D%22180%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20171%20180%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_192d26452ad%20text%20%7B%20fill%3A%23999%3Bfont-weight%3Anormal%3Bfont-family%3AArial%2C%20Helvetica%2C%20Open%20Sans%2C%20sans-serif%2C%20monospace%3Bfont-size%3A10pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_192d26452ad%22%3E%3Crect%20width%3D%22171%22%20height%3D%22180%22%20fill%3D%22%23373940%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%2259.921875%22%20y%3D%2294.5%22%3E171x180%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E');
  const [faviconFile, setFaviconFile] = useState('data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22171%22%20height%3D%22180%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20171%20180%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_192d26452ad%20text%20%7B%20fill%3A%23999%3Bfont-weight%3Anormal%3Bfont-family%3AArial%2C%20Helvetica%2C%20Open%20Sans%2C%20sans-serif%2C%20monospace%3Bfont-size%3A10pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_192d26452ad%22%3E%3Crect%20width%3D%22171%22%20height%3D%22180%22%20fill%3D%22%23373940%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%2259.921875%22%20y%3D%2294.5%22%3E171x180%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E');
  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to="/admin/dashboard">
      Dashboard
    </Link>,
    <Typography key="3" sx={{ color: "text.primary" }}>
      Company Settings
    </Typography>,
  ];

  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }

  const handleFileChange = (e, field) => {
    const file = e.target.files[0];
    if (file) {
      const url = URL.createObjectURL(file);
      // if (field === "logo") {
      //   document.getElementById('logoId').style.display = 'none';
      //   setLogoFile(URL.createObjectURL(file))
      // }
      // if (field === "transparentLogo") {
      //   document.getElementById('transparentLogoId').style.display = 'none';
      // }
      // if (field === "favicon") {
      //   document.getElementById('faviconicon').style.display = 'none';
      // }

      setSettings(prevSettings => ({ ...prevSettings, [field]: file }));
      if (field === "logo") setLogoFile(url);
      if (field === "transparentLogo") setLogoFile2(url);
      if (field === "favicon") setFaviconFile(url);
    }
  };

  const [settings, setSettings] = useState({
    SITE_NAME: '',
    SITE_TITLE: '',
    SITE_DESCRIPTION: '',
    SITE_LINK: '',
    copyrights: '',
    theme: '',
    logo: '',
    transparentLogo: '',
    favicon: '',
    commissionPercentage: '',
    BIC_FEE: '',
    FROM_NAME: '',
    FROM_EMAIL: '',
    CONTACT_EMAIL: '',
    PHONE: '',
    ADDRESS: '',
    FACEBOOK: '',
    TWITTER: '',
    LINKEDIN: '',
    YOUTUBE: '',
  });
  const handleRequest = () => {
    adminhttp()
    .get(`/admin/websitesettings`)
    .then((response) => {
      setLoading(true)
      const data = response.data.data.settings;
      setLogoFile(path + data.logo)
      setLogoFile2(path + data.transparent)
      setFaviconFile(path + data.favicon)
      setSettings({
        SITE_NAME: data.SITE_NAME || '',
        SITE_TITLE: data.SITE_TITLE || '',
        SITE_DESCRIPTION: data.SITE_DESCRIPTION || '',
        SITE_LINK: data.SITE_LINK || '',
        copyrights: data.copyrights || '',
        theme: data.theme || '',
        logo: logoFile,
        transparentLogo: logoFile2,
        favicon: faviconFile,
        BIC_FEE: data.BIC_FEE || '',
        FROM_NAME: data.FROM_NAME || '',
        FROM_EMAIL: data.FROM_EMAIL || '',
        CONTACT_EMAIL: data.CONTACT_EMAIL || '',
        PHONE: data.PHONE || '',
        ADDRESS: data.ADDRESS || '',
        FACEBOOK: data.FACEBOOK || '',
        TWITTER: data.TWITTER || '',
        LINKEDIN: data.LINKEDIN || '',
        YOUTUBE: data.YOUTUBE || '',
        commissionPercentage: data.commissionPercentage || ''
      });
      setLoading(false)
    })
    .catch((error) => {
      console.error("Error fetching settings:", error);
    });
  }

  const showAlert = () => {
    if (Object.values(permissions).length > 0 && !permissions['edit-settings']) {
      Swal.fire({
        title: "Permission Denied",
        text: "You don't have permission, please contact Administrator",
        icon: "warning",
        confirmButtonColor: "#d33",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/admin/dashboard");
        }
      });
    }
  };
  
  useEffect(() => {
    handleRequest();
  }, []);

  const handleSave = () => {
    setProgress(true)
    const formData = new FormData();
    for (const key in settings) {
      formData.append(key, settings[key]);
    }

    adminhttp()
      .post("/admin/websitesettingsupdate", formData)
      .then(() => {
        Swal.fire({
          title: "Company Settings is saved successfully!",
          icon: "success",
          allowOutsideClick: false,
          confirmButtonColor: "#e30613",
        }).then((result) => {
          handleRequest();
          setProgress(false)
        });


        // Optionally, navigate to another page or refresh the settings data
      })
      .catch((error) => {
        console.error("Error saving settings:", error);
      });
  };

  // useEffect(() => {
  //     getPermissions().then((res)=> (setPermissions(res)))
  // },[]);

  return (
    <AdminLayout>
      {loading && <Box display="flex" flexDirection="column" justifyContent="center" alignItems='center' height='100%'>
          <Spinner size='lg' className='me-2 ' animation='border' />
       </Box>}
      {(Object.values(permissions).length>0 && permissions['edit-settings']) ? <Container fluid className="pb-5">
        <Box component="section" sx={{ px: 2, pt: 2, pb: 1 }}>
          <AdminBreadCrumbs breadcrumbs={breadcrumbs} />
        </Box>

        <Box component="section" sx={{ px: 2, pt: 0, pb: 1 }}>
          <h4> Company Settings </h4>
        </Box>
        <ListGroup variant="flush" className="bg-body rounded-2 shadow-sm mx-3">
          <ListGroup.Item className="bg-transparent">
            <>
              <Button
                type="button"
                variant="primary"
                className="fw-bold position-absolute end-0 me-3"
                onClick={handleSave}  // Call handleSave on button click
              >
               {progress && (<Spinner size='sm' className="me-2" animation="border" />)} Save Settings
              </Button>
              <Tabs
                defaultActiveKey="General"
                id="uncontrolled-tab-example"
                className="mb-3 mx-0"
              >
                <Tab eventKey="General" title="General">


                  <Form>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Website Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="name@example.com"
                        value={settings.SITE_NAME}
                        onChange={(e) => setSettings({ ...settings, SITE_NAME: e.target.value })} />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Website Title</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="name@example.com"
                        value={settings.SITE_TITLE}
                        onChange={(e) => setSettings({ ...settings, SITE_TITLE: e.target.value })}
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Website Description</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="name@example.com"
                        value={settings.SITE_DESCRIPTION}
                        onChange={(e) => setSettings({ ...settings, SITE_DESCRIPTION: e.target.value })}
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Copyright Text</Form.Label>
                      <Form.Control type="text" placeholder="Copyright Text"
                        value={settings.copyrights}
                        onChange={(e) => setSettings({ ...settings, copyrights: e.target.value })}

                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Site Link</Form.Label>
                      <Form.Control type="text" placeholder="https://"
                        value={settings.SITE_LINK}
                        onChange={(e) => setSettings({ ...settings, SITE_LINK: e.target.value })}

                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>Theme</Form.Label>
                      <Form.Select aria-label="Default select example" value={settings.theme}
                        onChange={(e) => setSettings({ ...settings, theme: e.target.value })}
                      >
                        <option>Select Theme</option>
                        <option value="1">BIC UK</option>
                        <option value="2">BIC ME</option>
                      </Form.Select>
                    </Form.Group>

                  </Form>
                </Tab>
                <Tab eventKey="graphics" title="Graphics">
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <Item>
                        <Form.Label>Company Logo <p><b>250*90 px</b></p></Form.Label>

                        <InputGroup className="mb-3 d-flex flex-column">
                          <img id="logoId"
                            src={logoFile}
                            alt="Company Logo"
                            style={{ width: "100%", height: "auto", marginBottom: "10px" }}
                          />
                          {/* {settings.logo ? <img src={localStorage.getItem('bucketName') + '/images/front/thumbnails/' + settings.logo} alt="Company Logo" style={{ width: "100%", height: "auto", marginBottom: "10px" }} />
                            : <Image src={logoFile} className="rounded-top" fluid />} */}
                          {/* <input
  type="file"
  hidden
  onChange={(e) => handleFileChange(e, "logo")}
/> */}
                          {/* <Form.Control type="file" className="w-100 rounded-bottom" /> */}
                          <MuiButton
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            color="error"
                            className="rounded-bottom-2 "
                            startIcon={<CloudUploadIcon />}
                          >
                            &nbsp;Upload Company Logo
                            <input
                              type="file"
                              hidden
                              id='companylogo'
                              onChange={(e) => handleFileChange(e, "logo")}
                            />
                          </MuiButton>
                        </InputGroup>
                      </Item>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Item>
                        <Form.Label>Company Logo (transparent)<p><b>250*90 px</b></p></Form.Label>
                        <InputGroup className="mb-3 d-flex flex-column">
                          {settings.transparentLogo ? <img id='transparentLogoId' 
                          src={logoFile2}
                          // src={localStorage.getItem('bucketName') + '/images/front/thumbnails/' + settings.transparentLogo}
                           alt="Company Logo" style={{ width: "100%", height: "auto", marginBottom: "10px" }} />
                            : ''}
                          {/* {settings.transparentLogo ? <img src={localStorage.getItem('bucketName') + '/images/front/thumbnails/' + settings.transparentLogo} alt="Company Logo" style={{ width: "100%", height: "auto", marginBottom: "10px" }} />
                            : <Image fluid src={logoFile2} className="rounded-top" />} */}
                          {logoFile2 && settings.transparentLogo === '' ? <Image fluid src={logoFile2} className="rounded-top" /> : ''}
                          <MuiButton
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            color='error'
                            className='rounded-bottom-2'
                            startIcon={<CloudUploadIcon />}
                          >
                            &nbsp;Upload Transparent Logo
                            {/* <input
                              type="file"
                              hidden
                              onChange={(e) => setSettings({ ...settings, logoFile2: URL.createObjectURL(e.target.files[0]) })}
                            /> */}

                            <input
                              type="file"
                              hidden
                              onChange={(e) => handleFileChange(e, "transparentLogo")}
                            />
                          </MuiButton>
                        </InputGroup>
                      </Item>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Item>
                        <Form.Label>Favicon<p><b>512*512 px</b></p></Form.Label>
                        <InputGroup className="mb-3 d-flex flex-column">
                          {/* {settings.favicon ? <Image src={localStorage.getItem('bucketName') + '/images/front/thumbnails/' + settings.favicon} className="rounded-top" fluid />
                            : ''} */}
                          <img id='faviconicon'
                          src={faviconFile}
                            // src={settings.favicon ? `${localStorage.getItem('bucketName')}/images/front/thumbnails/${settings.favicon}` : ''}
                            alt="Company Logo"
                            style={{ width: "100%", height: "auto", marginBottom: "10px" }}
                          />
                          {/* {settings.faviconFile ? <Image src={settings.faviconFile} className="rounded-top" fluid />
                            : <Image fluid src={faviconFile} className="rounded-top"  />} */}
                          <MuiButton
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            color='error'
                            className='rounded-bottom-2'
                            startIcon={<CloudUploadIcon />}
                          >
                            &nbsp;Upload Facivon
                            {/* <input
                              type="file"
                              hidden
                              onChange={(e) =>
                                 setSettings({ ...settings, faviconFile: URL.createObjectURL(e.target.files[0]) })}
                            /> */}
                            <input
                              type="file"
                              hidden
                              onChange={(e) => handleFileChange(e, "favicon")}
                            />
                          </MuiButton>
                        </InputGroup>
                      </Item>
                    </Grid>
                  </Grid>
                </Tab>
                <Tab eventKey="payment" title="Payment">
                  <Form>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Platform Commission Percentage(%)</Form.Label>
                      <Form.Control type="text" placeholder="1" value={settings.commissionPercentage}
                        onChange={(e) =>
                          setSettings({ ...settings, commissionPercentage: e.target.value })
                        }
                      />



                    </Form.Group>

                  </Form>
                </Tab>
                <Tab eventKey="contact" title="Contact">
                  <Form>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>From Name</Form.Label>
                      <Form.Control type="text" placeholder="From Name"
                        value={settings.FROM_NAME}
                        onChange={(e) => setSettings({ ...settings, FROM_NAME: e.target.value })}



                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>From Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="info@domain.com"
                        value={settings.FROM_EMAIL}
                        onChange={(e) => setSettings({ ...settings, FROM_EMAIL: e.target.value })}


                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Contact Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="info@domain.com"
                        value={settings.CONTACT_EMAIL}
                        onChange={(e) => setSettings({ ...settings, CONTACT_EMAIL: e.target.value })}


                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Phone</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="+000-123-456-789"
                        value={settings.PHONE}
                        onChange={(e) => setSettings({ ...settings, PHONE: e.target.value })}
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Address</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Abc Area Street #32"
                        value={settings.ADDRESS}
                        onChange={(e) => setSettings({ ...settings, ADDRESS: e.target.value })}

                      />
                    </Form.Group>

                  </Form>
                </Tab>
                <Tab eventKey="socialmedia" title={"Social Meida"}>
                  <Form>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Facebook</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="https://www.facebook.com"
                        value={settings.FACEBOOK}
                        onChange={(e) => setSettings({ ...settings, FACEBOOK: e.target.value })}

                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Twitter</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="http://www.twitter.com"
                        value={settings.TWITTER}
                        onChange={(e) => setSettings({ ...settings, TWITTER: e.target.value })}

                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Linkedin</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="http://www.linkedin.com"
                        value={settings.LINKEDIN}
                        onChange={(e) => setSettings({ ...settings, LINKEDIN: e.target.value })}

                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Youtube</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="http://www.youtube.com"
                        value={settings.YOUTUBE}
                        onChange={(e) => setSettings({ ...settings, YOUTUBE: e.target.value })}


                      />
                    </Form.Group>

                  </Form>
                </Tab>
              </Tabs>
            </>
          </ListGroup.Item>
        </ListGroup>
      </Container> : (showAlert())}
    </AdminLayout>
  );
};

export default Settings;
