import React, { useState, useEffect, isValidElement } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FloatingLabel, Modal } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { Link, useLocation } from "react-router-dom";
import adminhttp from "../../../adminhttp";
import Swal from "sweetalert2";
import Divider from "@mui/material/Divider";
import Spinner from 'react-bootstrap/Spinner';

function AddAdminRole(props) {

  const [loginModalShow, setLoginModalShow] = useState(props.showModal);

  const [validated, setValidated] = useState(false);
  const [progress, setProgress] = useState(false);
  const [sponsor, setSponsor] = useState([]);

  const location = useLocation();
  const data = location.state;
  const splitURL = location.pathname.toString().split("/");

  // const [name, setName] = useState("");
  // const [category, setCategory] = useState("");
  // const [link, setLink] = useState("");
  // const [logo, setLogo] = useState("");
  // const [position, setPosition] = useState("");

  const [show, setShow] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    status: "",
    position: "",
  });

  // Handle input changes
  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
          setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === true) {
      setProgress(true)
      const submitData = new FormData();
      submitData.append("name", form.name.value);
      submitData.append("status", form.status.value);
      submitData.append("position", form.position.value);
      
      if(props.edit===true){
        submitData.append("id", props.adminRoleID);
        
        adminhttp()
        .post(`/admin/update_admin_role`, submitData)
        .then((response) => {
          Swal.fire({
            title: 'Admin Role updated successfully!',
            icon: "success",
            allowOutsideClick: false,
            confirmButtonColor: "#e30613",
          });
          setProgress(false);
          props.close();
          props.handleRequest();
        })
        .catch((error) => {
          console.error("There was an error submitting the form!", error);
        });

        
      } else {
        adminhttp().post("/admin/create_admin_role", submitData).then((response) => {
          Swal.fire({
            title: 'New Admin Role added successfully!',
            icon: "success",
            allowOutsideClick: false,
            confirmButtonColor: "#e30613",
          });
          setProgress(false);
          props.close();
          props.handleRequest();
        })
        .catch((error) => {
          console.error("There was an error submitting the form!", error);
        });
      }
      
      
      }
  };

  const handleRequest = () => {
    
    adminhttp().get(`/admin/admin_user_role/${props.adminRoleID}`).then((data) => {       
        if (data.data.data.length > 0) {
          setFormData({
            name: data.data.data[0].name,
            category: data.data.data[0].status,
            position: data.data.data[0].position,
          });
        }
      });
  };
  useEffect(() => {
    if(props.edit===true){
      handleRequest();
    } else {
      setFormData({
        name: "",
        status: "",
        position: "",
      });
    }
    
  }, [props.edit, props.adminRoleID]);

  return (
    <Modal centered backdrop="static"  show={props.show}
    onHide={props.close}>
      <Modal.Header className="py-1 my-1" closeButton>
        <Modal.Title>{props.edit===true ? 'Edit' : 'Add'} Admin Role</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            id="adminRoleForm"
          >
        <Form.Group controlId="validationCustom01" className="mb-1">
          <Form.Control
            name='name'
            required
            type="text"
            // className={formData.roleName && formData.roleName.trim().length > 0 ? "is-valid" : "is-invalid"}
            placeholder="Role Name"
            className={formData.name && Object.values(formData.name).length > 0 ? "is-valid" : "is-invalid"}
            value={formData.name && formData.name}
            onChange={handleChange}
          />
                    {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}

        </Form.Group>
        <Form.Group controlId="validationCustom02" className="mb-1">
        <Form.Select name='status' value={formData.status} onChange={handleChange}>
           <option value="1" selected>Active</option>
           <option value="0">InActive</option>
         </Form.Select>
    </Form.Group>
        <Form.Group controlId="validationCustom03" className="mb-1">
          <Form.Control
            name='position'
            required
            type="text"
            className={formData.position && Object.values(formData.position).length > 0 ? "is-valid" : "is-invalid"}
            placeholder="Position"
            value={formData.position}
            onChange={handleChange}
          />
                    {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}

        </Form.Group>
          </Form>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.close}>
          Close
        </Button>
        <Button
          variant="primary"
          type="submit"
          // onClick={handleSubmit}
          form="adminRoleForm"
        >
          {progress && (<Spinner size='sm' className="me-2" animation="border" />)} Save Admin Role
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddAdminRole;

