// import logo from './logo.svg';
// import './App.css';
// import './index.css';
import Home from './pages/Home';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react'
import Register from './pages/Register';
import Contact from './pages/Contact';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Layout from './components/layouts/Layout';
import PavilionLayout from './components/layouts/PavilionLayout';
import PrivacyPolicy from './pages/PrivacyPolicy';
import CSAEPolicy from './pages/CSAEPolicy';
import BusinessServices from './pages/BusinessServices';
import ResetPwd from './pages/ResetPwd';
import AboutPavilion from './pages/pavilion/AboutPavilion';
import DetailPavilion from './pages/pavilion/DetailPavilion';
import ProfilePavilion from './pages/pavilion/ProfilePavilion';
import SharePavilion from './pages/pavilion/SharePavilion';
import AboutTab from './pages/pavilion/PavilionTabs/AboutTab';
import WallPostTab from './pages/pavilion/PavilionTabs/WallPostTab';
import MembersTab from './pages/pavilion/PavilionTabs/MembersTab';
import EventsTab from './pages/pavilion/PavilionTabs/EventsTab';
import PublicationsTab from './pages/pavilion/PavilionTabs/PublicationsTab';
import PollsTab from './pages/pavilion/PavilionTabs/PollsTab';
import MCPressTab from './pages/pavilion/PavilionTabs/MCPressTab';
import MCVideosTab from './pages/pavilion/PavilionTabs/MCVideosTab';
import MCPhotosTab from './pages/pavilion/PavilionTabs/MCPhotosTab';
import LibraryTab from './pages/pavilion/PavilionTabs/LibraryTab';
import WallGalleryTab from './pages/pavilion/PavilionTabs/WallGalleryTab';
import ShopTab from './pages/pavilion/PavilionTabs/ShopTab';
import MyProfileTab from './pages/pavilion/ProfileTabs/MyProfileTab';
import MyWallTab from './pages/pavilion/ProfileTabs/MyWallTab';
import MyCommunityTab from './pages/pavilion/ProfileTabs/MyCommunityTab';
import MyWallGalleryTab from './pages/pavilion/ProfileTabs/MyWallGalleryTab';
import MyResourcesTab from './pages/pavilion/ProfileTabs/MyResourcesTab';
import MyPurchasedHistoryTab from './pages/pavilion/ProfileTabs/MyPurchasedHistoryTab';
import MyEventsTab from './pages/pavilion/ProfileTabs/MyEventsTab';
import HomePavilion from './pages/pavilion/HomePavilion';
import MyPublicProfileTab from './pages/pavilion/ProfileTabs/MyPublicProfileTab';
import PVLNPolls from './components/pavilion/Boxes/RightSection/PVLNPolls';
import PVLNWallPosts from './components/pavilion/Boxes/RightSection/PVLNWallPosts';
import PVLNEvents from './components/pavilion/Boxes/RightSection/PVLNEvents';
import PVLNPublications from './components/pavilion/Boxes/RightSection/PVLNPublications';
import PRFLMyPublicProfile from './components/pavilion/Boxes/LeftSection/PRFLMyPublicProfile';
import PVLNPress from './components/pavilion/Boxes/RightSection/PVLNPress';
import PVLNShop from './components/pavilion/Boxes/RightSection/PVLNShop';
import http, { siteUrl } from './http';
import PRFLMenu2 from './components/pavilion/Boxes/LeftSection/PRFLMenu2';
import PrivateRoutes from './utils/PrivateRoutes'
import PublicRoutes from './utils/PublicRoutes';
import PRFLMenu3 from './components/pavilion/Boxes/LeftSection/PRFLMenu3';
import UserCheck from './components/pavilion/Boxes/RightSection/UserCheck';
import LandingPage from './pages/LandingPage';
import SponsorshipAPPG from './pages/SponsorshipAPPG';
import ErrorPage from './pages/ErrorPage'
import Dashboard from './admin/pages/Dashboard';
import AdminLayout from './admin/layouts/AdminLayout';
import AllPavilions from './admin/pages/AllPavilions';
import Pavilion from './admin/pages/Pavilion';
import PavilionCategories from './admin/pages/PavilionCategories';
import MembershipPlans from './admin/pages/MembershipPlan';
import Members from './admin/pages/Members';
import SEOSettings from './admin/pages/SEOSettings';
import AdminLogin from './admin/pages/AdminLogin';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import AdminUsers from './admin/pages/AdminUsers';
import Sponsors from './admin/pages/pavilion/Sponsors';
import Roles from './admin/pages/pavilion/Roles';
import Memberships from './admin/pages/pavilion/Memberships';
import Banners from './admin/pages/pavilion/Banners';
import RoleACL from './admin/pages/pavilion/RoleACL';
import ACL from './admin/pages/pavilion/ACL';
import EventCategories from './admin/pages/pavilion/EventCategories';
import Settings from './admin/pages/Settings';
import MembershipACL from './admin/pages/pavilion/MembershipACL';
import PavilionSettings from './admin/pages/PavilionSettings';
import Tickets from './admin/pages/Tickets';
import Menus from './admin/pages/pavilion/Menus';
import AdminUserRoles from './admin/pages/AdminUserRoles';
import Users from './admin/pages/pavilion/Users';
import PaidMemships from './admin/pages/pavilion/PaidMemships';
import ExpiredMemships from './admin/pages/pavilion/ExpiredMemships';
import PubCategories from './admin/pages/pavilion/PubCategories';
import ComposeEmail from './admin/pages/pavilion/ComposeEmail';
import AdminUserRoleACL from './admin/pages/AdminUserRoleACL';
import AdminRoutes from './admin/utils/AdminRoutes';
import adminhttp from './adminhttp';
import Swal from 'sweetalert2';
import PublicSurvey from './pages/PublicSurvey2';
import ParliamentarySurvey from './pages/ParliamentarySurvey';
import CommunitySurvey from './pages/CommunitySurvey';
import { useDeviceType } from './components/Hooks';

const theme = createTheme({
  palette: {
    primary: {
      main: "#2a9461"
    }
  }
});
function App() {




  const navigate = useNavigate();
  const locaiton = useLocation();

  const deviceType = useDeviceType();
  //console.log(deviceType);
  // const { theme } = useContext(ThemeContext);
  const currentDomain = window.location.hostname;

  // const liveDomain = "localhost";
  const liveDomain = "bicpavilion.com";
  const bucketUrl = currentDomain === liveDomain
    ? "https://bic3-live.s3.eu-west-2.amazonaws.com"
    : "https://bic3-staging.s3.eu-west-2.amazonaws.com";
  // console.log(currentDomain, bucketUrl);
  localStorage.setItem('bucketName', bucketUrl);
  // localStorage.setItem('bucketName', "https://bic3-staging.s3.eu-west-2.amazonaws.com");
  localStorage.setItem('data-bs-theme', 'light');
  
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  useEffect(() => {
    const currentUrl = window.location.href;
    const homepage = "https://bicpavilion.com"; // Define the exact homepage URL
    // Allow popup only on the exact homepage

    if (currentUrl === homepage || currentUrl === `${homepage}/`) {
    if(deviceType==='iOS' || deviceType==='Android'){
      Swal.fire({
        // title: "Do you want to save the changes?",
        // showDenyButton: true,
        customClass: 'swal-vertical',
        showCancelButton: true,
        confirmButtonText: `Open in ${deviceType} App for Mobile Use`,
        confirmButtonText: `Open in ${deviceType} App for better mobile experience`,
        confirmButtonColor: "#e30613",
        cancelButtonText: `Continue with browser designed for PC web use`
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
        if(deviceType==='iOS'){
          // window.location.href='https://apps.apple.com/pk/app/pavilion/id6450182778';
          window.location.href='https://pavillionapp.page.link/start';
        } else if(deviceType==='Android'){
          window.location.href='https://play.google.com/store/apps/details?id=com.pavillionapp.pavillion';
        }
        } 
      });
    }
  }
  }, [deviceType]);

  const theme = localStorage.getItem("data-bs-theme");
  // const [csrfToken, setCsrfToken] = useState('');

  async function fetchCsrfToken() {
    // const response = await axios.get('https://react-live.bicpavilion.com/csrf-cookie');
    http().get(`${siteUrl}/sanctum/csrf-cookie`).then((response) => {
      if (response.status === 200) {
        // setCsrfToken(response.data.csrfToken);
        localStorage.setItem("X-XSRF-TOKEN", response.data.csrfToken);
      }
    });
  }
  fetchCsrfToken();

 return (

    // <div id="app-theme" className={`App ${theme}`} data-bs-theme='dark'>
    <div id="app-theme" data-bs-theme={theme} >
      <Routes>
        <Route path='*' element={<ErrorPage />} />
        <Route path='/' component={<Layout />}>
           <Route path='admin' component={<AdminLayout />}>
           <Route path='login' element={<AdminLogin />}></Route>
           <Route element={<AdminRoutes />}>
             <Route path='dashboard' element={<Dashboard />}></Route>
             <Route path='pavilions' element={ <AllPavilions /> }/>
             <Route path='pavilions/:id' element={<Pavilion id=':id'/>} /> 
             <Route path='pavilions/:id/sponsors' element={<Sponsors id=':id'/>} /> 
             <Route path='pavilions/:id/roles' element={<Roles id=':id'/>} /> 
             <Route path='pavilions/:id/memberships' element={<Memberships id=':id'/>} /> 
             <Route path='pavilions/:id/memberships/:membershipid' element={<MembershipACL />} /> 
             <Route path='pavilions/:id/banners' element={<Banners id=':id'/>} /> 
             <Route path='pavilions/:id/roles/:roleid' element={<RoleACL />} /> 
             <Route path='pavilions/:id/eventcategories' element={<EventCategories id=':id'/>} /> 
             <Route path='pavilions/:id/categories' element={<PubCategories id=':id'/>} /> 
             <Route path='pavilions/:id/sendemail' element={<ComposeEmail />} /> 
             <Route path='pavilions/:id/settings' element={<PavilionSettings/>} /> 
             <Route path='pavilions/:id/menus' element={<Menus/>} /> 
             <Route path='pavilions/:id/users' element={<Users/>} /> 
             <Route path='pavilions/:id/paidmemships' element={<PaidMemships/>} /> 
             <Route path='pavilions/:id/expiredmemships' element={<ExpiredMemships/>} /> 
             <Route path='pavilioncats' element={<PavilionCategories />}></Route>
             <Route path='plans' element={<MembershipPlans />}></Route>
             <Route path='adminusers' element={<AdminUsers />}></Route>
             <Route path='roles' element={<AdminUserRoles /> }></Route>
             <Route path='roles/:roleid' element={<AdminUserRoleACL />} /> 
             <Route path='members' element={ <Members /> }></Route>
             <Route path='seo' element={<SEOSettings />}></Route>
             <Route path='tickets' element={<Tickets  /> }></Route>
             <Route path='settings' element={<Settings /> }></Route>
             </Route>
            </Route>
        
          <Route element={<PublicRoutes />}>
            <Route path='/' element={<Home />}></Route>
          </Route>
          <Route path='big-innovation-centre' element={<LandingPage />}></Route>
        </Route>
        <Route path='parliamentary-ai-awareness-survey' element={<ParliamentarySurvey />}></Route> 
        <Route path='community-ai-awareness-survey' element={<CommunitySurvey />}></Route> 
          <Route path='govt-approach-to-regulation-of-AI' element={<PublicSurvey />}></Route> 
          <Route path='big-innovation-centre' element={<LandingPage />}></Route> 
          <Route path='SponsorshipAPPG' element={<SponsorshipAPPG />}></Route>
          <Route path='register' element={<Register />}></Route>
          <Route path='contact' element={<Contact />}></Route>
          <Route path='csae-policy' element={<CSAEPolicy />}></Route>
          <Route path='privacypolicy' element={<PrivacyPolicy />}></Route>
          <Route path='plans' element={<BusinessServices />}>
            <Route path=':id' element={<BusinessServices />} />
          </Route>
          <Route path='resetpassword' element={<ResetPwd />}></Route>
          <Route path='resetpassword/:token' element={<ResetPwd />}></Route>
          <Route element={<PrivateRoutes />}>
            <Route path='/homepavilion' element={<HomePavilion />}>
              <Route path='pavilion' element={<HomePavilion />}>
                <Route path=':id' element={<HomePavilion />}>
                  <Route path="poll" element={<PVLNPolls />}></Route>
                  <Route path="poll/:id" element={<PVLNPolls />}></Route>
                  <Route path="walls" element={<PVLNWallPosts />}></Route>
                  <Route path="walls/:id" element={<PVLNWallPosts />}></Route>
                  <Route path="event" element={<PVLNEvents />}></Route>
                  <Route path="event/:id" element={<PVLNEvents />}></Route>
                  <Route path="publication" element={<PVLNPublications />}></Route>
                  <Route path="publication/:id" element={<PVLNPublications />}></Route>
                  <Route path="newsinformation" element={<PVLNPress />}></Route>
                  <Route path="newsinformation/:id" element={<PVLNPress />}></Route>
                </Route>
              </Route>
            </Route>
          </Route>
          <Route path='/about_pavilion' element={<HomePavilion />}>
            <Route path=":id" element={<PRFLMenu2 />}></Route>
          </Route>
          <Route path='/shareprofiles' element={<HomePavilion />}>
            <Route path=":id" element={<PRFLMyPublicProfile />}></Route>
          </Route>
          <Route path='/poll' element={<HomePavilion />}>
            <Route path=":id" element={<PVLNPolls />}></Route>
          </Route>
          <Route path='/event' element={<HomePavilion />}>
            <Route path=":id" element={<PVLNEvents />}></Route>
          </Route>
          <Route path='/publication' element={<HomePavilion />}>
            <Route path=":id" element={<PVLNPublications />}></Route>
          </Route>
          <Route path='/newsinformation' element={<HomePavilion />}>
            <Route path=":id" element={<PVLNPress />}></Route>
          </Route>
          <Route path='/walls' element={<HomePavilion />}>
            <Route path=":id" element={<PVLNWallPosts />}></Route>
          </Route>
          <Route path='/preview' element={<HomePavilion />}>
            <Route path=":id" element={<PRFLMenu3 />}></Route>
          </Route>
          <Route path=":id/:id1/:id2" element={<UserCheck />}></Route>
          <Route element={<PrivateRoutes />}>
            <Route path='/shop' element={<HomePavilion />}>
              <Route path=":id" element={<PVLNShop />}></Route>    {/* pavilionid  */}
            </Route>
          </Route>
        



        {/* <Route path='/' component={<PavilionLayout />}>
          <Route path='/aboutpavilion' element={<AboutPavilion />}></Route>
          <Route path='detailpavilion' element={<DetailPavilion />}>
            <Route path='about' element={<AboutTab />}></Route>
            <Route path='wallpost' element={<WallPostTab />}></Route>
            <Route path='members' element={<MembersTab />}></Route>
            <Route path='events' element={<EventsTab />}></Route>
            <Route path='publications' element={<PublicationsTab />}></Route>
            <Route path='polls' element={<PollsTab />}></Route>
            <Route path='mcpress' element={<MCPressTab />}></Route>
            <Route path='mcvideos' element={<MCVideosTab />}></Route>
            <Route path='mcphotos' element={<MCPhotosTab />}></Route>
            <Route path='library' element={<LibraryTab />}></Route>
            <Route path='wallgallery' element={<WallGalleryTab />}></Route>
            <Route path='shop' element={<ShopTab />}></Route>
          </Route>
          <Route path='/profilepavilion' element={<ProfilePavilion />}>
            <Route path='myprofile' element={<MyProfileTab />}></Route>
            <Route path='mywall' element={<MyWallTab />}></Route>
            <Route path='mycommunity' element={<MyCommunityTab />}></Route>
            <Route path='myevents' element={<MyEventsTab />}></Route>
            <Route path='mywallgallery' element={<MyWallGalleryTab />}></Route>
            <Route path='myresources' element={<MyResourcesTab />}></Route>
            <Route path='mypurchasedhistory' element={<MyPurchasedHistoryTab />}></Route>
          </Route>
          <Route path='/homepavilion' element={<HomePavilion />}>
            <Route path='mypublicprofile' element={<MyPublicProfileTab />}></Route>
          </Route>
          <Route path='/sharepavilion' element={<SharePavilion />}></Route>
        </Route> */}
      </Routes>
    </div>

  );
}

export default App;
