import React, { useState, useEffect, isValidElement } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FloatingLabel } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import adminhttp from "../../../adminhttp";
import Swal from "sweetalert2";
import Divider from "@mui/material/Divider";
import Spinner from "react-bootstrap/Spinner";
import { Modal } from "react-bootstrap";
function AddPavilionCategory(props) {
  const [validated, setValidated] = useState(false);
  const [progress, setProgress] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    description: "",
  });

  const handleChange = (e) => {
    const { name, value, type} = e.target;
      setFormData({ ...formData, [name]: value });
  };


  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);

    if (form.checkValidity() === true) {
      setProgress(true);
      const submitData = new FormData();
      submitData.append("name", formData.name);
      submitData.append("description", formData.description);

      if (props.edit === true) {
        submitData.append("id", props.catID);
        adminhttp()
          .post(`/admin/updatepavilioncatagory`, submitData)
          .then((response) => {
            Swal.fire({
              title: "Pavilion Category updated successfully!",
              icon: "success",
              allowOutsideClick: false,
              confirmButtonColor: "#e30613",
            });
            setProgress(false);
            props.close();
            props.handleRequest();
          })
          .catch((error) => {
            console.error("There was an error submitting the form!", error);
          });
      } else {
        adminhttp()
          .post("/admin/createpavilioncatagory", submitData)
          .then((response) => {
            Swal.fire({
              title: "Pavilion Category added successfully!",
              icon: "success",
              allowOutsideClick: false,
              confirmButtonColor: "#e30613",
            });
            setProgress(false);
            props.close();
            props.handleRequest();
          })
          .catch((error) => {
            console.error("There was an error submitting the form!", error);
          });
      }
    }
  };

  const handleRequest = () => {
    adminhttp().get(`/admin/singlepavilioncatagory/${props.catID}`)
      .then((data) => {
        if (data.data.data.all_data) {
          setFormData({
            name: data.data.data.all_data.name,
            description: data.data.data.all_data.description,
          });
        }
      });
  };

  useEffect(() => {
    if (props.edit) {
      handleRequest();
    } else {
      setFormData({
        name: "",
        description: "",
      });
    }
  }, [props.edit, props.catID]);

  return (
    <Modal centered backdrop="static" show={props.show} onHide={props.close}>
      <Modal.Header className="py-1 my-1" closeButton>
        <Modal.Title>
          {props.edit === true ? "Edit" : "Add"} Pavilion Category
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            id="pavilionCatForm"
          >
            <Form.Group controlId="validationCustom01" className="mb-1">
            <Form.Control
            name='name'
            required
            type="text"
            placeholder="Category Name"
            className={formData.name && Object.values(formData.name).length > 0 ? "is-valid" : "is-invalid"}
            value={formData.name && formData.name}
            onChange={handleChange}
          />
              {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
            </Form.Group>
            <Form.Group controlId="validationCustom02" className="mb-1">
            <Form.Control
            name='description'
            rows={3}
            as="textarea"
            placeholder="Category Description"
            value={formData.description && formData.description}
            onChange={handleChange}
          />
              {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
            </Form.Group>
          </Form>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.close}>
          Close
        </Button>
        <Button variant="primary" type="submit" form="pavilionCatForm">
          {progress && (
            <Spinner size="sm" className="me-2" animation="border" />
          )}{" "}
          Save Category
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddPavilionCategory;
