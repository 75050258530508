import React, { useState, useEffect, isValidElement } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FloatingLabel, Modal } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { Link, useLocation } from "react-router-dom";
import adminhttp from "../../../adminhttp";
import Swal from "sweetalert2";
import Divider from "@mui/material/Divider";
import Spinner from 'react-bootstrap/Spinner';
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
function UpdateRoleMembership(props) {

  const [loginModalShow, setLoginModalShow] = useState(props.showModal);

  const [validated, setValidated] = useState(false);
  const [progress, setProgress] = useState(false);
  const [sponsor, setSponsor] = useState([]);

  const location = useLocation();
  const data = location.state;
  const splitURL = location.pathname.toString().split("/");
  const [memberships, setMemberships]=useState([]);
  // const [name, setName] = useState("");
  // const [category, setCategory] = useState("");
  // const [link, setLink] = useState("");
  // const [logo, setLogo] = useState("");
  // const [position, setPosition] = useState("");

  const [show, setShow] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    role: "",
    membership: "",
  });

  const [selectedValue, setSelectedValue]=useState()

  const [areEqual, setAreEqual]=useState(false)
  const [result, setResult]=useState('')
  // Handle input changes
  const handleChange = (e) => {
    const { name, value, type, files, checked } = e.target;
      setFormData({ ...formData, [name]: value });
  };


  const [selectedItems, setSelectedItems] = useState([])
	

	function checkboxHandler(e){
		let isSelected = e.target.checked;
		let value = parseInt(e.target.value);

		if( isSelected ){
			setSelectedItems( [...selectedItems, value ] )
		}else{
			setSelectedItems((prevData)=>{
				return prevData.filter((id)=>{
					return id!==value
				})
			})
		}
	}


  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === true) {
      setProgress(true)
      const submitData = new FormData();
      submitData.append("pavilion_id", splitURL[3]);
      submitData.append("user_id", props.userID);
      submitData.append("role_id", formData.role);
      submitData.append("data_flag", 'No');
      submitData.append("membership_id", formData.membership);

      if(props.edit===true){
        submitData.append("id", props.adminUserID);
        adminhttp()
        .post(`/admin/attachuser`, submitData)
        .then((response) => {
          Swal.fire({
            title: 'Assigned Role and Membership to this user successfully!',
            icon: "success",
            allowOutsideClick: false,
            confirmButtonColor: "#e30613",
          });
          setProgress(false);
          props.close();
          props.handleRequest();
        })
        .catch((error) => {
          console.error("There was an error submitting the form!", error);
        });

        
      } 
      // else {

      //   submitData.append("password", formData.password);
      //   submitData.append("password_confirmation", formData.cpassword);
      //   submitData.append("roles[]", formData.role);

      //   adminhttp().post("/admin/createuser", submitData).then((response) => {
      //     Swal.fire({
      //       title: 'New Admin Role added successfully!',
      //       icon: "success",
      //       allowOutsideClick: false,
      //       confirmButtonColor: "#e30613",
      //     });
      //     setProgress(false);
      //     props.close();
      //     props.handleRequest();
      //   })
      //   .catch((error) => {
      //     console.error("There was an error submitting the form!", error);
      //   });
      // }
      
      
      }
  };
  const [roles, setRoles]=useState([]);
  const fetchRoles = () => {
    adminhttp().get(`/admin/pavilionsUsersRoles/${splitURL[3]}`).then((data) => {
       if (data.data.data.roles_dp.length > 0) {
         setRoles(data.data.data.roles_dp);
       }
        });
  };
  const fetchMemberships = () => {
    adminhttp().get(`/admin/pavilionsUsersRoles/${splitURL[3]}`).then((data) => {
      if (data.data.data.memberships.length > 0) {
         setMemberships(data.data.data.memberships);
      }
        });
  };

  const handleRequest = () => {
    
    adminhttp().get(`/admin/userrolemembership/${splitURL[3]}/${props.userID}`).then((data) => {
        // if (data.data.data.user) {
          setFormData({
            name: data.data.data.users[0].name,
            email: data.data.data.users[0].email,
            role: data.data.data.users[0].roleselectedId,
            membership: data.data.data.users[0].membershipselectedId,
          });
        // }
      });
  };
  useEffect(() => {
    fetchRoles();
    fetchMemberships();

    if(props.edit===true){
       handleRequest();
    } else {
      setFormData({
        name: "",
        email: "",
        role: "",
        membership: "",
      });
    }
    
  }, [props.edit, props.userID]);

  return (
    <Modal centered backdrop="static"  show={props.show}
    onHide={props.close}>
      <Modal.Header className="py-1 my-1" closeButton>
        <Modal.Title>{props.edit===true ? 'Edit' : 'Add'} User's Role & Membership</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            id="adminRoleForm"
          >
        <Form.Group  className="mb-1">
          <Form.Control
            name='name'
            disabled 
            type="text"
            placeholder="Name"
            className={formData.name && Object.values(formData.name).length > 0 ? "is-valid" : "is-invalid"}
            value={formData.name && formData.name}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group  className="mb-1">
          <Form.Control
            name='email'
            disabled 
            type="email"
            placeholder="Email"
            className={formData.email && Object.values(formData.email).length > 0 ? "is-valid" : "is-invalid"}
            value={formData.email && formData.email}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group  className="mb-1">
          <Form.Select name='role'  placeholder="Assign Role" size="sm" 
                        value={formData.role && formData.role}
                        onChange={handleChange}
          >
    {roles.length > 0 && ( 
      roles.map((role) => (  
        <option key={role.id} value={role.id}>{role.name}</option>
      )  ))}
      </Form.Select>
        {/* </div> */}
          </Form.Group>
          <Form.Group  className="mb-1">
          <Form.Select name='membership' placeholder="Assign Membership" size="sm" 
            value={formData.membership && formData.membership}
            onChange={handleChange}
          >
     {memberships.length > 0 && ( 
      memberships.map((membership) => (  
        <option key={membership.id} value={membership.id}>{membership.name}</option>
      )) 
     )}
      </Form.Select>
        {/* </div> */}
          </Form.Group>
          </Form>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.close}>
          Close
        </Button>
        <Button
          variant="primary"
          type="submit"
          onClick={handleSubmit}
          form="adminRoleForm"
        >
          {progress && (<Spinner size='sm' className="me-2" animation="border" />)} Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default UpdateRoleMembership;

