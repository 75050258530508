import React, { useState, useEffect } from "react";
import AdminLayout from "../../layouts/AdminLayout";
import { Form, Container, ListGroup, Button } from "react-bootstrap";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import adminhttp from "../../../adminhttp";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
// import Stack from '@mui/material/Stack'
import Stack from "react-bootstrap/Stack";
import Card from "react-bootstrap/Card";

// import Card from '@mui/material/Card';
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AdminBreadCrumbs from "../../components/AdminBreadCrumbs";
import { Link } from "react-router-dom";
// import Badge from '@mui/material/Badge';
import Badge from "react-bootstrap/Badge";
import { formatDate } from "../../../components/PavilionConstants";
import DateIcon from "@mui/icons-material/InsertInvitation";
import Dropdown from "react-bootstrap/Dropdown";
import { Row, Col, Modal } from "react-bootstrap";
// import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation } from "react-router-dom";
import EditIcon from "@mui/icons-material/DriveFileRenameOutlineTwoTone";
import SettingsIcon from "@mui/icons-material/SettingsTwoTone";
import MenusIcon from "@mui/icons-material/ListAltTwoTone";
import SponsorsIcon from "@mui/icons-material/Diversity2TwoTone";
import MultiBannerIcon from "@mui/icons-material/PermMediaTwoTone";
import StoreIcon from "@mui/icons-material/StoreTwoTone";
import RolesIcon from "@mui/icons-material/WorkTwoTone";
import MembershipsIcon from "@mui/icons-material/PeopleAltTwoTone";
import LibraryIcon from "@mui/icons-material/LibraryBooksTwoTone";
import EventIcon from "@mui/icons-material/EventAvailableTwoTone";
import EmailIcon from "@mui/icons-material/EmailTwoTone";
import PinsIcon from "@mui/icons-material/PushPinTwoTone";
import { useNavigate } from "react-router-dom";
import SponsorCard from "../../components/AdminCards/SponsorCard";
import Alert from "@mui/material/Alert";
import AddSponsor from "../../components/AdminForms/AddSponsor";
import Swal from "sweetalert2";
import Spinner from "react-bootstrap/Spinner";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
  variants: [
    {
      props: ({ expand }) => !expand,
      style: {
        transform: "rotate(0deg)",
      },
    },
    {
      props: ({ expand }) => !!expand,
      style: {
        transform: "rotate(180deg)",
      },
    },
  ],
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  ...theme.applyStyles("dark", {
    backgroundColor: "#1A2027",
  }),
}));

const Sponsors = (props) => {
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);
  
  const [showModal, setShowModal] = useState(false);

  const location = useLocation();
  const data = location.state;
  const splitURL = location.pathname.toString().split("/");

  // const [show, setShow] = useState(false);
  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  const [isSubmit, setIsSubmit] = useState(false);
  const [sponsors, setSponsors] = useState([]);
  const [pvlnName, setPvlnName] = useState("");
  const [rows, setRows] = useState(0);
  const [counter, setCounter] = useState(0);
  const [pavilionName, setPavilionName] = useState("");
  const navigate = useNavigate();

  const [editSponsor, setEditSponsor] = React.useState(false);
  const [sponsorID, setSponsorID] = React.useState();

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to="/admin/dashboard">
      Dashboard
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      to="/admin/pavilions"
      state={data}
    >
      Pavilions
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      to={`/admin/pavilions/${splitURL[3]}`}
      state={data}
    >
      {data.name}
    </Link>,
    <Typography key="3" sx={{ color: "text.primary" }}>
      Supporters
    </Typography>,
  ];

  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }

  // const handleExpandClick = () => {
  //   setExpanded(!expanded);
  // };

  function handleAddSpnsor() {
    setShowModal(true)
    setEditSponsor(false)
  }

  const handleEdit = (id) => (e) => {
    setSponsorID(id)
    setEditSponsor(true)
    setShowModal(true)
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   const form=e.currentTarget;
  //   const name=form.name.value;
  //   const category=form.category.value;
  //   const link=form.link.value;
  //   const logo=form.logo.files[0];
  //   const position=form.position.value;

  //   if(form.checkValidity()){
  //       adminhttp().post('/admin/add_sponsor', { pavilion_id: splitURL[3], name: name,  category: category, link: link, image: '234234234.png', position_at: position}).then((res) => {
  //           Swal.fire({
  //           title: "Sponsor added successfully!",
  //           icon: "success",
  //           allowOutsideClick: false,
  //           confirmButtonColor: "#e30613",
  //       });
  //       handleClose();
  //       handleRequest();
  //       })
  //   }

  // };

  const handleRequest = (e) => {
    adminhttp()
      .post(`/admin/sponsors?pavilion_id=${splitURL[3]}`)
      .then((data) => {
        if (data.data.data.sponsors.length > 0) {
          setLoading(true)
          setSponsors(data.data.data.sponsors);
          setLoading(false)
        }else{
          setNoData(true)
          setLoading(false)
        }
      });
  };

  useEffect(() => {
    handleRequest();
  }, []);
  return (
    <>
      <AdminLayout>
        <Container fluid className="bg-light h-100">
          <Box component="section" sx={{ px: 2, pt: 2, pb: 1 }}>
            <AdminBreadCrumbs breadcrumbs={breadcrumbs} />
          </Box>

          <Box
            component="section"
            className="d-flex align-items-center justify-content-between"
            sx={{ px: 2, pt: 0, pb: 1 }}
            data-aos="fade-up"
          >
            <h4>Supporters</h4>
            <div className="d-flex justify-content-end align-items-center">
              {/* <Form className="me-2">
                <Form.Control
                  type="search"
                  size="small"
                  placeholder="Search Members"
                />
              </Form> */}
              <Button
                variant="primary"
                type="button"
                onClick={handleAddSpnsor}
              >
                Add Supporter
              </Button>
              {/* <Box sx={{ alignItems: "center",}}>
                <Pagination className='my-3' count={totalPages} page={page}  variant="outlined" shape="rounded" onChange={handleChange}/>
           </Box> */}
            </div>
          </Box>

          <ListGroup variant="flush">
            <ListGroup.Item className="bg-transparent">
            {loading ? (
      <Box sx={{ flexGrow: 1, textAlign:'center' }}>
      <Spinner size='lg' className='me-2 ' animation='border' />
      </Box>
    ):(
                <Box sx={{ flexGrow: 1 }}>
                  <Grid
                    container
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                  >
                    {sponsors.map((sponsor, index) => (
                      <Grid item xs={2} sm={2} md={2}
                      >
                        <SponsorCard
                          data={sponsor}
                          handleRequest={handleRequest}
                          handleEdit={handleEdit}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              )}
              {noData && (
                <Box
                  sx={{ flexGrow: 1 }}
                  className="d-flex justify-content-center"
                >
                  <Alert variant="outlined" severity="error">
                    Supporter not found
                  </Alert>
                </Box>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Container>
      </AdminLayout>
      <AddSponsor handleRequest={handleRequest} show={showModal} edit={editSponsor} sponsorID={sponsorID} close={() => setShowModal(false)} />
      {/* <Modal centered backdrop="static" show={show} onHide={handleClose} >
<Modal.Header className='py-1 my-1' closeButton>
  <Modal.Title >Add Sponsor</Modal.Title>
</Modal.Header>
<Modal.Body>
  <AddSponsor submit={isSubmit}/>
</Modal.Body>
<Modal.Footer>
  <Button variant="secondary"  onClick={handleClose}>
    Close
  </Button>
  <Button variant="primary" type="button" onClick={()=>setIsSubmit(true)} form="sponsorForm">
    Save Sponsor
  </Button>
</Modal.Footer>
</Modal> */}
    </>
  );
};

export default Sponsors;
