import React from 'react'
import { Grid,Paper, Avatar, TextField, Button, Typography,Link } from '@mui/material'
import Container from 'react-bootstrap/Container'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import AdminLoginForm from '../components/AdminLoginForm'
import Image from 'react-bootstrap/Image'
const AdminLogin=()=>{

    const paperStyle={ padding :20,width:350, borderRadius:25 }
    const avatarStyle={backgroundColor:'#1bbd7e'}
    const btnstyle={backgroundColor:'#e30613',margin:'8px 0'}
    return(
        <Container fluid className='content-container d-flex align-items-center justify-content-center ' style={{height:'100vh'}} >
            <Paper elevation={10} style={paperStyle}>
                <Grid align='center'>
                     {/* <Avatar style={avatarStyle}><LockOutlinedIcon/></Avatar> */}
                     <Image width='200px' src='https://bic3-staging.s3.eu-west-2.amazonaws.com/assets/admin/logo/logo-large.png'/>
                    <h4 className='my-4'> Sign In to Admin Dashboard</h4>
                </Grid>
           <AdminLoginForm/>
            </Paper>
        </Container>
    )
}

export default AdminLogin