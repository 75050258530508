import React, { useState, useEffect } from "react";
import AdminLayout from "../../layouts/AdminLayout";
import { Form, Container, ListGroup } from "react-bootstrap";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import adminhttp from "../../../adminhttp";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
// import Stack from '@mui/material/Stack'
import Stack from "react-bootstrap/Stack";
import Card from "react-bootstrap/Card";

// import Card from '@mui/material/Card';
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AdminBreadCrumbs from "../../components/AdminBreadCrumbs";
import { Link } from "react-router-dom";
// import Badge from '@mui/material/Badge';
import Badge from "react-bootstrap/Badge";
import { formatDate } from "../../../components/PavilionConstants";
import DateIcon from "@mui/icons-material/InsertInvitation";
import Dropdown from "react-bootstrap/Dropdown";
import { Row, Col, Modal, Button } from "react-bootstrap";
// import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation } from "react-router-dom";
import EditIcon from "@mui/icons-material/DriveFileRenameOutlineTwoTone";
import SettingsIcon from "@mui/icons-material/SettingsTwoTone";
import MenusIcon from "@mui/icons-material/ListAltTwoTone";
import SponsorsIcon from "@mui/icons-material/Diversity2TwoTone";
import MultiBannerIcon from "@mui/icons-material/PermMediaTwoTone";
import StoreIcon from "@mui/icons-material/StoreTwoTone";
import RolesIcon from "@mui/icons-material/WorkTwoTone";
import MembershipsIcon from "@mui/icons-material/PeopleAltTwoTone";
import LibraryIcon from "@mui/icons-material/LibraryBooksTwoTone";
import EventIcon from "@mui/icons-material/EventAvailableTwoTone";
import EmailIcon from "@mui/icons-material/EmailTwoTone";
import PinsIcon from "@mui/icons-material/PushPinTwoTone";
import { useNavigate } from "react-router-dom";
import SponsorCard from "../../components/AdminCards/SponsorCard";
import Alert from "@mui/material/Alert";
import RoleCard from "../../components/AdminCards/RoleCard";
import MembershipCard from "../../components/AdminCards/MembershipCard";
import AddMembership from "../../components/AdminForms/AddMembership";
import Swal from 'sweetalert2'
import Spinner from "react-bootstrap/Spinner";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
  variants: [
    {
      props: ({ expand }) => !expand,
      style: {
        transform: "rotate(0deg)",
      },
    },
    {
      props: ({ expand }) => !!expand,
      style: {
        transform: "rotate(180deg)",
      },
    },
  ],
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  ...theme.applyStyles("dark", {
    backgroundColor: "#1A2027",
  }),
}));

function handleAction(row) {
  alert(row.id);
}

const Memberships = (props) => {

  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [editMembership, setEditMembership] = useState(false);
  const [membershipID, setMembershipID] = useState();

  const location = useLocation();
  const data = location.state;

  const splitURL = location.pathname.toString().split("/");

  const [memberships, setMemberships] = useState([]);
  const [pvlnName, setPvlnName] = useState("");
  const [rows, setRows] = useState(0);
  const [counter, setCounter] = useState(0);
  const [pavilionName, setPavilionName] = useState("");
  const navigate = useNavigate();

  const [value, setValue] = useState(''); 
  const [filter, setFilter] = useState('0'); 

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [expanded, setExpanded] = React.useState(false);

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to="/admin/dashboard">
      Dashboard
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      to="/admin/pavilions"
      state={data}
    >
      Pavilions
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      to={`/admin/pavilions/${splitURL[3]}`}
      state={data}
    >
      {data.name}
    </Link>,
    <Typography key="3" sx={{ color: "text.primary" }}>
      Memberships
    </Typography>,
  ];


  const handleSearch = (e) => {
    setValue(e.target.value);
    adminhttp().get(`/admin/search_membership?search=${e.target.value}&pavilion_id=${splitURL[3]}`).then((data) => {
        // alert(data.data.data.length)
        if (data.data.data.length > 0) {
          setMemberships(data.data.data);
        }
      }); 
  };

  const handleSelect = (e) => {
    setFilter(e.target.value);
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    const form=e.currentTarget;
    const name=form.name.value;
    const price=form.price.value;
    const productType=form.producttype.value;
    const description=form.description.value;
    const status=form.status.value;
    const membershipplan=form.membershipplan.value;
    const image=form.imagefilen.value;
    if(form.checkValidity()){
        adminhttp().post('/admin/add_membership', { name: name, pavilion_id: splitURL[3], price: price,  product_type: productType, description: description, status: status, membership_plan: membershipplan, image: image}).then((res) => {
            Swal.fire({
            title: "Membership added successfully!",
            icon: "success",
            allowOutsideClick: false,
            confirmButtonColor: "#e30613",
          }).then((result) => {
            if (result['isConfirmed']){
              handleClose();
              handleRequest();
            }
          })
        
        })
    } 
  };

  function handleAddMembership() {
    setShowModal(true)
    setEditMembership(false)
  }

  const handleEdit = (id) => (e) => {
    setMembershipID(id)
    setEditMembership(true)
    setShowModal(true)
  };

 const handleRequest = () =>{
    adminhttp().post(`/admin/memberships?pavilion_id=${splitURL[3]}`)
    .then((data) => {
      if (data.data.data.membership.length > 0) {
        setLoading(true)
        setMemberships(data.data.data.membership);
        setLoading(false)
      }else{
        setNoData(true)
        setLoading(false)
      }
    });
  }


  useEffect(() => {
    handleRequest();    
  }, []);
  return (
    <>
    <AdminLayout>
      <Container fluid className="bg-light " sx={{height:'100vh'}}>
        <Box component="section" sx={{ px: 2, pt: 2, pb: 1 }}>
          <AdminBreadCrumbs breadcrumbs={breadcrumbs} />
        </Box>

        <Box
          component="section"
          className="d-flex align-items-center justify-content-between"
          sx={{ px: 2, pt: 0, pb: 1 }}
          data-aos="fade-up"
        >
          <h4>Memberships</h4>
          <div className="d-flex justify-content-end align-items-center">
            <Form className="me-2">
              <Form.Control
                type="search"
                size="small"
                value={value}
                onChange={handleSearch}
                placeholder="Search Members"
              />
              
            </Form>
            {/* <Form.Select  size="small" onChange={handleSelect}
 className='w-auto me-2'>
      <option selected value='0'>All Memberships</option>
      <option value="1">Paid Memberships</option>
      <option value="2">Expired Memberships</option>
    </Form.Select> */}
            <Button
              variant="primary"
              type="button"
              className='w-auto'
              onClick={handleAddMembership}
            >
              Add Membership
            </Button>
            {/* <Box sx={{ alignItems: "center",}}>
                <Pagination className='my-3' count={totalPages} page={page}  variant="outlined" shape="rounded" onChange={handleChange}/>
           </Box> */}
          </div>
        </Box>

        <ListGroup variant="flush">
          <ListGroup.Item className="bg-transparent">
          {loading ? (
      <Box sx={{ flexGrow: 1, textAlign:'center' }}>
      <Spinner size='lg' className='me-2 ' animation='border' />
      </Box>
    ):(
              <Box sx={{ flexGrow: 1 }}>
                <Grid
                  container
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >

                {/* {filter==='0' && ( */}
                  {memberships.map((membership, index) => (
                    <Grid item xs={2} sm={2} md={12}>
                      <MembershipCard data={membership} handleRequest={handleRequest}
                          handleEdit={handleEdit} />
                    </Grid>
                  ))}
                {/* )} */}
                 {/* {filter==='1' && (
                  memberships.filter(membership => membership.stripe_price_status === '1').map((membership, index) => ( 
                    <Grid item xs={2} sm={2} md={12}>
                      <MembershipCard data={membership} handleRequest={handleRequest} />
                    </Grid>
                  ))
                  )}
                  {filter==='2' && (
                  memberships.filter(membership => membership.stripe_product_status === '1').map((membership, index) => ( 
                    <Grid item xs={2} sm={2} md={12}>
                      <MembershipCard data={membership} handleRequest={handleRequest} />
                    </Grid>
                  ))
                  )} */}

                </Grid>
              </Box>
            )}
            {noData && (
              <Box
                sx={{ flexGrow: 1 }}
                className="d-flex justify-content-center"
              >
                <Alert variant="outlined" severity="error">
                  Memberships not found
                </Alert>
              </Box>
            )}
          </ListGroup.Item>
        </ListGroup>
      </Container>
    </AdminLayout>
    <AddMembership handleRequest={handleRequest} edit={editMembership} membershipID={membershipID} show={showModal} close={() => setShowModal(false)} />
    {/* <Modal centered backdrop="static" show={show} onHide={handleClose} >
    <Modal.Header className='py-1 my-1' closeButton>
      <Modal.Title >Add Membership</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <AddMembership handleSubmit={handleSubmit} />
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary"  onClick={handleClose}>
        Close
      </Button>
      <Button variant="primary" type="submit" form="membershipForm">
        Save Membership
      </Button>
    </Modal.Footer>
    </Modal> */}
    </>
  );
};

export default Memberships;
