import React, { useState, useEffect, isValidElement } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FloatingLabel, Modal } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { Link, useLocation } from "react-router-dom";
import adminhttp from "../../../adminhttp";
import Swal from "sweetalert2";
import Divider from "@mui/material/Divider";
import Spinner from 'react-bootstrap/Spinner';
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
function AddAdminUser(props) {

  const [loginModalShow, setLoginModalShow] = useState(props.showModal);

  const [validated, setValidated] = useState(false);
  const [progress, setProgress] = useState(false);
  const [sponsor, setSponsor] = useState([]);

  const location = useLocation();
  const data = location.state;
  const splitURL = location.pathname.toString().split("/");

  // const [name, setName] = useState("");
  // const [category, setCategory] = useState("");
  // const [link, setLink] = useState("");
  // const [logo, setLogo] = useState("");
  // const [position, setPosition] = useState("");

  const [show, setShow] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    cpassword: "",
    role:"",
  });

  const [selectedValue, setSelectedValue]=useState()

  const [areEqual, setAreEqual]=useState(false)
  const [result, setResult]=useState('')
  // Handle input changes
  const handleChange = (e) => {


    const { name, value, type, files, checked } = e.target;
    
    if (type === "radio") {
      setFormData({ ...formData, role: e.target.value }); 
    } else {
      setFormData({ ...formData, [name]: value });
    }
      // }else
    // if (type === "checkbox") {
    //   setFormData({
    //     roles: prev => ({ ...prev, roles: checked }),
    //   });
    

  };


  const [selectedItems, setSelectedItems] = useState([])
	

	function checkboxHandler(e){
		let isSelected = e.target.checked;
		let value = parseInt(e.target.value);

		if( isSelected ){
			setSelectedItems( [...selectedItems, value ] )
		}else{
			setSelectedItems((prevData)=>{
				return prevData.filter((id)=>{
					return id!==value
				})
			})
		}
	}


  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity() === true) {
      setProgress(true)
      const submitData = new FormData();
      submitData.append("userTypeId", 1);
      submitData.append("name", formData.name);
      submitData.append("email", formData.email);
      
      if(props.edit===true){
        submitData.append("id", props.adminUserID);
        adminhttp()
        .post(`/admin/updateuser`, submitData)
        .then((response) => {
          Swal.fire({
            title: 'Admin User updated successfully!',
            icon: "success",
            allowOutsideClick: false,
            confirmButtonColor: "#e30613",
          });
          setProgress(false);
          props.close();
          props.handleRequest();
        })
        .catch((error) => {
          console.error("There was an error submitting the form!", error);
        });

        
      } else {

        submitData.append("password", formData.password);
        submitData.append("password_confirmation", formData.cpassword);
        submitData.append("roles[]", formData.role);

        adminhttp().post("/admin/createuser", submitData).then((response) => {
          Swal.fire({
            title: 'New Admin Role added successfully!',
            icon: "success",
            allowOutsideClick: false,
            confirmButtonColor: "#e30613",
          });
          setProgress(false);
          props.close();
          props.handleRequest();
        })
        .catch((error) => {
          console.error("There was an error submitting the form!", error);
        });
      }
      
      
      }
  };
  const [roles, setRoles]=useState([]);
  const fetchRoles = () => {
    adminhttp().get(`/admin/admin_user_roles`).then((data) => {
          if (data.data.data.length > 0) {
            setRoles(data.data.data);
          }
        });
  };

  const handleRequest = () => {
    
    adminhttp().get(`/admin/singleuser/${props.adminUserID}`).then((data) => {
        // if (data.data.data.user) {
          setFormData({
            name: data.data.data.user.first_name,
            email: data.data.data.user.email,
          });
        // }
      });
  };
  useEffect(() => {
    fetchRoles();

    if(props.edit===true){
       handleRequest();
    } else {
      setFormData({
        name: "",
        email: "",
        password: "",
        cpassword: "",
        role:"",
      });
    }
    
  }, [props.edit, props.adminRoleID]);

  return (
    <Modal centered backdrop="static"  show={props.show}
    onHide={props.close}>
      <Modal.Header className="py-1 my-1" closeButton>
        <Modal.Title>{props.edit===true ? 'Edit' : 'Add'} Admin User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            id="adminRoleForm"
          >
        
        <Form.Group  className="mb-1">
          <Form.Control
            name='name'
            required
            type="text"
            placeholder="Name"
            className={formData.name && Object.values(formData.name).length > 0 ? "is-valid" : "is-invalid"}
            value={formData.name && formData.name}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group  className="mb-1">
          {props.edit ? (
            <Form.Control name='email' required disabled
            type="email" placeholder="Email"
            className={formData.email && Object.values(formData.email).length > 0 ? "is-valid" : "is-invalid"}
            value={formData.email && formData.email}
            onChange={handleChange}
          />
          ) : (
          <Form.Control name='email' required 
            type="email" placeholder="Email"
            className={formData.email && Object.values(formData.email).length > 0 ? "is-valid" : "is-invalid"}
            value={formData.email && formData.email}
            onChange={handleChange}
          />
          )}
          
        </Form.Group>
        {!props.edit && (<>
        <Form.Group  className="mb-1">
          <Form.Control
            name='password'
            required
            type="password"
            placeholder="Password"
            className={formData.password ?  (formData.password === formData.cpassword ? 'is-valid' : 'is-invalid') : 'is-invalid'}
            value={formData.password && formData.password}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group  className="mb-1">
          <Form.Control
            name='cpassword'
            required
            type="password"
            placeholder="Confirm Password"
            className={formData.cpassword ?  (formData.password === formData.cpassword ? 'is-valid' : 'is-invalid') : 'is-invalid'}
            value={formData.cpassword && formData.cpassword}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group  className="my-2">Roles:</Form.Group>
        <Form.Group  className="mb-1">
        <RadioGroup
                      row
                      name="row-radio-buttons-group"
                      checked={formData.role && formData.role}
                      onChange={handleChange}
                      value={formData.role && formData.role}
                      name="roles"
                    >
       {roles.length > 0 && (
                    roles.map((role, index) => (
                     
                      <FormControlLabel
                        value={role.id}
                        control={<Radio required color="error" size='small' />}
                        label={role.name}
                      />
                     
        //  <div key={`inline-checkbox`} className="mb-3">             
        //      <Form.Check   inline name='group1' type='radio' id={role.id} className='ps-0 pe-2 pt-2'>
        //       <Form.Check.Input onChange={checkboxHandler} value={role.id} checked={ selectedItems.includes( role.id ) } type='radio' className='mt-0 me-2' /> 
        //      <Form.Check.Input onChange={handleChange} value={role.id} checked={ formData.roles } type='radio' className='mt-0 me-2' />
        //      <Form.Check.Label>{role.name}</Form.Check.Label>
        //         </Form.Check>
        //         </div> 
      
                     ))
                     
        )}           
        
        </RadioGroup>
        
          </Form.Group> 
          </>  )}
          </Form>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.close}>
          Close
        </Button>
        <Button
          variant="primary"
          type="submit"
          form="adminRoleForm"
        >
          {progress && (<Spinner size='sm' className="me-2" animation="border" />)} Save Admin User
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddAdminUser;

