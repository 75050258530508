import React, { useState, useEffect } from 'react'

import ListGroup from 'react-bootstrap/ListGroup';
import CardGroup from 'react-bootstrap/CardGroup';
import Card from 'react-bootstrap/Card';
import { AccountBalanceOutlined } from '@mui/icons-material'
import googlePlay from '../../../../assets/icons/googleplay.svg';
import appStore from '../../../../assets/icons/appstore.svg';
import appgAI1 from '../../../../assets/img/appgai1.png';
import appgAI2 from '../../../../assets/img/appgai2.png';
import appgAILogo from '../../../../assets/img/appg-ai-logo.jpeg';
import whoswhoLogo from '../../../../assets/img/whoswho-logo.jpeg';
import Carousel from 'react-bootstrap/Carousel';
import { Button, Row, Col, Container } from 'react-bootstrap';
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';
import BadgeAvatar from '../../../BadgeAvatar';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import HelpIcon from '@mui/icons-material/HelpCenter';
import VideoIcon from '@mui/icons-material/VideoCameraFront';
import Avatar from '@mui/material';
import { Image } from 'react-bootstrap';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CropperUpload from '../../../Cropper/Upload';
import CropperPopup from '../../../Cropper/Popup';
import { Modal, Form } from 'react-bootstrap';
import http from '../../../../http';
import { AWSIMAGE_PATH } from '../../../PavilionConstants';
import Swal from 'sweetalert2';
import Spinner from 'react-bootstrap/Spinner';

const CropperInput = styled("input")({
  display: "none"
});


function MiddlePavilion(props, { handleClick }) {
  
  const [progress, setProgress]=useState(false);
  const token=localStorage.getItem('token')
  
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const handleParentClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [pavilionBanner1, setPavilionBanner1] = useState('pavilionBanner1');
  const [pavilionBanner2, setPavilionBanner2] = useState('pavilionBanner2');
  const [pavilionBanner3, setPavilionBanner3] = useState('pavilionBanner3');

  const [deleteBanner1, setDeleteBanner1] = useState('deleteBanner1');
  const [deleteBanner2, setDeleteBanner2] = useState('deleteBanner2');
  const [deleteBanner3, setDeleteBanner3] = useState('deleteBanner3');

  const handleParentClose2 = () => setShow2(false);

  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [image, setImage] = useState(appgAILogo);
  const [imageLogo, setImageLogo] = useState();
  const [imageLogo2, setImageLogo2] = useState();
  
  const [imageBannerOne2, setImageBannerOne2] = useState();
  
  const [imageBannerTwo2, setImageBannerTwo2] = useState();
  
  const [imageBannerThree2, setImageBannerThree2] = useState();
 
  const [imagePath, setImagePath]=useState(localStorage.getItem('bucketName')+'/images/pavilion/');
  const [imagePath2, setImagePath2]=useState(localStorage.getItem('bucketName')+'/images/pavilion/');
  
  const [pvlnTitle, setPvlnTitle] = useState('');
  const [pvlnLogo, setPvlnLogo] = useState('');
  const [banners, setBanners]=useState([]);
  const [imageBannerOne, setImageBannerOne] = useState(imagePath + banners[0]);
  const [imageBannerTwo, setImageBannerTwo] = useState(imagePath + banners[1]);
  const [imageBannerThree, setImageBannerThree] = useState(imagePath + banners[2]); 
  
  const [bannerOne, setBannerOne] = useState('event-photo.png');
  const [bannerTwo, setBannerTwo] = useState('event-photo.png');
  const [bannerThree, setBannerThree] = useState('event-photo.png');  
  const [bannerlinkcolor1, setbannerlinkcolor1] = useState(false);
  const [bannerlinkcolor2, setbannerlinkcolor2] = useState(false);
  const [bannerlinkcolor3, setbannerlinkcolor3] = useState(false);



  const bannerlinkcolor = {
    color: "blue"
  };

  const [membersCount, setMembersCount] = useState();  
  const [memberName, setMemberName] = useState();  
  
  const handleShow2 = () => {
    setShow2(true);
    http().get(`/about/${props.pvlnIndex}`).then((data) => {
      if (data && data.data && data.data.data) {

        if (data.data.data.banner1) {
          // document.getElementById('deleteBanner1').display.style='none';
          document.getElementById('pavilionBanner1').src = imagePath + data.data.data.banner1;
          setImageBannerOne(imagePath + data.data.data.banner1)
          setBannerOne(data.data.data.banner1)
          setbannerlinkcolor1(true)
        } else {
          document.getElementById('pavilionBanner1').src = 'https://bic3-staging.s3.eu-west-2.amazonaws.com/assets/pavilion/images/event-photo.png';
          setImageBannerOne('https://bic3-staging.s3.eu-west-2.amazonaws.com/assets/pavilion/images/event-photo.png')
          setBannerOne('event-photo.png')
          setbannerlinkcolor1(false)
        }
        if (data.data.data.banner2) {
          // document.getElementById('deleteBanner2').display.style='none';
          document.getElementById('pavilionBanner2').src = imagePath + data.data.data.banner2;
          setImageBannerTwo(imagePath + data.data.data.banner2)
          setBannerTwo(data.data.data.banner2)
          setbannerlinkcolor2(true)
        } else {
          document.getElementById('pavilionBanner2').src = 'https://bic3-staging.s3.eu-west-2.amazonaws.com/assets/pavilion/images/event-photo.png';
          setImageBannerTwo('https://bic3-staging.s3.eu-west-2.amazonaws.com/assets/pavilion/images/event-photo.png')
          setBannerTwo('event-photo.png')
          setbannerlinkcolor2(false)
        }
        if (data.data.data.banner3) {
          // document.getElementById('deleteBanner3').display.style='none';
          document.getElementById('pavilionBanner3').src = imagePath + data.data.data.banner3;
          setImageBannerThree(imagePath + data.data.data.banner3)
          setBannerThree(data.data.data.banner3)
          setbannerlinkcolor3(true)
        } else {
          document.getElementById('pavilionBanner3').src = 'https://bic3-staging.s3.eu-west-2.amazonaws.com/assets/pavilion/images/event-photo.png';
          setImageBannerThree('https://bic3-staging.s3.eu-west-2.amazonaws.com/assets/pavilion/images/event-photo.png')
          setBannerThree('event-photo.png')
          setbannerlinkcolor3(false)
        }
      }
    });


  }

  
  useEffect(() => {
    if(token){
      if(props.pvlnIndex){
        http().get(`/webpavilionmembercount/${props.pvlnIndex}`).then((data) => {
          //console.log(data.data.data.pavilionMemberCount);
          setMembersCount(data.data.data.pavilionMemberCount);
          setMemberName(data.data.data.pavilionMember);
        });
        }
      }
  }, [token]); 

 useEffect(() => {
  if(token){
    if(props.pvlnIndex){
      http().get(`/about/${props.pvlnIndex}`).then((data) => {
        //console.log(data.data.data.aboutPavilion.mobile_banner.split(','));
        if (data && data.data && data.data.data && data.data.data.aboutPavilion && data.data.data.aboutPavilion.mobile_banner) {
          setBanners(data.data.data.aboutPavilion.mobile_banner.split(','));
        }
         setPvlnTitle(data.data.data.aboutPavilion.name);
         setImageLogo(imagePath+data.data.data.aboutPavilion.logo);
       });
      }
      }
  },[token]);

  useEffect(() => {
    if(banners[0]){
    setImageBannerOne(imagePath + banners[0])
    setBannerOne(banners[0])
	          setbannerlinkcolor1(true)
	}else{
    setImageBannerOne('https://bic3-staging.s3.eu-west-2.amazonaws.com/assets/pavilion/images/event-photo.png')
    setBannerOne('event-photo.png')
          setbannerlinkcolor1(false)
	}
	if(banners[1]){
    setImageBannerTwo(imagePath + banners[1])
    setBannerTwo(banners[1])
          setbannerlinkcolor2(true)
	}else{
    setImageBannerTwo('https://bic3-staging.s3.eu-west-2.amazonaws.com/assets/pavilion/images/event-photo.png')
    setBannerTwo('event-photo.png')
          setbannerlinkcolor2(false)
	}
	if(banners[2]){
    setImageBannerThree(imagePath + banners[2])
    setBannerThree(banners[2])
          setbannerlinkcolor3(true)
	}else{
    setImageBannerThree('https://bic3-staging.s3.eu-west-2.amazonaws.com/assets/pavilion/images/event-photo.png')
    setBannerThree('event-photo.png')
          setbannerlinkcolor3(false)
	}
    
  });

  useEffect(() => {
    setImageBannerOne(imagePath + imageBannerOne2)
  },[imageBannerOne2]);


//input file dialogue
  const handleClose = () => {
    setOpen(false);
    setOpen1(false);
    setOpen2(false);
    setOpen3(false);
  };

  const onFileChange = (e) => {
    e.preventDefault();
    const dataValue = e.currentTarget.getAttribute("data-value");
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    if (files.length === 0) {
      return alert("Please select a file.");
    }

    const reader = new FileReader();
    reader.onload = () => {
      // getUploadedFile(reader.result);
      if (dataValue === "LOGO") { 
	  setImageLogo2(reader.result); 
	  setOpen(true);
	  }
      if (dataValue === "BNR1") {
	  setImageBannerOne2(reader.result);
	  setOpen1(true);
	  }
      if (dataValue === "BNR2") { 
	  setImageBannerTwo2(reader.result); 
	  setOpen2(true);
	  }
      if (dataValue === "BNR3") { 
	  setImageBannerThree2(reader.result);
	  setOpen3(true);
	  }
      // if(!dataValue){setOpen(false)}
    };
    
    reader.readAsDataURL(files[0]);
  };

  //input file dialog

  const handleBannersSubmit=(e)=>{
    // console.log(imageBannerOne2);
    // console.log(imageBannerTwo2);
    // console.log(imageBannerThree2);
	setProgress(true);
	http().post('/bannerupdate', {
			"pavilion_id":props.pvlnIndex,
			"oldbanner1":bannerOne,
			"oldbanner2":bannerTwo,
			"oldbanner3":bannerThree,
			"banner1":imageBannerOne2,
			"banner2":imageBannerTwo2,
			"banner3":imageBannerThree2,
	}).then((data) => {
			
      Swal.fire({
        title: 'Pavilion Banners updated succesfully',
        icon: 'success',
        allowOutsideClick: false,
        confirmButtonColor: "#e30613",
      });
      setProgress(false);
      http().get(`/about/${props.pvlnIndex}`).then((data) => {
        if (data && data.data && data.data.data && data.data.data.aboutPavilion && data.data.data.aboutPavilion.mobile_banner) {
          setBanners(data.data.data.aboutPavilion.mobile_banner.split(','));
        }
      });      
			handleParentClose2();
	});
    
  }
  
  
  function BannerDelete(seraialno, id, name) {
    //alert(seraialno + 'ssss' + id + 'vvvv' + name)
    const elementId = name;
    Swal.fire({
      title: "Are you sure to delete this Banner?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#e30613",
      cancelButtonColor: "#6c757d",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
    http().post('/webpavilionbannerdelete', {
      pavilionid: props.pvlnIndex,
      banner: elementId,
    }).then((data) => {
      console.log(data)
      console.log(data.data)
      console.log(data.data.data)
      console.log(data.data.data.banner1)
      console.log(data.data.data.banner2)
      console.log(data.data.data.banner3)


      // if(data.data.data.banner1){
      //   document.getElementById(id).src=imagePath + data.data.data.banner1;
      //   setImageBannerOne(imagePath + data.data.data.banner1)
      //   setBannerOne(data.data.data.banner1)
      // }else{
      //   document.getElementById(id).src='https://bic3-staging.s3.eu-west-2.amazonaws.com/assets/pavilion/images/event-photo.png';
      //   setImageBannerOne('https://bic3-staging.s3.eu-west-2.amazonaws.com/assets/pavilion/images/event-photo.png')
      //   setBannerOne('event-photo.png')
      // }
      // if(data.data.data.banner2){
      //   document.getElementById(id).src=imagePath + data.data.data.banner2;
      //   setImageBannerTwo(imagePath + data.data.data.banner2)
      //   setBannerTwo(data.data.data.banner2)
      // }else{
      //   document.getElementById(id).src='https://bic3-staging.s3.eu-west-2.amazonaws.com/assets/pavilion/images/event-photo.png';
      //   setImageBannerTwo('https://bic3-staging.s3.eu-west-2.amazonaws.com/assets/pavilion/images/event-photo.png')
      //   setBannerTwo('event-photo.png')
      // }
      // if(data.data.data.banner3){
      //   document.getElementById(id).src=imagePath + data.data.data.banner3;
      //   setImageBannerThree(imagePath +data.data.data.banner3)
      //   setBannerThree(data.data.data.banner3)
      // }else{
      //   document.getElementById(id).src='https://bic3-staging.s3.eu-west-2.amazonaws.com/assets/pavilion/images/event-photo.png';
      //   setImageBannerThree('https://bic3-staging.s3.eu-west-2.amazonaws.com/assets/pavilion/images/event-photo.png')
      //   setBannerThree('event-photo.png')
      // }
      // http().get(`/about/${props.pvlnIndex}`).then((data) => {
      //   if (data && data.data && data.data.data && data.data.data.aboutPavilion && data.data.data.aboutPavilion.mobile_banner) {
      //     setBanners(data.data.data.aboutPavilion.mobile_banner.split(','));
      //   }
      //  });
      http().get(`/about/${props.pvlnIndex}`).then((data) => {
        if (data && data.data && data.data.data && data.data.data.aboutPavilion && data.data.data.aboutPavilion.mobile_banner) {
          setBanners(data.data.data.aboutPavilion.mobile_banner.split(','));
        }
      });

      Swal.fire({
        title: "Banner is deleted successfully!",
        icon: "success",
        allowOutsideClick: false,
        confirmButtonColor: "#e30613",
      });
      handleParentClose2();
    });
  }
});

  }

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    description: '',
    priority: '',
    attachFile: null,
  });

  // Handle form field changes
  const handleChangeTicket = (e) => {
    const { name, value, type, files } = e.target;
    if (type === 'file') {
      setFormData({ ...formData, [name]: files[0] }); // File input
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };


   // Handle form submission
   const handleSubmitAddTicket = async (e) => {
    e.preventDefault();
    const { name, email, subject, description, priority, attachFile } = formData;

    if (!name || !email || !subject || !description || !priority) {
      alert('Please fill in all required fields.');
      return;
    }

    const formPayload = new FormData();
    formPayload.append('name', name);
    formPayload.append('email', email);
    formPayload.append('subject', subject);
    formPayload.append('description', description);
    formPayload.append('priority', priority);
    if (attachFile) {
      formPayload.append('attachFile', attachFile);
    }else{
      formPayload.append('attachFile', '');
    }

    try {
      const response = await http().post("/addticket", formPayload, {
        headers: {
          "Content-Type": "multipart/form-data", // Specify multipart/form-data
        },
      });

      if (response) {
        Swal.fire({
          title: 'Ticket submitted successfully!',
          icon: 'success',
          allowOutsideClick: false,
          confirmButtonColor: "#e30613",
        });


        handleParentClose();
        setFormData({
          name: '',
          email: '',
          subject: '',
          description: '',
          priority: '',
          attachFile: null,
        });
      } else {
        const errorData = await response.json();
        alert(`Failed to submit ticket: ${errorData.message}`);
      }
    } catch (error) {
      console.error('Error submitting ticket:', error);
      alert('An error occurred while submitting the ticket.');
    }
  };
  return (
    <>
      <CardGroup className="flex-column align-items-center justify-content-between  h-100" style={{ width: "320px" }}>
        <Card className="zoom  d-flex flex-column align-items-center justify-content-center py-2 bg-light bg-opacity-75 w-100" style={{ borderRadius: "15px", maxHeight:'200px' }} >
          {/* <Card.Img style={{ borderRadius: "15px", height:"175px" }} variant="top" src={appgAI} /> */}
          <Row className="w-100 " >
            <Col xs={3} md={3} className='d-flex flex-column align-items-center justify-content-between mx-0'>
            <Button variant="light" data-aos="fade-left" size="sm" className="d-flex flex-column small align-items-center justify-content-center" style={{width:'60px', height:'50px'}} >
                <span className="badge badge-pill badge-primary bg-primary" style={{ fontSize: "12px" }}>{membersCount}</span>
                <Typography variant="small">Members</Typography>
              </Button>
              <IconButton data-aos="fade-right" onClick={handleShow} className="btn btn-outline-mode" size="small"><HelpIcon /></IconButton>
              
            </Col>
            <Col xs={6} md={6} className='d-flex flex-column align-items-center justify-content-start  gap-0 px-0'>
              <Image className="rounded" src={imageLogo} style={{ width: "160px", height: "auto" }} />
              {/* <Card.Title className="fs-6 text-secondary text-center fw-bold pb-0 mb-0">{pvlnTitle}</Card.Title> */}
              {/* <Card.Text className="small mb-0 text-center">
                321 Members
              </Card.Text> */}

            </Col>
            <Col xs={3} md={3} className="d-flex flex-column align-items-center justify-content-between mx-0 px-0">
              {/* <div>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", cursor: "pointer" }}>
                  <Box my={2}         >
                    <label htmlFor="fileLogo">
                      <CropperInput accept="image/*" data-value="LOGO" id="fileLogo" type="file" onChange={onFileChange} />
                      <Button as="span" variant="light" data-aos="fade-left" size="sm" className="d-flex flex-column small align-items-center justify-content-end mb-2" >
                        <AddPhotoAlternateIcon />
                        <Typography variant="small">Update Logo</Typography>
                      </Button>
                    </label>
                  </Box>
                  {/* <Upload
              getUploadedFile={(image) => {
            setOpen(true);
            setImage(image);
          }}
        /> 
                  <CropperPopup open={open} image={imageLogo2} handleClose={handleClose}
                    getCroppedFile={(imageLogo) => {
                      setImageLogo(imageLogo)
                      handleClose();
                    }}
                    aspectRatio={16 / 10} />
                </Box></div> */}
             {memberName!=='Become a Member' && <Button  variant="light" size="sm" data-aos="fade-left" className="d-flex flex-column small align-items-center justify-content-end" style={{width:'60px', height:'50px'}} >
                {/*<PersonAddIcon />*/}
                <Typography variant="small" >{memberName}</Typography>
              </Button>}
              {memberName==='Become a Member' && <Button onClick={event => props.handleClick('PVLN-SHOPP', props.pvlnIndex)} variant="light" size="sm" data-aos="fade-left" className="d-flex flex-column small align-items-center justify-content-end" style={{width:'60px', height:'50px'}} >
                <PersonAddIcon />
                <Typography variant="small" >Become Member</Typography>
              </Button>} 
              
              <IconButton data-aos="fade-right" onClick={() => window.open('https://www.youtube.com/channel/UCpvImeYUgu-UAHyjRKDaPTA', '_blank')} className="btn btn-outline-mode" size="small"><VideoIcon /></IconButton>
            </Col>
          </Row>
          <Row>
            <Col><Card.Title className="fs-6 text-secondary text-center fw-bold pb-0 mb-0">{pvlnTitle}</Card.Title></Col>
          </Row>
        </Card>
        <Card className="zoom " style={{ borderRadius: "15px", maxHeight:'200px', width:'320px' }} >
          {/* <Card.Img style={{ borderRadius: "15px", height:"200px" }} variant="top" src={appgAI2} /> */}

          {/* <Button variant="light" onClick={handleShow2} size="sm" className="position-absolute top-0 end-0 m-2 small" style={{ zIndex: "1" }}>
            <AddPhotoAlternateIcon />
            <Typography variant="small">Update Banners</Typography>
          </Button> */}

          <Carousel slide={true} className='h-100' >
            {Array.from({ length: banners.length }).map((_, idx) => (
              <Carousel.Item key={idx} interval={2000}>
                <Card.Img style={{ borderRadius: "15px",width:"320px", height: "200px" }} variant="top" src={`${imagePath+((banners[idx]).replace(/^.*[\\/]/, ''))}`}/>
              </Carousel.Item>
            ))}
            {/* <Carousel.Item>
              <Card.Img style={{ borderRadius: "15px", height: "200px" }} variant="top" src={appgAI2} /> */}
            {/* <Carousel.Caption>
                <h4>Premium Members</h4>
                <p>Members: 400</p>
              </Carousel.Caption> */}
            {/* </Carousel.Item> */}

          </Carousel>
        </Card>
        <Card className="zoom" style={{ borderRadius: "15px", maxHeight: "80px" }}>
          <Card.Body className="d-flex flex-column align-items-center justify-content-between my-2 pt-0 pb-2">
            {/* <Card.Title className="fs-6 fw-bold">Welcome to pCloud!</Card.Title> */}
            <Card.Text className="small my-2 text-center">
              Experience Pavilion on the go - now available on your mobile phone through the Play Store and App Store
            </Card.Text>
            <Card.Text >
            <a href="https://play.google.com/store/apps/details?id=com.pavillionapp.pavillion&hl=en&gl=US" target='_blank'>
                <img src={localStorage.getItem('bucketName')+'/images/googleplay.svg'} height="30px" className="me-2" alt="Google Play" /> 
            </a>
            <a href="https://apps.apple.com/us/app/pavilion/id6450182778" target='_blank'><img src={localStorage.getItem('bucketName')+'/images/appstore.svg'} height="30px" className="" alt="App Store" /></a>
            </Card.Text>
          </Card.Body>
        </Card>

      </CardGroup>
      <Modal show={show} size="md" backdrop="static" keyboard={false} onHide={handleParentClose} centered>
      <Modal.Header closeButton className="py-1">
        <Modal.Title className="lead">Request Ticket</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmitAddTicket}>
          <Row>
            <Col>
              <Form.Control
                type="text"
                size="sm"
                name="name"
                placeholder="Name"
                className="my-2 py-1"
                value={formData.name}
                onChange={handleChangeTicket}
                required
              />
              <Form.Control
                type="email"
                size="sm"
                name="email"
                placeholder="Email"
                className="my-2 py-1"
                value={formData.email}
                onChange={handleChangeTicket}
                required
              />
              <Form.Control
                type="text"
                size="sm"
                name="subject"
                placeholder="Subject"
                className="my-2 py-1"
                value={formData.subject}
                onChange={handleChangeTicket}
                required
              />
              <Form.Control
                as="textarea"
                rows={3}
                size="sm"
                name="description"
                placeholder="Description"
                className="my-2 py-1"
                value={formData.description}
                onChange={handleChangeTicket}
                required
              />
              <Form.Select
                required
                size="sm"
                id="priority"
                name="priority"
                className="my-1 me-2 py-1 w-100"
                value={formData.priority}
                onChange={handleChangeTicket}
              >
                <option value="" disabled>
                  Choose your Priority
                </option>
                <option value="LOW">Low Priority</option>
                <option value="MEDIUM">Medium Priority</option>
                <option value="HIGH">High Priority</option>
              </Form.Select>
              <Form.Group className="d-flex flex-row">
                <Form.Control
                  size="sm"
                  type="file"
                  id="attachFile"
                  name="attachFile"
                  className="my-1 py-1"
                  onChange={handleChangeTicket}
                />
              </Form.Group>
            </Col>
          </Row>
          <Modal.Footer className="d-flex align-items-center justify-content-end py-1">
            <Button size="sm" variant="secondary" onClick={handleParentClose}>
              Cancel
            </Button>
            <Button size="sm" variant="primary" type="submit">
              Submit Ticket
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>

      {/* <Modal show={show} size="md" backdrop='static' keyboard={false} onHide={handleParentClose} centered>
        <Modal.Header closeButton className="py-1">
          <Modal.Title className="lead" >Request Ticket</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col >
              <Form.Control type="text" size="sm" name="name" placeholder="Name" className="my-2  py-1" />
              <Form.Control type="email" size="sm" name="email" placeholder="Email" className="my-2  py-1" />
              <Form.Control type="text" size="sm" name="subject" placeholder="Subject" className="my-2  py-1" />
              <Form.Control type="text" as="textarea" rows={3} size="sm" name="description" placeholder="Description" className="my-2  py-1" />

              <Form.Select required size="sm" id="priority" name="priority" className="my-1 me-2  py-1 w-100">
                <option value="0" selected disabled>Choose your Priority</option>
                <option value="1">Low Priority</option>
                <option value="2">Medium Priority</option>
                <option value="2">High Priority</option>
              </Form.Select>

              <Form.Group className="d-flex flex-row">
                <Form.Control size="sm" type="file"   accept="image/*" id="attachFile" name="attachFile" className="my-1  py-1 " />
              </Form.Group>


            </Col>
          </Row>

        </Modal.Body>
        <Modal.Footer className='d-flex align-items-center justify-content-end py-1'>
          <Button size="sm" variant="secondary" onClick={handleParentClose}>
            Cancel
          </Button>
          <Button size="sm" variant="primary" onClick={handleParentClose}>
            Submit Ticket
          </Button>
        </Modal.Footer>
      </Modal> */}

      <Modal show={show2} size="md" backdrop='static' keyboard={false} onHide={handleParentClose2} centered>
        <Modal.Header closeButton className="py-1">
          <Modal.Title className="lead" >Update Pavilion Banners </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className='mb-3'>
            <Col md={3}><strong>Banner 1</strong></Col>
            <Col md={9}>
              
             
                <Box my={2} >
                  <label htmlFor="fileBanner1">
                    <CropperInput accept="image/*" data-value="BNR1" id="fileBanner1" type="file" onChange={onFileChange} />
                    <Image id={pavilionBanner1}  style={{ borderRadius: "15px", cursor: "pointer", width: "320px", height: "200px" }} src={imageBannerOne2 ? imageBannerOne2 : imageBannerOne} />
                  </label>
                </Box>
                <CropperPopup
                  open={open1} image={imageBannerOne2} handleClose={handleClose}
                  getCroppedFile={(imageBannerOne) => {
                    setImageBannerOne2(imageBannerOne)
                    handleClose();
                  }}
                  aspectRatio={16 / 10}
                />
            
              {/* {bannerlinkcolor1 && (
                <span style={bannerlinkcolor} onClick={() => BannerDelete(1, pavilionBanner1, banners[0])} id={deleteBanner1}>Delete Banner1</span>
              )} */}
              
            </Col>
          </Row>
          <Row className='mb-3'>
            <Col md={3}><strong>Banner 2</strong></Col>
            <Col md={9}>
              
                <Box my={2} >
                  <label htmlFor="fileBanner2">
                    <CropperInput accept="image/*" data-value="BNR2" id="fileBanner2" type="file" onChange={onFileChange} />
                    <Image id={pavilionBanner2}  style={{ borderRadius: "15px", cursor: "pointer", width: "320px", height: "200px" }} src={imageBannerTwo2 ? imageBannerTwo2 : imageBannerTwo} />
                  </label>
                </Box>
                <CropperPopup open={open2} image={imageBannerTwo2} handleClose={handleClose}
                  getCroppedFile={(imageBannerTwo) => {
                    setImageBannerTwo2(imageBannerTwo)
                    handleClose();
                  }}
                  aspectRatio={16 / 10}
                />
              {bannerlinkcolor2 && (
                <div style={{color:'#e30613', cursor:'pointer'}} onClick={() => BannerDelete(2, pavilionBanner2, banners[1])} id={deleteBanner2} >Delete Banner2</div>
              )}
			  
            </Col>
          </Row>
          <Row>
            <Col md={3}><strong>Banner 3</strong></Col>
            <Col md={9}>
              
                <Box my={2} >
                  <label htmlFor="fileBanner3">
                    <CropperInput accept="image/*" data-value="BNR3" id="fileBanner3" type="file" onChange={onFileChange} />
                    <Image id={pavilionBanner3}  style={{ borderRadius: "15px", cursor: "pointer", width: "320px", height: "200px" }} src={imageBannerThree2 ? imageBannerThree2 : imageBannerThree} />
                  </label>
                </Box>
                <CropperPopup open={open3} image={imageBannerThree2} handleClose={handleClose}
                  getCroppedFile={(imageBannerThree) => {
                    setImageBannerThree2(imageBannerThree)
                    

                    handleClose();
                  }}
                  aspectRatio={16 / 10}
                />
              {bannerlinkcolor3 && (
                <div style={{color:'#e30613', cursor:'pointer'}} onClick={() => BannerDelete(3, pavilionBanner3, banners[2])} id={deleteBanner3}>Delete Banner3</div>
              )}

            </Col>
          </Row>

        </Modal.Body>
        <Modal.Footer className='d-flex align-items-center justify-content-between py-1'>
          <div>Banner Size (w x h): <strong>320 x 200 pixels</strong></div>
          <div><Button size="sm" className='me-2' variant="secondary" onClick={handleParentClose2}>
            Cancel
          </Button>
            <Button size="sm" variant="primary" onClick={handleBannersSubmit}>
            {progress && (<Spinner size='sm' className="me-2" animation="border" />)} Submit
            </Button>
          </div>

        </Modal.Footer>
      </Modal>
    </>
  )
}

export default MiddlePavilion
