// import { PropaneSharp } from '@mui/icons-material'
import React, { useState, useEffect } from 'react'
import Group from '@mui/icons-material/Group';
import LoginIcon from '@mui/icons-material/Login';
import HandshakeIcon from '@mui/icons-material/Handshake';
import AllPavilionsBox from '../../components/pavilion/Boxes/AllPavilionsBox';
import Logo from '../../components/Logo';
import UserBox from '../../components/pavilion/admin/UserBox';
import IamMemberBox from '../../components/pavilion/Boxes/IamMemberBox';
import SponsorsBox from '../../components/pavilion/Boxes/SponsorsBox';
import UserDetailsBox from '../../components/pavilion/Boxes/UserDetailsBox';
import { ListGroup, Navbar, Nav, NavLink } from 'react-bootstrap';
import { HomeOutlined } from '@mui/icons-material';
import Accordion from 'react-bootstrap/Accordion';
import Collapse from 'react-bootstrap/Collapse';
import { Link } from 'react-router-dom'
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import MobileScreenShareIcon from '@mui/icons-material/MobileScreenShare';
import SettingsIcon from '@mui/icons-material/Settings';
import DashboardIcon from '@mui/icons-material/DashboardOutlined';
import PavilionIcon from '@mui/icons-material/AccountBalanceOutlined';
import PlansIcon from '@mui/icons-material/CardTravel';
import MembersIcon from '@mui/icons-material/GroupOutlined';
import SEOIcon from '@mui/icons-material/TravelExploreOutlined';
import NewTicketIcon from '@mui/icons-material/AddCardOutlined';
import CompletedIcon from '@mui/icons-material/CreditCardOutlined';
import InProgressIcon from '@mui/icons-material/CreditScoreOutlined';
import ClosedIcon from '@mui/icons-material/CreditCardOffOutlined';
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PavilionCategoryIcon from '@mui/icons-material/AssuredWorkload';
import Typography from '@mui/material/Typography';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import AdminSideMenu from './AdminSideMenu';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SendIcon from '@mui/icons-material/Send';
import { useNavigate } from "react-router-dom"
import LogoutIcon from '@mui/icons-material/Logout';
import Swal from 'sweetalert2'

function AdminSidebar(props) {
    const [show, setShow] = useState(props.isLoggedIn);
    const [isPavilion, setIsPavilion] = useState(props.isPavilion);
    const [showAP, setShowAP] = useState(props.showAP);
    const [showUB, setShowUB] = useState(props.showUB);
    const [showIM, setShowIM] = useState(props.showIM);
    const [showSB, setShowSB] = useState(props.showSB);
    const [showUD, setShowUD] = useState(props.showUD);
    const [subMenu1, setSubMenu1] = useState(false);
    const [subMenu2, setSubMenu2] = useState(false);
    const navigate = useNavigate();

    const handleSubMenu1 = () => {
        setSubMenu1(!subMenu1)
        setSubMenu2(false)
    }
    const handleSubMenu2 = () => {
        setSubMenu2(!subMenu2)
        setSubMenu1(false)
    }

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {props.placement}
        </Tooltip>
    );

    const handleLogout = () => {
        Swal.fire({
          title: "Are you sure?",
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#666",
          confirmButtonText: "Yes, Logout"
        }).then((result) => {
          if (result.isConfirmed) {
              localStorage.clear();
              navigate('/admin/login');
          }
        });
      };

    return (<>

        <nav id="sidebar" className={props.toggle ? 'active bg-light border border-1' : 'bg-light border border-1'} >
            <div style={{height:'80px'}} className="sidebar-header bg-light border-bottom d-flex flex-column align-items-center justify-content-center flex-wrap">
                <img width="40" alt="" src="https://bic3-staging.s3.eu-west-2.amazonaws.com/assets/admin/logo/logo.png" />
                <h5 className='ms-2 text-light-dark'>{!props.toggle && 'Big Innovation Centre'}</h5>
            </div>
            <div className=" d-flex flex-column justify-content-between" style={{ height: '87vh' }}  >
                {/* <ul className="list-unstyled components slim-scrollbar overflow-auto pt-0 mb-0" style={{ height: '85vh' }} >
                    <li className="mb-0" style={{ cursor: 'pointer' }} >
                        <Link to='/admin/dashboard' className='fs-5' >
                            <DashboardIcon className='me-1 pb-1' />
                            {!props.toggle && 'Dashboard'}
                        </Link>
                    </li>
                    <li className={subMenu1 ? "active mb-0" : "mb-0"} onClick={handleSubMenu1} style={{ cursor: 'pointer' }}>
                        <Link className='fs-5 d-flex justify-content-between '>
                            <div>
                                <PavilionIcon className='me-1 pb-1' />
                                {!props.toggle && 'Pavilions'}
                            </div>
                            {subMenu1 ? <ArrowDownIcon /> : <ArrowRightIcon />}
                        </Link>
                        <Collapse in={subMenu1}>
                            <ul className="collapse list-unstyled" >
                                <li className='mb-0'>
                                    <Link to="/admin/allpavilions">
                                    {props.toggle ? (
                                        <OverlayTrigger
                                            placement="right"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={<Tooltip id="button-tooltip-2">All Pavilions</Tooltip>}
                                        >
                                        <div className="failed_icon">
                                        <PavilionIcon className='me-1 pb-1' />
  <span><PavilionIcon fontSize='small'/></span>
</div>
                       
                                        </OverlayTrigger>
                                    ):(<>
                                        <PavilionIcon className='me-1 pb-1' />All Pavilion
                                        </>
                                    )}
                                    </Link>
                                </li>
                                <li className='mb-0'>
                                    <Link to="/admin/pavilioncats">
                                        {props.toggle ? (
                                            <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={<Tooltip id="button-tooltip-2">Pavilions Categories</Tooltip>}
                                            >
                                                <PavilionCategoryIcon className='me-1 pb-1' />
                                            </OverlayTrigger>
                                        ) : (<>
                                            <PavilionCategoryIcon className='me-1 pb-1' />Pavilion Categories
                                        </>
                                        )}
                                    </Link>
                                </li>
                            </ul>
                        </Collapse>
                    </li>
                    <li className="mb-0" >
                        <Link to="/admin/plans" className='fs-5'>
                            <PlansIcon className='me-1 pb-1' />
                            {!props.toggle && 'Membership Plans'}
                        </Link>
                    </li>
                    <li className="mb-0" >
                        <Link to="/admin/members" className='fs-5'>
                            <MembersIcon className='me-1 pb-1' />
                            {!props.toggle && 'Members'}
                        </Link>
                    </li>
                    <li className='mb-0'>
                        <Link to="/admin/seo" className='fs-5'>
                            <SEOIcon className='me-1 pb-1' />
                            {!props.toggle && 'SEO'}
                        </Link>
                    </li>
                    <li className={subMenu2 ? "active mb-0" : "mb-0"} onClick={handleSubMenu2} style={{ cursor: 'pointer' }}>
                        <Link className='fs-5 d-flex justify-content-between'>
                            <div>
                                <CompletedIcon className='me-1 pb-1' />
                                {!props.toggle && 'Tickets'}
                            </div>
                            {subMenu2 ? <ArrowDownIcon /> : <ArrowRightIcon />}
                        </Link>
                        <Collapse in={subMenu2}>
                            <ul className="collapse list-unstyled" >
                                <li className='mb-0'><Link to="/admin/newticket"><NewTicketIcon className='me-1 pb-1' /> {!props.toggle && 'New Ticket'}</Link></li>
                                <li className='mb-0'><Link to="/admin/inprogress"><InProgressIcon className='me-1 pb-1' /> {!props.toggle && 'In-Progress'}</Link></li>
                                <li className='mb-0'><Link to="/admin/completed"><CompletedIcon className='me-1 pb-1' /> {!props.toggle && 'Completed'}</Link></li>
                                <li className='mb-0'><Link to="/admin/closed"><ClosedIcon className='me-1 pb-1' /> {!props.toggle && 'Closed'}</Link></li>
                            </ul>
                        </Collapse>
                    </li>

                </ul> */}
                <div className='m-0 slim-scrollbar ' >
                   <AdminSideMenu toggle={props.toggle} permissions={props.permissions}/>
                </div>

                {/* <ul className="list-unstyled components mb-3 mt-0 pt-2 " > */}
                <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
   >
                    {/* <ListItemButton  onClick={handleLogout}>
        <ListItemIcon> <LogoutIcon /> </ListItemIcon>
        <ListItemText primary={props.toggle ? "" : "Logout"}   />
      </ListItemButton> */}
      <ListItemButton sx={{minHeight:'40px', maxHeight:'40px'}} onClick={handleLogout}>
        <ListItemIcon style={{minWidth:'40px ',marginLeft:'15px'}}> 
          <LogoutIcon sx={{ color: "#e30613", }} fontSize='small' /> 
          </ListItemIcon>
        {!props.toggle && <ListItemText  primary="Logout"   />}
      </ListItemButton>
                    </List>
                    {/* <li className='mb-0'>
                        <Link to="/admin/settings" className='fs-5'>
                            <SettingsIcon className='me-1 pb-1' />
                            {!props.toggle && 'Settings'}
                        </Link>
                    </li>
                </ul> */}
            </div>
        </nav>
    </>
    )
}

export default AdminSidebar
