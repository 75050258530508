import React, { useState, useEffect } from "react";
import AdminLayout from '../layouts/AdminLayout';
import { Row, Col, Form, Container, Button, Stack, ListGroup, ButtonGroup, FloatingLabel, Modal } from 'react-bootstrap';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import AdminBreadCrumbs from '../components/AdminBreadCrumbs';
import { Link, useNavigate } from 'react-router-dom';
import { Pagination, Typography, Grid, Badge, Card, CardMedia, CardContent } from "@mui/material";
import PlansCard from '../components/AdminCards/PlansCard';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import adminhttp from '../../adminhttp';
import AddPlan from '../components/AdminForms/AddPlan';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Swal from "sweetalert2";
import Spinner from "react-bootstrap/Spinner";
import CRUDDropdown from "../../components/CRUDDropdown";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CardHeader from '@mui/material/CardHeader';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import { ExportToExcel } from "../components/ExportToExcel";
import { formatDate } from "../../components/PavilionConstants";
import Alert from "@mui/material/Alert";

const ToggleButton1 = styled(ToggleButton)({
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: '#e30613'
  }
});

const breadcrumbs = [
  <Link underline="hover" key="1" color="inherit" to="/admin/dashboard" >
    Dashboard
  </Link>,
  <Typography key="3" sx={{ color: 'text.primary' }}>
    Company Plans
  </Typography>,
];

const columns = [
  { field: 'id', headerName: 'ID', width: 20 },
  {
    field: 'plan_name',
    headerName: 'Plan Name',
    editable: true,
  },
  {
    field: 'price',
    headerName: 'Price',
    type: 'number',
    editable: true,
  },
  {
    field: 'type',
    headerName: 'Type',
    editable: true,
  },
  {
    field: 'status',
    headerName: 'Active',
    sortable: false,
  },
  {
    field: 'created_at',
    headerName: 'Created at',
    sortable: false,
  },

  {
    field: "action",
    headerName: "Action",
    width: 200,
    sortable: false,
    renderCell: ({ row }) =>
      <ButtonGroup>
        <Button variant='primary_outline' size='sm' onClick={() => handleAction(row)}>
          Active
        </Button>
        <Button variant='primary_outline' size='sm' onClick={() => handleAction(row)}>
          InActive
        </Button>
        <Button variant='primary_outline' size='sm' onClick={() => handleAction(row)}>
          Edit
        </Button>
      </ButtonGroup>,

  },
];

function handleAction(row) {
  alert(row.id)
}
const MembershipPlans = () => {

  const [exportData, setExportData] = useState([]);
  const today = new Date();
  const fileName = `Company Plans List ${formatDate(today)}`; // here enter filename for your excel file

  const [progress, setProgress] = useState(false);
  const [showModal, setShowModal] = useState(false); // Keep this
  const [validated, setValidated] = useState(false);
  const [editPlan, setEditPlan] = useState(false);

  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);

  const [page, setPage] = React.useState(1);
  // const handleChange = (event, value) => {
  //     setPage(value);
  // };

  const [planID, setPlanID] = useState();

  const [duration, setDuration] = useState('monthly');
  const [packages, setPackages] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); // State for search term

  const handleChangeAlign = (event, newDuration) => {
    setDuration(newDuration);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value); // Update the search term
  };
  const handleDropdown = () => {
    //setShow(true)
  };
  const handleEdit = (id) => (e) => {
    setEditPlan(true)
    setPlanID(id)
    handleRequestedit(id);
    setShowModal(true)


  };

  const handleAddPlan = (e) => {
    setFormData({
      name: "",
      price: "",
      type: "monthly",
      stripeProductID: "",
      stripePriceID: "",
      description: "",
      icon: "",
      status: 1,
      id: "",
    });
    setEditPlan(false)
    setShowModal(true)
  }

  const handleStatus = (planId, planStatus) => (e) => {
    e.stopPropagation();
    Swal.fire({
      title: `Are you sure to ${planStatus === 1 ? 'Deactive' : 'Activate'} this Plan?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      width: 'auto',
      showCancelButton: true,
      confirmButtonColor: "#e30613",
      cancelButtonColor: "#6c757d",
      confirmButtonText: `Yes, ${planStatus === 1 ? 'Deactive' : 'Activate'} it!`
    }).then((result) => {
      if (result.isConfirmed) {
        adminhttp().post('/admin/changepackageplanstatus', {
          package_id: planId,
          status: planStatus === 1 ? 0 : 1,
        }).then((data) => {
          Swal.fire({
            title: `Plan has been ${planStatus === 1 ? 'Deactivated' : 'Activated'}.`,
            icon: "success",
            allowOutsideClick: false,
            confirmButtonColor: "#e30613",
          }).then((result) => {
            if (result['isConfirmed']) {
              handleRequest();
            }
          })
        });
      }
    });
  };

  const handleRequest = () => {
    setLoading(true)
    adminhttp().get('/admin/packageplans').then((data) => {
      if (data.data.data.packages.length > 0) {
        setPackages(data.data.data.packages);
        setLoading(false)
      } else {
        setNoData(true)
        setLoading(false)
      }

    });
  }

  const [formData, setFormData] = useState({
    name: "",
    price: "",
    type: "monthly",
    stripeProductID: "",
    stripePriceID: "",
    description: "",
    icon: "",
    status: 1,
    id: "",
  });


  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setFormData({ ...formData, [name]: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };



  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity() === true) {
      setProgress(true);
      if (editPlan) {
        const submitData = new FormData();
        submitData.append("name", formData.name);
        submitData.append("price", formData.price);
        submitData.append("type", formData.type);
        submitData.append("stripe_product_id", formData.stripeProductID);
        submitData.append("stripe_price_id", formData.stripePriceID);
        submitData.append("description", formData.description);
        submitData.append("icon", formData.icon);
        submitData.append("status", formData.status);
        submitData.append("id", planID);
        adminhttp().post(`/admin/updatepackageplan`, submitData).then((response) => {
          Swal.fire({
            title: 'Plan updated successfully!',
            icon: "success",
            allowOutsideClick: false,
            confirmButtonColor: "#e30613",
          });
          setProgress(false);
          setShowModal(false); // Close modal here
          handleRequest();
        })
          .catch((error) => {
            console.error("There was an error submitting the form!", error);
          });
      } else {
        const submitData = new FormData();
        submitData.append("name", formData.name);
        submitData.append("price", formData.price);
        submitData.append("type", formData.type);
        submitData.append("stripe_product_id", formData.stripeProductID);
        submitData.append("stripe_price_id", formData.stripePriceID);
        submitData.append("description", formData.description);
        submitData.append("icon", formData.icon);
        submitData.append("status", formData.status);

        adminhttp().post("/admin/createpackageplan", submitData).then((response) => {
          Swal.fire({
            title: 'New plan added successfully!',
            icon: "success",
            allowOutsideClick: false,
            confirmButtonColor: "#e30613",
          });
          setProgress(false);
          setShowModal(false); // Close modal here
          handleRequest();
          // setFormData({
          //   name: "",
          //   price: "",
          //   type: "monthly",
          //   stripeProductID: "",
          //   stripePriceID: "",
          //   description: "",
          //   icon: "",
          //   status: 1,
          //   id: "",
          // });
        })
          .catch((error) => {
            console.error("There was an error submitting the form!", error);
          });
        // }
      }
    }
  };




  const handleRequestedit = (planID) => {
    adminhttp().get(`/admin/singlepackageplan/${planID}`).then((data) => {
      if (data.data && data.data.data && data.data.data.packages) {
        const { name, price, type, stripe_product_id, stripe_price_id, description, icon, status } = data.data.data.packages;
        setFormData({
          name: name || "",
          price: price || "",
          type: type,  // Default to "monthly" if no type provided
          stripeProductID: stripe_product_id || "",
          stripePriceID: stripe_price_id || "",
          description: description || "",
          icon: icon || "",
          status: status,  // Default to 1 (Active) if status not provided
          id: planID,  // Make sure the ID is updated
        });
      }
    }).catch((error) => {
      console.error("Error fetching plan data:", error);
    });
  };

  useEffect(() => {
    handleRequest();
  }, []);  // Only runs once when the component mounts


  useEffect(() => {
    const fetchData = () => {
      adminhttp().get(`/admin/packageplans`).then((data) => {
        // reshaping the array
        const customHeadings = data.data.data.packages.map(plan => ({
          "Plan Id": plan.id,
          "Plan Name": plan.name,
          "Price": plan.price,
          "Type": plan.type,
          "Description": plan.description,
        }))
        setExportData(customHeadings)
      })
    }
    fetchData()
  }, [])
  const filteredPackages = packages.filter(
    (plan) =>
      plan.type === duration &&
      plan.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  return (
    <>
      <AdminLayout >
        <Container fluid className='bg-light pb-5' style={{ minHeight: '100vh' }} >

          <Box component="section" sx={{ px: 2, pt: 2, pb: 1 }}>
            <AdminBreadCrumbs breadcrumbs={breadcrumbs} />
          </Box>
          <Box component="section" className='d-flex justify-content-between' sx={{ px: 2, pt: 0, pb: 1 }}>
            <h4>Company Plans</h4>
            <ToggleButtonGroup
              color="primary"
              value={duration}
              exclusive
              size='small'
              onChange={handleChangeAlign}
              aria-label="Platform"
            >
              <ToggleButton1 className='ps-3' sx={{ borderRadius: '50px 0 0 50px ' }} value="monthly">Monthly</ToggleButton1>
              <ToggleButton1 className='pe-3' sx={{ borderRadius: '0 50px 50px 0 ' }} value="annually">Yearly</ToggleButton1>
            </ToggleButtonGroup>
            <div className="d-flex justify-content-center align-items-center">
              <Form className='me-2'>
                {/* <Form.Control size='sm' type="search" placeholder="Search Plans" /> */}
                <Form.Control
                  size="sm"
                  type="search"
                  placeholder="Search Plans"
                  value={searchTerm}
                  onChange={handleSearch}
                />
              </Form>

              <Button
                variant="primary"
                type="button"
                size='sm'
                className='w-auto me-2'
                onClick={handleAddPlan}
              >
                Add Plan
              </Button>
              <ExportToExcel apiData={exportData} fileName={fileName} />
            </div>
          </Box>



          <Container fluid className={`mt-4 ${loading && 'text-center'}`}>
            {loading ? (<Spinner size='lg' className='me-2 ' animation='border' />) : (
              <Row xs={1} md={3} className="g-4">
                {filteredPackages.filter(plan => plan.type === duration).map((plan, index) => (
                  // {Array.from({ length: plans.length }).map((_, idx) => (
                  <Col key={index}>
                    <Card className='rounded-3' sx={{ ':hover': { boxShadow: 5 }, }}>
                      <CardHeader
                        action={
                          <CRUDDropdown
                            title={<MoreVertIcon fontSize="small" />}
                            handleDropdown={handleDropdown}
                            addTitle="Edit Plan"
                            addClick={handleEdit(plan.id)}
                            // editTitle="Delete Plan"
                            // editClick={handleDelete}
                            deleteTitle={
                              plan.status === 1
                                ? "Deactivate Plan"
                                : "Activate Plan"
                            }
                            deleteClick={handleStatus(plan.id, plan.status)}
                          />
                        }
                        title={<Chip label={plan.name} variant="outlined" />}
                      />


                      <CardContent className='pt-0'>
                        <Typography variant="h3" component="h3" gutterBottom>
                          £{plan.price}
                          <Typography variant="subtitle1" component="span">
                            / {plan.type === 'monthly' ? 'month' : 'year'}
                          </Typography>
                        </Typography>
                        <Divider />
                        <ul className='fs-8' dangerouslySetInnerHTML={{ __html: plan.description }} />



                      </CardContent>


                    </Card>

                    {/* )} */}
                  </Col>
                ))}
              </Row>
            )}
            {noData && (
              <Box
                sx={{ flexGrow: 1 }}
                className="d-flex justify-content-center"
              >
                <Alert variant="outlined" severity="error">
                  Plans not found
                </Alert>
              </Box>
            )}
          </Container>

        </Container>
      </AdminLayout>
      {/* <AddPlan handleRequest={handleRequest} show={showModal} edit={editPlan} planID={planID} close={() => setShowModal(false)} /> */}
      <Modal
        size="lg"
        centered
        backdrop="static"
        show={showModal} // Use the correct state
        onHide={() => setShowModal(false)} // Add this to handle closing the modal
      >

        <Modal.Header className="py-1 my-1" closeButton>
          <Modal.Title>
            {editPlan ? 'Edit' : 'Add'} Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>

            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
              id="planForm"
            >
              <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                  <Form.Control
                    name="name"
                    required
                    type="text"
                    className={formData.name && Object.values(formData.name).length > 0 ? "is-valid" : "is-invalid"}
                    placeholder="Plan Name"
                    value={formData.name && formData.name}
                    onChange={handleChange}
                  />
                  <Form.Control
                    name="price"
                    required
                    type="text"
                    value={formData.price && formData.price}
                    className={formData.price && Object.values(formData.price).length > 0 ? "is-valid" : "is-invalid"}
                    placeholder="Plan Price"
                    onChange={handleChange}
                  />
                  <Form.Select
                    name="type"
                    required
                    value={formData.type && formData.type}
                    onChange={handleChange}
                  >
                    <option selected value="monthly">Monthly</option>
                    <option value="annually">Yearly</option>
                  </Form.Select>
                  <Form.Select
                    name="status"
                    required
                    value={formData.status && formData.status} // Ensure it's a string
                    onChange={handleChange}
                  >
                    <option selected value="1">Active</option>
                    <option value="0">Inactive</option>
                  </Form.Select>
                  <Form.Control
                    name="stripeProductID"
                    type="text"
                    placeholder="Stripe Product ID"
                    value={formData.stripeProductID && formData.stripeProductID}
                    onChange={handleChange}
                  />
                  <Form.Control
                    name="stripePriceID"
                    type="text"
                    placeholder="Stripe Price ID"
                    value={formData.stripePriceID && formData.stripePriceID}
                    onChange={handleChange}
                  />
                  <Form.Control
                    name="icon"
                    type="text"
                    placeholder="Icon"
                    value={formData.icon && formData.icon}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <Form.Group controlId="description" className="mb-1">
                    <CKEditor
                      editor={ClassicEditor}
                      data={formData.description || ""}
                      onReady={(editor) => {
                        editor.editing.view.change((writer) => {
                          writer.setStyle("min-height", "150px", editor.editing.view.document.getRoot());
                        });
                        console.log("Editor ready", editor);
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setFormData({ ...formData, description: data });
                      }}
                      onBlur={(event, editor) => console.log("Blur.", editor)}
                      onFocus={(event, editor) => console.log("Focus.", editor)}
                    />
                  </Form.Group>
                </Grid>
              </Grid>
            </Form>




          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>

          <Button
            variant="primary"
            type="submit"
            form="planForm"
          >
            {progress && (
              <Spinner size="sm" className="me-2" animation="border" />
            )} Save Plan
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  )
}

export default MembershipPlans
