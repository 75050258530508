import React, { useEffect, useState } from 'react';
import Layout from '../components/layouts/Layout2';
import { Form, Card, Button, Container, Image, Modal, Row, Col } from 'react-bootstrap';
import http from '../http'; // Ensure this is correctly set up to use Axios
import Swal from 'sweetalert2';
import { Chart } from "react-google-charts";
import '../survey.css';
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'

function PublicSurvey() {
    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer');
      };
      
    const navigate=useNavigate();

    const [formData, setFormData] = useState({
        quest1: "",
        quest2: "",
        quest3: "",
        quest4: "",
        quest5: "",
        comments: "",
    });

    
    const [errors, setErrors] = useState({}); // State for validation errors

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        setErrors({
            ...errors,
            [name]: "", // Clear the error for this field when user interacts
        });
    };

    const validateForm = () => {
        const newErrors = {};

        if (!formData.quest1) newErrors.quest1 = "Please select an answer for Question 1.";
        if (!formData.quest2) newErrors.quest2 = "Please select an answer for Question 2.";
        if (!formData.quest3) newErrors.quest3 = "Please select an answer for Question 3.";
        if (!formData.quest4) newErrors.quest4 = "Please select an answer for Question 4.";
        if (!formData.quest5) newErrors.quest5 = "Please select an answer for Question 5.";

        return newErrors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            console.log("Form Submitted:", formData);
            http()
                .post('/publicaddpolls', formData) // Send formData as the request body
                .then((response) => {
                    console.log("API Response:", response.data);
                    Swal.fire({
                        title: "Survey is submitted successfully!",
                        icon: "success", // Changed icon to 'success' for better UX
                        allowOutsideClick: false,
                        confirmButtonColor: "#e30613",
                    }).then(() => {
                        // Optionally reset the form or perform other actions
                        setFormData({
                            quest1: "",
                            quest2: "",
                            quest3: "",
                            quest4: "",
                            quest5: "",
                            comments: "",
                        });
                        // Optionally, refresh poll data after submission
                        fetchPollData();
                    });
                })
                .catch((error) => {
                    console.error("API Error:", error.response ? error.response.data : error.message);
                    Swal.fire({
                        title: "Submission Failed",
                        text: "There was an error submitting your survey. Please try again later.",
                        icon: "error",
                        confirmButtonColor: "#e30613",
                    });
                });
        }
    };
    const [show, setShow] = useState(false);

    // Handlers to open and close the modal
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    // Chart state
    const [data1, setData1] = useState([
        ["Opinion", "No of Persons"],
        ["Yes", 0],
        ["No", 0],
        ["Not Sure", 0],
    ]);

    // Chart state
    const [data2, setData2] = useState([
        ["Opinion", "No of Persons"],
        ["Yes", 0],
        ["No", 0],
    ]);


    const [data3, setData3] = useState([
        ["Opinion", "No of Persons"],
        ["Yes", 0],
        ["No", 0],
        ["Not Sure", 0],
    ]);


    const [data4, setData4] = useState([
        ["Opinion", "No of Persons"],
        ["Yes", 0],
        ["No", 0],
        ["Not Sure", 0],
    ]);


    const [data5, setData5] = useState([
        ["Opinion", "No of Persons"],
        ["Yes", 0],
        ["No", 0],
        ["Not Sure", 0],
    ]);

    const [loading, setLoading] = useState(true); // Loading state

    useEffect(() => {
        fetchPollData();
    }, []);

    const fetchPollData = () => {
        setLoading(true);
        // Fetch data from the Laravel API
        http().get('/webpublicpollresult4') // Adjust the endpoint URL as needed
            .then((response) => {
                const data = response.data;
                if (data?.data) {
                    const { yes_count_1, no_count_1, not_sure_count_1, yes_count_2, no_count_2, yes_count_3, no_count_3, not_sure_count_3, yes_count_4, no_count_4, not_sure_count_4, yes_count_5, no_count_5, not_sure_count_5 } = data.data;

                    // Update the chart data dynamically
                    setData1([
                        ["Opinion", "No of Persons"],
                        ["Yes", yes_count_1 || 0],
                        ["No", no_count_1 || 0],
                        ["Not Sure", not_sure_count_1 || 0],
                    ]);
                    setData2([
                        ["Opinion", "No of Persons"],
                        ["Yes", yes_count_2 || 0],
                        ["No", no_count_2 || 0],
                    ]);
                    setData3([
                        ["Opinion", "No of Persons"],
                        ["Yes", yes_count_3 || 0],
                        ["No", no_count_3 || 0],
                        ["Not Sure", not_sure_count_3 || 0],
                    ]);
                    setData4([
                        ["Opinion", "No of Persons"],
                        ["Yes", yes_count_4 || 0],
                        ["No", no_count_4 || 0],
                        ["Not Sure", not_sure_count_4 || 0],
                    ]);
                    setData5([
                        ["Opinion", "No of Persons"],
                        ["Yes", yes_count_5 || 0],
                        ["No", no_count_5 || 0],
                        ["Not Sure", not_sure_count_5 || 0],
                    ]);

                }
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching poll data:", error);
                setLoading(false);
            });
    };

    const options1 = {
        title: "Q1. Do you understand what the new government's approach to regulation of AI is?",
        pieHole: 0, // Set to 0 for a standard pie chart, use 0.4 for a donut chart
        is3D: false,
        chartArea: { width: '95%', height: '350px' },
        // Additional customization options
        legend: { position: 'bottom' },
        colors: ['#4caf50', '#f44336', '#ffeb3b'], // Colors for Yes, No, Not Sure
    };

    const options2 = {
        title: "Q2.  Have you had any approach or input into the government's policy development for AI?",
        pieHole: 0, // Set to 0 for a standard pie chart, use 0.4 for a donut chart
        is3D: false,
        chartArea: { width: '95%', height: '350px' },
        // Additional customization options
        legend: { position: 'bottom' },
        colors: ['#4caf50', '#f44336', '#ffeb3b'], // Colors for Yes, No, Not Sure
    };

    const options3 = {
        title: "Q3. Do you, in general terms, support the UK government's approach to regulation of AI to date?",
        pieHole: 0, // Set to 0 for a standard pie chart, use 0.4 for a donut chart
        is3D: false,
        chartArea: { width: '95%', height: '350px' },
        // Additional customization options
        legend: { position: 'bottom' },
        colors: ['#4caf50', '#f44336', '#ffeb3b'], // Colors for Yes, No, Not Sure
    };

    const options4 = {
        title: "Q4.  Do you think the new Labour government has placed enough emphasis on AI during its first five months in office?",
        pieHole: 0, // Set to 0 for a standard pie chart, use 0.4 for a donut chart
        is3D: false,
        chartArea: { width: '95%', height: '350px' },
        // Additional customization options
        legend: { position: 'bottom' },
        colors: ['#4caf50', '#f44336', '#ffeb3b'], // Colors for Yes, No, Not Sure
    };

    const options5 = {
        title: "Q5.  Do you favour a more light touch, US-style regulatory approach, or do you prefer the approach taken by the EU in the AI Act?",
        pieHole: 0, // Set to 0 for a standard pie chart, use 0.4 for a donut chart
        is3D: false,
        chartArea: { width: '95%', height: '350px' },
        // Additional customization options
        legend: { position: 'bottom' },
        colors: ['#4caf50', '#f44336', '#ffeb3b'], // Colors for Yes, No, Not Sure
    };
const [load,setLoad]=useState(0)
const [url,setUrl]=useState('https://docs.google.com/forms/d/e/1FAIpQLSePfzv4PDCGLjTMXcf7SxCLfuqF6xieLQGwq9pIptIwhO_6Ow/viewform?embedded=true')
const [urlResults,setUrlResults]=useState('https://docs.google.com/forms/d/e/1FAIpQLSePfzv4PDCGLjTMXcf7SxCLfuqF6xieLQGwq9pIptIwhO_6Ow/viewanalytics?embedded=true')
    const scrollToTop = () => {
        // alert(load)
        // if(load===1){
        //     setUrl('https://docs.google.com/forms/d/e/1FAIpQLSePfzv4PDCGLjTMXcf7SxCLfuqF6xieLQGwq9pIptIwhO_6Ow/viewanalytics?embedded=true')
        //  }    
        window.scrollTo({
            top: 0,
            behavior: "smooth"
            /* you can also use 'auto' behaviour
         in place of 'smooth' */
        });
        setLoad(load+1)
    };

    
    return (
        <Layout>
            <>
            <Container fluid className='px-0 mx-0 bg-primary d-flex justify-content-center align-items-center'>
                <Image src="https://bic3-live.s3.eu-west-2.amazonaws.com/images/pavilion/88941701198001.png" className="mw-100" alt="Public Survey Banner" />
            </Container>

            <Container className="pt-4 px-5 mb-5 text-center icontainer">
                {/* <h1 className="survey-title text-center mb-4">
                    Government Approach to Regulation of AI
                </h1> */}



                <iframe   onLoad = {scrollToTop}
                className='slim-scrollbar responsive-iframe' src='https://docs.google.com/forms/d/e/1FAIpQLSePfzv4PDCGLjTMXcf7SxCLfuqF6xieLQGwq9pIptIwhO_6Ow/viewform?embedded=true'  frameborder="0" height={load>1 ? '280px' : '1850px'} marginheight="0" marginwidth="0">Loading…</iframe>
                {/* {load>1 && <a className='mb-3' target='_blank' href="https://docs.google.com/forms/d/e/1FAIpQLSePfzv4PDCGLjTMXcf7SxCLfuqF6xieLQGwq9pIptIwhO_6Ow/viewanalytics"><span className="badge rounded-pill fs-5 bg-primary px-3 py-2 ms-2">VIEW RESPONSES</span></a> } */}
                <Button as={Link} to={urlResults} target='_blank'  variant="primary" >View Responses</Button>
            </Container>
          
            <Modal show={show} size='lg' centered onHide={handleClose}>
        <Modal.Header className='py-2' closeButton>
          <Modal.Title>View Responses</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <iframe  
                className='slim-scrollbar responsive-iframe' src='https://docs.google.com/forms/d/e/1FAIpQLSePfzv4PDCGLjTMXcf7SxCLfuqF6xieLQGwq9pIptIwhO_6Ow/viewanalytics?embedded=true'  frameborder="0" height='450px' marginheight="0" marginwidth="0">Loading…</iframe>
        </Modal.Body>
        <Modal.Footer className='py-2'>
          <Button size='sm' variant="secondary" onClick={()=>setShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
            </>
        </Layout>
    );

}

export default PublicSurvey;
