import React, { useState, useEffect, isValidElement } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FloatingLabel, Modal } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { Link, useLocation } from "react-router-dom";
import adminhttp from "../../../adminhttp";
import Swal from "sweetalert2";
import Divider from "@mui/material/Divider";
import Spinner from "react-bootstrap/Spinner";

function AddMember(props) {
  const [loginModalShow, setLoginModalShow] = useState(props.showModal);

  const [validated, setValidated] = useState(false);
  const [progress, setProgress] = useState(false);
  const [sponsor, setSponsor] = useState([]);

  const location = useLocation();
  const data = location.state;
  const splitURL = location.pathname.toString().split("/");

  // const [name, setName] = useState("");
  // const [category, setCategory] = useState("");
  // const [link, setLink] = useState("");
  // const [logo, setLogo] = useState("");
  // const [position, setPosition] = useState("");

  const [show, setShow] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    cpassword: "",
    designation: "0",
    organization: "",
    country: "",
    status: "",
  });
  const [countryList, setCountryList] = useState([]);

  const [areEqual, setAreEqual] = useState(false);
  const [result, setResult] = useState("");
  // Handle input changes
  const handleChange = (e) => {

    const { name, value, type, files } = e.target;
    setFormData({ ...formData, [name]: value });
    
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true)

    if (form.checkValidity() === true) {
      setProgress(true);
      const submitData = new FormData();
      submitData.append("usertype", 2);
      submitData.append("name", formData.name);
      submitData.append("email", formData.email);
      submitData.append("password", formData.password);
      submitData.append("password_confirmation", formData.cpassword);
      submitData.append("designation", formData.designation);
      submitData.append("organization", formData.organization);
      submitData.append("country", formData.country);
      submitData.append("status", formData.status);

      if (props.edit === true) {
        submitData.append("id", props.memberID);
        adminhttp()
          .post("/admin/updatemember", submitData)
          .then((response) => {
            console.log(response);
            if (response.data && response.data.data && response.data.data.message) {
              Swal.fire({
                title: response.data.data.message,
                icon: "success",
                allowOutsideClick: false,
                confirmButtonColor: "#e30613",
              });
              setProgress(false);
              props.close();
              props.handleRequest(); // Uncomment if you need to refresh the list
            }
          })
          .catch((error) => {
            console.error("There was an error submitting the form!", error);

            if (error.response && error.response.data) {
              const errorData = error.response.data;
              if (errorData.errors && errorData.errors.password) {
                Swal.fire({
                  title: errorData.errors.password[0],
                  icon: "error",
                  allowOutsideClick: false,
                  confirmButtonColor: "#e30613",
                });
              } else if (errorData.error) {
                Swal.fire({
                  title: errorData.error,
                  icon: "error",
                  allowOutsideClick: false,
                  confirmButtonColor: "#e30613",
                });
              } else {
                Swal.fire({
                  title: "An unknown error occurred!",
                  icon: "error",
                  allowOutsideClick: false,
                  confirmButtonColor: "#e30613",
                });
              }
            } else {
              Swal.fire({
                title: "Something went wrong!",
                icon: "error",
                allowOutsideClick: false,
                confirmButtonColor: "#e30613",
              });
            }
          });


      } else {
        adminhttp()
          .post("/admin/createmember", submitData)
          .then((response) => {
            console.log(response);
            Swal.fire({
              title: response.data.data.success,
              icon: "success",
              allowOutsideClick: false,
              confirmButtonColor: "#e30613",
            });
            setProgress(false);
            props.close();
             props.handleRequest(); // Uncomment if you need to refresh the list
          })
          .catch((error) => {
            console.error("There was an error submitting the form!", error);

            if (error.response && error.response.data) {
              const errorData = error.response.data;

              if (errorData.errors && errorData.errors.password) {
                Swal.fire({
                  title: errorData.errors.password[0],
                  icon: "error",
                  allowOutsideClick: false,
                  confirmButtonColor: "#e30613",
                });
              } else if (errorData.error) {
                Swal.fire({
                  title: errorData.error,
                  icon: "error",
                  allowOutsideClick: false,
                  confirmButtonColor: "#e30613",
                });
              } else {
                Swal.fire({
                  title: "Something went wrong!",
                  icon: "error",
                  allowOutsideClick: false,
                  confirmButtonColor: "#e30613",
                });
              }
            } else {
              Swal.fire({
                title: "Unable to connect to the server!",
                icon: "error",
                allowOutsideClick: false,
                confirmButtonColor: "#e30613",
              });
            }
          });

      }
    }
  };

  const [designations, setDesignations] = useState([]);
  const fetchDesignations = () => {
    adminhttp()
      .get(`/admin/contriesndesignation`)
      .then((data) => {
        if (data.data.data.designations.length > 0) {
          setDesignations(data.data.data.designations);
        }
      });
  };

  const fetchCountries = () => {
    adminhttp()
      .get("/getcountries")
      .then((data) => {
        setCountryList(data.data.data.countries);
        setFormData((prev) => ({
          ...prev,
          country: data.data.data.countries[0],
        }));
      });
  };

  const handleRequest = () => {
    adminhttp()
      .get(`/admin/singlemember/${props.memberID}`)
      .then((data) => {
        // if (data.data.data.user) {
        setFormData({
          name: data.data.data.user.first_name,
          email: data.data.data.user.email,
          designation: data.data.data.user.designation,
          organization: data.data.data.user.organization,
          country: data.data.data.user.country,
          status: data.data.data.user.status,
        });
        // }
      });
  };

  useEffect(() => {
    fetchCountries();
    fetchDesignations();
  }, []);

  useEffect(() => {
    // fetchCountries();
    //fetchDesignations();
    if (props.edit === true) {
      handleRequest();
    } else {
      setFormData({
        name: "",
        email: "",
        password: "",
        cpassword: "",
        designation: "",
        organization: "",
        country: "",
        status: "",
      });
    }
  }, [props.edit, props.memberID]);

  return (
    <Modal centered backdrop="static" show={props.show} onHide={props.close}>
      <Modal.Header className="py-1 my-1" closeButton>
        <Modal.Title>{props.edit === true ? "Edit" : "Add"} Member</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            id="memberForm"
          >
            <Form.Group controlId="" className="mb-1">
              <Form.Control
                name="name"
                required
                type="text"
                placeholder="Name"
                className={
                  formData.name && Object.values(formData.name).length > 0
                    ? "is-valid"
                    : "is-invalid"
                }
                value={formData.name && formData.name}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="" className="mb-1">
              <Form.Control
                name="email"
                required
                type="email"
                placeholder="Email"
                className={
                  formData.email && Object.values(formData.email).length > 0
                    ? "is-valid"
                    : "is-invalid"
                }
                value={formData.email && formData.email}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="" className="mb-1">
              {props.edit ? (
                <Form.Control name="password" type="password" placeholder="********"
                  className={formData.password ? (formData.password === formData.cpassword ? 'is-valid' : 'is-invalid') : 'is-invalid'}
                  value={formData.password && formData.password}
                  onChange={handleChange}
                />
              ) : (
                <Form.Control name="password" required type="password" placeholder="Password"
                  className={formData.password ? (formData.password === formData.cpassword ? 'is-valid' : 'is-invalid') : 'is-invalid'}
                  value={formData.password && formData.password}
                  onChange={handleChange}
                />
              )}
            </Form.Group>
            <Form.Group controlId="" className="mb-1">
              {props.edit ? (
                <Form.Control name="cpassword" type="password" placeholder="********"
                  className={formData.cpassword ? (formData.cpassword === formData.password ? 'is-valid' : 'is-invalid') : 'is-invalid'}
                  value={formData.cpassword && formData.cpassword} onChange={handleChange} />
              ) : (
                <Form.Control name="cpassword" required type="password" placeholder="Confirm Password"
                  className={formData.cpassword ? (formData.cpassword === formData.password ? 'is-valid' : 'is-invalid') : 'is-invalid'}
                  value={formData.cpassword && formData.cpassword} onChange={handleChange} />
              )}

            </Form.Group>
            <Form.Group controlId="" className="mb-1">
              <Form.Select
                name="designation"
                defaultValue="0"
                value={formData.designation && formData.designation}
                onChange={handleChange}
              >
                <option value="0" selected disabled>
                  Select Designation
                </option>
                {designations.length > 0 &&
                  designations.map((designation, index) => (
                    <option value={designation.name}>{designation.name}</option>
                  ))}
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="" className="mb-1">
              <Form.Control
                name="organization"
                required
                type="text"
                className={
                  Object.values(formData.organization).length > 0
                    ? "is-valid"
                    : "is-invalid"
                }
                placeholder="Organization"
                value={formData.organization && formData.organization}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Select
                name="country"
                className={
                  Object.values(formData.country).length > 0 
                    ? "is-valid"
                    : "is-invalid"
                }
                value={formData.country && formData.country}
                onChange={handleChange}
              >
                <option selected value=''>Select Country</option>
                {countryList.map((country) => (
                  <option key={country.id} value={country.id}>
                    {country.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="" className="mb-1">
              <Form.Select
                name="status"
                value={formData.status}
                onChange={handleChange}
              >
                <option value="1" selected>
                  Active
                </option>
                <option value="0">InActive</option>
              </Form.Select>
            </Form.Group>
          </Form>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.close}>
          Close
        </Button>
        <Button
          variant="primary"
          type="submit"
          form="memberForm"
        >
          {progress && (
            <Spinner size="sm" className="me-2" animation="border" />
          )}{" "}
          Save Member
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddMember;
