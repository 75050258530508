import React , {useEffect, useState} from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import MenuIcon from '@mui/icons-material/Menu';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import IconButton from '@mui/material/IconButton';
import BadgeAvatar from '../../components/BadgeAvatar';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';


import Swal from 'sweetalert2';
import { useNavigate, useLocation } from "react-router-dom"
import Tooltip from '@mui/material/Tooltip';
import adminhttp from '../../adminhttp'

const AdminNavBar = (props) => {
    const token = localStorage.getItem('token');
    const [avatar,setAvatar]=useState('');
    const [userID,setUserID]=useState(localStorage.getItem('localstorage_user_id'));
    const [userName,setUserName]=useState();
    const [isIconClicked, setIsIconClicked] = useState(true);
    const navigate = useNavigate()

    const handleLogin = () => navigate(`/`);

    const handleLogout = () => {
      Swal.fire({
        title: "Are you sure?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#666",
        confirmButtonText: "Yes, Logout"
      }).then((result) => {
        if (result.isConfirmed) {
            localStorage.clear();
            navigate('/admin/login');
        }
      });
    };
    
    const handleDarkMode = (event) => {
        setIsIconClicked(!isIconClicked);
        const thm = document.documentElement.getAttribute("data-bs-theme");
        const appId = document.getElementById('app-theme');
        const thm2 = appId.getAttribute('data-bs-theme');
        const thmToggler = event.currentTarget;
        const theme = localStorage.getItem("data-bs-theme");
        // thmToggler.innerHTML='<small>{theme}</small>';


        if (thm2 === 'light') {
          appId.setAttribute('data-bs-theme', 'dark');
          document.documentElement.setAttribute("data-bs-theme", "dark");
          localStorage.setItem("data-bs-theme", "dark");
          // thmToggler.innerHTML='<small>LIGHT MODE<small>';
        } else {
          appId.setAttribute('data-bs-theme', 'light');
          document.documentElement.setAttribute("data-bs-theme", "light");
          localStorage.setItem("data-bs-theme", "light");
          //  thmToggler.innerHTML='<small>DARK MODE</small>';
        }
      }
    
    useEffect(() => {
      adminhttp().get(`/admin/singleuser/${userID}`)
      .then((data) => {
      if (data.data.data.user) {
        setUserName(data.data.data.user.first_name);
      }
    });

    },[])

    
  return (
    <Navbar expand="lg" className="bg-toggle border-bottom" style={{padding:'16px 10px'}}>
    <Container fluid>
      <Navbar.Brand onClick={() => props.onChange() } style={{cursor:'pointer'}}><MenuIcon /></Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse className="justify-content-end">
        <Nav >
        {token ? (<>
               <Tooltip arrow title={userName && userName} slotProps={{popper: {modifiers: [{name: 'offset', options: {offset: [0, -15],},},],},}}>
                <Nav.Link href="">
                  <BadgeAvatar src={avatar && (`${localStorage.getItem('bucketName')}/images/profile_picture/${avatar}`)} name={userName && userName} size='25' isLoggedIn={token} />
                </Nav.Link>
                </Tooltip>
                <Tooltip arrow title='Logout' slotProps={{popper: {modifiers: [{name: 'offset', options: {offset: [0, -15],},},],},}}>
                <Nav.Link  onClick={handleLogout}>
                <IconButton className="p-1 btn btn-outline-mode btn-sm "><LogoutIcon /></IconButton>
                </Nav.Link>
                </Tooltip>
                </>
              ) : (
<Tooltip arrow title='Login' slotProps={{popper: {modifiers: [{name: 'offset', options: {offset: [0, -15],},},],},}}>
                <Nav.Link  onClick={handleLogin}>
                  <IconButton className="p-1 btn btn-outline-mode btn-sm "><LoginIcon /></IconButton>
                </Nav.Link>
                </Tooltip>
              )}
          {/* <Nav.Link href="#home">Home</Nav.Link>
          <Nav.Link href="#link">Link</Nav.Link>
          <NavDropdown title="Dropdown" id="basic-nav-dropdown">
            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.2">
              Another action
            </NavDropdown.Item>
            <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="#action/3.4">
              Separated link
            </NavDropdown.Item>
          </NavDropdown> */}
          <Tooltip arrow title={isIconClicked ? 'Dark Mode' : 'Light Mode'} slotProps={{popper: {modifiers: [{name: 'offset', options: {offset: [0, -15],},},],},}}>
          <Nav.Link to="javascript:void(0);" onClick={handleDarkMode} id="theme-toggler" >
                  <IconButton className="p-1 btn btn-outline-mode btn-sm">{isIconClicked ? <DarkModeOutlinedIcon /> : <LightModeOutlinedIcon />}</IconButton>
              </Nav.Link>
              </Tooltip>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
      
  )
}

export default AdminNavBar
