import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from "recharts";


export default function AreaCharts(props) {
  return (
    <ResponsiveContainer width="100%" height={100}>
    <AreaChart
      data={props.data}
      margin={{
        top: 10,
        right: 0,
        left: 0,
        bottom: 0,
      }}
    >
      <defs>
        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#ffffff" stopOpacity={0.9}/>
            <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0}/>
          </linearGradient>
      </defs>
      <Area
        type="monotone"
        dataKey="user"
        stroke="url(#colorUv)"
        strokeWidth={5}
        fillOpacity={1}
        fill="url(#colorUv)"
      />
      {/* <CartesianGrid strokeDasharray="3" /> */}
      {/* <XAxis dataKey="name" />
      <YAxis /> */}
      <Tooltip />
      {/* <Area type="monotone" dataKey="uv" stroke="#8884d8" fill="E3456E" /> */}
    </AreaChart>
    </ResponsiveContainer>
  );
}
