import React, { useState, useEffect } from "react";
import AdminLayout from "../layouts/AdminLayout";
import { Container, ListGroup } from "react-bootstrap";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import adminhttp from "../../adminhttp";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
// import Stack from '@mui/material/Stack'
import Stack from "react-bootstrap/Stack";
import Card from "react-bootstrap/Card";

// import Card from '@mui/material/Card';
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AdminBreadCrumbs from "../components/AdminBreadCrumbs";
import { Link } from "react-router-dom";
// import Badge from '@mui/material/Badge';
import Badge from "react-bootstrap/Badge";
import { formatDate } from "../../components/PavilionConstants";
import DateIcon from "@mui/icons-material/InsertInvitation";
import Dropdown from "react-bootstrap/Dropdown";
import { Row, Col, Button } from "react-bootstrap";
// import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation } from "react-router-dom";
import EditIcon from "@mui/icons-material/DriveFileRenameOutlineTwoTone";
import SettingsIcon from "@mui/icons-material/SettingsTwoTone";
import MenusIcon from "@mui/icons-material/ListAltTwoTone";
import SponsorsIcon from "@mui/icons-material/Diversity2TwoTone";
import MultiBannerIcon from "@mui/icons-material/PermMediaTwoTone";
import StoreIcon from "@mui/icons-material/StoreTwoTone";
import RolesIcon from "@mui/icons-material/WorkTwoTone";
import MembershipsIcon from "@mui/icons-material/PeopleAltTwoTone";
import LibraryIcon from "@mui/icons-material/LibraryBooksTwoTone";
import EventIcon from "@mui/icons-material/EventAvailableTwoTone";
import EmailIcon from "@mui/icons-material/EmailTwoTone";
import PinsIcon from "@mui/icons-material/PushPinTwoTone";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Image from "react-bootstrap/Image";
import InputGroup from "react-bootstrap/InputGroup";
import Swal from 'sweetalert2'

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
  variants: [
    {
      props: ({ expand }) => !expand,
      style: {
        transform: "rotate(0deg)",
      },
    },
    {
      props: ({ expand }) => !!expand,
      style: {
        transform: "rotate(180deg)",
      },
    },
  ],
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  ...theme.applyStyles("dark", {
    backgroundColor: "#1A2027",
  }),
}));

function handleAction(row) {
  alert(row.id);
}

const PavilionSettings = (props) => {
  const location = useLocation();
  const data = location.state;

  const splitURL = location.pathname.toString().split("/");

  const [pavilions, setPavilions] = useState([]);
  const [rows, setRows] = useState(0);
  const [counter, setCounter] = useState(0);
  const [pavilionName, setPavilionName] = useState("");
  const [stripurl, setstripurl] = useState("");
  
  

  const navigate = useNavigate();
  const [isLinked, setIsLinked] = useState(false); // Track link status
  const [accountLink, setAccountLink] = useState('');
  const [loading, setLoading] = useState(true);

  const [expanded, setExpanded] = React.useState(false);

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to="/admin/dashboard">
      Dashboard
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      to="/admin/pavilions"
      state={data}
    >
      Pavilions
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      to={`/admin/pavilions/${splitURL[3]}`}
      state={data}
    >
      {data.name}
    </Link>,
    <Typography key="3" sx={{ color: "text.primary" }}>
      Settings
    </Typography>,
  ];

  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const [error, setError] = useState(null);
  const [settings, setSettings] = useState({
    hide_default_event_categories: '1', // Default value
    // Initialize other properties as needed
  });

  useEffect(() => {
    adminhttp()
      .get(`/admin/settingspavilion/${splitURL[3]}`)
      .then((response) => {
        // Check if the response indicates success
        const resultData = response.data.data.result;
        const stripeurlData = response.data.data.stripeurl;
        setstripurl(response.data.data.stripeurl)
        if(stripeurlData){
          setIsLinked(true);
        }else{
          setIsLinked(false);

        }
      



        // Convert the array of key-value pairs into an object for easier access
        const settingsObject = resultData.reduce((acc, { key_name, key_value }) => {
          acc[key_name] = key_value;
          return acc;
        }, {});
        setSettings(settingsObject);
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
        setError('Error fetching data');
      });

      



  }, []);


  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission
    // Define any additional value you want to send
    const extraValue = `${splitURL[3]}`; // Replace this with your actual value or state

    // Create a payload that includes both settings and the extra value
    const payload = {
      ...settings, // Spread existing settings
      id: extraValue // Add the extra value
    };

    adminhttp()
      .post(`/admin/settings_update/${splitURL[3]}`, payload)
      .then((response) => {
        // Handle success (you might want to show a success message)
        // console.log('Settings saved successfully', response.data);
        Swal.fire({
          title: "Setting is saved successfully!",
          icon: "success",
          allowOutsideClick: false,
          confirmButtonColor: "#e30613",
        }).then((result) => {
        });	
      })
      .catch((err) => {
        console.error("Error saving settings:", err);
        setError('Error saving settings');
      });
  };
  const linkAccount = () => {
  const dataVal = { pavilion_id: `${splitURL[3]}` }; // Your payload
      adminhttp()
        .post(`/admin/link_account`, dataVal)
        .then((response) => {
          console.log('Account linked successfully:', response.data);
          if (response.data) {       
            setIsLinked(true);
          Swal.fire({
            title: "Link is updated successfully!",
            icon: "success",
            allowOutsideClick: false,
            confirmButtonColor: "#e30613",
          }).then((result) => {
            window.location.reload();
          });	

        } else {
            setError('Failed to retrieve account link.');
        }
           // Update link status to true
          // You might want to display a success message or handle redirection
        })
        .catch((error) => {
          console.error("Error linking account:", error);
          // Handle error (display error message, etc.)
        });
      }

  const deLinkAccount = () => {
    const data = { pavilion_id: `${splitURL[3]}` }; // Your payload
    adminhttp()
      .post(`/admin/relink_account`, data)
      .then((response) => {
        console.log('Account de-linked successfully:', response.data);
        Swal.fire({
          title: "Link is updated successfully!",
          icon: "success",
          allowOutsideClick: false,
          confirmButtonColor: "#e30613",
        }).then((result) => {
          window.location.reload();
        });	
      })
      .catch((error) => {
        console.error("Error de-linking account:", error);
        // Handle error (display error message, etc.)
      });
  };

  return (
    <AdminLayout>
      <Container fluid className="pb-5">
        <Box component="section" sx={{ px: 2, pt: 2, pb: 1 }}>
          <AdminBreadCrumbs breadcrumbs={breadcrumbs} />
        </Box>

        <Box component="section" sx={{ px: 2, pt: 0, pb: 1 }}>
          <h4> Settings </h4>
        </Box>
        <ListGroup variant="flush" className="bg-body rounded-2 shadow-sm mx-3">
          <ListGroup.Item className="bg-transparent">
            <>
              <Button
                type="submit"
                variant="primary"
                className="fw-bold position-absolute end-0 me-3"
                onClick={handleSubmit} // Call handleSubmit on button click
              >
                Save Settings
              </Button>
              <Tabs
                defaultActiveKey="Settings"
                id="uncontrolled-tab-example"
                className="mb-3 mx-0"
              >
                <Tab eventKey="Settings" title="Settings">
                  <Form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Form.Group className="mb-0 text-start" controlId="videoTypeSelect">
                          <Form.Label>Videos Type</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            value={settings['link_type_vidoes'] || ''} // Set the selected value from settings
                            onChange={(e) => setSettings({ ...settings, link_type_vidoes: e.target.value })} // Update settings on change
                          >
                            <option value="">Select Video Type</option>
                            <option value="playlist">Playlist</option>
                            <option value="channel">Channel</option>
                          </Form.Select>
                        </Form.Group>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Form.Group
                          className="mb-0 text-start"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>Videos Link</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Videos Link"
                            value={settings['link_vidoes'] || ''}
                            onChange={(e) => setSettings({ ...settings, link_vidoes: e.target.value })}
                          />

                        </Form.Group>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Form.Group className="mb-0 text-start" controlId="photosLinkInput">
                          <Form.Label>Photos Link</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Photos Link"
                            value={settings['link_photos'] || ''}
                            onChange={(e) => setSettings({ ...settings, link_photos: e.target.value })}
                          />
                        </Form.Group>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Form.Group className="mb-0 text-start" controlId="albumLinkInput">
                          <Form.Label>Album Link</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Album Link"
                            value={settings['link_album'] || ''}
                            onChange={(e) => setSettings({ ...settings, link_album: e.target.value })}
                          />
                        </Form.Group>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Form.Group className="mb-0 text-start" controlId="vimeoFolderIdInput">
                          <Form.Label>Vimeo Folder Id</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Vimeo Folder Id"
                            value={settings['vimeo_folder_id'] || ''}
                            onChange={(e) => setSettings({ ...settings, vimeo_folder_id: e.target.value })}
                          />
                        </Form.Group>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Form.Group className="mb-0 text-start" controlId="podcastLinkInput">
                          <Form.Label>Podcast Link</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Podcast Link"
                            value={settings['link_podcast'] || ''}
                            onChange={(e) => setSettings({ ...settings, link_podcast: e.target.value })}
                          />
                        </Form.Group>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Form.Group className="mb-0 text-start" controlId="twitterLinkInput">
                          <Form.Label>Twitter Link</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Twitter Link"
                            value={settings['twitter_link'] || ''}
                            onChange={(e) => setSettings({ ...settings, twitter_link: e.target.value })}
                          />
                        </Form.Group>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Form.Group className="mb-0 text-start" controlId="linkedinLinkInput">
                          <Form.Label>Linkedin Link</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Linkedin Link"
                            value={settings['linkedin_link'] || ''}
                            onChange={(e) => setSettings({ ...settings, linkedin_link: e.target.value })}
                          />
                        </Form.Group>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Form.Group className="mb-0 text-start" controlId="youtubeLinkInput">
                          <Form.Label>Youtube Link</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Youtube Link"
                            value={settings['youtube_link'] || ''}
                            onChange={(e) => setSettings({ ...settings, youtube_link: e.target.value })}
                          />
                        </Form.Group>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Form.Group className="mb-0 text-start" controlId="facebookLinkInput">
                          <Form.Label>Facebook Link</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Facebook Link"
                            value={settings['fb_link'] || ''}
                            onChange={(e) => setSettings({ ...settings, fb_link: e.target.value })}
                          />
                        </Form.Group>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Form.Group className="mb-0 text-start" controlId="hideDefaultEventCategoriesSelect">
                          <Form.Label>Hide Default Event Categories</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            value={settings['hide_default_event_categories'] || '1'} // Ensure this is correctly pointing to the state
                            onChange={(e) => setSettings({ ...settings, hide_default_event_categories: e.target.value })} // Update settings on change
                          >
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                          </Form.Select>
                        </Form.Group>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Form.Group className="mb-0 text-start" controlId="copyrightTextInput">
                          <Form.Label>Copyright Text Link</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Copyright Text"
                            value={settings['copy_right'] || ''}
                            onChange={(e) => setSettings({ ...settings, copy_right: e.target.value })}
                          />
                        </Form.Group>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Form.Group className="mb-0 text-start" controlId="bannerOverlayColorInput">
                          <Form.Label htmlFor="bannerOverlayColor">Banner Overlay Color</Form.Label>
                          <Form.Control
                            type="color"
                            id="bannerOverlayColor"
                            value={`#${settings['overlay_color'] || 'FFFFFF'}`}
                            onChange={(e) => setSettings({ ...settings, overlay_color: e.target.value.replace('#', '') })}
                          />
                        </Form.Group>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Form.Group className="mb-0 text-start" controlId="cardOverlayColorInput">
                          <Form.Label htmlFor="cardOverlayColor">Pavilion Card Overlay Color</Form.Label>
                          <Form.Control
                            type="color"
                            id="cardOverlayColor"
                            value={`#${settings['card_overlay_color'] || 'FFFFFF'}`}
                            onChange={(e) => setSettings({ ...settings, card_overlay_color: e.target.value.replace('#', '') })}
                          />
                        </Form.Group>
                      </Grid>

                    </Grid>


                    {/* <Form.Group
                      className="mb-3 text-center"
                      controlId="exampleForm.ControlInput1"
                    >
                        <Button type="submit" variant='primary' className="fw-bold w-25">Save Information</Button>
                    </Form.Group> */}
                  </Form>
                </Tab>
                <Tab eventKey="payment" title="Payment">
                  <div class="m-portlet__body">
                    <p>
                      Please follow the below steps to link your stripe account
                      with the pavilion account.
                    </p>
                    <ul>
                      <li>
                        Go to Stripe website:{" "}
                        <a href="https://stripe.com/" target="_blank">
                          Visit Stripe
                        </a>
                      </li>
                      <li>Create an account on the stripe.</li>

                      <li>
                        Click below Link Account button and you will get
                        onboarding link.
                      </li>
                      <li>
                        Just click on the link and complete the account linking
                        process on stripe.
                      </li>
                    </ul>
                    <p>
                      <strong>Note: </strong> Generated link is onetime link and
                      once you open you have to complete all details.
                      Otherwise you need to click on De-link account and then
                      link account button to get new link.
                    </p>

                    <div class="m-form__actions">
                      <div class="row">
                        <div class="col-lg-12">
                        <h3>Account OnBoarding</h3>
                        {isLinked ? (
                            <>
                              <p>Please follow this link and complete your on-boarding process with Stripe</p>
                              <a href="{stripurl}" target="_blank" id="link_url">{stripurl}</a>
                              <p></p>
                              <Button variant="primary" onClick={deLinkAccount}>
                                De-Link Account
                              </Button>
                            </>
                          ) : (
                            <Button variant="primary" onClick={linkAccount}>
                              Link Account
                            </Button>
                          )}
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </>
          </ListGroup.Item>
        </ListGroup>
      </Container>
    </AdminLayout>
  );
};

export default PavilionSettings;
