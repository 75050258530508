// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xpWfed, .DE3NNc{display:none!important}
iframe html{overflow:hidden}

footer div:first-child{display:none}
.icontainer {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 100%; /* 1:1 Aspect Ratio */
  }
  
  .responsive-iframe {
    
    width: 100%;
    border: none;
  }`, "",{"version":3,"sources":["webpack://./src/survey.css"],"names":[],"mappings":"AAAA,iBAAiB,sBAAsB;AACvC,YAAY,eAAe;;AAE3B,uBAAuB,YAAY;AACnC;IACI,kBAAkB;IAClB,WAAW;IACX,gBAAgB;IAChB,iBAAiB,EAAE,qBAAqB;EAC1C;;EAEA;;IAEE,WAAW;IACX,YAAY;EACd","sourcesContent":[".xpWfed, .DE3NNc{display:none!important}\niframe html{overflow:hidden}\n\nfooter div:first-child{display:none}\n.icontainer {\n    position: relative;\n    width: 100%;\n    overflow: hidden;\n    padding-top: 100%; /* 1:1 Aspect Ratio */\n  }\n  \n  .responsive-iframe {\n    \n    width: 100%;\n    border: none;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
