import { useState, useEffect } from "react";

const ResponsiveImage = ({ imageFileName, imageFolders })=>{
    const [imageSize, setImageSize] = useState("");
     useEffect(() => {
        const screenWidth = window.innerWidth;
        // alert(window.innerWidth)
        if (screenWidth>= 3840) { setImageSize("3840x2160"); // 4K screens
        } else if (screenWidth >= 2560) { setImageSize("2560x1440");  //2K screens 
        } else if (screenWidth >= 1920) { setImageSize("1920x1080"); // 1080p screens 
        } else if (screenWidth >= 1366) { setImageSize("1366x768"); // Laptops/Tablets
        }else{ setImageSize("720x480"); // Mobile 
        }
     }, [])
    const imageUrl = `https://d4cnu9kz2l247.cloudfront.net/${imageFolders}/${imageSize}/${imageFileName}`;

return <img src={imageUrl} alt="Product" style={{ width: "100%", height: "auto" }} />;

} 


export default ResponsiveImage;
