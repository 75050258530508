import React, { useState, useEffect } from "react";
import AdminLayout from "../layouts/AdminLayout";
import { styled } from "@mui/material/styles";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Navigate, Link, useNavigate } from "react-router-dom";
import AdminUserCard from "../components/AdminCards/AdminUserCard";
import {
  Form,
  Container,
  Button,
  Stack,
  ListGroup,
  ButtonGroup,
  Row,
  Col,
  Image,
} from "react-bootstrap";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
// import Card from "react-bootstrap/Card";
import BadgeAvatar from "../../components/BadgeAvatar";
import Grid from "@mui/material/Grid";
import AdminBreadCrumbs from "../components/AdminBreadCrumbs";
import Card from "@mui/material/Card";
import { CardMedia } from "@mui/material";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Badge from "@mui/material/Badge";
import adminhttp from "../../adminhttp";
import Spinner from "react-bootstrap/Spinner";
import { Pagination, Typography } from "@mui/material";
import AddAdminUser from "../components/AdminForms/AddAdminUser";
import { ExportToExcel } from "../components/ExportToExcel";
import { formatDate } from '../../components/PavilionConstants';
import Alert from "@mui/material/Alert";
// import {getPermissions} from '../utils/utils'
import Swal from 'sweetalert2'

// const columns = [
//   { field: "id", headerName: "ID", width: 50 },
//   {
//     field: "member_name",
//     headerName: "Member Name",
//     editable: true,
//   },
//   {
//     field: "email",
//     headerName: "Email",
//     editable: true,
//   },
//   {
//     field: "created_at",
//     headerName: "Created at",
//     sortable: false,
//   },
//   {
//     field: "status",
//     headerName: "Active",
//     sortable: false,
//   },
//   {
//     field: "last_login_at",
//     headerName: "Last Login at",
//     editable: true,
//   },
//   {
//     field: "last_seen",
//     headerName: "Last Seen",
//     editable: true,
//   },
//   {
//     field: "online_status",
//     headerName: "Online Status",
//     editable: true,
//   },
//   {
//     field: "action",
//     headerName: "Action",
//     width: 200,
//     sortable: false,
//     renderCell: ({ row }) => (
//       <ButtonGroup>
//         <Button
//           variant="primary_outline"
//           size="sm"
//           onClick={() => handleAction(row)}
//         >
//           Active
//         </Button>
//         <Button
//           variant="primary_outline"
//           size="sm"
//           onClick={() => handleAction(row)}
//         >
//           InActive
//         </Button>
//         <Button
//           variant="primary_outline"
//           size="sm"
//           onClick={() => handleAction(row)}
//         >
//           Edit
//         </Button>
//       </ButtonGroup>
//     ),
//   },
// ];

// const rows = [
//   {
//     id: 1,
//     member_name: "Basic",
//     email: "info@gmail.com	",
//     created_at: "15-02-2024",
//     status: "Active",
//     last_login_at: "2023-03-02 15:02:46",
//     last_seen: "1 year ago",
//     online_status: "offline",
//     action: "",
//   },
//   {
//     id: 2,
//     member_name: "Basic",
//     email: "info@gmail.com	",
//     created_at: "15-02-2024",
//     status: "Active",
//     last_login_at: "2023-03-02 15:02:46",
//     last_seen: "1 year ago",
//     online_status: "offline",
//     action: "",
//   },
//   {
//     id: 3,
//     member_name: "Basic",
//     email: "info@gmail.com	",
//     created_at: "15-02-2024",
//     status: "Active",
//     last_login_at: "2023-03-02 15:02:46",
//     last_seen: "1 year ago",
//     online_status: "offline",
//     action: "",
//   },
//   {
//     id: 4,
//     member_name: "Basic",
//     email: "info@gmail.com	",
//     created_at: "15-02-2024",
//     status: "Active",
//     last_login_at: "2023-03-02 15:02:46",
//     last_seen: "1 year ago",
//     online_status: "offline",
//     action: "",
//   },
//   {
//     id: 5,
//     member_name: "Basic",
//     email: "info@gmail.com	",
//     created_at: "15-02-2024",
//     status: "Active",
//     last_login_at: "2023-03-02 15:02:46",
//     last_seen: "1 year ago",
//     online_status: "offline",
//     action: "",
//   },
// ];

// function handleAction(row) {
//   alert(row.id);
// }
const AdminUsers = () => {
const [permissions, setPermissions]=useState(JSON.parse(localStorage.getItem('permissions')));
  const navigate=useNavigate();

  const [exportData, setExportData] = useState([]);
  const today = new Date();
  const fileName = `Admin Users List ${formatDate(today)}`; // here enter filename for your excel file

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [users, setUsers] = useState([]);
  const handleChange = (event, value) => {
    setPage(value);
  };

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to="/admin/dashboard">
      Dashboard
    </Link>,
    <Link underline="hover" key="1" color="inherit" to="/admin/adminusers">
      Admin Users
    </Link>,
    <Typography key="3" sx={{ color: "text.primary" }}>
      {page}
    </Typography>,
  ];

  const [showModal, setShowModal] = useState(false);
  const [editAdminUser, setEditAdminUser] = useState(false);
  const [adminUserID, setAdminUserID] = useState();
  
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);

  function handleAddAdminUser() {
    setShowModal(true);
    setEditAdminUser(false);
  }

  const handleEdit = (id) => (e) => {
    setAdminUserID(id);
    setEditAdminUser(true);
    setShowModal(true);
  };
  const showAlert = () => {
    
    if(Object.values(permissions).length>0){
    Swal.fire({
    title: "Permission Denied",
    text: "",
    icon: "warning",
    confirmButtonColor: "#d33",
  }).then((result) => {
    if (result.isConfirmed) {
        navigate('/admin/dashboard');
    }
  })}
  }
  const handleRequest = () => {
    adminhttp().get(`/admin/users?page=${page}`).then((data) => {
        setLoading(true);
        if(data.data.data.users.data.length>0){
          setUsers(data.data.data.users.data);
          setTotalPages(data.data.data.users.last_page);
          setLoading(false)
        } else {
          setNoData(true)
          setLoading(false)
        }
      });
  };

  useEffect(() => {
    handleRequest();
  }, [page]);

  useEffect(() => {
    const fetchData = () =>{
      adminhttp().get(`/admin/users`).then((data) => {
      // reshaping the array
     const customHeadings = data.data.data.users.map(user=>({
      "User Id": user.user_id,
      "User Name": user.first_name + ' ' + user.last_name,
      "Email": user.email,
    }))
    setExportData(customHeadings) 
     })
    }
    fetchData()
  }, [])  
 
  
  // useEffect(() => {
  //   getPermissions().then((res)=> (setPermissions(res)))
  // },[]);
  
  return (
    <>
      <AdminLayout>
      {loading && <Box display="flex" flexDirection="column" justifyContent="center" alignItems='center' height='100%'>
      <Spinner size='lg' className='me-2 ' animation='border' />
      </Box>}
      
        {(Object.values(permissions).length>0 && permissions['view-users']) ? 
        <Container
          fluid
          className="bg-light pb-5"
          style={{ minHeight: "100vh" }}
        >
          <Box component="section" sx={{ px: 2, pt: 2, pb: 1 }}>
            <AdminBreadCrumbs breadcrumbs={breadcrumbs} />
          </Box>
          <Box
            component="section"
            className="d-flex align-items-center justify-content-between header-menu"
            sx={{ px: 2, pt: 0, pb: 1 }}
          >
            <h4>Admin Users</h4>
            <div className="d-flex justify-content-end align-items-center">
              <Form className="me-2">
                <Form.Control
                  type="search"
                  size="small"
                  placeholder="Search Admin Users"
                />
              </Form>
          
              {/* <Box sx={{ alignItems: "center" }}>
                <Pagination
                  className="mt-2 mb-0 pb-0"
                  count={totalPages}
                  page={page}
                  variant="outlined"
                  shape="rounded"
                  onChange={handleChange}
                />
              </Box> */}
              {(permissions['create-users']) && (
              <Button variant="primary" type="button" size='sm' className="w-auto me-2" onClick={handleAddAdminUser}>
                Add Admin User
              </Button>
              )}
              {(permissions['export-users']) && (
              <ExportToExcel apiData={exportData} fileName={fileName}/>
              )}
            </div>
          </Box>
          <Container fluid className='text-center'>
            {loading===true && (<Spinner size='lg' className='me-2 ' animation='border' />)}
            {loading===false && (<>
            <Row xs={1} md={3} className="g-4">
              {users.map((user, index) => (
                <Col key={index}>
                  <AdminUserCard
                    data={user}
                    handleEdit={handleEdit}
                    handleRequest={handleRequest}
                  />
                </Col>
              ))}
            </Row>
            {/* {users.slice(perPage*(page-1), perPage*page).map((user, index) =>  (   */}
            <Box
              sx={{
                margin: "auto",
                width: "fit-content",
                alignItems: "center",
              }}
            >
              <Pagination
                className="my-3"
                count={totalPages}
                page={page}
                variant="outlined"
                shape="rounded"
                onChange={handleChange}
              />
            </Box></>
            )}
            {noData && (
                <Box
                  sx={{ flexGrow: 1 }}
                  className="d-flex justify-content-center"
                >
                  <Alert variant="outlined" severity="error">
                    Users not found
                  </Alert>
                </Box>
              )}
          </Container>
        </Container> : (showAlert())}
      </AdminLayout>
      <AddAdminUser
        handleRequest={handleRequest}
        show={showModal}
        close={() => setShowModal(false)}
        edit={editAdminUser}
        adminUserID={adminUserID}
      />
    </>
  );
};

export default AdminUsers;
