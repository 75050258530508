import React, { useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import LockResetIcon from '@mui/icons-material/LockReset';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import { deepOrange, deepPurple } from '@mui/material/colors';
import Dropdown from 'react-bootstrap/Dropdown';
import Badge from 'react-bootstrap/Badge';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PasswordIcon from '@mui/icons-material/Password';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import LogoutIcon from '@mui/icons-material/Logout';
import BadgeAvatar from '../../components/BadgeAvatar';
import { Link } from 'react-router-dom';

function AdminHeader(props) {
    const [isIconClicked, setIsIconClicked] = useState(true);
    const [show, setShow] = useState(props.isLoggedIn);

    const handleDarkMode = (event) => {
      setIsIconClicked(!isIconClicked);
  
      const thm = document.documentElement.getAttribute('data-bs-theme');
      const appId = document.getElementById('app-theme');
      const thm2 =appId.getAttribute('data-bs-theme');
      const thmToggler = event.currentTarget;
       const theme= localStorage.getItem("data-bs-theme");
      // thmToggler.innerHTML='<small>{theme}</small>';

  
      if(thm2==='light'){
        appId.setAttribute('data-bs-theme','dark');
        document.documentElement.setAttribute("data-bs-theme","dark");
        localStorage.setItem("data-bs-theme", "dark");
       // thmToggler.innerHTML='<small>LIGHT MODE<small>';
      } else{
         appId.setAttribute('data-bs-theme','light');
         document.documentElement.setAttribute("data-bs-theme","light");
         localStorage.setItem("data-bs-theme", "light");
        // thmToggler.innerHTML='<small>DARK MODE</small>';
      }
    
    }

    return (
        <nav className="navbar navbar-default" style={{padding:'15px 10px'}}>
                    <div className="container-fluid">
                        <div className="navbar-header">
                            <button type="button" id="sidebarCollapse" className="btn btn-secondary navbar-btn">
                                <i className="glyphicon glyphicon-align-left"></i>
                                <span>Toggle Sidebar</span>
                            </button>
                        </div>

                        <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                            <ul className="nav navbar-nav navbar-right">
                                <li><a href="#">Page</a></li>
                                <li><a href="#">Page</a></li>
                                <li><a href="#">Page</a></li>
                                <li><a href="#">Page</a></li>
                            </ul>
                        </div>
                    </div>
                </nav>
    )
}

export default AdminHeader
