import React, { useState, useEffect } from "react";
import AdminLayout from "../../layouts/AdminLayout";
import { Form, Container, ListGroup } from "react-bootstrap";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import adminhttp from "../../../adminhttp";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
// import Stack from '@mui/material/Stack'
import Stack from "react-bootstrap/Stack";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import { ExportToExcel } from "../../components/ExportToExcel";
// import Card from '@mui/material/Card';
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AdminBreadCrumbs from "../../components/AdminBreadCrumbs";
import { Link } from "react-router-dom";
// import Badge from '@mui/material/Badge';
import Badge from "react-bootstrap/Badge";
import { formatDate } from "../../../components/PavilionConstants";
import DateIcon from "@mui/icons-material/InsertInvitation";
import Dropdown from "react-bootstrap/Dropdown";
import { Row, Col, Modal, Button } from "react-bootstrap";
// import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation } from "react-router-dom";
import EditIcon from "@mui/icons-material/DriveFileRenameOutlineTwoTone";
import SettingsIcon from "@mui/icons-material/SettingsTwoTone";
import MenusIcon from "@mui/icons-material/ListAltTwoTone";
import SponsorsIcon from "@mui/icons-material/Diversity2TwoTone";
import MultiBannerIcon from "@mui/icons-material/PermMediaTwoTone";
import StoreIcon from "@mui/icons-material/StoreTwoTone";
import RolesIcon from "@mui/icons-material/WorkTwoTone";
import MembershipsIcon from "@mui/icons-material/PeopleAltTwoTone";
import LibraryIcon from "@mui/icons-material/LibraryBooksTwoTone";
import EventIcon from "@mui/icons-material/EventAvailableTwoTone";
import EmailIcon from "@mui/icons-material/EmailTwoTone";
import PinsIcon from "@mui/icons-material/PushPinTwoTone";
import { useNavigate } from "react-router-dom";
import SponsorCard from "../../components/AdminCards/SponsorCard";
import Alert from "@mui/material/Alert";
import RoleCard from "../../components/AdminCards/RoleCard";
import MembershipCard from "../../components/AdminCards/MembershipCard";
import AddMembership from "../../components/AdminForms/AddMembership";
import Swal from "sweetalert2";
import TicketCard from "../../components/AdminCards/TicketCard";
import AddTicket from "../../components/AdminForms/AddTicket";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import MenuCard from "../../components/AdminCards/MenuCard";
import AdminUserRoleCard from "../../components/AdminCards/AdminUserRoleCard";
import AddAdminRole from "../../components/AdminForms/AddAdminRole";
import UsersCard from "../../components/AdminCards/UsersCard";
import UpdateRoleMembership from "../../components/AdminForms/UpdateRoleMembership";
import { Pagination } from "@mui/material";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
  variants: [
    {
      props: ({ expand }) => !expand,
      style: {
        transform: "rotate(0deg)",
      },
    },
    {
      props: ({ expand }) => !!expand,
      style: {
        transform: "rotate(180deg)",
      },
    },
  ],
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  ...theme.applyStyles("dark", {
    backgroundColor: "#1A2027",
  }),
}));

function handleAction(row) {
  alert(row.id);
}

const Users = (props) => {
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  const data = location.state;

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);


  const [exportData, setExportData] = useState([]);
  const today = new Date();
  const fileName = `User & Roles List ${formatDate(today)}`; // here enter filename for your excel file

  const splitURL = location.pathname.toString().split("/");
  const [loading, setLoading] = useState(true);
  const [noUsers, setNoUsers] = useState(false);
  const [users, setUsers] = useState([]);
  const [pvlnName, setPvlnName] = useState("");
  const [rows, setRows] = useState(0);
  const [counter, setCounter] = useState(0);
  const [pavilionName, setPavilionName] = useState("");
  const navigate = useNavigate();
  const [searchResult, setSearchResult] = useState("");

  const [value, setValue] = useState("");
  const [filter, setFilter] = useState("0");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [editRoleMembership, setEditRoleMembership] = useState(false);
  const [userID, setUserID] = useState();

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to="/admin/dashboard">
      Dashboard
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      to="/admin/pavilions"
      state={data}
    >
      Pavilions
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      to={`/admin/pavilions/${splitURL[3]}`}
      state={data}
    >
      {data.name}
    </Link>,
    <Typography key="3" sx={{ color: "text.primary" }}>
      Users
    </Typography>,
  ];

  function handleClick(event) {
    event.preventDefault();
    console.info("You clicked a breadcrumb.");
  }

  const handlePagination = (event, value) => {
    setPage(value);
    fetchData(value, searchResult); // Include search term in the pagination request
  };
  const handleSearch = (e) => {
    // setValue(e.target.value);
    // adminhttp()
    //   .get(
    //     `/admin/search_membership?search=${e.target.value}&pavilion_id=${splitURL[3]}`
    //   )
    //   .then((data) => {
    //     // alert(data.data.data.length)
    //     if (data.data.data.length > 0) {
    //       setUsers(data.data.data);
    //     }
    //   });
    const keyword = e.target.value;
    setSearchResult(keyword);
    setPage(1); // Reset to the first page when a new search is performed
    fetchData(1, keyword); // Fetch results with the new search term

  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   const form = e.currentTarget;
  //   const name = form.name.value;
  //   const price = form.price.value;
  //   const productType = form.producttype.value;
  //   const description = form.description.value;
  //   const status = form.status.value;
  //   const membershipplan = form.membershipplan.value;
  //   const image = form.imagefilen.value;
  //   if (form.checkValidity()) {
  //     adminhttp()
  //       .post("/admin/add_membership", {
  //         name: name,
  //         pavilion_id: splitURL[3],
  //         price: price,
  //         product_type: productType,
  //         description: description,
  //         status: status,
  //         membership_plan: membershipplan,
  //         image: image,
  //       })
  //       .then((res) => {
  //         Swal.fire({
  //           title: "Membership added successfully!",
  //           icon: "success",
  //           allowOutsideClick: false,
  //           confirmButtonColor: "#e30613",
  //         }).then((result) => {
  //           if (result["isConfirmed"]) {
  //             handleClose();
  //             handleRequest();
  //           }
  //         });
  //       });
  //   }
  // };

  // function handleAddAdminRole() {
  //   setShowModal(true);
  //   setEditAdminRole(false);
  // }

  const handleEdit = (id) => (e) => {
    setUserID(id);
    setEditRoleMembership(true);
    setShowModal(true);
  };

  const handleRequest = () => {

    adminhttp().get(`/admin/pavilionsUsersRoles/${splitURL[3]}?page=${page}`).then((data) => {
      if (data.data.data.users.data.length > 0) {
        setLoading(true)
        setUsers(data.data.data.users.data);
        setTotalPages(data.data.data.users.last_page)
        setLoading(false)
      } else {
        setNoUsers(true)
      }
    });

  };

  // useEffect(() => {
  //   handleRequest();
  // }, [page]);

  // Fetch data based on page and searchResult
  const fetchData = (currentPage = 1, keyword = "") => {
    setLoading(true);
    adminhttp()
      .get(`/admin/pavilionsUsersRoles/${splitURL[3]}?page=${currentPage}&searchrestult=${keyword}`)
      .then((response) => {
        if (response.data.data.users.data.length > 0) {
          setUsers(response.data.data.users.data);
          setTotalPages(response.data.data.users.last_page);
        } else {
          setUsers([]);
        }
        setLoading(false);

      })
      .catch((error) => {
        console.error("Error fetching users:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    // const fetchData = () => {
    //   adminhttp()
    //     .get(`/admin/pavilionsUsersRoles/${splitURL[3]}`)
    //     .then((data) => {
    //       // reshaping the array
    //       if (data.data.data.users.data.length > 0) {
    //         const customHeadings = data.data.data.users.data.map((user) => ({
    //           // "Membership Id": user.membership_id,
    //           "Name": user.name,
    //           "Email": user.email,
    //           "Role Name": user.roleName,
    //           // "Pavilion Name": data.name,
    //           // "Designation": user.designation,
    //           // "Organization": user.organization,
    //           "Created at": user.created_at,
    //         }));
    //         setExportData(customHeadings);
    //       }
    //     });
    // };
    handleRequest();
    fetchData(page, searchResult);
    adminhttp()
      .get(`/admin/pavilionsUsersRoles/${splitURL[3]}`)
      .then((data) => {
        // reshaping the array
        if (data.data.data.users.data.length > 0) {
          const customHeadings = data.data.data.users.data.map((user) => ({
            // "Membership Id": user.membership_id,
            "Name": user.name,
            "Email": user.email,
            "Role Name": user.roleName,
            // "Pavilion Name": data.name,
            // "Designation": user.designation,
            // "Organization": user.organization,
            "Created at": user.created_at,
          }));
          setExportData(customHeadings);
        }
      });

  }, []);
  return (
    <>
      <AdminLayout>
        <Container fluid className="bg-light">
          <Box component="section" sx={{ px: 2, pt: 2, pb: 1 }}>
            <AdminBreadCrumbs breadcrumbs={breadcrumbs} />
          </Box>

          <Box
            component="section"
            className="d-flex align-items-center justify-content-between header-menu"
            sx={{ px: 2, pt: 0, pb: 1 }}
            data-aos="fade-up"
          >
            <h4>Users</h4>
            <div className="d-flex justify-content-end align-items-center">
              <Form className="me-2">
                <Form.Control
                  type="text"
                  size="small"
                  onChange={handleSearch}
                  placeholder="User Search"
                />
              </Form>
              <ExportToExcel apiData={exportData} fileName={fileName} />
              {/* <Button
                variant="primary"
                type="button"
                className="w-auto"
                onClick={handleAddAdminRole}
              >
                Add Role
              </Button> */}
              {/* <Box sx={{ alignItems: "center",}}>
                <Pagination className='my-3' count={totalPages} page={page}  variant="outlined" shape="rounded" onChange={handleChange}/>
           </Box> */}
            </div>
          </Box>

          <ListGroup variant="flush">
            <ListGroup.Item className="bg-transparent">
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  "& > :not(style)": {
                    m: 0,
                    p: 2,
                    width: "100%",
                    height: "auto",
                  },
                }}
              >
                <Paper
                  elevation={3}
                  className="bg-secondary py-3 mb-3 rounded-4"
                >
                  <Grid container spacing={2}>
                    <Grid item xs={6} md={3}>
                      <Typography
                        variant="body"
                        component="p"
                        className="mt-1 text-light"
                      >
                        User Name
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <Typography
                        variant="body"
                        component="p"
                        className="mt-1 text-light"
                      >
                        Email
                      </Typography>
                    </Grid>
                    {/* <Grid
                      item
                      xs={6}
                      md={1}
                      className="d-flex align-items-center"
                    >
                      <Typography
                        variant="body"
                        component="p"
                        className="text-light"
                      >
                        Designation
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={1}
                      className="d-flex align-items-center"
                    >
                      <Typography
                        variant="body"
                        component="p"
                        className="text-light"
                      >
                        Organization
                      </Typography>
                    </Grid> */}
                    <Grid
                      item
                      xs={6}
                      md={2}
                      className="d-flex align-items-center"
                    >
                      <Typography
                        variant="body"
                        component="p"
                        className="text-light"
                      >
                        Role
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={2}
                      className="d-flex align-items-center"
                    >
                      <Typography
                        variant="body"
                        component="p"
                        className="text-light"
                      >
                        Membership
                      </Typography>
                    </Grid>

                    <Grid item xs={6} md={2} className="d-flex align-items-center justify-content-end">
                      <Typography variant="body" component="p" className="text-light">
                        Actions
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Box>
              {loading ? (
                <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
                  <Spinner size='lg' className='me-2 ' animation='border' />
                </Box>
              ) : (

                <Box sx={{ flexGrow: 1 }}>
                  <Grid
                    container
                    spacing={{ xs: 1, md: 1 }}
                    columns={{ xs: 1, md: 12 }}
                  >
                    {users.map((user, index) => (
                      <Grid item xs={12} md={12}>
                        <UsersCard
                          data={user}
                          handleRequest={handleRequest}
                          handleEdit={handleEdit}
                        />
                      </Grid>
                    ))}
                  </Grid>
                  {noUsers ? (
                    <Box
                      sx={{ flexGrow: 1 }}
                      className="d-flex justify-content-center"
                    >
                      <Alert variant="outlined" severity="error">
                        Users not found
                      </Alert>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        margin: "auto",
                        width: "fit-content",
                        alignItems: "center",
                      }}
                    >
                      {users.length > 0 ?
                        <Pagination
                          className="my-3 pb-0"
                          count={totalPages}
                          page={page}
                          variant="outlined"
                          shape="rounded"
                          onChange={handlePagination}
                        /> : 'No Records Found'}
                    </Box>
                  )}

                </Box>
              )}


            </ListGroup.Item>
          </ListGroup>
        </Container>
      </AdminLayout>
      <UpdateRoleMembership handleRequest={handleRequest} show={showModal} close={() => setShowModal(false)} edit={editRoleMembership} userID={userID} />
    </>
  );
};

export default Users;
