import React, {useEffect, useState} from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import { useNavigate } from "react-router-dom"
import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircle';
import DashboardIcon from '@mui/icons-material/SpaceDashboardTwoTone';

import PavilionIcon from '@mui/icons-material/AccountBalanceTwoTone';

import AdminIcon from '@mui/icons-material/AdminPanelSettingsTwoTone';

import PlansIcon from '@mui/icons-material/PollTwoTone';

import MembersIcon from '@mui/icons-material/PeopleAltTwoTone';

import SearchIcon from '@mui/icons-material/ScreenSearchDesktopTwoTone';

import TicketsIcon from '@mui/icons-material/LocalActivityTwoTone';
import AllIcon from '@mui/icons-material/AllInclusiveTwoTone';
import SettingsIcon from '@mui/icons-material/SettingsApplicationsTwoTone';
import PavilionCategoriesIcon from '@mui/icons-material/AssuredWorkloadTwoTone';
import WorkIcon from '@mui/icons-material/WorkTwoTone';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useLocation } from "react-router-dom";
import adminhttp from '../../adminhttp';
// import {getPermissions} from '../utils/utils'
import Swal from 'sweetalert2'
import Fade from '@mui/material/Fade';
import Stack from '@mui/material/Stack'
import LogoutIcon from '@mui/icons-material/MeetingRoomTwoTone';
// const styles = theme => ({
//   listItemText:{
//     fontSize:'5rem',//Insert your required size
//   }
// });

// const useStyles = makeStyles((theme) => ({
//   icon: {
//       minWidth: '40px!important',
//       marginLeft:'20px',
//   }
// }));
// const Item = styled(Paper)(({ theme }) => ({
//   ...theme.typography.body2,
//   textAlign: 'center',
//   color: theme.palette.text.secondary,
//   height: 60,
//   lineHeight: '60px',
// }));


export default function AdminSideMenu(props) {
  const location = useLocation();
  const data = location.state;

  

const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const theme2 = localStorage.getItem("data-bs-theme");
  const darkTheme = createTheme({ palette: { mode: 'dark' } });
  const lightTheme = createTheme({ palette: { mode: 'light' } });
  
 const navigate = useNavigate();
 const handleExpand = () => {
   setOpen(!open);
};

  // useEffect(() => {
  //    getPermissions().then((res)=> (setPermissions(res)))
  // //   adminhttp()
  // //    .get(`admin/admin_user_permissions_by_role/${localStorage.getItem('localstorage_role_id') ? localStorage.getItem('localstorage_role_id') : 'all'}`)
  // //    .then((data) => (setPermissions(data.data.data)) )
  //  },[]);

  // const handleClick = (permission) => {
  //   if(permissions[permission]){
  //     if(permission==='view-pavilions'){  navigate('/admin/pavilions') }
  //     if(permission==='view-users'){  navigate('/admin/adminusers') }
  //     if(permission==='index-role'){  navigate('/admin/roles') }
  //     if(permission==='view-members'){  navigate('/admin/members') }
  //     if(permission==='view-tickets'){  navigate('/admin/tickets') }
  //     if(permission==='edit-settings'){  navigate('/admin/settings') }
  //   } else {
  //     Swal.fire({
  //                 title: "Permission Denied",
  //                 icon: "info",
  //                 allowOutsideClick: false,
  //                 confirmButtonColor: "#e30613",
  //               })
  //   }
  // };

  return (
    <ThemeProvider theme={theme2==='light' ? lightTheme : darkTheme}>

    <List
      sx={{ width: '100%', maxWidth: 360 }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      // subheader={
      //   <ListSubheader component="div" id="nested-list-subheader">
      //     Nested List Items
      //   </ListSubheader>
      // }
    >
      <ListItemButton sx={{minHeight:'40px', maxHeight:'40px'}} onClick={()=>navigate("/admin/dashboard")}>
        <ListItemIcon style={{minWidth:'40px ',marginLeft:'15px'}}> 
          <DashboardIcon sx={{ color: "#e30613", }} fontSize='small' /> 
          </ListItemIcon>
        {!props.toggle && <ListItemText  primary="Dashboard"   />}
      </ListItemButton>
      {props.permissions['view-pavilions'] && ( 
      <ListItemButton sx={{minHeight:'40px', maxHeight:'40px'}}  onClick={(e)=> navigate('/admin/pavilions')}>
        <ListItemIcon style={{minWidth:'40px ',marginLeft:'15px'}}> 
          {/* <PavilionIcon sx={{ color: "#e30613", }} />  */}
          <Stack spacing={-2}className='d-flex align-items-end justify-content-end' >
      <PavilionIcon sx={{ color: "#e30613", }} fontSize="medium" />
      <PlaylistAddCheckCircleIcon className='text-primary rounded-5 ps-1' fontSize="small" sx={{ paintOrder: 'stroke fill',
stroke: "#ffffff", strokeWidth: 3, }}/>
</Stack>
          </ListItemIcon>
        {!props.toggle && <ListItemText primary="All Pavilions" />}
      </ListItemButton>
      )}
      {props.permissions['view-pavilions'] && ( 
      <ListItemButton sx={{minHeight:'40px', maxHeight:'40px'}}  onClick={()=>navigate("/admin/pavilioncats")}>
        <ListItemIcon style={{minWidth:'40px ',marginLeft:'15px'}}> <PavilionCategoriesIcon sx={{ color: "#e30613", }} /> </ListItemIcon>
        {!props.toggle && <ListItemText primary="Pavilion Categories"   />}
      </ListItemButton>
      )}
      {/* <ListItemButton onClick={handleExpand}>
        <ListItemIcon> <PavilionIcon /> </ListItemIcon>
        <ListItemText primary={props.toggle ? "" :  "Pavilions"} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 3 }} onClick={(e)=> navigate('/admin/pavilions')}>
            <ListItemIcon> <StarBorder /> </ListItemIcon>
            <ListItemText primary={props.toggle ? "" : "All Pavilions"} />
          </ListItemButton>
          <ListItemButton sx={{ pl: 3 }} onClick={()=>navigate("/admin/pavilioncats")}>
            <ListItemIcon> <StarBorder /> </ListItemIcon>
            <ListItemText primary={props.toggle ? "" :  "Pavilion Categories"} />
          </ListItemButton>
        </List>
      </Collapse> */}
      {props.permissions['view-users'] && ( 
      <ListItemButton sx={{minHeight:'40px', maxHeight:'40px'}} onClick={(e)=> navigate('/admin/adminusers')}>
        <ListItemIcon style={{minWidth:'40px ',marginLeft:'15px'}}> <AdminIcon sx={{ color: "#e30613", }} /> </ListItemIcon>
        {!props.toggle && <ListItemText primary="Admin Users"   />}
      </ListItemButton>
      )}
      {props.permissions['index-role'] && ( 
      <ListItemButton sx={{minHeight:'40px', maxHeight:'40px'}}  onClick={(e)=> navigate('/admin/roles')}>
        <ListItemIcon style={{minWidth:'40px ',marginLeft:'15px'}}> <WorkIcon sx={{ color: "#e30613", }}/> </ListItemIcon>
        {!props.toggle && <ListItemText primary="Admin User Roles" />}
      </ListItemButton>
      )}
      {props.permissions['view-plans'] && ( 
      <ListItemButton sx={{minHeight:'40px', maxHeight:'40px'}} onClick={()=>navigate("/admin/plans")}>
        <ListItemIcon style={{minWidth:'40px ',marginLeft:'15px'}}> <PlansIcon sx={{ color: "#e30613", }}/> </ListItemIcon>
        {!props.toggle && <ListItemText primary="Company Plans" />}
      </ListItemButton>
      )}
      {props.permissions['view-members'] && ( 
      <ListItemButton sx={{minHeight:'40px', maxHeight:'40px'}} onClick={(e)=> navigate('/admin/members')}>
        <ListItemIcon style={{minWidth:'40px ',marginLeft:'15px'}}> <MembersIcon sx={{ color: "#e30613", }}/> </ListItemIcon>
        {!props.toggle && <ListItemText primary="Members" />}
      </ListItemButton>
      )}
      {props.permissions['view-members'] && ( 
      <ListItemButton  sx={{minHeight:'40px', maxHeight:'40px'}} onClick={()=>navigate("/admin/seo")}>
        <ListItemIcon style={{minWidth:'40px ',marginLeft:'15px'}}> <SearchIcon sx={{ color: "#e30613", }}/> </ListItemIcon>
        {!props.toggle && <ListItemText primary="SEO Settings" />}
      </ListItemButton>
      )}
      {props.permissions['view-tickets'] && ( 
      <ListItemButton sx={{minHeight:'40px', maxHeight:'40px'}} onClick={(e)=> navigate('/admin/tickets')}>
        <ListItemIcon style={{minWidth:'40px ',marginLeft:'15px'}}> <TicketsIcon sx={{ color: "#e30613", }}/> </ListItemIcon>
        {!props.toggle && <ListItemText primary="Tickets Requests" />}
      </ListItemButton>
       )} 
      {/* <Collapse in={open1} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon> <StarBorder /> </ListItemIcon>
            <ListItemText primary={props.toggle ? "" :  "New Ticket"} />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon> <StarBorder /> </ListItemIcon>
            <ListItemText primary={props.toggle ? "" :  "In-Progress"} />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon> <StarBorder /> </ListItemIcon>
            <ListItemText primary={props.toggle ? "" :  "Completed"} />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon> <StarBorder /> </ListItemIcon>
            <ListItemText primary={props.toggle ? "" :  "Closed"} />
          </ListItemButton>
        </List>
</Collapse> */}
{props.permissions['edit-settings'] && ( 
    <ListItemButton sx={{minHeight:'40px', maxHeight:'40px'}}  onClick={(e)=> navigate('/admin/settings')}>
        <ListItemIcon style={{minWidth:'40px ',marginLeft:'15px'}}> <SettingsIcon sx={{ color: "#e30613", }}/> </ListItemIcon>
        {!props.toggle && <ListItemText primary="Company Settings" />}
      </ListItemButton>
)}
    </List>

    </ThemeProvider>

  );
}
