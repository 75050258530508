import React from "react";
import { styled } from '@mui/material/styles';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Link, useNavigate } from 'react-router-dom'
import { formatDate } from '../../../components/PavilionConstants';
import {
  Form,
  Container,
  Button,
  Stack,
  ListGroup,
  ButtonGroup,
  Row, Col, Image
} from "react-bootstrap";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
// import Card from "react-bootstrap/Card";
import BadgeAvatar from "../../../components/BadgeAvatar";
import Grid from "@mui/material/Grid";
import AdminBreadCrumbs from '../../components/AdminBreadCrumbs';
import Card from "@mui/material/Card";
import {CardMedia} from "@mui/material";
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
// import Badge from '@mui/material/Badge';
import Badge from 'react-bootstrap/Badge';

import { DateDiff } from "../../../components/PavilionConstants";
import CRUDDropdown from "../../../components/CRUDDropdown";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { Pagination, Typography } from "@mui/material";
import Swal from 'sweetalert2'
import adminhttp from "../../../adminhttp";
import Slide from '@mui/material/Slide';

const MemberCard = (props) => {
  const handleEdit = () => {
    //setShow(true)
  };
  const handleDelete = (e) => {
    e.stopPropagation();
    Swal.fire({
      title: "Are you sure to delete this Member?",
      text: "You won't be able to revert this!",
      icon: "warning",
      width: 'auto',
      showCancelButton: true,
      confirmButtonColor: "#e30613",
      cancelButtonColor: "#6c757d",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        adminhttp().post('/admin/destroy', {
          id:props.data.id,
      }).then((data) => {
        Swal.fire({
          title: "Member has been deleted.",
          icon: "success",
          allowOutsideClick: false,
          confirmButtonColor: "#e30613",
        }).then((result) => {
          if (result['isConfirmed']){
            props.handleRequest();
          }
        })
      });
        
      }
    });
};

const handleStatus = (e) => {
  e.stopPropagation();
  Swal.fire({
    title: `Are you sure to ${props.data.status===1 ? 'Deactive' : 'Activate'} this Member?`,
    text: "You won't be able to revert this!",
    icon: "warning",
    width: 'auto',
    showCancelButton: true,
    confirmButtonColor: "#e30613",
    cancelButtonColor: "#6c757d",
    confirmButtonText: `Yes, ${props.data.status===1 ? 'Deactive' : 'Activate'} it!`
  }).then((result) => {
    if (result.isConfirmed) {
      adminhttp().post('/admin/memberstatus', {
        user_id:props.data.id,
        status:props.data.status===1?0:1,
    }).then((data) => {
      Swal.fire({
        title: `Member has been ${props.data.status===1 ? 'Deactivated' : 'Activated'}.`,
        icon: "success",
        allowOutsideClick: false,
        confirmButtonColor: "#e30613",
      }).then((result) => {
        if (result['isConfirmed']){
          props.handleRequest();
        }
      });
    });
      
    }
  });
};
  const handleDropdown = () => {
    //setShow(true)
  };
  return (
    <Card className='rounded-3'   sx={{':hover': {boxShadow: 5},}} >
    <div style={{ position: "relative" }}>
      <CardMedia style={{ }}   component="img" image={"https://img.freepik.com/free-vector/colorful-watercolor-background_23-2148492944.jpg?size=626&ext=jpg&ga=GA1.1.942094041.1718883224&semt=ais_hybrid"} title="Pancakes" alt="Pancakes"/> 
      <div className='text-end position-absolute text-white top-0 start-50 p-2' style={{width:'100%',height:'100%',backgroundColor:'rgba(0,0,0,0.5)',transform: "translateX(-50%)",}}> 

      <CRUDDropdown
            title={<MoreVertIcon sx={{color:'#fff'}} fontSize="small" />}
            handleDropdown={handleDropdown}
            addTitle="Edit Member"
            addClick={props.handleEdit(props.data.id)}
            // editTitle="Delete"
            // editClick={handleDelete}
            deleteTitle={
              props.data.status === 1
                ? "Deactivate"
                : "Activate"
            }
            deleteClick={handleStatus}
          />
      <div className='h-100 d-flex flex-column align-items-center justify-content-center w-100'>
        <BadgeAvatar
                  src={props.data.image && (`${localStorage.getItem('bucketName')}/images/profile_picture/${props.data.image}`)}
                  name={props.data.name}
                  size="70"
                />    
                  <Typography variant='h5' className='mt-1 text-light fw-bold'>{props.data.name}</Typography>
                  <Typography variant='h6' className='text-light'>{props.data.email}</Typography>
        <ProgressBar now={60} variant='warning' style={{height:'5px' , width:'100%'}} className='w-75 my-3' />
            User Since: above {DateDiff(props.data.created_at)} 
        </div>
      </div>
  </div>
  <CardContent className='pb-1 pt-3 text-center'>
  <Row container spacing={2}>
        <Col xs={4} >
        {/* <Badge  className='mb-3 ' color="success" max={100} badgeContent={props.data.last_login_at ? DateDiff(props.data.last_login_at)+' ago' : 'NA' }></Badge> */}
        <Badge pill  className='mb-1 ' bg={props.data.last__login_at ?'success':'danger'}>
          {props.data.last_login_at ? DateDiff(props.data.last_login_at)+' ago' : 'NA' }
        </Badge> 
        <h6>Last Login</h6>
        </Col>
        <Col xs={4}>
        <Badge pill  className='mb-1 ' bg={props.data.last_seen ?'warning':'danger'}> {props.data.last_seen ? DateDiff(props.data.last_seen)+' ago' : 'NA' }</Badge>
        <h6>Last Seen</h6>
        </Col>
        <Col  xs={4}>
        <Badge pill className='mb-1' bg={props.data.status===1 ?'info':'danger'}> {props.data.status===1 ? 'Active' : 'InActive'}</Badge>
        <h6>Status</h6>
        </Col>
      </Row>
      </CardContent>
</Card>
  )
}

export default MemberCard
