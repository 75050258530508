import React, { useState, useEffect, isValidElement } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FloatingLabel, Modal } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { Link, useLocation } from "react-router-dom";
import adminhttp from "../../../adminhttp";
import Swal from "sweetalert2";
import Divider from "@mui/material/Divider";
import Spinner from 'react-bootstrap/Spinner';

function AddRole(props) {
  const [validated, setValidated] = useState(false);
  
  const [status, setStatus] = useState("");
  const [position, setPosition] = useState("");

  const location = useLocation();
  const data = location.state;
  const splitURL = location.pathname.toString().split("/");
  const [progress, setProgress] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    pavilion_id: splitURL[3],
    status: "",
    position: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === true) {
      setProgress(true)
      const submitData = new FormData();
      submitData.append("name", formData.name);
      submitData.append("pavilion_id", splitURL[3]);
      submitData.append("status", formData.status);
      submitData.append("position", formData.position);

      if(props.edit===true){
        
        adminhttp()
        .post(`/admin/update_role/${props.roleID}`, submitData)
        .then((response) => {
          Swal.fire({
            title: 'Role updated successfully!',
            icon: "success",
            allowOutsideClick: false,
            confirmButtonColor: "#e30613",
          });
          setProgress(false);
          props.close();
          props.handleRequest();
        })
        .catch((error) => {
          console.error("There was an error submitting the form!", error);
        });

        
      } else {
        
        adminhttp()
        .post("/admin/add_role", submitData)
        .then((response) => {
          Swal.fire({
            title: 'New role added successfully!',
            icon: "success",
            allowOutsideClick: false,
            confirmButtonColor: "#e30613",
          });
          setProgress(false);
          props.close();
          props.handleRequest();
        })
        .catch((error) => {
          console.error("There was an error submitting the form!", error);
        });
      }
      }
  };

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setFormData({ ...formData, logo: files[0] }); // Store file object
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleRequest = () => {
    
    adminhttp()
      .post(`/admin/fetch_single_role?pavilion_id=${splitURL[3]}&role_id=${props.roleID}`)
      .then((data) => {
        if (data.data.data.length > 0) {
          // setSponsor(data.data.data);
          setFormData({
            name: data.data.data[0].name,
            pavilion_id: data.data.data[0].pavilion_id, // Default value
            status: data.data.data[0].status,
            position: data.data.data[0].position,
          });
        }
      });
  };

  useEffect(() => {
    if(props.edit){
      handleRequest();
    } else {
      setFormData({
        name: "",
        pavilion_id: splitURL[3], // Default value
        status: "",
        position: "",
      });
    }
    
  }, [props.edit, props.roleID]);
  return (
    <Modal centered backdrop="static" show={props.show} onHide={props.close}>
      <Modal.Header className="py-1 my-1" closeButton>
      <Modal.Title>{props.edit===true ? 'Edit' : 'Add'} Role</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            id="roleForm"
          >
            <Form.Group controlId="validationCustom01" className="mb-1">
            <Form.Control
                name="name"
                required
                type="text"
                className={Object.values(formData.name).length > 0 ? "is-valid" : "is-invalid"}
                placeholder="Event Category Name"
                value={formData.name && formData.name}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="validationCustom02" className="mb-1">
              <Form.Select
                name="status"
                value={formData.status && formData.status}
                onChange={handleChange}
              >
                <option value="1">Active</option>
                <option value="0">InActive</option>
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="validationCustom03" className="mb-1">
              <Form.Control
                name="position"
                required
                type="text"
                className={formData.position &&
                  (Object.values( formData.position).length > 0 ? "is-valid" : "is-invalid")
                }
                placeholder="Position"
                value={formData.position && formData.position}
                onChange={handleChange}
              />
              {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
            </Form.Group>
          </Form>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.close}>
          Close
        </Button>
        <Button variant="primary" type="submit" form="roleForm">
        {progress && (<Spinner size='sm' className="me-2" animation="border" />)} Save Role
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddRole;
