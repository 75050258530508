import React, { useState, useEffect, isValidElement } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FloatingLabel, Modal } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { Link, useLocation } from "react-router-dom";
import adminhttp from "../../../adminhttp";
import Swal from "sweetalert2";
import Divider from "@mui/material/Divider";
import Spinner from 'react-bootstrap/Spinner';

function AddPubCategory(props) {
  const [validated, setValidated] = useState(false);

  const [catName, setCatName] = useState("");
  const [catDesn, setCatDesn] = useState("");

  const [roleName, setRoleName] = useState("");
  const [status, setStatus] = useState("");
  const [position, setPosition] = useState("");

  const location = useLocation();
  const data = location.state;
  const splitURL = location.pathname.toString().split("/");
  const [progress, setProgress] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    type: "category", // Default value for the dropdown
    status: "1",      // Default value for Active
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === true) {
      setProgress(true)
      const submitData = new FormData();
      submitData.append("pavilion_id", splitURL[3]);
      submitData.append("name", formData.name);
      submitData.append("description", formData.description);
      submitData.append("type", formData.type);
      submitData.append("status", formData.status);

      if (props.edit === true) {
        submitData.append("id", props.pubCatID);
        adminhttp()
          .post(`/admin/updatepublicationcategories`, submitData)
          .then((response) => {
            Swal.fire({
              title: 'Publication Category updated successfully!',
              icon: "success",
              allowOutsideClick: false,
              confirmButtonColor: "#e30613",
            });
            setProgress(false);
            props.close();
            props.handleRequest();
          })
          .catch((error) => {
            console.error("There was an error submitting the form!", error);
          });


      } else {
        submitData.append("pavilion_id", splitURL[3]);
        adminhttp()
          .post("/admin/createpublicationcategories", submitData)
          .then((response) => {
            Swal.fire({
              title: 'Publication Category added successfully!',
              icon: "success",
              allowOutsideClick: false,
              confirmButtonColor: "#e30613",
            });
            setProgress(false);
            props.close();
            props.handleRequest();
          })
          .catch((error) => {
            console.error("There was an error submitting the form!", error);
          });
      }
    }
  };

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setFormData({ ...formData, logo: files[0] }); // Store file object
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  const handleRequest = () => {
    adminhttp().get(`/admin/singlepublicationcategories/${props.pubCatID}`).then((data) => {
      if (data.data.data.PublicationCategory) {
        setFormData({
          name: data.data.data.PublicationCategory.name,
          description: data.data.data.PublicationCategory.description,
          type: data.data.data.PublicationCategory.type || "category", // Default to "category" if not provided
          status: data.data.data.PublicationCategory.status || "1", // Default to Active
        });
      }
    });
  };

  useEffect(() => {
    if (props.edit) {
      handleRequest();
    } else {
      setFormData({
        name: "",
        description: "",
        type: "category",
        status: "1",
      });
    }

  }, [props.edit, props.pubCatID]);
  return (
    <Modal centered backdrop="static" show={props.show} onHide={props.close}>
      <Modal.Header className="py-1 my-1" closeButton>
        <Modal.Title>{props.edit === true ? 'Edit' : 'Add'} Category</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            id="eventCategoryForm"
          >
            <Form.Group controlId="validationCustom01" className="mb-1">
              <Form.Control
                name="name"
                required
                type="text"
                className={formData.name && Object.values(formData.name).length > 0 ? "is-valid" : "is-invalid"}
                placeholder="Category Name"
                value={formData.name && formData.name}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="validationCustom02" className="mb-1">
              <Form.Control
                name='description'
                required
                rows={3}
                as='textarea'
                className={formData.description && Object.values(formData.description).length > 0 ? "is-valid" : "is-invalid"}
                placeholder="Category Description"
                value={formData.description && formData.description}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="validationCustom03" className="mb-1">
              {/*<Form.Label>Type</Form.Label>*/}
              <Form.Select
                name="type"
                value={formData.type}
                onChange={handleChange}
              >
                <option value="category">Category</option>
                <option value="document_type">Document Type</option>
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="validationCustom04" className="mb-1">
              <Form.Label>Status</Form.Label>
              <Form.Select
                name="status"
                value={formData.status}
                onChange={handleChange}
              >
                <option value="1">Active</option>
                <option value="0">Inactive</option>
              </Form.Select>
            </Form.Group>
          </Form>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.close}>
          Close
        </Button>
        <Button variant="primary" type="submit" form="eventCategoryForm">
          {progress && (<Spinner size='sm' className="me-2" animation="border" />)} Save Category
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddPubCategory;

