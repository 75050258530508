import React, { useState, useEffect } from 'react';
import AdminLayout from '../../layouts/AdminLayout';
import { Form, Container, ListGroup, Button } from 'react-bootstrap';
import adminhttp from '../../../adminhttp';
import { Box } from '@mui/material';
// import Button from '@mui/material/Button';
import Swal from 'sweetalert2';
import { Modal, Spinner } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import AddBanner from '../../components/AdminForms/AddBanner';
import BannerCard from '../../components/AdminCards/BannerCard';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Card from "@mui/material/Card";
import { CardMedia } from "@mui/material";
import CRUDDropdown from "../../../components/CRUDDropdown";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Typography from '@mui/material/Typography';
import AdminBreadCrumbs from '../../components/AdminBreadCrumbs';


const Banners = (props) => {
  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);

  const [validated, setValidated] = useState(false);
  const [progress, setProgress] = useState(false);
  const [showModal, setShowModal] = useState(false); // state to control modal visibility
  const location = useLocation();
  const data = location.state;
  const splitURL = location.pathname.toString().split('/');
  const [banners, setBanners] = useState([]);
  const [editBanner, setEditBanner] = useState(null); // State for selected banner to edit


  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to="/admin/dashboard" >
      Dashboard
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      to='/admin/pavilions'
      state={ data}
    >
      Pavilions
    </Link>,
    <Link
    underline="hover"
    key="2"
    color="inherit"
    to={`/admin/pavilions/${splitURL[3]}`}
    state={ data}
  >
    {data.name}
  </Link>,
    <Typography key="3" sx={{ color: 'text.primary' }}>
      Banners
    </Typography>,
  ];

  const [formData, setFormData] = useState({
    pavilion_id: splitURL[3],
    mobile_banner: "",
  });

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setFormData({ ...formData, mobile_banner: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleDropdown = () => {
    //setShow(true)
  };
  const handleRequest = () => {
    adminhttp()
      .get(`/admin/multibanner/${splitURL[3]}`)
      .then((data) => {
        if (data.data.data.mobile_banner.length > 0) {
          setLoading(true)
          setBanners(data.data.data.mobile_banner);
          setLoading(false)
		  // console.log('vvvv'+data.data.data.mobile_banner)
        }else{
          setNoData(true)
          setLoading(false)
        }
      });
  };

  const handleEdit = () => {
    //setShow(true)
  };
  const handleDelete = (banner) => {
    console.log("Editing banner:", banner); // Debug: Check banner data
    // e.stopPropagation();
    Swal.fire({
      title: "Are you sure to delete this Banner?",
      text: "You won't be able to revert this!",
      icon: "warning",
      width: 'auto',
      showCancelButton: true,
      confirmButtonColor: "#e30613",
      cancelButtonColor: "#6c757d",
      confirmButtonText: "Yes, delete it!"
    }).then((result) => {
      if (result.isConfirmed) {
        adminhttp().post('/admin/deletebanner', {
          pavilionid: splitURL[3],
          mobile_banner: banner,
        }).then((data) => {
          handleRequest();
          Swal.fire({
            title: "Your Banner has been deleted.",
            icon: "success",
            allowOutsideClick: false,
            confirmButtonColor: "#e30613",
          });
        }).catch((error) => {
          console.error('Error deleting banner:', error);
          Swal.fire({
            title: "Failed to delete the banner.",
            text: error.message,
            icon: "error",
            confirmButtonColor: "#e30613",
          });
        });

      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === true) {
      setProgress(true);
      const submitData = new FormData();
      submitData.append('pavilion_id', splitURL[3]);
      submitData.append('mobile_banner', formData.mobile_banner);


      // If editing, include the banner's ID to update it
      if (editBanner) {
        submitData.append('id', formData.id);
        submitData.append('oldbannerImage', formData.oldbannerImage);
      }

      // Submit the form using axios or fetch
      adminhttp()
        .post(editBanner ? '/admin/updatemobilebanner' : '/admin/createmultibanner', submitData)
        .then((response) => {
          Swal.fire({
            title: editBanner ? response.data.data.success : response.data.data.success,
            icon: 'success',
            allowOutsideClick: false,
            confirmButtonColor: '#e30613',
          });
          setProgress(false);
          setShowModal(false); // Close modal after successful form submission
          handleRequest(); // Reload banners list
          setEditBanner(null); // Reset edit state
        })
        .catch((error) => {
          console.error('There was an error submitting the form!', error);
        });
    }
  };


  const handleEditClick = (banner) => {
    console.log("Editing banner:", banner); // Debug: Check banner data
    setFormData({
      pavilion_id: splitURL[3], // Set pavilion ID
      id: splitURL[3], // Set the banner's unique ID
      oldbannerImage: banner, // Set the existing banner image name
      mobile_banner: banner, // Set the initial mobile banner value
    });
    setEditBanner(banner); // Set selected banner to edit
    setShowModal(true); // Show the modal
  };




  useEffect(() => {
    handleRequest();
  }, []);

  return (
    <>
      <AdminLayout>
        <Container fluid className='bg-light h-100'>
		<Box component="section" sx={{ px: 2, pt: 2, pb: 1 }}>
          <AdminBreadCrumbs breadcrumbs={breadcrumbs} />
        </Box>
          <Box component="section" className="d-flex align-items-center justify-content-between" sx={{ px: 2, pt: 2, pb: 1 }}>
            <h4>Banners</h4> 
            
            <div className="d-flex justify-content-end align-items-center">
              <Button variant={banners.length>=3 ? 'secondary' : 'primary' } onClick={() => setShowModal(true)}
                 disabled={banners.length>=3} 
                >
                Add Banner
              </Button>
            </div>
            
          </Box>

          <ListGroup variant="flush">
            <ListGroup.Item className='bg-transparent'>
            {loading ? (
      <Box sx={{ flexGrow: 1, textAlign:'center' }}>
      <Spinner size='lg' className='me-2 ' animation='border' />
      </Box>
    ):(
               <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2} columns={12}>
                    {banners.map((banner, index) => (
                      <Grid item xs={1} sm={2} md={4} key={banner.id}>
                        <Card className="rounded-3 " sx={{ ":hover": { boxShadow: 5 } }}>
                          <div
                            className="d-flex justify-content-center align-items-center"
                            style={{ position: "relative" }}
                          >
                            <CardMedia
                              component="img"
                              className="p-3 rounded-3"
                              sx={{ width: "320px", height: "200px", objectFit: "contain" }}
                              image={
                                localStorage.getItem("bucketName") +
                                "/images/pavilion/" + banner
                              }
                            />
                            <div
                              className="text-end p-2 rounded-5"
                              style={{
                                backgroundColor: "#fff",
                                position: "absolute",
                                color: "white",
                                top: 0,
                                right: "0",
                                transform: "translateX(0)",
                              }}
                            >

                              <CRUDDropdown
                                title={<MoreVertIcon fontSize="small" />}
                                handleDropdown={handleDropdown}
                                editTitle="Edit"
                                editClick={() => handleEditClick(banner)} // Corrected here
                                deleteTitle="Delete"
                                deleteClick={() => handleDelete(banner)} // Adjusted to use 'banner' data
                              />



                            </div>
                          </div>
                        </Card>

                      </Grid>
                    ))}

                  </Grid>
                </Box>
              )}
              <Alert variant='outlined' severity="warning" className='mt-3'>
                <medium className='me-4'><strong>&bull; Banner Size:</strong> 320x200px (1x,2x,3x)</medium>
                <medium><strong>&bull; No. of Banners:</strong> Maximum 3 banners allowed</medium>
              </Alert>
              {noData && (
                <Box sx={{ flexGrow: 1 }} className='d-flex justify-content-center'>
                  <Alert variant='outlined' severity="error">Banners not found</Alert>
                </Box>
              )}
            </ListGroup.Item>
          </ListGroup>

          <Modal centered backdrop="static" show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header className="py-1 my-1" closeButton>
              <Modal.Title>{editBanner ? "Edit Banner" : "Add Banner"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container fluid>
                <Form noValidate validated={validated} onSubmit={handleSubmit} id="bannerForm">
                  <Form.Group controlId="validationCustom04" className="mb-3">
                    <Form.Label htmlFor='mobile_banner'>Banner Size: 320x200px (1x,2x,3x)</Form.Label> 
                    <Form.Control
                      type="file"
                      required
                      className={formData.mobile_banner ? "is-valid" : "is-invalid"}
                      name="mobile_banner"
                      onChange={handleChange}
                      defaultValue={editBanner ? editBanner.mobile_banner : ""}
                    />
                  </Form.Group>
                </Form>
              </Container>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                Close
              </Button>
              <Button variant="primary" type="submit" form="bannerForm">
                {progress && <Spinner size="sm" className="me-2" animation="border" />}
                {editBanner ? "Save Changes" : "Save Banner"}
              </Button>
            </Modal.Footer>
          </Modal>

        </Container>
      </AdminLayout>
    </>
  );
};

export default Banners;
